import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { listarPaginandoLogs, totalLogs } from "../../api/logs";
import { withRouter } from "../../utils/withRouter";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Load } from "../load/load";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function TblLogs(props) {
    const { location, history } = props;

    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }, []);

    const [listarOrdenes, setListOrdenes] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0); // Cambié a 0 para coincidir con la paginación basada en cero de MUI
    const [noTotalLogs, setNoTotalLogs] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [contentModal, setContentModal] = useState(null);
    const [titulosModal, setTitulosModal] = useState(null);

    const theme = useTheme();
    const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const fetchLogs = async () => {
            //setLoading(true);
            try {
                const totalResponse = await totalLogs();
                setNoTotalLogs(totalResponse.data);

                const logsResponse = await listarPaginandoLogs(page + 1, rowsPerPage); // Se agrega 1 para que coincida con el índice de página de la API
                setListOrdenes(formatModelOrdenes(logsResponse.data));
            } catch (e) {
                console.error(e);
                // Manejar errores aquí
            } finally {
                //setLoading(false);
            }
        };

        fetchLogs();
    }, [location, page, rowsPerPage]);

    const handleChangePage = (page) => {
        setPage(page);
    };

    const handleChangeRowsPerPage = (newPerPage) => {
        setRowsPerPage(newPerPage);
        setPage(0); // Reinicia la página a la primera al cambiar el número de filas por página
    };

    const columns = [
        { name: "id", label: "ID", options: { display: "excluded" } },
        { name: "usuario", label: "USUARIO" },
        { name: "correo", label: "EMAIL" },
        { name: "dispositivo", label: "DISPOSITIVO" },
        { name: "ip", label: "IP" },
        { name: "mensaje", label: "ACCIÓN REALIZADA" },
        // Descomentar y manejar acciones si es necesario
        // {
        //     name: "ACCIONES",
        //     options: {
        //         filter: false,
        //         sort: false,
        //         empty: true,
        //         customBodyRender: (value, tableMeta, updateValue) => (
        //             <Dropdown>
        //                 <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
        //                     <FontAwesomeIcon icon={faBars} />
        //                 </Dropdown.Toggle>
        //                 <Dropdown.Menu>
        //                     <Dropdown.Item onClick={() => modificarUsuarios(<ModificarUsuarios history={history} setShow={setShowModal} data={tableMeta.rowData} />)}>
        //                         <FontAwesomeIcon icon={faPen} style={{ color: "#ffc107" }} /> &nbsp; Modificar
        //                     </Dropdown.Item>
        //                     <Dropdown.Item onClick={() => eliminarUsuarios(<EliminarUsuarios history={history} setShow={setShowModal} data={tableMeta.rowData} />)}>
        //                         <FontAwesomeIcon icon={faTrash} style={{ color: "#dc3545" }} /> &nbsp; Eliminar
        //                     </Dropdown.Item>
        //                 </Dropdown.Menu>
        //             </Dropdown>
        //         ),
        //     },
        // },
    ];

    const options = {
        filterType: "checkbox",
        responsive: isSmOrDown ? 'standard' : 'scroll',
        selectableRows: isSmOrDown ? 'none' : 'multiple',
        pagination: true,
        serverSide: true,
        onChangeRowsPerPage: handleChangeRowsPerPage,
        onChangePage: handleChangePage,
        count: noTotalLogs,
        textLabels: {
            body: {
                noMatch: "No se encontraron registros",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Registros por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtros",
            },
            filter: {
                all: "Todos",
                title: "FILTROS",
                reset: "RESTAURAR",
            },
            viewColumns: {
                title: "Mostrar columnas",
                titleAria: "Show/Hide Table Columns",
            },
            selectedRows: {
                text: "row(s) selected",
                delete: "Delete",
                deleteAria: "Delete Selected Rows",
            },
        },
    };

    return (
        <>
            {loading && <Load />}
            <h1>Logs del sistema</h1>
            <div className="divTabla">
                <MUIDataTable
                    title={"Listado de usuarios registrados"}
                    data={listarOrdenes}
                    columns={columns}
                    options={options}
                />
            </div>
            <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
                {contentModal}
            </BasicModal>
        </>
    );
}

function formatModelOrdenes(data) {
    return data.map(item => ({
        id: item._id,
        folio: item.folio,
        usuario: item.usuario,
        correo: item.correo,
        dispositivo: item.dispositivo,
        ip: item.ip,
        descripcion: item.descripcion,
        mensaje: item.detalles.mensaje,
    }));
}

export default withRouter(TblLogs);
