import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faPlus, faBars } from "@fortawesome/free-solid-svg-icons";
import {
  listarEstablecimientos,
  listarEstablecimientosPorCorreo,
} from "../../../api/establecimientos";
import { obtenerDoctoresPorCorreo } from "../../../api/doctores";
//import EliminarAlmacenLP from "./eliminar";
import { Button, Dropdown, Badge } from "react-bootstrap";
import { withRouter } from "../../../utils/withRouter";
import EliminarCliente from "./Eliminar";
import ModificarCliente from "./Modificar";
import AltaCliente from "./AltaCliente";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import { Load } from "../../load/load";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import { obtenerUsuario } from "../../../api/usuarios";
import { toast } from "react-toastify";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


function TblEstablecimientos(props) {
  const { location, history } = props;

  const [tipoUsuario, setTipoUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");
  const [correoDoctor, setCorreoDoctor] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const obtenerCorreoDoctor = () => {
    try {
      obtenerDoctoresPorCorreo(correoUsuario)
        .then((response) => {
          const { data } = response;
          setCorreoDoctor(data.correoConsultorio);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerCorreoDoctor();
  }, [tipoUsuario]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarEstablecimiento, setListEstablecimiento] = useState([]);

  const obtenerEstablecimiento = () => {
    if (tipoUsuario == "administrador") {
      try {
        listarEstablecimientos()
          .then((response) => {
            const { data } = response;

            if (!listarEstablecimiento && data) {
              setListEstablecimiento(formatModelEstablecimiento(data));
            } else {
              const datosEstablecimiento = formatModelEstablecimiento(data);
              setListEstablecimiento(datosEstablecimiento);
            }
          })
          .catch((e) => { });
      } catch (e) { }
    } else if (tipoUsuario == "consultorio") {
      try {
        listarEstablecimientosPorCorreo(correoUsuario)
          .then((response) => {
            const { data } = response;

            if (!listarEstablecimiento && data) {
              setListEstablecimiento(formatModelEstablecimiento(data));
            } else {
              const datosEstablecimiento = formatModelEstablecimiento(data);
              setListEstablecimiento(datosEstablecimiento);
            }
          })
          .catch((e) => { });
      } catch (e) { }
    } else if (tipoUsuario == "doctor") {
      try {
        listarEstablecimientosPorCorreo(correoDoctor)
          .then((response) => {
            const { data } = response;

            if (!listarEstablecimiento && data) {
              setListEstablecimiento(formatModelEstablecimiento(data));
            } else {
              const datosEstablecimiento = formatModelEstablecimiento(data);
              setListEstablecimiento(datosEstablecimiento);
            }
          })
          .catch((e) => { });
      } catch (e) { }
    }
  };

  useEffect(() => {
    obtenerEstablecimiento();
  }, [location, correoUsuario]);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarEstablecimiento);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);


  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroCliente = (content) => {
    setTitulosModal("Registrar establecimiento");
    setContentModal(content);
    setShowModal(true);
  };

  const modificarCliente = (content) => {
    setTitulosModal("Modificar establecimiento");
    setContentModal(content);
    setShowModal(true);
  };

  const eliminarCliente = (content) => {
    setTitulosModal("Eliminar establecimiento");
    setContentModal(content);
    setShowModal(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "folio",
      label: "FOLIO",
    },
    {
      name: "razonSocial",
      label: "RAZON SOCIAL",
    },
    {
      name: "estadoEstablecimiento",
      label: "ESTADO",
    },
    {
      name: "municipio",
      label: "MUNICIPIO",
    },
    {
      name: "colonia",
      label: "COLONIA",
    },
    {
      name: "calle",
      label: "CALLE",
    },
    {
      name: "numeroExterior",
      label: "NUMERO EXTERIOR",
    },
    {
      name: "numeroInterior",
      label: "NUMERO INTERIOR",
    },
    {
      name: "codigoPostal",
      label: "CODIGO POSTAL",
    },
    {
      name: "telefono",
      label: "TELEFONO",
    },
    {
      name: "haceEsquina",
      label: "HACE ESQUINA",
    },
    {
      name: "email",
      label: "EMAIL",
    },
    {
      name: "horario",
      label: "HORARIO",
    },
    {
      name: "observaciones",
      label: "OBSERVACIONES",
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "true") {
            estiloTexto = "Activo";
            estadoTexto = "Activo";
          } else {
            estiloTexto = "Inhabilitado";
            estadoTexto = "Inhabilitado";
          }

          return <>
            <Badge
              bg={estado == "true" ? "success" : "danger"}
              //className="estado"
            >
              {estiloTexto}
            </Badge>
          </>;
        },
      },
    },
    {
      name: "alias",
      label: "ALIAS",
    },
    {
      name: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tipoUsuario != "consultorio" ? (
            <>
              <Dropdown>
                <Dropdown.Toggle
                  className="botonDropdown"
                  id="dropdown-basic"
                >
                  <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      modificarCliente(
                        <ModificarCliente
                          history={history}
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faPen}
                      style={{ color: "#ffc107" }}
                    />
                    &nbsp; Modificar
                  </Dropdown.Item>
                  {tipoUsuario != "doctor" && (
                    <Dropdown.Item
                      onClick={() =>
                        eliminarCliente(
                          <EliminarCliente
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{ color: "#dc3545" }}
                      />
                      &nbsp; Eliminar
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            "No disponibles"
          );
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && 'standard',
    selectableRows: isSmOrDown ? 'none' : 'multiple',
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      {loading && <Load />}
      <h1>Consultorios</h1>
      {tipoUsuario != "consultorio" && (
        <>
          <div>
            <div className="divButton">
              <Button
                className="btnAddTables"
                onClick={() =>
                  registroCliente(
                    <AltaCliente history={history} setShow={setShowModal} />
                  )
                }
              >
                <FontAwesomeIcon icon={faPlus} /> Agregar
              </Button>
            </div>
          </div>
        </>
      )}
      <div className="divTabla">
        <MUIDataTable
          title={"Lista de consultorios registrados"}
          data={listarEstablecimiento}
          columns={columns}
          options={options}
        />
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function formatModelEstablecimiento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      razonSocial: data.razonSocial,
      estadoEstablecimiento: data.estadoEstablecimiento,
      municipio: data.municipio,
      colonia: data.colonia,
      calle: data.calle,
      numeroExterior: data.numeroExterior,
      numeroInterior: data.numeroInterior,
      codigoPostal: data.codigoPostal,
      telefono: data.telefono,
      haceEsquina: data.haceEsquina,
      email: data.email,
      observaciones: data.observaciones,
      horario: data.horario,
      estado: data.estado,
      alias: data.alias,
      doctor: data.doctor,
      rfcDoctor: data.rfcDoctor,
    });
  });
  return dataTemp;
}

export default withRouter(TblEstablecimientos);
