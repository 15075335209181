import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faPlus,
  faBars,
  faCarOn,
  faCheckCircle,
  faScrewdriverWrench,
  faArrowRight,
  faCarTunnel,
} from "@fortawesome/free-solid-svg-icons";
import {
  actualizaDeshabilitarVehiculos,
  actualizarVehiculosKm,
  listarVehiculos,
} from "../../../api/vehiculos";
import { Button, Dropdown, Badge, Image, Modal, ProgressBar } from "react-bootstrap";
import { withRouter } from "../../../utils/withRouter";
import { useNavigate } from "react-router-dom";
import EliminarVehiculo from "./Eliminar";
import ModificarVehiculo from "./Modificar";
import Vehiculos from "./Vehiculos";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import { Load } from "../../load/load";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function TblVehiculos(props) {
  const { location, history } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarVehiculo, setListVehiculo] = useState([]);

  const obtenerVehiculo = () => {
    try {
      listarVehiculos()
        .then((response) => {
          const { data } = response;

          if (!listarVehiculo && data) {
            setListVehiculo(formatModelVehiculos(data));
          } else {
            const datosVehiculos = formatModelVehiculos(data);
            setListVehiculo(datosVehiculos);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerVehiculo();
  }, [location]);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarVehiculo);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [idVehiculoFila, setIdVehiculoFila] = useState(null);
  const [modeloVehiculo, setModeloVehiculo] = useState(null);
  const [estadoVehiculo, setEstadoVehiculo] = useState(null);

  const handleShow = (value) => {
    setIdVehiculoFila(value);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleShow2 = (value, value2) => {
    setIdVehiculoFila(value);
    setEstadoVehiculo(value2);
    setShow2(true);
  };

  const handleClose2 = () => {
    setShow2(false);
  };

  const filtrarVehiculosId = () => {
    const vehiculoConNumeroEconomico1 = listarVehiculo.filter(
      (vehiculo) => vehiculo.id === idVehiculoFila
    );

    if (vehiculoConNumeroEconomico1.length > 0) {
      const primerVehiculoConNumeroEconomico1 = vehiculoConNumeroEconomico1[0];
      setModeloVehiculo(primerVehiculoConNumeroEconomico1.modelo);
    } else {
      console.log("No se encontró ningún vehículo con id ", idVehiculoFila);
    }
  };

  useEffect(() => {
    filtrarVehiculosId();
  }, [idVehiculoFila]);

  const actualizaVehiculoServicio = () => {
    try {
      const dataTemp = {
        kilometrajeRecorrido: 0,
      };

      actualizarVehiculosKm(idVehiculoFila, dataTemp).then((response) => {
        const { data } = response;
        toast.success(data.mensaje);
        deshabilitarVehiculo();
        setIdVehiculoFila(null);
        setModeloVehiculo(null);
        setEstadoVehiculo(null);
        obtenerVehiculo();
        handleClose();
      });
    } catch (e) {
      console.log(e);
    }
  };

  const deshabilitarVehiculo = () => {
    try {
      const dataTemp = {
        estado:
          estadoVehiculo == "true"
            ? "Mantenimiento"
            : "true"
      };

      actualizaDeshabilitarVehiculos(idVehiculoFila, dataTemp).then(
        (response) => {
          const { data } = response;
          console.log(data);
          toast.success(data.mensaje);
          obtenerVehiculo();
          handleClose2();
          setIdVehiculoFila(null);
          setModeloVehiculo(null);
          setEstadoVehiculo(null);
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroVehiculo = (content) => {
    setTitulosModal("Registrar vehiculo");
    setContentModal(content);
    setShowModal(true);
  };

  const modificarVehiculo = (content) => {
    setTitulosModal("Modificar vehiculo");
    setContentModal(content);
    setShowModal(true);
  };

  const eliminarVehiculo = (content) => {
    setTitulosModal("Eliminar vehiculo");
    setContentModal(content);
    setShowModal(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "modelo",
      label: "MODELO",
    },
    {
      name: "placa",
      label: "PLACAS",
    },
    {
      name: "numEconomico",
      label: "# ECONOMICO",
    },
    {
      name: "color",
      label: "COLOR",
    },
    {
      name: "kilometrajeActual",
      label: "KILOMETRAJE ACTUAL",
    },
    {
      name: "kilometrajeRecorrido",
      label: "SERVICIO",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const kilometraje = parseFloat(value);
          const now = (kilometraje * 100) / 10000;
          return (
            <>
              <ProgressBar
                variant={
                  now >= 100
                    ? "danger"
                    : now >= 80
                    ? "warning"
                    : now < 80 && "success"
                }
                now={now}
              />
              {now >= 100 && (
                <>
                  <label
                    style={{
                      color: "red",
                      fontWeight: "normal",
                      fontSize: "x-small",
                    }}
                  >
                    {tableMeta.rowData[8] == "Mantenimiento" ? "El vehiculo está en servicio" : "El vehiculo necesita servicio"}
                  </label>
                  <div>
                    {tableMeta.rowData[8] == "Mantenimiento" ? (
                      <Button
                        variant="success"
                        onClick={() => handleShow(tableMeta.rowData[0])}
                      >
                        <FontAwesomeIcon icon={faCarOn} />
                      </Button>
                    ) : (
                      <Button
                        variant="warning"
                        onClick={() =>
                          handleShow2(
                            tableMeta.rowData[0],
                            tableMeta.rowData[8]
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faScrewdriverWrench} />
                        &nbsp;
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Button>
                    )}
                  </div>
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title style={{ textTransform: "capitalize" }}>
                        {modeloVehiculo}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                      style={{ fontSize: "large", fontWeight: "bold" }}
                    >
                      ¿Se ha dado servicio al vehiculo?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="success"
                        onClick={actualizaVehiculoServicio}
                      >
                        <FontAwesomeIcon icon={faCheckCircle} />
                        &nbsp;Si
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal show={show2} onHide={handleClose2}>
                    <Modal.Header closeButton>
                      <Modal.Title style={{ textTransform: "capitalize" }}>
                        {modeloVehiculo}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                      style={{ fontSize: "large", fontWeight: "bold" }}
                    >
                      ¿Enviar a servicio el vehiculo?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="warning" onClick={deshabilitarVehiculo}>
                        <FontAwesomeIcon icon={faCarTunnel} />
                        &nbsp;Si
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              )}
            </>
          );
        },
      },
    },
    {
      name: "imagen",
      label: "IMAGEN",
      options: {
        customBodyRender: (value) => {
          const imagen = value;

          return imagen != "Sin Imagen" ? (
            <Image
              src={imagen}
              alt={`Imagen de ${imagen}`}
              style={{ maxWidth: "100px" }}
            />
          ) : (
            <p>No hay imagen del articulo</p>
          );
        },
      },
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "true") {
            estiloTexto = "Activo";
            estadoTexto = "Activo";
          } else if (estado == "Mantenimiento") {
            estiloTexto = "Mantenimiento";
            estadoTexto = "Mantenimiento";
          } else {
            estiloTexto = "Inhabilitado";
            estadoTexto = "Inhabilitado";
          }

          return (
            <>
              <Badge
                bg={estado == "true" ? "success" : "danger"}
                //className="estado"
              >
                {estiloTexto}
              </Badge>
            </>
          );
        },
      },
    },
    {
      name: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Dropdown>
                <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                  <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      modificarVehiculo(
                        <ModificarVehiculo
                          history={history}
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faPen}
                      style={{ color: "#ffc107" }}
                    />
                    &nbsp; Modificar
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      eliminarVehiculo(
                        <EliminarVehiculo
                          history={history}
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{ color: "#dc3545" }}
                    />
                    &nbsp; Eliminar
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          );
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && 'standard',
    selectableRows: isSmOrDown ? 'none' : 'multiple',
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      {loading && <Load />}
      <h1>Vehiculos</h1>
      <div>
        <div className="divButton">
          <Button
            className="btnAddTables"
            onClick={() =>
              registroVehiculo(
                <Vehiculos history={history} setShow={setShowModal} />
              )
            }
          >
            <FontAwesomeIcon icon={faPlus} /> Agregar
          </Button>
        </div>
      </div>
      <div className="divTabla">
        <MUIDataTable
          title={"Lista vehiculos registrados"}
          data={listarVehiculo}
          columns={columns}
          options={options}
        />
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function formatModelVehiculos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      modelo: data.modelo,
      placa: data.placa,
      numEconomico: data.numEconomico,
      color: data.color,
      kilometrajeActual: data.kilometrajeActual,
      kilometrajeRecorrido: data.kilometrajeRecorrido,
      imagen: !data.imagen ? "Sin Imagen" : data.imagen,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default withRouter(TblVehiculos);
