import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faRemoveFormat, faBars } from "@fortawesome/free-solid-svg-icons";
import { listarOrdenesServicio } from "../../api/ordenesServicio";
import { Button, Badge, Dropdown } from "react-bootstrap";
import { withRouter } from "../../utils/withRouter";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Load } from "../load/load";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import { toast } from "react-toastify";
import "./style.css";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AsignacionRuta from "./AsignacionRutas";
import DeshacerActualizacion from "./DeshacerActualizacion";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

function TblRutasAsignadas(props) {
  const { location, history } = props;

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const [loading, setLoading] = useState(true);

  const [tipoUsuario, setTipoUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarOrdenes, setListOrdenes] = useState([]);

  const obtenerOrdenesServicio = () => {
    try {
      listarOrdenesServicio()
        .then((response) => {
          const { data } = response;
          const filteredData = data.filter(order => order.estado === "2" || order.estado === "7");
          setListOrdenes(formatModelOrdenes(filteredData));
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerOrdenesServicio();
  }, [location]);

  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarOrdenes);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, [listarOrdenes]);

  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const asignarOrdenes = (content) => {
    setTitulosModal("Asignar una ruta");
    setContentModal(content);
    setShowModal(true);
  };

  const deshacerAsignacion = (content) => {
    setTitulosModal("Deshacer asignacion");
    setContentModal(content);
    setShowModal(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded",
      },
    },
    {
      name: "folio",
      label: "FOLIO",
    },
    {
      name: "fecha",
      label: "FECHA DE ALTA",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
            {dayjs(value).format("LL")}
            </>
          );
        },
      },
    },
    {
      name: "direccionConsultorio",
      label: "RUTA",
    },
    {
      name: "nombreDoctor",
      label: "NOMBRE DEL DOCTOR",
    },
    {
      name: "nombrePaciente",
      label: "NOMBRE DEL PACIENTE",
    },
    {
      name: "tipoDentadura",
      label: "TIPO DE DENTADURA",
      options: {
        customBodyRender: (value) => {
          const dentadura = value;
          let estiloTexto = "";
          let estadoTexto = "";
          if (dentadura == "DP") {
            estiloTexto = "Permanente";
            estadoTexto = "Permanente";
          } else {
            estiloTexto = "Temporal";
            estadoTexto = "Temporal";
          }
          return <div className={estiloTexto}>{estadoTexto}</div>;
        },
      },
    },
    {
      name: "recibio",
      label: "RECIBIO MATERIAL",
    },
    {
      name: "fechaEntrada",
      label: "FECHA DE RECEPCION",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
            {dayjs(value).format("LL")}
            </>
          );
        },
      },
    },
    {
      name: "correoDoctor",
      label: "EMAIL DOCTOR",
      options: {
        display: "excluded",
      },
    },
    {
      name: "correoConsultorio",
      label: "CORREO CONSULTORIO",
      options: {
        display: "excluded",
      },
    },
    {
      name: "estado",
      label: "ESTADO DEL PEDIDO",
      options: {
        customBodyRender: (value) => {
          const estado = value;
          let estiloTexto = "";
          let estadoTexto = "";
          if (estado == "1") {
            estiloTexto = "Orden nueva";
            estadoTexto = "Orden nueva";
          } else if (estado == "2") {
            estiloTexto = "Recoleccion asignada";
            estadoTexto = "Recoleccion asignada";
          } else if (estado == "3") {
            estiloTexto = "Orden entregada";
            estadoTexto = "Orden entregada";
          } else if (estado == "4") {
            estiloTexto = "Fabricando";
            estadoTexto = "Fabricando";
          } else if (estado == "5") {
            estiloTexto = "Orden finalizada";
            estadoTexto = "Orden finalizada";
          } else if (estado == "0") {
            estiloTexto = "Orden cancelada";
            estadoTexto = "Orden cancelada";
          } else if (estado == "6") {
            estiloTexto = "En proceso";
            estadoTexto = "En proceso";
          } else if (estado == "7") {
            estiloTexto = "En ruta de entrega";
            estadoTexto = "En ruta de entrega";
          } else if (estado == "8") {
            estiloTexto = "Entregado";
            estadoTexto = "Entregado";
          } else if (estado == "9") {
            estiloTexto = "Retrabajo";
            estadoTexto = "Retrabajo";
          }
          return (
            <>
              <Badge
                bg={
                  estado == "1"
                    ? "info"
                    : estado == "2"
                      ? "warning"
                      : estado == "3"
                        ? "primary"
                        : estado == "4"
                          ? "secondary"
                          : estado == "5"
                            ? "dark"
                            : estado == "6"
                              ? "primary"
                              : estado == "7"
                                ? "light"
                                : estado == "8"
                                  ? "success"
                                  : estado == "9" ?
                                    "secondary"
                                    : "danger"
                }
              >
                {estiloTexto}
              </Badge>
            </>
          );
        },
      },
    },
    {
      name: "telefonoDoctor",
      label: "TELEFONO DOCTOR",
    },
    {
      name: "cancelacion",
      label: "CANCELACION",
      options: {
        display: "excluded",
      },
    },
    {
      name: "cargo",
      label: "CARGO",
      options: {
        display: "excluded",
      },
    },
    {
      name: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Dropdown>
                <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                  <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      deshacerAsignacion(
                        <DeshacerActualizacion
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                          history={history}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faRemoveFormat}
                      style={{ color: "#28a745" }}
                    />
                    &nbsp; Deshacer la asignacion
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      <div>
        <div>
          {loading && <Load />}
          <h1>Ordenes de cliente asignadas</h1>
          <div>
            <div className="divButton">
              <Button
                className="btnAddTables"
                onClick={() =>
                  asignarOrdenes(
                    <AsignacionRuta history={history} setShow={setShowModal} />
                  )
                }
              >
                <FontAwesomeIcon icon={faPlus} /> Asignar
              </Button>
            </div>
          </div>
          <div className="divTabla">
            <MUIDataTable
              title={"Lista de ordenes de cliente asignadas"}
              data={listarOrdenes}
              columns={columns}
              options={options}
            />
          </div>
          <BasicModal
            show={showModal}
            setShow={setShowModal}
            title={titulosModal}
          >
            {contentModal}
          </BasicModal>
        </div>
      </div>
    </>
  );
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      fecha: data.fecha,
      localidad: data.localidad,
      nombreDoctor: data.nombreDoctor,
      nombrePaciente: data.nombrePaciente,
      tipoDentadura: data.tipoDentadura,
      servicios: data.servicios,
      otros: data.otros,
      recibio: data.recibio,
      fechaEntrada: data.fechaEntrada,
      correoDoctor: data.correoDoctor,
      correoConsultorio: data.correoConsultorio,
      estado: data.estado,
      telefonoDoctor: data.telefonoDoctor,
      cancelacion: data.cancelacion,
      cargo: data.cargoOrden,
      direccionConsultorio: data?.direccionConsultorio?.municipio,
    });
  });
  return dataTemp;
}

export default withRouter(TblRutasAsignadas);
