import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faFileExcel, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { obtenerOrdenesServicio } from "../../api/ordenesServicio";
import { listarServiciosFolio } from "../../api/servicios";
import { obtenerDoctoresPorCorreo } from "../../api/doctores";
import { toast } from "react-toastify";
import { facturacion, consultarArchivos } from "../../api/facturas";
import { actualizaEstadoFacturasMostrador } from "../../api/facturasMostrador";
import queryString from "query-string";

const XMLComponent = ({ id, pdf, xml, factura, history }) => {
  console.log(factura)

  const data = factura;

  const [datos, setDatos] = useState();

  const obtenerDatosOrden = () => {
    try {
      obtenerOrdenesServicio(id)
        .then((response) => {
          const { data } = response;
          console.log(data)
          setDatos(data);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosOrden();
  }, []);

  const [doctores, setDoctores] = useState();

  const obtenerDoctores = () => {
    try {
      obtenerDoctoresPorCorreo(datos?.correoDoctor)
        .then((response) => {
          const { data } = response;
          console.log(data)
          setDoctores(data);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDoctores();
  }, [datos]);

  console.log(doctores)

  const [listarServicios, setListServicios] = useState([]);

  const obtenerServcios = () => {
    try {
      listarServiciosFolio(datos?.folio)
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (!listarServicios && data) {
            setListServicios(formatModelServicios(data));
          } else {
            const datosServicios = formatModelServicios(data);
            setListServicios(datosServicios);
          }
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerServcios();
  }, [datos?.folio]);

  function limpiarYConvertirMayusculas(nombre) {
    // Eliminar espacios adicionales entre palabras y convertir a mayúsculas
    return nombre.trim().replace(/\s+/g, ' ').toUpperCase();
  }

  const xmlConceptos = data[15].map((servicio, index) => `
    <cfdi:Concepto 
        NoIdentificacion="${servicio?.id}" 
        Cantidad="${servicio?.diente.length}" 
        Unidad="PIEZA" 
        Descripcion="${servicio?.descripcionAdicional}" 
        ValorUnitario="${(Number(servicio?.precio) / (servicio?.diente.length))}" 
        Importe="${Number(servicio?.precio).toFixed(2)}" 
        ClaveProdServ="42151500"
        ClaveUnidad="H87" 
        ObjetoImp="02" 
    >
      <cfdi:Impuestos>
        <cfdi:Traslados>
          <cfdi:Traslado 
            Base="${Number(servicio?.precio)}" 
            Impuesto="002" 
            TipoFactor="Tasa" 
            TasaOCuota="0.160000" 
            Importe="${(Number(servicio?.precio) * 0.16).toFixed(6)}"
          />
        </cfdi:Traslados>
      </cfdi:Impuestos>
    </cfdi:Concepto>
`).join('');


  const xmlString = `
    <cfdi:Comprobante xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:cfdi="http://www.sat.gob.mx/cfd/4"
        xsi:schemaLocation="http://www.sat.gob.mx/cfd/4 http://www.sat.gob.mx/sitio_internet/cfd/4/cfdv40.xsd"
        Version="4.0" Fecha="${data[10]}" Serie="FA" Folio="FA${data[1]?.split("-")[1]}" LugarExpedicion="76804"
        Moneda="${data[8]}" SubTotal="${data[11]}" Total="${Number(data[11]) + (Number(data[11]) * 0.16)}" TipoDeComprobante="I" Exportacion="01" 
        TipoCambio="${data[7]}" MetodoPago="PUE" FormaPago="${data[9]}">
        <cfdi:Emisor Rfc="HCD230213587" Nombre="HERFRAN CORPORATIVO DENTAL" RegimenFiscal="601" />
        <cfdi:Receptor Rfc="${data[3]}" Nombre="${limpiarYConvertirMayusculas(data[2])}" UsoCFDI="${data[6]}" DomicilioFiscalReceptor="${data[4]}" RegimenFiscalReceptor="${data[5]}"/>
        <cfdi:Conceptos>
          ${xmlConceptos}
        </cfdi:Conceptos>
        <cfdi:Impuestos TotalImpuestosTrasladados="${(Number(data[11]) * 0.16)}">
            <cfdi:Traslados>
                <cfdi:Traslado Base="${data[11]}" Impuesto="002" TipoFactor="Tasa" 
                    TasaOCuota="0.160000" Importe="${(Number(data[11]) * 0.16)}" />
            </cfdi:Traslados>
        </cfdi:Impuestos>
        <cfdi:Complemento>
            <tfd:TimbreFiscalDigital xmlns:tfd="http://www.sat.gob.mx/TimbreFiscalDigital"
                xsi:schemaLocation="http://www.sat.gob.mx/TimbreFiscalDigital http://www.sat.gob.mx/sitio_internet/cfd/TimbreFiscalDigital/TimbreFiscalDigitalv11.xsd"
                Version="1.1" UUID="" FechaTimbrado="" RfcProvCertif="" SelloCFD="" NoCertificadoSAT="" SelloSAT="" />
        </cfdi:Complemento>
    </cfdi:Comprobante>
  `;

  // Función para convertir a Base64 soportando caracteres Unicode
  const encodeToBase64 = (str) => {
    return btoa(unescape(encodeURIComponent(str)));
  };

  const sendXMLToSoapService = async () => {
    try {
      const xmlBase64 = encodeToBase64(xmlString); // Convertir el XML a Base64

      // Enviar el Base64 al servicio SOAP
      const response = await facturacion(xmlBase64, "FA" + data[1]?.split("-")[1]);
      toast.success(response.data.mensaje);
      if (response.data.archivoXml && response.data.archivoPdf) {
        const dataTemp = {
          estado: "1",
        };

        await actualizaEstadoFacturasMostrador(factura[0], dataTemp);
        history({
          search: queryString.stringify(""),
        });
      }
      // Descargar el XML
      //downloadXML(xmlString);
    } catch (error) {
      // Manejo de errores
      toast.error("Error al enviar el XML");
      console.log(error);
    }
  };

  async function downloadFileFromBase64(folio, extension) {
    try {
      // Realiza la solicitud a la API para obtener el archivo en Base64
      const response = await consultarArchivos(folio, extension);

      if (response.data.success) {
        const base64String = response.data.file; // Obtiene la cadena en Base64
        const fileName = folio + '.' + extension; // Nombre del archivo

        // Decodifica el string base64
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        // Convierte los números de bytes en un array de bytes
        const byteArray = new Uint8Array(byteNumbers);

        // Crea un blob con el array de bytes y el tipo MIME correspondiente
        const blob = new Blob([byteArray], { type: extension === 'pdf' ? 'application/pdf' : 'application/xml' });

        // Crea un enlace para descargar el archivo
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;

        // Simula un clic en el enlace para iniciar la descarga
        link.click();
      } else {
        console.error('Error al obtener el archivo:', response.data.error);
      }
    } catch (error) {
      console.error('Error al hacer la solicitud:', error.message);
    }
  }

  // Ejemplo de uso para PDF
  function downloadPDF(folio) {
    downloadFileFromBase64(folio, 'pdf'); // Llama a la función para PDF
  }

  // Ejemplo de uso para XML
  function downloadXML(folio) {
    downloadFileFromBase64(folio, 'xml'); // Llama a la función para XML
  }

  return (
    <Dropdown>
      <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
        <FontAwesomeIcon icon={faBars} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {data[18] == "0" ? (
          <>
            <Dropdown.Item onClick={sendXMLToSoapService}>
              <FontAwesomeIcon
                icon={faFileExcel}
                style={{ color: "#ffc107" }}
              />
              &nbsp; Facturar
            </Dropdown.Item>
          </>
        ) : (
          <>
            <Dropdown.Item onClick={() => downloadXML("FA" + data[1]?.split("-")[1])}>
              <FontAwesomeIcon
                icon={faFileExcel}
                style={{ color: "#3B83BD" }}
              />
              &nbsp; Descargar XML
            </Dropdown.Item>
            <Dropdown.Item onClick={() => downloadPDF("FA" + data[1]?.split("-")[1])}>
              <FontAwesomeIcon
                icon={faFilePdf}
                style={{ color: "#FF0000" }}
              />
              &nbsp; Descargar PDF
            </Dropdown.Item>

          </>
        )
        }
      </Dropdown.Menu>
    </Dropdown>
  );
};

function formatModelServicios(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      folioODS: data.ODT,
      fecha: data.fechaHora,
      pieza: data.pieza,
      sistemaColor: data.sistemaColor,
      tipoDentadura: data.distencion,
      tonos:
        data.tonoInferior + ", " + data.tonoMedio + ", " + data.tonoSuperior,
      dientes: data.diente,
      departamento: data.departamento,
      imagenDiente: data.imagenDiente,
      imagenEsquema: data.imagenEsquema,
      prioridad: data.prioridad,
      estado: data.estado,
      direccionConsultorio: data.municipio,
      dia: data.dia,
      numeroProceso: data.numeroProceso,
      nombreProceso: data.nombreProceso,
      motivoPausa: data.motivoPausa,
      imagenResultadoFinal: !data.imagenResultadoFinal ? "Sin Imagen" : data.imagenResultadoFinal,
      garantia: !data.garantia ? "1" : data.garantia,
      descripcionAdicional: data.descripcionAdicional,
      precio: data.precio
    });
  });
  return dataTemp;
}

export default XMLComponent;
