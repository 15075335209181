import { Form, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Load } from "../load/load";
import { actualizarCargos } from "../../api/cargos";
import { toast } from "react-toastify";
import queryString from "query-string";
import { LogsInformativos } from "../logs/logs";
import { listarDepartamentos } from "../../api/departamentos";
import { map } from "lodash";

export default function ModificarCargos({ data, history, setShow }) {
    console.log(data);

    const idDocumental = data[0];

    const [listarDepartamento, setListDepartamento] = useState([]);

    const obtenerDepartamento = () => {
        try {
            listarDepartamentos()
                .then((response) => {
                    const { data } = response;

                    if (!listarDepartamento && data) {
                        setListDepartamento(formatModelDepartamento(data));
                    } else {
                        const datosDepartamento = formatModelDepartamento(data);
                        setListDepartamento(datosDepartamento);
                    }
                })
                .catch((e) => { });
        } catch (e) { }
    };

    useEffect(() => {
        obtenerDepartamento();
    }, []);

    const dataTemp = {
        nombre: data[1],
        descripcion: data[2],
        departamento: data[3]
    };

    const [formData, setFormData] = useState(initialFormValue(dataTemp));

    //load
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    //insert
    const onSubmit = (e) => {
        e.preventDefault();

        try {
            setLoading(true);

            const dataTemp = {
                nombre: formData.nombre,
                descripcion: formData.descripcion,
                departamento: formData.departamento
            };

            actualizarCargos(idDocumental, dataTemp).then((response) => {
                const { data } = response;
                //notificación
                LogsInformativos("Se ha modificado el cargo " + dataTemp.nombre, dataTemp);
                toast.success(data.mensaje);

                history({
                    search: queryString.stringify(""),
                });
                setLoading(false);
                setShow(false);
            });
        } catch (e) {
            console.log(e);
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            {loading && <Load />}
            <div className='generalDoctores'>
                <Form className='formDoctores' onSubmit={onSubmit} onChange={onChange}>
                    <Row className="mb-3">
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Nombre: <code>*</code> </Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Ingresa nombre del puesto'
                                    value={formData.nombre}
                                    name="nombre"
                                />
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Descripción: <code>*</code> </Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Ingresa la descripción del puesto'
                                    defaultValue={formData.descripcion}
                                    name="descripcion"
                                />
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Departamento: <code>*</code> </Form.Label>
                                <Form.Control
                                    as="select"
                                    value={formData.departamento}
                                    name="departamento"
                                >
                                    <option value="">Selecciona una opción</option>
                                    {map(listarDepartamento, (cat, index) => (
                                        <option key={index} value={cat.nombre}>
                                            {cat.nombre}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>

                    <div className="divSubmit">
                        <input className="submit" value="Enviar" type="submit" />
                    </div>
                </Form>
            </div>
        </>
    );
}

function initialFormValue(data) {
    return {
        nombre: data.nombre,
        descripcion: data.descripcion,
        departamento: data.departamento,
    };
}

function formatModelDepartamento(data) {
    return data.map((data) => ({
        id: data._id,
        folio: data.folio,
        nombre: data.nombre,
        tipo: data.tipo,
        estado: data.estado,
    }));
}
