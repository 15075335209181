import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faTruck, faBars, faMoneyCheckDollar, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { listarVisitasMensajeros } from "../../api/visitasMensajeros";
import { Button, Card, Dropdown, Badge } from "react-bootstrap";
import BasicModal from "../Modal/BasicModal/BasicModal";
import ModificarEntregas from "./ModificarEntregas";
import EliminarEntregas from "./EliminarEntregas";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import { toast } from "react-toastify";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { Load } from "../load/load";
import { withRouter } from "../../utils/withRouter";
import PagosOrdenesM from "../ordenServicio/pagosOrdenesM/PagosOrdenesM";
import QRScannerModal from "./scanner_qr";

function TarjetasVisitas(props) {
  const { history, location } = props;

  dayjs.locale("es");
  dayjs.extend(localizedFormat);

  const [loading, setLoading] = useState(true);
  const [tipoUsuario, setTipoUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");
  const [listarViaje, setListViaje] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  useEffect(() => {
    obtenerViajes();
  }, [correoUsuario, location]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const obtenerViajes = () => {
    try {
      listarVisitasMensajeros()
        .then((response) => {
          const { data } = response;

          if (!listarViaje && data) {
            setListViaje(formatModelViaje(data));
          } else {
            const datosViaje = formatModelViaje(data);
            setListViaje(datosViaje);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

function formatModelViaje(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      idDoctor: data.idDoctor,
      idMensajero: data.idMensajero,
      doctor: data.doctor.nombre + " " + data.doctor.apellidoPaterno + " " + data.doctor.apellidoMaterno,
      mensajero: data.mensajero.nombre,
      estado: data.estado,
      fechaCreacion: dayjs(data.createdAt).format('DD/MM/YYYY hh:mm:ss A')
    });
  });
  return dataTemp;
}

  const modificarViajes = (content) => {
    setTitulosModal(tipoUsuario !== "mensajero" ? "Modificar entrega" : "Realizar entrega");
    setContentModal(content);
    setShowModal(true);
  };

  const eliminarViajes = (content) => {
    setTitulosModal("Eliminar entrega");
    setContentModal(content);
    setShowModal(true);
  };

  const recibirPago = (content) => {
    setTitulosModal("Pago de orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const escanearQR = (content) => {
    setTitulosModal("Escanear QR");
    setContentModal(content);
    setShowModal(true);
  };

  const renderTarjetas = () => {
    return listarViaje.map((viaje) => (
      <Card key={viaje.id} className="mb-3">
        <Card.Body>
          <Card.Title>Mensajero {viaje.mensajero}</Card.Title><br />
          <Card.Text>
            Mensajero: {viaje.doctor}<br />
            Doctor: {viaje.doctor}<br />
            Fecha de visitsa: {dayjs(viaje.fechaCreacion).format('LLL')}<br />
            Estado: <Badge bg={viaje.estado === "false" ? "danger" : "success"}>
              {viaje.estado === "false" ? "Deshabilitado" : "Activo"}
            </Badge>
          </Card.Text>
        </Card.Body>
      </Card>
    ));
  };

  return (
    <>
      {loading ? <Load /> : (
        <>
          <h1>Visitas de mensajeros</h1>
          <Button
            className="btnAddTables"
            onClick={() =>
              escanearQR(
                <QRScannerModal history={history} setShow={setShowModal} />
              )
            }
          >
            <FontAwesomeIcon icon={faQrcode} /> Escanear QR
          </Button>
          <div>{renderTarjetas()}</div>
        </>
      )}
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

export default withRouter(TarjetasVisitas);
