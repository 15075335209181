import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { listarCoordenadas } from "../../../api/coordenadas";
import { withRouter } from "../../../utils/withRouter";
import { Load } from "../../load/load";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEye } from "@fortawesome/free-solid-svg-icons";
import { MapaMarcaUbicacion } from "./MapaMarcaUbicacion";
import { MapaMarcaUbicacionUno } from "./MapaMarcaUbicacionUno";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function TblCoordenadas(props) {
  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const { location, history } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarUbicacion, setListUbicacion] = useState([]);

  const obtenerUbicacion = () => {
    try {
      listarCoordenadas()
        .then((response) => {
          const { data } = response;

          if (!listarUbicacion && data) {
            setListUbicacion(formatModelUbicacion(data));
          } else {
            const datosUbicacion = formatModelUbicacion(data);
            setListUbicacion(datosUbicacion);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerUbicacion();
  }, [location]);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarUbicacion);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  const [mostrarPrimerDiv, setMostrarPrimerDiv] = useState(true);
  const [latSel, setLatSel] = useState("");
  const [lngSel, setLngSel] = useState("");
  const [domSel, setDomSel] = useState("");

  const handleClick = (tableMeta) => {
    // Verifica si tableMeta es undefined antes de acceder a rowData
    if (tableMeta && tableMeta.rowData) {
      // Accede a la información de la fila actual
      const rowData = tableMeta.rowData;

      // Obtén el valor de la primera columna (supongamos que es la posición 0 del array)
      const valorLatitud = rowData[1];
      const valorLongitud = rowData[2];
      const valorDomicilio = rowData[3];

      // Actualiza la variable con el valor de la primera columna
      setLatSel(valorLatitud);
      setLngSel(valorLongitud);
      setDomSel(valorDomicilio);

      // Puedes hacer cualquier otra lógica que necesites con el valor obtenido
    } else {
      console.error("tableMeta o tableMeta.rowData es undefined");
    }
    setMostrarPrimerDiv(!mostrarPrimerDiv);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "latitud",
      label: "LATITUD",
    },
    {
      name: "longitud",
      label: "LONGITUD",
    },
    {
      name: "ubicacion",
      label: "DIRECCIÓN",
    },
    {
      name: "usuario",
      label: "MENSAJERO",
    },
    {
      name: "button",
      label: "MAPA",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button variant="link" onClick={() => handleClick(tableMeta)}>
              <FontAwesomeIcon
              icon={faEye}
              style={{ color: "#000080" }}
            />
              </Button>
            </>
          );
        },
      },
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "true") {
            estiloTexto = "activo";
            estadoTexto = "Activo";
          } else {
            estiloTexto = "inhabilitado";
            estadoTexto = "Inhabilitado";
          }

          return <div className={estiloTexto}>{estadoTexto}</div>;
        },
      },
    },
    {
      name: "fecha",
      label: "FECHA",
      options: {
        customBodyRender: (value) => {
          const fecha = value;

          return <div>{dayjs(fecha).format("LL hh:mm A")}</div>;
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && 'standard',
    selectableRows: isSmOrDown ? 'none' : 'multiple',
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      <div>
        {mostrarPrimerDiv ? (
          <div>
            {loading && <Load />}
            <h1>Coordenadas</h1>
            <div className="divTabla">
              <MUIDataTable
                title={"Lista de coordenadas registradas"}
                data={listarUbicacion}
                columns={columns}
                options={options}
              />
            </div>
          </div>
        ) : (
          <div style={{ position: "relative", padding:"2%" }}>
            <div
              style={{
                position: "absolute",
                top: "2px",
                left: "10px",
              }}
            >
              <Button
                variant="primary"
                style={{ color: "#fff", textDecoration: "none" }}
                onClick={handleClick}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </Button>
            </div>
            <div style={{textAlign:"center"}}>
              <h1>Ubicación</h1>
              <h5 style={{fontStyle:"italic"}}>{domSel}</h5>
            </div>
            <div>
              <MapaMarcaUbicacionUno
                latU={latSel}
                lngU={lngSel}
                nombreUbi={domSel}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function formatModelUbicacion(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      latitud: data.latitud,
      longitud: data.longitud,
      ubicacion: data.ubicacion,
      usuario: data.usuario,
      estado: data.estado,
      fecha: data.createdAt,
    });
  });
  return dataTemp;
}

export default withRouter(TblCoordenadas);
