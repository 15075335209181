import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faPlus,
  faBars,
  faTruck,
  faMoneyCheckDollar
} from "@fortawesome/free-solid-svg-icons";
import {
  listarEntregas,
  listarEntregasCorreoMensajero,
} from "../../api/entregas";
import { Button, Dropdown, Badge } from "react-bootstrap";
import { withRouter } from "../../utils/withRouter";
import RegistroEntregas from "./Entregas";
import ModificarEntregas from "./ModificarEntregas";
import EliminarEntregas from "./EliminarEntregas";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Load } from "../load/load";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import { toast } from "react-toastify";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import PagosOrdenesM from "../ordenServicio/pagosOrdenesM/PagosOrdenesM";

function TblEntregas(props) {
  const { location, history } = props;

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const [loading, setLoading] = useState(true);

  const [tipoUsuario, setTipoUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarViaje, setListViaje] = useState([]);

  const obtenerViajes = () => {
    try {
      if (tipoUsuario == "administrador") {
        listarEntregas()
          .then((response) => {
            const { data } = response;

            if (!listarViaje && data) {
              setListViaje(formatModelViaje(data));
            } else {
              const datosViaje = formatModelViaje(data);
              setListViaje(datosViaje);
            }
          })
          .catch((e) => { });
      } else if (tipoUsuario == "mensajero") {
        listarEntregasCorreoMensajero(correoUsuario)
          .then((response) => {
            const { data } = response;

            if (!listarViaje && data) {
              setListViaje(formatModelViaje(data));
            } else {
              const datosViaje = formatModelViaje(data);
              setListViaje(datosViaje);
            }
          })
          .catch((e) => { });
      }
    } catch (e) { }
  };

  useEffect(() => {
    obtenerViajes();
  }, [location, correoUsuario]);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarViaje);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroViajes = (content) => {
    setTitulosModal("Registar entrega");
    setContentModal(content);
    setShowModal(true);
  };

  const modificarViajes = (content) => {
    setTitulosModal(tipoUsuario != "mensajero" ? "Modificar entrega" : "Realizar entrega");
    setContentModal(content);
    setShowModal(true);
  };

  const eliminarViajes = (content) => {
    setTitulosModal("Eliminar entrega");
    setContentModal(content);
    setShowModal(true);
  };

  const recibirPago = (content) => {
    setTitulosModal("Pago de orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "folio",
      label: "FOLIO",
    },
    {
      name: "mensajero",
      label: "MENSAJERO",
    },
    {
      name: "ordenTrabajo",
      label: "ORDEN DE TRABAJO",
    },
    {
      name: "fechaEntrega",
      label: "FECHA DE ENTREGA",
    },
    {
      name: "ubicacion",
      label: "UBICACIÓN DE ENTREGA",
    },
    {
      name: "motivoEntrega",
      label: "MOTIVO DE ENTREGA",
    },
    {
      name: "comentarios",
      label: "COMENTARIOS",
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "0") {
            estiloTexto = "Listo para entrega";
            estadoTexto = "Listo para entrega";
          } else if (estado == "1") {
            estiloTexto = "Entregado";
            estadoTexto = "Entregado";
          }

          return (
            <>
              <Badge
                bg={
                  estado == "0"
                    ? "warning"
                    : estado == "1"
                      ? "success"
                      : "danger"
                }
              //className="estado"
              >
                {estiloTexto}
              </Badge>
            </>
          );
        },
      },
    },
    {
      name: "tipo",
      label: "TIPO",
    },
    {
      name: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[8] == "0" ? (
            <>
              <Dropdown>
                <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                  <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {tipoUsuario == "administrador" && (
                    <>
                      <Dropdown.Item
                        onClick={() =>
                          modificarViajes(
                            <ModificarEntregas
                              history={history}
                              setShow={setShowModal}
                              data={tableMeta.rowData}
                            />
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faPen}
                          style={{ color: "#ffc107" }}
                        />
                        &nbsp; Modificar
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          eliminarViajes(
                            <EliminarEntregas
                              history={history}
                              setShow={setShowModal}
                              data={tableMeta.rowData}
                            />
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          style={{ color: "#dc3545" }}
                        />
                        &nbsp; Eliminar
                      </Dropdown.Item>
                    </>
                  )}
                  {tipoUsuario == "mensajero" && (
                    <>
                      <Dropdown.Item
                        onClick={() =>
                          modificarViajes(
                            <ModificarEntregas
                              history={history}
                              setShow={setShowModal}
                              data={tableMeta.rowData}
                            />
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faTruck}
                          style={{ color: "#ffc107" }}
                        />
                        &nbsp; Entregar
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            <>
            {tipoUsuario == "mensajero" ? (
              <Dropdown>
                <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                  <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      recibirPago(
                        <PagosOrdenesM
                          history={history}
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                          origen={"tablaEntregas"}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faMoneyCheckDollar}
                      style={{ color: "#28a745" }}
                    />
                    &nbsp;Recibir pago
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              ) : (
                "No disponibles"
              )}
            </>
          );
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      {loading && <Load />}
      <h1>Entregas</h1>
      <div className="divTabla" style={{ textTransform: "capitalize" }}>
        <MUIDataTable
          title={"Lista de entregas registradas"}
          data={listarViaje}
          columns={columns}
          options={options}
        />
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function formatModelViaje(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      mensajero: data.mensajero,
      fechaEntrega: data.fechayHoraEntrega,
      ubicacion: data.ubicacion,
      ordenTrabajo: data.ordenTrabajo,
      motivoEntrega: data.motivoEntrega,
      comentarios: data.comentarios,
      tipo: !data.tipo ? "No disponible" : data.tipo,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default withRouter(TblEntregas);
