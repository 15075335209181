import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deshabilitarMesas } from "../../../api/mesas";
import queryString from "query-string";
import { Load } from "../../load/load";
import { LogsInformativos } from "../../logs/logs";

export default function DeshabilitarComedor({ data, history, setShow }) {
    const [loading, setLoading] = useState(true);
    const [estado, setEstado] = useState(1); // Estado por defecto es "ocupada"

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();

        try {
            setLoading(true);

            deshabilitarMesas(estado).then((response) => { // Pasa el estado seleccionado a la función
                const { data } = response;
                toast.success(data.mensaje);

                history({
                    search: queryString.stringify(""),
                });
                setLoading(false);
                setShow(false);
                LogsInformativos("Se ha actualizado el estado del comedor");
            });
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            {loading && <Load />}
            <div className="generalOrden2">
                <br />
                <Form className="formOrden2" onSubmit={onSubmit}>
                    <div style={{ textAlign: "center", marginBottom: "1%" }}>
                        <h3>¿Actualizar el estado del comedor?</h3>
                    </div>
                    <Form.Group controlId="formEstado" className="mb-3" style={{ textAlign: "center" }}>
                        <Form.Label>Selecciona el estado:</Form.Label>
                        <Form.Control
                            as="select"
                            value={estado}
                            onChange={(e) => setEstado(e.target.value)}
                            style={{ width: '50%', margin: '0 auto', textAlign: 'center' }} // Estilo centrado
                        >
                            <option value="0">Ocupadas</option>
                            <option value="1">Desocupadas</option>
                        </Form.Control>
                    </Form.Group>
                    <div className="divSubmit">
                        <input className="submit" value="Enviar" type="submit" />
                    </div>
                </Form>
            </div>
        </>
    );
}
