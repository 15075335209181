import React, { useEffect, useState } from "react";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Load } from "../load/load";
import Registro from "./Registro";
import { Badge, Button, Dropdown, Table, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCancel,
  faMoneyBill,
  faPlus,
  faEye
} from "@fortawesome/free-solid-svg-icons";
import MUIDataTable from "mui-datatables";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { listarRequisicionCompras } from "../../api/compras";
import Cancelar from "./Cancelar";
import RegistroOC from "../material/ordenCompra/RegistroOC";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

const TablaReq = () => {
  const [loading, setLoading] = useState(true);

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroReq = (content) => {
    setTitulosModal("Registrar requisición");
    setContentModal(content);
    setShowModal(true);
  };

  const realizarOrdenCompra = (content) => {
    setTitulosModal("Generar orden de compra");
    setContentModal(content);
    setShowModal(true);
  };

  const cancelarReq = (content) => {
    setTitulosModal("Cancelar requisición");
    setContentModal(content);
    setShowModal(true);
  };

  /*********************************************** */
  const [listReq, setListReq] = useState([]);

  const obtenerReq = () => {
    try {
      listarRequisicionCompras()
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (!listReq && data) {
            setListReq(formatModelReq(data));
          } else {
            setListReq(formatModelReq(data));
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    if (!showModal) {
      obtenerReq();
    }
  }, [showModal]);
  /*********************************************** */

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showProductsModal, setShowProductsModal] = useState(false);

  const handleProductClick = (productos) => {
    setSelectedProducts(Array.isArray(productos) ? productos : []);
    setShowProductsModal(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "folio",
      label: "FOLIO",
    },
    {
      name: "fecha",
      label: "FECHA",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
            {dayjs(value).format("LL")}
            </>
          );
        },
      },
    },
    {
      name: "productos",
      label: "PRODUCTOS",
      options: {
        customBodyRender: (value) => (
          <Button variant="link" onClick={() => handleProductClick(value)}>
            <FontAwesomeIcon
              icon={faEye}
              style={{ color: "#000080" }}
            />
          </Button>
        ),
      },
    },
    {
      name: "solicito",
      label: "SOLICITA",
    },
    {
      name: "departamento",
      label: "MARCA",
    },
    {
      name: "proveedor",
      label: "PROVEEDOR",
    },
    {
      name: "folioProveedor",
      label: "FOLIO PROVEEDOR",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "observaciones",
      label: "OBSERVACIONES",
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "true") {
            estiloTexto = "Activa";
            estadoTexto = "Activa";
          } else {
            estiloTexto = "Cancelada";
            estadoTexto = "Cancelada";
          }

          return (
            <>
              <Badge
                bg={estado == "true" ? "success" : "danger"}
                //className="estado"
              >
                {estiloTexto}
              </Badge>
            </>
          );
        },
      },
    },
    {
      name: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[9] == "true" ? (
            <>
              <Dropdown>
                <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                  <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      realizarOrdenCompra(
                        <RegistroOC
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faMoneyBill}
                      style={{ color: "#28a745" }}
                    />
                    &nbsp; Realizar orden de compra
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      cancelarReq(
                        <Cancelar
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faCancel}
                      style={{ color: "#dc3545" }}
                    />
                    &nbsp; Cancelar
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            "No disponibles"
          );
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <div>
      {loading && <Load />}
      <h1>Requisición de compras</h1>
      <div className="divButton">
        <Button
          className="btnAddTables"
          onClick={() => registroReq(<Registro setShow={setShowModal} />)}
        >
          <FontAwesomeIcon icon={faPlus} /> Agregar
        </Button>
      </div>
      <div className="divTabla">
        <MUIDataTable
          title={"Lista de ordenes de cliente registradas"}
          data={listReq}
          columns={columns}
          options={options}
        />
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>

            {/* Modal para mostrar productos */}
            <Modal show={showProductsModal} onHide={() => setShowProductsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Detalles de productos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Producto</th>
                <th>UM</th>
                <th>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {selectedProducts.map((producto, index) => (
                <tr key={index}>
                  <td>{producto.producto}</td>
                  <td>{producto.unidadMedida}</td>
                  <td>{producto.cantidad}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowProductsModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

function formatModelReq(data) {
  const dataTemp = [];
  data.forEach((item) => {
    dataTemp.push({
      id: item._id,
      folio: item.folio,
      fecha: item.fecha,
      productos: item.productos,
      solicito: item.solicito,
      departamento: item.departamento,
      observaciones: item.observaciones,
      proveedor: item.proveedor,
      folioProveedor: item.folioProveedor,
      estado: item.estado,
    });
  });
  return dataTemp;
}

export default TablaReq;
