import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import {
  listarMensajerosGastos,
  listarSaldosMensajeroGral,
} from "../../../api/mensajerosGastos";
import { Badge, Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import { withRouter } from "../../../utils/withRouter";
import { Load } from "../../load/load";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faDollarSign,
  faEye,
  faHandHoldingDollar,
} from "@fortawesome/free-solid-svg-icons";
import SaldosMensajero from "./SaldosMensajero";
import TablaGastos from "../../administrador/mensajeros/TablaGastos";
import {
  consultarSaldosMensajero,
  listarSaldosMensajero,
  obtenerGastosMensajero,
} from "../../../api/gastosMensajero";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

function TblGastos(props) {
  const { location, history } = props;

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const [fechaFormat, setFechaFormat] = useState("");
  const [fechaInp, setfechaInp] = useState("");

  useEffect(() => {
    let fecha = new Date();
    let dia = fecha.getDate();
    let mes = fecha.getMonth() + 1;
    let year = fecha.getFullYear();
    const fechaHoy = `${dia}/${mes}/${year}`;
    const fechInp = `${year}-${mes}-${dia}`;
    setfechaInp(fechInp);
    setFechaFormat(fechaHoy);
  }, []);

  useEffect(() => {
    const partesFecha = fechaInp.split("-");
    const mes =
      partesFecha[1] && partesFecha[1].startsWith("0")
        ? partesFecha[1].substring(1)
        : partesFecha[1];
    const fechaFormateada = partesFecha[2] + "/" + mes + "/" + partesFecha[0];
    setFechaFormat(fechaFormateada);
  }, [fechaInp]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarPrecio, setListPrecio] = useState([]);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registrarPago = (content) => {
    setTitulosModal("Agregar presupuesto al mensajero");
    setContentModal(content);
    setShowModal(true);
  };

  const verPagos = (content) => {
    setTitulosModal("Ver Pagos");
    setContentModal(content);
    setShowModal(true);
  };

  const obtenerPrecios = () => {
    try {
      listarSaldosMensajero()
        .then((response) => {
          const { data } = response;
          if (!listarPrecio && data) {
            setListPrecio(formatModelPrecio(data));
          } else {
            const datosPrecio = formatModelPrecio(data);

            // Filtrar los registros donde la fecha sea igual a fechaFormat
            const preciosFiltrados = datosPrecio.filter(
              (registro) => registro.fecha === fechaFormat
            );
            setListPrecio(preciosFiltrados);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  const [saldosMensajerosGral, setsaldosMensajerosGral] = useState([]);

  const obtenerSaldos = () => {
    try {
      listarSaldosMensajeroGral()
        .then((response) => {
          const { data } = response;
          if (!listarPrecio && data) {
            setListPrecio(formatModelPrecio2(data));
          } else {
            const datosPrecio = formatModelPrecio2(data);

            // Filtrar los registros donde la fecha sea igual a fechaFormat
            const preciosFiltrados = datosPrecio.filter(
              (registro) => registro.fecha === fechaFormat
            );
            setsaldosMensajerosGral(preciosFiltrados);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerSaldos();
    obtenerPrecios();
  }, [fechaFormat]);

  const [arrNuevoDatos, setarrNuevoDatos] = useState([]);

  useEffect(() => {
    const idsExistentes = new Set(listarPrecio.map((item) => item.id));
    const nuevosElementos = saldosMensajerosGral.filter(
      (item) => !idsExistentes.has(item.id)
    );
    const arregloFusionado = [...listarPrecio, ...nuevosElementos];
    setarrNuevoDatos(arregloFusionado);
  }, [saldosMensajerosGral, listarPrecio]);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarPrecio);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  useEffect(() => {
    if (!showModal) {
      obtenerSaldos();
      obtenerPrecios();
    }
  }, [showModal]);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "nombre",
      label: "NOMBRE",
    },
    {
      name: "total",
      label: "GASTOS DEL DÍA",
      options: {
        customBodyRender: (value) =>
          formatCurrency(value !== undefined ? value : 0),
      },
    },
    {
      name: "saldo",
      label: "SALDO DEL DIA",
      options: {
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: "fecha",
      label: "FECHA",
      options: {
        customBodyRender: (value, tableMeta) => {

          return (
            <>
              {dayjs(value).format("LL")}
            </>
          );
        },
      },
    },
    {
      name: "balance",
      label: "BALANCE",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const total = parseFloat(
            tableMeta.rowData[2] !== undefined ? tableMeta.rowData[2] : 0
          );
          const saldo = parseFloat(tableMeta.rowData[3]);

          const balanceValue = saldo - total;
          const formattedPendiente = formatCurrency(balanceValue);
          const style = {
            color: balanceValue < 0 ? "red" : "green",
            fontWeight: "bold",
          };

          return <span style={style}>{formattedPendiente}</span>;

          return balanceValue;
        },
      },
    },
    {
      name: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <Dropdown>
              <Dropdown.Toggle
                style={{ backgroundColor: "#0c1b30", borderColor: "#0c1b30" }}
                id="dropdown-basic"
              >
                <FontAwesomeIcon icon={faBars} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    verPagos(
                      <TablaGastos
                        history={history}
                        setShow={setShowModal}
                        data={tableMeta.rowData[0]}
                        id="saldos"
                      />
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faEye}
                    style={{ color: "#fd7e14", fontWeight: "bold" }}
                  />
                  &nbsp;Ver gastos
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        ),
      },
    },
  ];

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      {loading && <Load />}
      <h1>Saldos de mensajeros</h1>
      <div style={{ padding: "1%", paddingBottom: "0" }}>
        <Row>
          <Col
            sm={12}
            md={6}
            lg={6}
            style={{ display: "flex" }}
            className="mb-2"
          >
            <Col sm={12} md={3} lg={3}>
              <Form.Label>Seleccionar día</Form.Label>
            </Col>
            <Col sm={12} md={9} lg={9}>
              <Form.Control
                type="date"
                value={fechaInp}
                onChange={(e) => setfechaInp(e.target.value)}
                style={{ marginRight: "1%" }}
              />
            </Col>
          </Col>
          <Col sm={12} md={6} lg={6} style={{ textAlign: "right" }}>
            <Button
              className="btnAddTables"
              onClick={() =>
                registrarPago(
                  <SaldosMensajero history={history} setShow={setShowModal} />
                )
              }
            >
              <FontAwesomeIcon icon={faHandHoldingDollar} /> Agregar Saldo
            </Button>
          </Col>
        </Row>
      </div>
      <div className="divTabla">
        <MUIDataTable
          title={"Lista de saldos otorgados el " + fechaFormat}
          data={arrNuevoDatos}
          columns={columns}
          options={options}
        />
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function formatModelPrecio(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data.correoMensajero,
      nombre: data.nombre,
      saldo: data.saldo,
      total: data.totalCantidad,
      fecha: data.fecha,
    });
  });
  return dataTemp;
}

function formatModelPrecio2(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data.correoCliente,
      nombre: data.nombreCliente,
      saldo: data.saldo,
      total: data.totalCantidad,
      fecha: data.fecha,
    });
  });
  return dataTemp;
}

export default withRouter(TblGastos);
