import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { listarSaldosCliente } from "../../../api/saldosCliente";
import {
  Badge,
  Button,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { withRouter } from "../../../utils/withRouter";
import { Load } from "../../load/load";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  ObtenerSaldosPeriodo,
  listarOrdenesySaldos,
} from "../../../api/ordenesServicio";
import {
  listarCorreoPreciosOrden,
  listarPreciosOrden,
} from "../../../api/preciosOrden";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import PagosClientes from "../pagosClientes/PagosClientes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBorderAll,
  faCalendarDays,
  faDollarSign,
  faExchange,
  faEye,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import TablaPagos from "../pagosClientes/TablaPagos";
import Movimientos from "../pagosClientes/Movimientos";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "./style.css";
import Select from "react-select";
import { listarDoctores } from "../../../api/doctores";
import { listarEstablecimientos } from "../../../api/establecimientos";
import {
  listarAbonosAprobados,
  listarAbonosEntreFechas,
} from "../../../api/abonosOrdenes";
import OrdenesCliienteSaldo from "./OrdenesCliienteSaldo";

function TblSaldos(props) {
  const { location, history } = props;

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const [formData, setFormData] = useState(initialFormValue());

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [mostrarPrimerDiv, setMostrarPrimerDiv] = useState(false);

  const handleClick = () => {
    setMostrarPrimerDiv(!mostrarPrimerDiv);
  };

  useEffect(() => {
    console.log(mostrarPrimerDiv);
  }, [mostrarPrimerDiv]);

  const [selectedOption, setSelectedOption] = useState("");

  const handleSeleccionChange = (option) => {
    setSelectedOption(option.value);
  };

  const [listarDoctor, setListDoctor] = useState([]);

  const obtenerDoctor = () => {
    try {
      listarDoctores()
        .then((response) => {
          const { data } = response;

          if (!listarDoctor && data) {
            setListDoctor(formatModelDoctor(data));
          } else {
            const datosDoctor = formatModelDoctor(data);
            funcArrSelect(datosDoctor, setListDoctor);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [listarEstablecimiento, setListEstablecimiento] = useState([]);

  const obtenerEstablecimiento = () => {
    try {
      listarEstablecimientos()
        .then((response) => {
          const { data } = response;

          if (!listarEstablecimiento && data) {
            setListEstablecimiento(formatModelEstablecimiento(data));
          } else {
            const datosEstablecimiento = formatModelEstablecimiento(data);
            funcArrSelect(datosEstablecimiento, setListEstablecimiento);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    if (mostrarPrimerDiv) {
      obtenerEstablecimiento();
      obtenerDoctor();
    }
  }, [mostrarPrimerDiv]);

  const [arrClients, setArrClients] = useState([]);

  useEffect(() => {
    const arrTodos = [
      { value: "Todos/Todos los Clientes", label: "Todos los Clientes" },
    ];
    const arrClient = [...arrTodos, ...listarEstablecimiento, ...listarDoctor];
    setArrClients(arrClient);
  }, [listarEstablecimiento, listarDoctor]);

  const funcArrSelect = (element, arr) => {
    const newArray = element.map(({ email, razonSocial }) => ({
      value: email + "/" + razonSocial,
      label: razonSocial,
    }));
    arr(newArray);
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarPrecio, setListPrecio] = useState([]);

  const obtenerPrecios = () => {
    try {
      listarOrdenesySaldos()
        .then((response) => {
          const { data } = response;
          if (!listarPrecio && data) {
            setListPrecio(formatModelPrecio(data));
          } else {
            const datosPrecio = formatModelPrecio(data);
            setListPrecio(datosPrecio);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerPrecios();
  }, [location]);

  const total = listarPrecio.reduce(
    (amount, item) => amount + parseInt(item.totalPrecio),
    0
  );

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarPrecio);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registrarPago = (content) => {
    setTitulosModal("Registrar pago de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const registrarMovimiento = (content) => {
    setTitulosModal("Registrar movimiento");
    setContentModal(content);
    setShowModal(true);
  };

  const verPagos = (content) => {
    setTitulosModal("Ver Pagos");
    setContentModal(content);
    setShowModal(true);
  };

  const [listAbonosA, setListAbonosA] = useState([]);

  const obtenerAA = () => {
    try {
      listarAbonosAprobados()
        .then((response) => {
          const { data } = response;
          console.log("AA: -> ", data);
          if (!listAbonosA && data) {
            setListAbonosA(formatModelAA(data));
          } else {
            setListAbonosA(formatModelAA(data));
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerAA();
  }, []);

  const [clientesConAbonos, setClientesConAbonos] = useState([]);

  useEffect(() => {
    const calcularClientesConAbonos = () => {
      const clientesConAbonosCalculados = listarPrecio.map((cliente) => {
        const abono = listAbonosA.find((abono) => abono.id === cliente.id);
        return {
          ...cliente,
          totalCantidadRecibida: abono ? abono.totalCantidadRecibida : 0,
        };
      });
      setClientesConAbonos(clientesConAbonosCalculados);
    };

    calcularClientesConAbonos();
  }, [listarPrecio, listAbonosA]);

  const [listarMovimientos2, setListMovimientos2] = useState([]);

  const obtenerMovimientos2 = () => {
    try {
      listarPreciosOrden()
        .then((response) => {
          const { data } = response;
          if (!listarMovimientos2 && data) {
            setListMovimientos2(formatModelMovimientos(data));
          } else {
            const datosMovimientos = formatModelMovimientos(data);
            setListMovimientos2(datosMovimientos);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerMovimientos2();
  }, []);

  useEffect(() => {
    obtenerMovimientos2();
  }, [showModal]);

  const [listarMovimientos, setListMovimientos] = useState([]);

  const obtenerMovimientos = (ordenServicio) => {
    try {
      listarCorreoPreciosOrden(ordenServicio)
        .then((response) => {
          const { data } = response;
          if (!listarMovimientos && data) {
            setListMovimientos(formatModelMovimientos(data));
          } else {
            const datosMovimientos = formatModelMovimientos(data);
            setListMovimientos(datosMovimientos);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerMovimientos();
  }, []);

  const [showModal2, setShowModal2] = useState(false);

  // Utilizamos Array.reduce() para realizar las operaciones según el tipo
  const resultado = listarMovimientos.reduce((acumulador, elemento) => {
    if (elemento.tipo === "cargo") {
      // Sumar al acumulador si el tipo es 'cargo'
      return acumulador + Number(elemento.cantidad);
    } else if (elemento.tipo === "descuento") {
      // Restar al acumulador si el tipo es 'descuento'
      return acumulador - Number(elemento.cantidad);
    } else {
      // Manejar otros tipos si es necesario
      return acumulador;
    }
  }, 0); // El segundo parámetro de reduce es el valor inicial del acumulador

  const resultadoo = listarMovimientos2.reduce((acumulador, elemento) => {
    if (elemento.tipo === "cargo") {
      // Sumar al acumulador si el tipo es 'cargo'
      return acumulador + Number(elemento.cantidad);
    } else if (elemento.tipo === "descuento") {
      // Restar al acumulador si el tipo es 'descuento'
      return acumulador - Number(elemento.cantidad);
    } else {
      // Manejar otros tipos si es necesario
      return acumulador;
    }
  }, 0); // El segundo parámetro de reduce es el valor inicial del acumulador

  useEffect(() => {
    if (!showModal) {
      obtenerPrecios();
    }
  }, [showModal]);

  const modificarSistemasColorimetria = (content) => {
    setTitulosModal("Lista de ordenes de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "nombreCliente",
      label: "CLIENTE",
      options: {
        customBodyRender: (value) => {
          if (value != null) {
            return value;
          } else {
            return "No especificado";
          }
        },
      },
    },
    {
      name: "totalOrdenes",
      label: "TOTAL DE ORDENES",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Button
            variant="link"
            title="Ver ordenes"
            style={{ textDecoration: "none" }}
            onClick={() =>
              modificarSistemasColorimetria(
                <OrdenesCliienteSaldo
                  history={history}
                  setShow={setShowModal}
                  data={tableMeta.rowData}
                />
              )
            }
          >
            {value}
          </Button>
        ),
      },
    },
    {
      name: "totalPrecio",
      label: "TOTAL DE ORDENES $ (INCLUYE CARGOS Y DESCUENTOS)",
      options: {
        customBodyRender: (value, tableMeta) => {
          const total = value;
          // Supongamos que ordenServicio es el valor con el que quieres filtrar
          const ordenServicioAFiltrar = tableMeta.rowData[0];

          const movimientosFiltrados = listarMovimientos2.filter((elemento) => {
            return elemento.ordenServicio === ordenServicioAFiltrar;
          });

          const resultado2 = movimientosFiltrados.reduce(
            (acumulador, elemento) => {
              if (elemento.tipo === "cargo") {
                return acumulador + Number(elemento.cantidad);
              } else if (elemento.tipo === "descuento") {
                return acumulador - Number(elemento.cantidad);
              } else {
                return acumulador;
              }
            },
            0
          );
          const formattedTotal = formatCurrency(total + resultado2);
          return <>{formattedTotal}</>;
        },
      },
    },
    {
      name: "totalCantidadRecibida",
      label: "ABONOS DE ORDENES",
      options: {
        customBodyRender: (value) => formatCurrency(value != null ? value : 0),
      },
    },
    {
      name: "saldo",
      label: "PAGADO",
      options: {
        customBodyRender: (value) => formatCurrency(value != null ? value : 0),
      },
    },
    {
      label: "PENDIENTE",
      name: "pendiente",
      options: {
        customBodyRender: (value, tableMeta) => {
          const totalPrecio = tableMeta.rowData[3];
          const abonado = tableMeta.rowData[4];
          const saldo = tableMeta.rowData[5];
          const pendiente =
            (totalPrecio != null ? totalPrecio : 0) -
            (saldo != null ? saldo : 0) -
            abonado;
          // Supongamos que ordenServicio es el valor con el que quieres filtrar
          const ordenServicioAFiltrar = tableMeta.rowData[0];
    
          const movimientosFiltrados = listarMovimientos2.filter((elemento) => {
            return elemento.ordenServicio === ordenServicioAFiltrar;
          });
    
          const resultado2 = movimientosFiltrados.reduce(
            (acumulador, elemento) => {
              if (elemento.tipo === "cargo") {
                return acumulador + Number(elemento.cantidad);
              } else if (elemento.tipo === "descuento") {
                return acumulador - Number(elemento.cantidad);
              } else {
                return acumulador;
              }
            },
            0
          );
    
          const totalPendiente = pendiente + resultado2;
          const formattedPendiente = formatCurrency(totalPendiente);
          const style = {
            color: totalPendiente > 0 ? "red" : "green",
            backgroundColor: totalPendiente >= 20000 ? "yellow" : "inherit"
          };
    
          return <span style={style}>{formattedPendiente}</span>;
        },
      },
    },    
    {
      name: "movimientos",
      label: "CARGOS Y DESCUENTOS",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Button
            variant="link"
            onClick={() => {
              obtenerMovimientos(tableMeta.rowData[0]);
              setShowModal2(true);
            }}
          >
            Ver balance
          </Button>
        ),
      },
    },
    {
      label: "AVISO",
      name: "aviso",
      options: {
        customBodyRender: (value, tableMeta) => {
          const totalPrecio = tableMeta.rowData[3];
          const abonado = tableMeta.rowData[4];
          const saldo = tableMeta.rowData[5];
          const pendiente =
            (totalPrecio != null ? totalPrecio : 0) -
            (saldo != null ? saldo : 0) -
            (abonado != null ? abonado : 0);
    
          // Supongamos que ordenServicio es el valor con el que quieres filtrar
          const ordenServicioAFiltrar = tableMeta.rowData[0];
    
          const movimientosFiltrados = listarMovimientos2.filter((elemento) => {
            return elemento.ordenServicio === ordenServicioAFiltrar;
          });
    
          const resultado2 = movimientosFiltrados.reduce(
            (acumulador, elemento) => {
              if (elemento.tipo === "cargo") {
                return acumulador + Number(elemento.cantidad);
              } else if (elemento.tipo === "descuento") {
                return acumulador - Number(elemento.cantidad);
              } else {
                return acumulador;
              }
            },
            0
          );
    
          const totalPendiente = pendiente + resultado2;
          const formattedPendiente = totalPendiente >= 20000 
            ? "Saldo vencido, por favor realiza un abono" 
            : "La cuenta está en orden";
          const style = {
            color: totalPendiente >= 20000 ? "red" : "green",
          };
    
          return <span style={style}>{formattedPendiente}</span>;
        },
      },
    },    
    {
      name: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <Dropdown>
              <Dropdown.Toggle
                style={{ backgroundColor: "#0c1b30", borderColor: "#0c1b30" }}
                id="dropdown-basic"
              >
                <FontAwesomeIcon icon={faBars} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    registrarPago(
                      <PagosClientes
                        history={history}
                        setShow={setShowModal}
                        data={tableMeta.rowData}
                      />
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faDollarSign}
                    style={{ color: "#28a745", fontWeight: "bold" }}
                  />
                  &nbsp;Registrar Pago
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    verPagos(
                      <TablaPagos
                        history={history}
                        setShow={setShowModal}
                        data={tableMeta.rowData}
                      />
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faEye}
                    style={{ color: "#fd7e14", fontWeight: "bold" }}
                  />
                  &nbsp;Ver pagos
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    registrarMovimiento(
                      <Movimientos
                        history={history}
                        setShow={setShowModal}
                        data={tableMeta.rowData}
                      />
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faExchange}
                    style={{ color: "#000080", fontWeight: "bold" }}
                  />
                  &nbsp;Registrar cargo/descuento
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        ),
      },
    },
  ];

  const columns2 = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "id",
      label: "CLIENTE",
      options: {
        customBodyRender: (value) => {
          if (value != null) {
            return value;
          } else {
            return "No especificado";
          }
        },
      },
    },
    {
      name: "totalRegistros",
      label: "TOTAL DE ORDENES",
    },
    {
      name: "totalPrecio",
      label: "TOTAL DE ORDENES $",
      options: {
        customBodyRender: (value, tableMeta) => (
          <span style={{ color: "red", fontWeight: "bold" }}>
            {formatCurrency(value != null ? value : 0)}
          </span>
        ),
      },
    },
    {
      name: "totalCantidadRecibida",
      label: "TOTAL DE ABONOS DE ORDENES",
      options: {
        customBodyRender: (value, tableMeta) => (
          <span style={{ color: "green", fontWeight: "bold" }}>
            {formatCurrency(value != null ? value : 0)}
          </span>
        ),
      },
    },
    {
      name: "totalcantidadDescuentos",
      label: "DESCUENTOS",
      options: {
        customBodyRender: (value, tableMeta) => (
          <span style={{ color: "green", fontWeight: "bold" }}>
            {formatCurrency(value != null ? value : 0)}
          </span>
        ),
      },
    },
    {
      name: "totalCantidadCargo",
      label: "CARGOS",
      options: {
        customBodyRender: (value, tableMeta) => (
          <span style={{ color: "red", fontWeight: "bold" }}>
            {formatCurrency(value != null ? value : 0)}
          </span>
        ),
      },
    },
    {
      name: "totalCantidadPagos",
      label: "PAGADO",
      options: {
        customBodyRender: (value, tableMeta) => (
          <span style={{ color: "green", fontWeight: "bold" }}>
            {formatCurrency(value != null ? value : 0)}
          </span>
        ),
      },
    },
    {
      label: "BALANCE",
      name: "pendiente",
      options: {
        customBodyRender: (value, tableMeta) => {
          const totalPrecio = tableMeta.rowData[3];
          const abonosOrdenes = tableMeta.rowData[4];
          const descuentos = parseFloat(tableMeta.rowData[5]);
          const cargos = tableMeta.rowData[6];
          const saldo = tableMeta.rowData[7];
          const sumas = totalPrecio + cargos;
          const restas = descuentos + saldo + abonosOrdenes;

          const pendiente = sumas - restas;

          const formattedPendiente = formatCurrency(pendiente);
          const style = {
            color: pendiente > 0 ? "red" : pendiente < 0 ? "green" : "inherit",
            fontWeight: "bold",
            backgroundColor: pendiente >= 20000 ? "yellow" : "inherit",
          };

          return <span style={style}>{formattedPendiente}</span>;
        },
      },
    }
  ];

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  const [saldosPeriodos, setSaldosPeriodos] = useState([]);

  const date1 =
    dayjs(formData.fechaInicio).format("YYYY") +
    "-" +
    dayjs(formData.fechaInicio).format("M") +
    "-" +
    dayjs(formData.fechaInicio).format("D");
  const date2 =
    dayjs(formData.fechaFin).format("YYYY") +
    "-" +
    dayjs(formData.fechaFin).format("M") +
    "-" +
    dayjs(formData.fechaFin).format("D");

  const obtenerSaldosFechas = () => {
    try {
      ObtenerSaldosPeriodo(date1, date2)
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (!saldosPeriodos && data) {
            setSaldosPeriodos(formatModelSaldosFechas(data));
          } else {
            const datosMovimientos = formatModelSaldosFechas(data);
            setSaldosPeriodos(datosMovimientos);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [abonosPeriodos, setAbonosPeriodos] = useState([]);
  const obtenerAbonosFechas = () => {
    try {
      listarAbonosEntreFechas(date1, date2)
        .then((response) => {
          const { data } = response;
          if (!abonosPeriodos && data) {
            setAbonosPeriodos(data);
          } else {
            setAbonosPeriodos(data);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    if (mostrarPrimerDiv) {
      obtenerSaldosFechas();
      obtenerAbonosFechas();
    }
  }, [mostrarPrimerDiv, formData.fechaInicio, formData.fechaFin]);

  const [clientesConAbonosFechas, setClientesConAbonosFechas] = useState([]);

  useEffect(() => {
    const calcularClientesConAbonos = () => {
      const clientesConAbonosCalculados = saldosPeriodos.map((cliente) => {
        const abono = abonosPeriodos.find((abono) => abono._id === cliente.id);
        return {
          ...cliente,
          totalCantidadRecibida: abono ? abono.totalCantidadRecibida : 0,
        };
      });
      console.log(clientesConAbonosCalculados);
      setClientesConAbonosFechas(clientesConAbonosCalculados);
    };

    calcularClientesConAbonos();
  }, [saldosPeriodos, abonosPeriodos]);

  const [filtroElementos, setFiltroElementos] = useState([]);

  useEffect(() => {
    const seleccion = selectedOption.split("/")[0];
    console.log(clientesConAbonosFechas);
    if (seleccion === "Todos") {
      setFiltroElementos(saldosPeriodos);
    } else {
      const saldosFilter = clientesConAbonosFechas.filter(
        (saldo) => saldo.id === seleccion
      );
      console.log(saldosFilter);
      setFiltroElementos(saldosFilter);
    }
  }, [selectedOption, clientesConAbonosFechas]);

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  // Utilizamos reduce para obtener la suma de los campos indicados
  const resultad = filtroElementos.reduce((acumulador, objeto) => {
    return (
      acumulador +
      objeto.totalCantidadCargo +
      objeto.totalPrecio -
      objeto.totalCantidadPagos -
      objeto.totalcantidadDescuentos
    );
  }, 0);

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros coincidentes con los filtros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    customFooter: () => {
      return (
        <tfoot>
          <tr>
            <td colSpan={7} style={{ fontSize: "larger", fontWeight: "bold" }}>
              Total:{" "}
              {!mostrarPrimerDiv
                ? formatCurrency(total)
                : formatCurrency(resultad)}
            </td>{" "}
            {/* Cambia el número de columnas según tu tabla */}
          </tr>
        </tfoot>
      );
    },
  };

  return (
    <>
      {loading && <Load />}
      <h1>Saldos de clientes</h1>
      <div>
        <div className="btnSaldosDiv">
          <Button className="btnAddTables" onClick={handleClick}>
            {!mostrarPrimerDiv ? (
              <>
                <FontAwesomeIcon icon={faCalendarDays} /> Por periodo
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faBorderAll} /> General
              </>
            )}
          </Button>
        </div>
        {mostrarPrimerDiv && (
          <div className="divSaldosClientes">
            <div className="divContainerOptions">
              <Form onChange={onChange}>
                <Row>
                  <Col sm={12} md={3} lg={3} className="mb-2">
                    <Form.Label>Fecha inicial</Form.Label>
                    <Form.Control
                      name="fechaInicio"
                      defaultValue={formData.fechaInicio}
                      type="date"
                      style={{ marginRight: "1%" }}
                    />
                  </Col>
                  <Col sm={12} md={3} lg={3} className="mb-2">
                    <Form.Label>Fecha Final</Form.Label>
                    <Form.Control
                      name="fechaFin"
                      defaultValue={formData.fechaFin}
                      type="date"
                      style={{ marginRight: "1%" }}
                    />
                  </Col>
                  <Col sm={12} md={5} lg={5} className="mb-2">
                    <Form.Label>Cliente</Form.Label>
                    <Select
                      isSearchable={true}
                      options={arrClients}
                      onChange={(selectedOption) =>
                        handleSeleccionChange(selectedOption)
                      }
                    />
                  </Col>
                  <Col
                    sm={12}
                    md={1}
                    lg={1}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    className="mb-2 text-right align-items-end btnSearchTableSaldos"
                  ></Col>
                </Row>
              </Form>
            </div>
          </div>
        )}
      </div>
      <div className="divTabla">
        <MUIDataTable
          title={
            !mostrarPrimerDiv
              ? "Lista de saldos de clientes registrados"
              : "Saldo del cliente " +
              (selectedOption !== "" ? selectedOption.split("/")[1] : "")
          }
          data={!mostrarPrimerDiv ? clientesConAbonos : clientesConAbonosFechas}
          columns={!mostrarPrimerDiv ? columns : columns2}
          options={options}
        />
        <div style={{ textAlign: "left", fontStyle: "italic", fontSize: "small" }}>
          <span >Clic en el numero de ordenes para visualizar el detalle</span>

        </div>
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>

      <Modal show={showModal2} onHide={() => setShowModal2(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Balance de los cargos y descuentos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Concepto</th>
                  <th>Cantidad</th>
                  <th>Correo de quien realiza la operacion</th>
                  <th>Fecha</th>
                </tr>
              </thead>
              <tbody>
                {listarMovimientos.map((selected, index) => (
                  <tr key={index}>
                    <td>{selected.tipo}</td>
                    <td>{selected.concepto}</td>
                    <td>{selected.cantidad}</td>
                    <td>{selected.correoRegistro}</td>
                    <td>{dayjs(selected.fechaCreacion).format("LL")}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                {/* Add your footer content here */}
                <tr>
                  <td colSpan="4">Balance: {resultado}</td>
                </tr>
              </tfoot>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal2(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function formatModelPrecio(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombreCliente: data.razonSocial,
      totalOrdenes: data.count,
      totalPrecio: data.totalPrecio,
      saldo: data.saldo,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelSaldosFechas(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id.cargoOrden,
      totalRegistros: data.totalRegistros,
      totalPrecio: data.totalPrecio,
      totalCantidadPagos: data.totalCantidadPagos,
      totalcantidadDescuentos: data.totalCantidadDescuento,
      totalCantidadCargo: data.totalCantidadCargo,
    });
  });
  return dataTemp;
}

function formatModelMovimientos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      ordenServicio: data.ordenServicio,
      tipo: data.tipo,
      concepto: data.concepto,
      cantidad: data.cantidad,
      correoRegistro: data.correoRegistro,
      estado: data.estado,
      fechaCreacion: data.createdAt,
    });
  });
  return dataTemp;
}

function formatModelDoctor(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      razonSocial:
        data.nombre + " " + data.apellidoPaterno + " " + data.apellidoMaterno,
      email: data.email,
    });
  });
  return dataTemp;
}

function formatModelEstablecimiento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      razonSocial: data.razonSocial,
      email: data.email,
    });
  });
  return dataTemp;
}

function formatModelAA(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      totalCantidadRecibida: data.totalCantidadRecibida,
    });
  });
  return dataTemp;
}

function initialFormValue() {
  return {
    fechaInicio: "",
    fechaFin: "",
  };
}

export default withRouter(TblSaldos);
