import React, { useEffect, useState } from "react";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import { Badge, Button } from "react-bootstrap";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEye } from "@fortawesome/free-solid-svg-icons";
import { Load } from "../../load/load";
import RegistroDineroMantenimiento from "./RegistroDineroMantenimiento";
import MUIDataTable from "mui-datatables";
import { listarCajaChica } from "../../../api/cajaChica";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { withRouter } from "../../../utils/withRouter";

const TablaGastosMaquinas = ({ history, location }) => {
  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const registroGasto = (content) => {
    setTitulosModal("Registar Gasto");
    setContentModal(content);
    setShowModal(true);
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listGastos, setListGastos] = useState([]);

  const obtenerGastos = () => {
    try {
      listarCajaChica()
        .then((response) => {
          const { data } = response;
          if (data) {
            // Filter the data to include only those where departamento is "mantenimiento"
            const filteredData = data.filter((item) => item.departamento === "Mantenimiento");
            setListGastos(formatModelGastos(filteredData));
          }
        })
        .catch((e) => {
          console.error(e);
        });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    obtenerGastos();
  }, [location]);


  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "fechayHora",
      label: "FECHA Y HORA",
      options: {
        customBodyRender: (value, tableMeta) => {

          return (
            <>
              {dayjs(value.split(" ")[0]).format("LL")}
            </>
          );
        },
      },
    },
    {
      name: "tipo",
      label: "TIPO",
    },
    {
      name: "concepto",
      label: "CONCEPTO",
    },
    {
      name: "cantidad",
      label: "CANTIDAD",
      options: {
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: "idUsuario",
      label: "USUARIO",
    },
    {
      name: "comprobante",
      label: "COMPROBANTE",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const isValidURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value);

          if (isValidURL) {
            return (
              <a href={value} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon
                  icon={faEye}
                  style={{ color: "#000080" }}
                />
              </a>
            );
          } else {
            return "No hay comprobante para visualizar";
          }
        },
      },
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "true") {
            estiloTexto = "activo";
            estadoTexto = "Activo";
          } else {
            estiloTexto = "inhabilitado";
            estadoTexto = "Inhabilitado";
          }

          return (
            <>
              <Badge
                bg={estado == "true" ? "success" : "danger"}
              //className="estado"
              >
                {estiloTexto}
              </Badge>
            </>
          );
        },
      },
    }
  ];

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };


  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      {loading && <Load />}
      <h1>Gastos en mantenimiento de máquinas</h1>
      <div>
        <div className="divButton">
          <Button
            className="btnAddTables"
            onClick={() =>
              registroGasto(
                <RegistroDineroMantenimiento setShow={setShowModal} history={history} />
              )
            }
          >
            <FontAwesomeIcon icon={faPlus} /> Agregar
          </Button>
        </div>
      </div>
      <div className="divTabla">
        <MUIDataTable
          title={"Lista de gastos registrados"}
          data={listGastos}
          columns={columns}
          options={options}
        />
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
};

function formatModelGastos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      fechayHora: data.fechayHora,
      tipo: data.tipo,
      concepto: data.concepto,
      cantidad: data.cantidad,
      idUsuario:
        data.idUsuario == "" ? "Sin registro" : data.idUsuario.split("/")[0],
      comprobante: data.comprobante,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default withRouter(TablaGastosMaquinas);
