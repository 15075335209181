import { Form, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { Load } from "../load/load";
import { registraDoctores, obtenerNumeroDoctor } from "../../api/doctores";
import {
  obtenerEstablecimientosPorFolio,
  listarEstablecimientosPorCorreo,
} from "../../api/establecimientos";
import { registraUsuarios } from "../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import queryString from "query-string";
import { subeArchivosCloudinary } from "../../api/cloudinary";
import Dropzone from "../dropzone/Dropzone";
import estadosPaisData from '../administrador/catalogosJSON/estadosPais.json';
import municipiosEstadoData from '../administrador/catalogosJSON/municipiosEstadosPais.json'
import { LogsInformativos } from "../logs/logs";

export default function DatosDoctores({ history, setShow }) {
  const [formData, setFormData] = useState(initialFormValue());
  const [estadoPais, setEstadoPais] = useState("");
  const [municipioSeleccionado, setMunicipioSeleccionado] = useState("");
  const [calle, setCalle] = useState("");
  const [numeroExterior, setNumeroExterior] = useState("");
  const [numeroInterior, setNumeroInterior] = useState("");
  const [colonia, setColonia] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [haceEsquina, setHaceEsquina] = useState("");


  // Estado para almacenar las direcciones
  const [addresses, setAddresses] = useState([]);

  // Function to add address to array
  const handleAddAddress = (e) => {
    e.preventDefault();
    const newAddress = {
      calle,
      numeroExterior,
      numeroInterior,
      colonia,
      estado: estadoPais,
      municipio: municipioSeleccionado,
      codigoPostal,
      haceEsquina,
    };

    setAddresses([...addresses, newAddress]);

    // Limpiar campos después de agregar la dirección
    setCalle("");
    setNumeroExterior("");
    setNumeroInterior("");
    setColonia("");
    setEstadoPais("");
    setMunicipioSeleccionado("");
    setCodigoPostal("");
    setHaceEsquina("");
  };

  //Para almacenar la imagen del producto que se guardara a la bd
  const [imagenProducto, setImagenProducto] = useState(null);

  //load
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [tipoUsuario, setTipoUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [listarDoctor, setListDoctor] = useState([]);

  const obtenerDoctor = () => {
    try {
      listarEstablecimientosPorCorreo(correoUsuario)
        .then((response) => {
          const { data } = response;

          if (!listarDoctor && data) {
            setListDoctor(formatModelDoctor(data));
          } else {
            const datosDoctor = formatModelDoctor(data);
            setListDoctor(datosDoctor);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerDoctor();
  }, [correoUsuario]);

  const [nombreConsultorio, setNombreConsultorio] = useState("");
  const [correoConsultorio, setCorreoConsultorio] = useState("");

  const cargarDatosConsultorio = () => {
    //
    obtenerEstablecimientosPorFolio(
      tipoUsuario == "administrador"
        ? formData.folioConsultorio
        : listarDoctor[0]?.folio
    )
      .then((response) => {
        const { data } = response;

        //setNombreConsultorio(data.razonSocial);
        setCorreoConsultorio(data.email);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    cargarDatosConsultorio();
  }, [formData.folioConsultorio, tipoUsuario, listarDoctor[0]?.folio]);

  const [password, setPassword] = useState("");

  const generateRandomPassword = () => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
    const passwordLength = 12; // Cambia esto para ajustar la longitud de la contraseña
    let newPassword = "";

    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      newPassword += charset[randomIndex];
    }

    setPassword(newPassword);
  };

  useEffect(() => {
    generateRandomPassword();
  }, []);

  // Para almacenar el folio actual
  const [folioActual, setFolioActual] = useState("");

  const obtenerFolio = () => {
    try {
      obtenerNumeroDoctor()
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { noDoctor } = data;
          setFolioActual(noDoctor);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerFolio();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    if (
      !formData.nombre ||
      !formData.apellidoPaterno ||
      !formData.telefonoCelular ||
      !formData.email
    ) {
      toast.warning("Completa el formulario");
      return;
    }

    if (!nombreConsultorio) {
      toast.warning("No existe un consultorio con este Folio");
      return;
    }

    try {
      setLoading(true);

      let imagenUrl = "";

      // Upload the image to Cloudinary if available
      if (imagenProducto) {
        const uploadResponse = await subeArchivosCloudinary(imagenProducto, "doctores");
        imagenUrl = uploadResponse.data.secure_url;
      }

      // Create the data object to register the doctor
      const dataTemp = {
        folio: folioActual,
        nombre: formData.nombre,
        apellidoPaterno: formData.apellidoPaterno,
        apellidoMaterno: formData.apellidoMaterno,
        fechaNacimiento: formData.fechaNacimiento,
        telefonoCelular: formData.telefonoCelular,
        email: formData.email,
        rfc: formData.rfc,
        observaciones: formData.observaciones,
        estado: "true",
        nombreConsultorio: nombreConsultorio,
        imagen: imagenUrl,
        calle: calle,
        nExterior: numeroExterior,
        nInterior: numeroInterior,
        colonia: colonia,
        estadoDom: estadoPais,
        municipio: municipioSeleccionado,
        cPostal: codigoPostal,
        referencia: haceEsquina,
        direcciones: addresses
      };

      // Create the data object to register the user
      const dataTemp2 = {
        nombre: `${formData.nombre} ${formData.apellidoPaterno} ${formData.apellidoMaterno}`,
        email: formData.email,
        password: password,
        tipo: "doctor",
        estado: "true",
        imagen: imagenUrl,
      };

      await registraUsuarios(dataTemp2)
        .then(() => {
          console.log("Usuario registrado, ahora registrando doctor");
          return registraDoctores(dataTemp);
        })
        .then((messengerResponse) => {
          console.log("Doctor registrado con éxito:", messengerResponse.data);

          const messengerData = messengerResponse.data;
          // Update the history and reset form
          history({ search: queryString.stringify("") });
          console.log(messengerData);
          LogsInformativos(`Se ha registrado el doctor ${dataTemp.nombre}`, dataTemp);

          toast.success(messengerData.mensaje);
          setShow(false);
          setLoading(false);

        })
    .catch((ex) => {
      if (ex.message === "Network Error") {
        toast.error("Conexión al servidor no disponible");
      } else if (ex.response && ex.response.status === 401) {
        toast.warning("Ya existe un usuario con este correo");
      } else {
        console.log(ex);
      }
      setLoading(false);
    });

} catch (ex) {
  console.log(ex);
}
  };

const onChange = (e) => {
  setFormData({ ...formData, [e.target.name]: e.target.value });
};

return (
  <>
    {loading && <Load />}
    <div className="generalDoctores">
      <div className="imagenPrincipal">
        <h4 className="textoImagenPrincipal">Sube tu imagen</h4>
        <div
          title="Seleccionar imagen de la categoría"
          className="imagenProducto"
        >
          <Dropzone setImagenFile={setImagenProducto} />
        </div>
      </div>
      <Form className="formDoctores" onSubmit={onSubmit} onChange={onChange}>
        <Form.Label className="lblNombreDoc">Folio: <code>*</code></Form.Label>
        <Form.Control
          type="text"
          className="inpNombreEst"
          placeholder="Ingresa nombre"
          name="folio"
          value={folioActual}
          disabled
        />
        <Form.Label className="lblNombreDoc">Consultorio: </Form.Label>
        <Form.Control
          type="text"
          className="inpNombreEst"
          placeholder="Ingresa el nombre del consultorio"
          name="nombreConsultorio"
          value={nombreConsultorio}
          onChange={(e) => setNombreConsultorio(e.target.value.toUpperCase())}
        />
        <Form.Label className="lblNombreDoc">Nombre(s): <code>*</code></Form.Label>
        <Form.Control
          type="text"
          className="inpNombreEst"
          placeholder="Ingresa nombre"
          name="nombre"
          defaultValue={formData.nombre}
        />
        <Form.Group className="datosPersonalesDoc">
          <Form.Label className="lblpApellido">Primer Apellido: <code>*</code></Form.Label>
          <Form.Control
            type="text"
            className="inpPrimerADoc"
            placeholder="Ingresa primer apellido"
            defaultValue={formData.apellidoPaterno}
            name="apellidoPaterno"
          />
          <Form.Label className="lblsApellido">Segundo Apellido: </Form.Label>
          <Form.Control
            type="text"
            className="inpSegundoA"
            placeholder="Ingresa segundo apellido"
            defaultValue={formData.apellidoMaterno}
            name="apellidoMaterno"
          />
        </Form.Group>

        <Form.Group className="datosDoctores">
          <Form.Label className="lblFechaDoc">
            Fecha de nacimiento:{" "}
          </Form.Label>
          <Form.Control
            type="date"
            className="inpFechaDoc"
            placeholder="dd/mm/aaaa"
            defaultValue={formData.fechaNacimiento}
            name="fechaNacimiento"
          />
          <Form.Label className="lblRFCDoc">RFC: </Form.Label>
          <Form.Control
            type="text"
            className="inpRFCDoc"
            placeholder="Ingresa RFC"
            defaultValue={formData.rfc}
            name="rfc"
          />
        </Form.Group>
        <Form.Group className="contactoDoc">
          <Form.Label className="lblTelefonoDoc">
            Telefono celular: <code>*</code>
          </Form.Label>
          <Form.Control
            type="number"
            className="inpTelefonoDoc"
            placeholder="Ingresa telefono a 10 digitos"
            defaultValue={formData.telefonoCelular}
            name="telefonoCelular"
          />
          <Form.Label className="lblCorreoDoc">
            Correo Electrónico: <code>*</code>
          </Form.Label>
          <Form.Control
            type="email"
            className="inpCorreoDoc"
            placeholder="ejemplo@ejemplo.com"
            defaultValue={formData.email}
            name="email"
          />
        </Form.Group>

        <Form.Group className="formObservacionesDoc">
          <Form.Label>Observaciones:</Form.Label>
          <Form.Control
            className="txtObservacionesDoc "
            as="textarea"
            rows={3}
            placeholder="Observaciones"
            defaultValue={formData.observaciones}
            name="observaciones"
          />
        </Form.Group>
        <br />
        <h4>Domicilio</h4>
        <hr />
        <Form.Group className="dom1Cli">
          <Form.Label className="lblCalle">Calle: <code>*</code></Form.Label>
          <Form.Control
            type="text"
            className="inpCalle"
            placeholder="Ingresa calle"
            value={calle}
            onChange={(e) => setCalle(e.target.value)}
          />
          <Form.Label className="lblnExt">Num Ext: <code>*</code></Form.Label>
          <Form.Control
            type="number"
            className="inpNumExt"
            placeholder="Ingresa numero exterior"
            value={numeroExterior}
            onChange={(e) => setNumeroExterior(e.target.value)}
          />
          <Form.Label className="lblnInt">Num Int: <code>*</code></Form.Label>
          <Form.Control
            type="number"
            className="inpNumInt"
            placeholder="Ingresa numero interior"
            value={numeroInterior}
            onChange={(e) => setNumeroInterior(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="dom2Cli">
          <div className="colonia">
            <Form.Label className="lblColonia">Colonia: <code>*</code></Form.Label>
            <Form.Control
              type="text"
              className="inpColonia"
              placeholder="Ingresa colonia"
              value={colonia}
              onChange={(e) => setColonia(e.target.value)}
            />
          </div>
          <div className="estadoCli">
            <Form.Label className="lblEstadoEst">Estado: <code>*</code></Form.Label>
            <Form.Control
              as="select"
              value={estadoPais}
              onChange={(e) => setEstadoPais(e.target.value)}
            >
              <option>Selecciona un Estado</option>
              {estadosPaisData.map((estado) => (
                <option key={estado.clave} value={estado.nombre}>
                  {estado.nombre}
                </option>
              ))}
            </Form.Control>
          </div>
          <div className="municipioCli">
            <Form.Label className="lblMunicipio">Municipio: <code>*</code></Form.Label>
            <Form.Control
              as="select"
              value={municipioSeleccionado}
              onChange={(e) => setMunicipioSeleccionado(e.target.value)}
            >
              <option>Selecciona un Municipio</option>
              {municipiosEstadoData[estadoPais] &&
                municipiosEstadoData[estadoPais].map((municipio) => (
                  <option key={municipio} value={municipio}>
                    {municipio}
                  </option>
                ))}
            </Form.Control>
          </div>
        </Form.Group>
        <Form.Group className="dom3Cli">
          <Form.Label className="lblCP">Codigo Postal: <code>*</code></Form.Label>
          <Form.Control
            type="number"
            className="inpCP"
            placeholder="Ingresa Codigo Postal"
            value={codigoPostal}
            onChange={(e) => setCodigoPostal(e.target.value)}
          />
          <Form.Label className="lblEsquina">Referencia: </Form.Label>
          <Form.Control
            type="text"
            className="inpEsquina"
            placeholder="Ingresa Referencia"
            value={haceEsquina}
            onChange={(e) => setHaceEsquina(e.target.value)}
          />
        </Form.Group>
        <br />
        {/* Botón para agregar dirección */}
        <div>
          <button type="button" onClick={handleAddAddress}>Agregar Dirección</button>
        </div>
        <br />
        {/* Tabla para mostrar las direcciones */}
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Calle</th>
              <th>Num Ext</th>
              <th>Num Int</th>
              <th>Colonia</th>
              <th>Estado</th>
              <th>Municipio</th>
              <th>Codigo Postal</th>
              <th>Referencia</th>
            </tr>
          </thead>
          <tbody>
            {addresses.map((address, index) => (
              <tr key={index}>
                <td>{address.calle}</td>
                <td>{address.numeroExterior}</td>
                <td>{address.numeroInterior}</td>
                <td>{address.colonia}</td>
                <td>{address.estado}</td>
                <td>{address.municipio}</td>
                <td>{address.codigoPostal}</td>
                <td>{address.haceEsquina}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <br />
        <label></label>
        <div className="divSubmit">
          <input className="submit" value="Enviar" type="submit" />
        </div>
      </Form>
    </div>
  </>
);
}

function initialFormValue() {
  return {
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    fechaNacimiento: "",
    telefonoCelular: "",
    email: "",
    rfc: "",
    observaciones: "",
    folioConsultorio: "",
  };
}

function formatModelDoctor(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
      apellidoPaterno: data.apellidoPaterno,
      apellidoMaterno: data.apellidoMaterno,
      fechaNacimiento: data.fechaNacimiento,
      telefonoCelular: data.telefonoCelular,
      email: data.email,
      rfc: data.rfc,
      observaciones: data.observaciones,
      estado: data.estado,
      consultorio: data.consultorio,
      nombreConsultorio: data.nombreConsultorio,
    });
  });
  return dataTemp;
}
