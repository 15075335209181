import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCancel,
  faTasks,
  faPen,
  faBars,
  faEye,
  faArrowLeft,
  faTable,
  faChartSimple,
  faPause,
  faRightLeft,
  faWarning,
  faRepeat,
  faFlagCheckered,
  faImage,
  faShieldAlt,
  faStarHalfStroke,
  faStopCircle,
  faFilePdf
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Dropdown,
  Badge,
  Tab,
  Tabs,
  Form,
  Col,
  Modal,
  Table,
  Row,
} from "react-bootstrap";
import { withRouter } from "../../utils/withRouter";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Load } from "../load/load";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import { toast } from "react-toastify";
import "./style.css";
import CancelarOrdenTrabajo from "./CancelarOrdenTrabajo";
import ActualizarPrioridad from "./ActualizarPrioridad";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  listarServiciosODT,
} from "../../api/servicios";
import AsignarDepartamento from "./AsignarDepartamento";
import GraficasProd from "./produccion/GraficasProd";
import GraficasTiempo from "./produccion/GraficasTiempo";
import GraficasDepartamentos from "./produccion/GraficasDepartamentos";
import Imagenes from "./Imagenes";
import PausarOT from "./PausarOT";
import AgregaIncidenciaOT from "./AgregaIncidenciaOT";
import FinalizarOT from "./FinalizarOT";
import RetrabajarOrdenTrabajo from "./RetrabajarOrdenTrabajo";
import GuardarImagenServicio from "./GuardarImagenServicio";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import ActualizarProceso from "./ActualizarProceso";
import Garantia from "./Garantia";
import FechaEntrega from "./FechaEntrega";
import { listarDiasFestivos } from "../../api/diasFestivos";
import IniciarOT from "./IniciarOT";
import { listarDepartamentosODT, actualizaDeshabilitarDepartamentosODT } from "../../api/departamentosODT";
import { LogsInformativos } from "../logs/logs";
import queryString from "query-string";
import TblTiempoMuertoDepartamento from "./Tabla";
import { listarDepartamentos } from "../../api/departamentos";
import ViewPDF from "./pdf";

function TblOrdenesTrabajo(props) {
  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const [departamento, setDepartamento] = useState("");

  let fecha = new Date();

  // Obtener el día, mes y año
  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
  let year = fecha.getFullYear();
  let hora = fecha.getHours(); // Obtener la hora (formato de 0 a 23)
  let minutos = fecha.getMinutes();
  let segundos = fecha.getSeconds(); // Obtener los minutos
  let datosFecha = `${year}-${mes < 10 ? "0" + mes : mes}-${dia} ${hora}:${minutos < 10 ? "0" + minutos : minutos}:${segundos < 10 ? "0" + segundos : segundos}`;

  const iniciarDepartamento = async (id) => {
    try {
      const dataTemp = {
        estado: "2", // Toggle state
        fechaInicio: datosFecha
      };
      const response = await actualizaDeshabilitarDepartamentosODT(id, dataTemp);
      const { data } = response;
      toast.success(data.mensaje);
      history({
        search: queryString.stringify(""),
      });
      LogsInformativos(`Se ha pausado la orden de trabajo ${id}`, dataTemp);
    } catch (e) {
      console.error(e);
    }
  };

  const finalizarDepartamento = async (id) => {
    try {
      const dataTemp = {
        estado: "3", // Toggle state
        fechaTermino: datosFecha
      };
      const response = await actualizaDeshabilitarDepartamentosODT(id, dataTemp);
      const { data } = response;
      toast.success(data.mensaje);
      history({
        search: queryString.stringify(""),
      });
      LogsInformativos(`Se ha pausado la orden de trabajo ${id}`, dataTemp);
    } catch (e) {
      console.error(e);
    }
  };

  const [listarOrdenes2, setListOrdenes2] = useState([]);

  const obtenerOrdenesServicio = async () => {
    try {
      const response = await listarDiasFestivos();
      const { data } = response;
      setListOrdenes2(formatModelOrdenes2(data));
    } catch (e) {
      console.error('Error al obtener las órdenes de servicio:', e);
    }
  };

  const [listarDeps, setListDeps] = useState([]);

  const obtenerDepartamentossss = async () => {
    try {
      const response = await listarDepartamentos();
      const { data } = response;
      setListDeps(formatModelDepartamento(data));
    } catch (e) {
      console.error('Error al obtener los departamentos:', e);
    }
  };

  useEffect(() => {
    obtenerDepartamentossss();
  }, []);

  const [listarDepartamentoss, setListDepartamentoss] = useState([]);

  const obtenerDepartamentos = (folio) => {
    try {
      listarDepartamentosODT()
        .then((response) => {
          const { data } = response;
          if (data) {
            const datosFiltrados = data.filter(item => item.folioODT === folio);
            const datosUltimoDepartamento = formatModelUltimoDepartamento(datosFiltrados);
            setListDepartamentoss(datosUltimoDepartamento);
          }
        })
        .catch((e) => {
          // manejar el error si es necesario
          console.error(e);
        });
    } catch (e) {
      // manejar el error si es necesario
      console.error(e);
    }
  };


  useEffect(() => {
    obtenerOrdenesServicio();
  }, []);

  const { location, history } = props;

  const [loading, setLoading] = useState(true);

  const [tipoUsuario, setTipoUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [departamentoUsuario, setDepartamentoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
          setCorreoUsuario(data.email);
          setNombreUsuario(data.nombre);
          setDepartamentoUsuario(data.departamento);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const [listarOrdenes, setListOrdenes] = useState([]);

  const obtenerServciosRuta = () => {
    try {
      listarServiciosODT()
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (!listarOrdenes && data) {
            setListOrdenes(formatModelOrdenes(data));
          } else {
            const datosOrdenes = formatModelOrdenes(data);
            setListOrdenes(datosOrdenes);
          }
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerServciosRuta();
  }, [location]);

  console.log(listarOrdenes)

  const [filterSelect, setFilterSelect] = useState("Todos");
  const [filterList, setFilterList] = useState([]);
  const [filterList2, setFilterList2] = useState([]);
  console.log(listarOrdenes);

  useEffect(() => {
    if (tipoUsuario == "administrador") {
      const ordenesFilter = listarOrdenes.filter(
        (ordenes) => ordenes.folioDepartamento === departamento
      );
      setFilterList2(ordenesFilter);
    } else {
      const ordenesFilter = listarOrdenes.filter(
        (ordenes) => ordenes.departamento === departamentoUsuario
      );
      setFilterList2(ordenesFilter);
    }
  }, [departamentoUsuario, listarOrdenes, tipoUsuario, departamento]);

  useEffect(() => {
    if (filterSelect === "Todos") {
      setFilterList(filterList2);
    } else {
      const ordenesFilter = filterList2.filter(
        (ordenes) => ordenes.estado === filterSelect
      );
      setFilterList(ordenesFilter);
    }
  }, [filterSelect, listarOrdenes, filterList2]);

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarOrdenes);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);
  const [size, setSizeModal] = useState(null);

  const eliminarOrden = (content) => {
    setTitulosModal("Cancelar orden de trabajo");
    setContentModal(content);
    setShowModal(true);
    //setSizeModal("lg");
  };

  const finalizarOrden = (content) => {
    setTitulosModal("Finalizar orden de trabajo");
    setContentModal(content);
    setShowModal(true);
    //setSizeModal("lg");
  };

  const pausarOT = (content) => {
    setTitulosModal("Pausar trabajo");
    setContentModal(content);
    setShowModal(true);
    //setSizeModal("lg");
  };
  const transferirOT = (content) => {
    setTitulosModal("Transferir trabajo");
    setContentModal(content);
    setShowModal(true);
  };
  const incidenciaOT = (content) => {
    setTitulosModal("Registrar incidencia");
    setContentModal(content);
    setShowModal(true);
  };
  const actualizarPrioridad = (content) => {
    setTitulosModal("Actualizar prioridad orden de trabajo");
    setContentModal(content);
    setShowModal(true);
  };
  const retrabajarOrdenTrabajo = (content) => {
    setTitulosModal("Retrabajar orden de trabajo");
    setContentModal(content);
    setShowModal(true);
  };
  const actualizarProceso = (content) => {
    setTitulosModal("Actualizar el proceso de la orden de trabajo");
    setContentModal(content);
    setShowModal(true);
  };
  const guardarImagen = (content) => {
    setTitulosModal("Guardar imagen");
    setContentModal(content);
    setShowModal(true);
  };
  const garantia = (content) => {
    setTitulosModal("Agregar garantia");
    setContentModal(content);
    setShowModal(true);
  };
  const iniciar = (content) => {
    setTitulosModal("Iniciar ODT");
    setContentModal(content);
    setShowModal(true);
  };

  const pdfMovimientos = (content) => {
    setTitulosModal("PDF de los movimientos del departamento " + departamento);
    setContentModal(content);
    setShowModal(true);
  };


  const [mostrarPrimerDiv, setMostrarPrimerDiv] = useState(true);
  const [linksImagenes, setlinksImagenes] = useState([]);
  const [folioOT, setfolioOT] = useState("");

  const handleClick = (tableMeta) => {
    if (tableMeta && tableMeta.rowData) {
      const rowData = tableMeta.rowData;
      const links = [rowData[10], rowData[11]];
      setlinksImagenes(links);
      setfolioOT(rowData[2]);
    } else {
      console.error("tableMeta o tableMeta.rowData es undefined");
    }
    setMostrarPrimerDiv(!mostrarPrimerDiv);
  };

  const [showModal2, setShowModal2] = useState(false);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "folioODS",
      label: "FOLIO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "folio",
      label: "ODS",
    },
    {
      name: "fecha",
      label: "FECHA DE CREACION",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {dayjs(value).format("LL")}
            </>
          );
        },
      },
    },
    {
      name: "pieza",
      label: "PIEZA",
      options: {
        customBodyRender: (value) => value.split("/")[0],
      },
    },
    {
      name: "sistemaColor",
      label: "COLORIMETRIA",
    },
    {
      name: "tipoDentadura",
      label: "TIPO DE DENTADURA",
      options: {
        customBodyRender: (value) => {
          if (value === "DT") {
            return "Temporal";
          } else if (value === "DP") {
            return "Permanente";
          }
        },
      },
    },
    {
      name: "dientes",
      label: "DIENTES",
    },
    {
      name: "tonos",
      label: "TONOS",
      options: {
        customBodyRender: (value) => {
          const tonos = value.split(",");
          return (
            <>
              <span>TS: {tonos[2]}</span>
              <br />
              <span>TM: {tonos[1]}</span>
              <br />
              <span>TI: {tonos[0]}</span>
            </>
          );
        },
      },
    },
    {
      name: "clasificacion",
      label: "DEPARTAMENTO",
    },
    {
      name: "imagenDiente",
      label: "IMAGEN DIENTE",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "imagenEsquema",
      label: "IMAGEN ESQUEMA",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "imagenes",
      label: "IMAGENES",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button variant="link" onClick={() => handleClick(tableMeta)}>
                <FontAwesomeIcon
                  icon={faEye}
                  style={{ color: "#000080" }}
                />
              </Button>
            </>
          );
        },
      },
    },
    {
      name: "prioridad",
      label: "PRIORIDAD",
      options: {
        customBodyRender: (value, tableMeta) => {
          if (value == "") {
            const hoy = dayjs(tableMeta.rowData[3]).format("d") - 1; //numero del dia de la semana de la fecha de entrega
            const diaElegido = tableMeta.rowData[16]; //dia de la semana (en letra) del dia que le toca recoleccion a esa ruta
            // Función para determinar la prioridad del día con desplazamiento
            const determinarPrioridadDia = (desplazamiento) => {
              if ((desplazamiento === 0 || desplazamiento === 1) && tipoUsuario != "departamento") {
                return "normal";
              } else if ((desplazamiento === 0 || desplazamiento === 1) && tipoUsuario == "departamento") {
                return "normal";
              } else if (desplazamiento === 2 || desplazamiento === 3) {
                return "normal";
              } else {
                return "urgencia";
              }
            };

            const diasSemana = [
              "lunes",
              "martes",
              "miercoles",
              "jueves",
              "viernes",
              "sabado",
              "domingo", // Ignoramos el domingo, ya que estamos tratando la semana como si solo tuviera 6 días
            ];

            const indexDiaElegido =
              (hoy - diasSemana.indexOf(diaElegido) + 6) % 6; // Ajustar para que el resultado esté entre 0 y 5
            const prioridadDiaElegido = determinarPrioridadDia(indexDiaElegido);

            const fechaActual = new Date();
            const fechaa = tableMeta.rowData[3].split(" ")[0];
            const fechaIngresada = new Date(fechaa + "T23:59:59");

            return (
              <>
                <Badge
                  bg={
                    fechaIngresada <= fechaActual
                      ? "danger"
                      : prioridadDiaElegido == "urgencia"
                        ? "danger"
                        : prioridadDiaElegido == "normal"
                          ? "warning"
                          : "info"
                  }
                  style={{ textTransform: "capitalize" }}
                >
                  {fechaIngresada <= fechaActual
                    ? prioridadDiaElegido + "⚠️"
                    : prioridadDiaElegido}
                </Badge>
              </>
            );
          } else {
            const estado = value;

            const fechaActual = new Date();
            const fechaa = tableMeta.rowData[3].split(" ")[0];
            const fechaIngresada = new Date(fechaa + "T23:59:59");
            return (
              <>
                <Badge
                  bg={
                    fechaIngresada <= fechaActual
                      ? "danger"
                      : estado == "urgencia"
                        ? "danger"
                        : estado == "normal"
                          ? "warning"
                          : "info"
                  }
                  style={{ textTransform: "capitalize" }}
                >
                  {fechaIngresada <= fechaActual ? (estado == "normal" && tipoUsuario == "departamento") ? "normal" : estado + "⚠️" : estado}
                </Badge>
              </>
            );
          }
        },
      },
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "2") {
            estiloTexto = "En Proceso";
            estadoTexto = "En Proceso";
          } else if (estado == "3") {
            estiloTexto = "Pausada";
            estadoTexto = "Pausada";
          } else if (estado == "4") {
            estiloTexto = "Finalizada";
            estadoTexto = "Finalizada";
          } else if (estado == "5") {
            estiloTexto = "Asignada";
            estadoTexto = "Asignada";
          } else {
            estiloTexto = "inhabilitado";
            estadoTexto = "Inhabilitado";
          }

          return (
            <>
              <Badge
                bg={
                  estado == "2"
                    ? "primary"
                    : estado == "3"
                      ? "warning"
                      : estado == "4"
                        ? estado == "5"
                          ? "warning" :
                          "success"
                        : "danger"
                }
              //className="estado"
              >
                {estiloTexto}
              </Badge>
            </>
          );
        },
      },
    },
    {
      name: "direccionConsultorio",
      label: "RUTA",
    },
    {
      name: "dia",
      label: "FECHA DE ENTREGA",
      options: {
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[0];
          const fecha = tableMeta.rowData[3];
          const ruta = tableMeta.rowData[15];

          return <FechaEntrega id={id} fecha={fecha} ruta={ruta} listarOrdenes={listarOrdenes2} />;
        },
      },
    },
    {
      name: "numeroProceso",
      label: "NUMERO DEL PROCESO ACTUAL",
    },
    {
      name: "nombreProceso",
      label: "NOMBRE DEL PROCESO ACTUAL",
    },
    {
      name: "motivoPausa",
      label: "MOTIVO DE LA PAUSA",
    },
    {
      name: "imagenResultadoFinal",
      label: "IMAGEN DEL RESULTADO DEL TRABAJO",
      options: {
        customBodyRender: (value) => {
          if (value != "Sin Imagen") {
            return (
              <a href={value} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon
                  icon={faEye}
                  style={{ color: "#000080" }}
                />
              </a>
            );
          } else {
            return (
              <Badge bg="secondary">
                no disponible
              </Badge>
            );
          }
        },
      },
    },
    {
      name: "garantia",
      label: "GARANTIA",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "0") {
            estiloTexto = "No disponible";
            estadoTexto = "No disponible";
          } else if (estado == "1") {
            estiloTexto = "En garantia";
            estadoTexto = "En garantia";
          } else if (estado == "2") {
            estiloTexto = "Sin garantia";
            estadoTexto = "sin garantia";
          }

          return (
            <>
              {estiloTexto}
            </>
          );
        },
      },
    },
    {
      name: "departamentos",
      label: "DEPARTAMENTOS",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Button
            variant="link"
            onClick={() => {
              obtenerDepartamentos(tableMeta.rowData[2]);
              setShowModal2(true);
            }}
          >
            <FontAwesomeIcon
              icon={faEye}
              style={{ color: "#000080" }}
            />
          </Button>
        ),
      },
    },
    {
      name: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[14] == "2" || tableMeta.rowData[14] == "3" || tableMeta.rowData[14] == "5" ? (
            tipoUsuario === "administrador" || tipoUsuario == "departamento" ? (
              <>
                <Dropdown>
                  <Dropdown.Toggle
                    style={{
                      backgroundColor: "#0c1b30",
                      borderColor: "#0c1b30",
                    }}
                    id="dropdown-basic"
                  >
                    <FontAwesomeIcon icon={faBars} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {tableMeta.rowData[14] == "3" ? (
                      <Dropdown.Item
                        onClick={() =>
                          pausarOT(
                            <PausarOT
                              history={history}
                              setShow={setShowModal}
                              data={tableMeta.rowData}
                            />
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faRepeat}
                          style={{ color: "#28a745" }}
                        />
                        &nbsp;&nbsp;Reanudar
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item
                        onClick={() =>
                          pausarOT(
                            <PausarOT
                              history={history}
                              setShow={setShowModal}
                              data={tableMeta.rowData}
                            />
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faPause}
                          style={{ color: "#fd7e14" }}
                        />
                        &nbsp;&nbsp;Pausar
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      onClick={() =>
                        transferirOT(
                          <AsignarDepartamento
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faRightLeft}
                        style={{ color: "#28a745" }}
                      />
                      &nbsp;Transferir
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        iniciar(
                          <IniciarOT
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faStarHalfStroke}
                        style={{ color: "#28a745" }}
                      />
                      &nbsp;Iniciar
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={() =>
                        actualizarProceso(
                          <ActualizarProceso
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faRightLeft}
                        style={{ color: "#28a745" }}
                      />
                      &nbsp;Proceso
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={() =>
                        incidenciaOT(
                          <AgregaIncidenciaOT
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                            usuario={nombreUsuario}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faWarning}
                        style={{ color: "#ffc107" }}
                      />
                      &nbsp;Incidencia
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        actualizarPrioridad(
                          <ActualizarPrioridad
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faPen}
                        style={{ color: "#007bff" }}
                      />
                      &nbsp;Actualizar prioridad
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        eliminarOrden(
                          <CancelarOrdenTrabajo
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faCancel}
                        style={{ color: "#dc3545" }}
                      />
                      &nbsp;Cancelar Orden
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        finalizarOrden(
                          <FinalizarOT
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faFlagCheckered} />
                      &nbsp;Finalizar Orden
                    </Dropdown.Item>
                    {(tipoUsuario === "administrador" || tipoUsuario === "mesa de control") && (
                      <Dropdown.Item
                        onClick={() =>
                          garantia(
                            <Garantia
                              history={history}
                              setShow={setShowModal}
                              data={tableMeta.rowData}
                            />
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faShieldAlt} style={{ color: "#6c757d" }} />
                        &nbsp;&nbsp;Garantía
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : tipoUsuario == "departamento" ? (
              <>
                <Dropdown>
                  <Dropdown.Toggle
                    style={{
                      backgroundColor: "#0c1b30",
                      borderColor: "#0c1b30",
                    }}
                    id="dropdown-basic"
                  >
                    <FontAwesomeIcon icon={faBars} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {tableMeta.rowData[14] == "3" ? (
                      <Dropdown.Item
                        onClick={() =>
                          pausarOT(
                            <PausarOT
                              history={history}
                              setShow={setShowModal}
                              data={tableMeta.rowData}
                            />
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faRepeat}
                          style={{ color: "#28a745" }}
                        />
                        &nbsp;&nbsp;Reanudar
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item
                        onClick={() =>
                          pausarOT(
                            <PausarOT
                              history={history}
                              setShow={setShowModal}
                              data={tableMeta.rowData}
                            />
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faPause}
                          style={{ color: "#fd7e14" }}
                        />
                        &nbsp;&nbsp;Pausar
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      onClick={() =>
                        finalizarOrden(
                          <FinalizarOT
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faFlagCheckered} />
                      &nbsp;Finalizar Orden
                    </Dropdown.Item>
                    {(tipoUsuario === "administrador" || tipoUsuario === "mesa de control") && (
                      <Dropdown.Item
                        onClick={() =>
                          garantia(
                            <Garantia
                              history={history}
                              setShow={setShowModal}
                              data={tableMeta.rowData}
                            />
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faShieldAlt} style={{ color: "#6c757d" }} />
                        &nbsp;&nbsp;Garantía
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : null
          ) : (
            <>
              {tipoUsuario == "administrador" && tableMeta.rowData[14] == "4" ? (
                <Dropdown>
                  <Dropdown.Toggle
                    style={{
                      backgroundColor: "#0c1b30",
                      borderColor: "#0c1b30",
                    }}
                    id="dropdown-basic"
                  >
                    <FontAwesomeIcon icon={faBars} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        retrabajarOrdenTrabajo(
                          <RetrabajarOrdenTrabajo
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faTasks}
                        style={{ color: "#28a745" }}
                      />
                      &nbsp;Retrabajar
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        guardarImagen(
                          <GuardarImagenServicio
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faImage}
                        style={{ color: "#87CEEB" }}
                      />
                      &nbsp;&nbsp;Guardar imagen del trabajo
                    </Dropdown.Item>
                    {(tipoUsuario === "administrador" || tipoUsuario === "mesa de control") && (
                      <Dropdown.Item
                        onClick={() =>
                          garantia(
                            <Garantia
                              history={history}
                              setShow={setShowModal}
                              data={tableMeta.rowData}
                            />
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faShieldAlt} style={{ color: "#6c757d" }} />
                        &nbsp;&nbsp;Garantía
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                "No disponibles"
              )}
            </>
          );
        },
      },
    }
  ];

  useEffect(() => {
    if (!showModal) {
      obtenerServciosRuta();
    }
  }, [showModal]);

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  console.log(filterList);
  console.log(listarOrdenes);

  return (
    <>
      {loading && <Load />}
      <h1>Ordenes de trabajo</h1>
      <Tabs
        defaultActiveKey="home"
        id="uncontrolled-tab-example"
        className="mb-3"
        justify
        style={{
          fontSize: "larger",
          fontWeight: "bold",
          marginTop: "1%",
        }}
      >
        <Tab
          eventKey="home"
          title={
            <span style={{ textTransform: "capitalize" }}>
              <FontAwesomeIcon icon={faTable} /> Tabla
            </span>
          }
        >
          {mostrarPrimerDiv ? (
            <>
              <div className="divTabla">
                <div className="mb-3">
                  <Row>
                    {/* Filtro por status */}
                    <Col xs={12} md={2} lg={2}>
                      <Form.Label style={{ fontSize: "larger" }}>
                        Filtrar por status:
                      </Form.Label>
                    </Col>
                    <Col xs={12} md={3} lg={3}>
                      <Form.Control
                        as="select"
                        value={filterSelect}
                        onChange={(e) => setFilterSelect(e.target.value)}
                      >
                        <option value="Todos">Todos</option>
                        <option value="2">En Proceso</option>
                        <option value="3">Pausada</option>
                        <option value="4">Finalizada</option>
                        <option value="5">Asignada</option>
                        <option value="false">Cancelada</option>
                      </Form.Control>
                    </Col>

                    {/* Filtro por departamento */}
                    <Col xs={12} md={2} lg={2}>
                      <Form.Label>
                        DEPARTAMENTO:
                      </Form.Label>
                    </Col>
                    <Col xs={12} md={3} lg={3}>
                      <Form.Control
                        as="select"
                        value={departamento}
                        onChange={(e) => setDepartamento(e.target.value)}
                      >
                        <option value="">Seleccionar departamento</option>
                        {listarDeps.map((departamento) => (
                          <option key={departamento.folio} value={departamento.folio}>
                            {departamento.nombre}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>

                    {/* Botón Descargar PDF */}
                    <Col xs={12} md={2} lg={2} className="d-flex align-items-center">
                      <Button
                        variant="danger"
                        onClick={() =>
                          pdfMovimientos(
                            <ViewPDF setShow={setShowModal} listOrdenes={filterList} />
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faFilePdf} /> Descargar PDF
                      </Button>
                    </Col>
                  </Row>
                </div>
                <MUIDataTable
                  title={"Lista de ordenes de trabajo registradas"}
                  data={filterList}
                  columns={columns}
                  options={options}
                />
              </div>

              <BasicModal
                show={showModal}
                setShow={setShowModal}
                title={titulosModal}
              >
                {contentModal}
              </BasicModal>
              <Modal
                show={showModal2}
                onHide={() => setShowModal2(false)}
                size="xl"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Información detallada</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>DEPARTAMENTO</th>
                          <th>FECHA DE INGRESO</th>
                          <th>FECHA DE SALIDA</th>
                          <th>ACCIONES</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listarDepartamentoss.map((selected, index) => (
                          <tr key={index}>
                            <td>{selected?.departamento}</td>
                            <td>{selected?.fechaEntrada}</td>
                            <td>{selected?.fechaSalida}</td>
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                                  <FontAwesomeIcon icon={faBars} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {!selected.fechaInicio && (
                                    <>
                                      <Dropdown.Item
                                        onClick={() =>
                                          iniciarDepartamento(selected?.id)}
                                      >
                                        <FontAwesomeIcon
                                          icon={faStarHalfStroke}
                                          style={{ color: "#87CEEB" }}
                                        />
                                        &nbsp;&nbsp;Iniciar
                                      </Dropdown.Item>
                                    </>
                                  )}
                                  {selected.fechaInicio && !selected.fechaTermino && (
                                    <>
                                      <Dropdown.Item
                                        onClick={() =>
                                          finalizarDepartamento(selected?.id)}
                                      >
                                        <FontAwesomeIcon
                                          icon={faStopCircle}
                                          style={{ color: "#87CEEB" }}
                                        />
                                        &nbsp;&nbsp;Finalizar
                                      </Dropdown.Item>
                                    </>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowModal2(false)}
                  >
                    Cerrar
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          ) : (
            <div>
              <div>
                <Button
                  variant="primary"
                  style={{ color: "#fff", textDecoration: "none" }}
                  onClick={handleClick}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Button>
              </div>
              <div>
                <Imagenes links={linksImagenes} folio={folioOT} />
              </div>
            </div>
          )}
        </Tab>
        {tipoUsuario == "administrador" && (
          <Tab
            eventKey="profile"
            title={
              <span style={{ textTransform: "capitalize" }}>
                <FontAwesomeIcon icon={faChartSimple} /> Gráficas
              </span>
            }
          >
            <div>
              <GraficasProd />
            </div>
          </Tab>
        )}
        {tipoUsuario == "administrador" && (
          <Tab
            eventKey="time"
            title={
              <span style={{ textTransform: "capitalize" }}>
                <FontAwesomeIcon icon={faChartSimple} /> Gráficas tiempo
              </span>
            }
          >
            <div>
              <GraficasTiempo />
            </div>
          </Tab>
        )}
        {tipoUsuario == "administrador" && (
          <Tab
            eventKey="deps"
            title={
              <span style={{ textTransform: "capitalize" }}>
                <FontAwesomeIcon icon={faChartSimple} /> Gráficas departamentos
              </span>
            }
          >
            <div>
              <GraficasDepartamentos />
            </div>
          </Tab>
        )}
        {tipoUsuario == "administrador" && (
          <Tab
            eventKey="table"
            title={
              <span style={{ textTransform: "capitalize" }}>
                <FontAwesomeIcon icon={faTable} /> Tabla tiempos por departamento
              </span>
            }
          >
            <div>
              <TblTiempoMuertoDepartamento />
            </div>
          </Tab>
        )}
      </Tabs>
    </>
  );
}

function formatModelDepartamento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      folio: data.folio,
      nombre: data.nombre,
    });
  });
  return dataTemp;
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      folioODS: data.ODT,
      fecha: data.fechaHora,
      pieza: data.pieza,
      sistemaColor: data.sistemaColor,
      tipoDentadura: data.distencion,
      folioDepartamento: data.folioDepartamento,
      tonos:
        data.tonoInferior + ", " + data.tonoMedio + ", " + data.tonoSuperior,
      dientes: data.diente,
      departamento: data.departamento,
      clasificacion: data.clasificacion,
      imagenDiente: data.imagenDiente,
      imagenEsquema: data.imagenEsquema,
      prioridad: data.prioridad,
      estado: data.estado,
      direccionConsultorio: data.municipio,
      dia: data.dia,
      numeroProceso: data.numeroProceso,
      nombreProceso: data.nombreProceso,
      motivoPausa: data.motivoPausa,
      imagenResultadoFinal: !data.imagenResultadoFinal ? "Sin Imagen" : data.imagenResultadoFinal,
      garantia: !data.garantia ? "1" : data.garantia
    });
  });
  return dataTemp;
}

function formatModelOrdenes2(data) {
  return data.map(data => ({
    id: data._id,
    fecha: data.fecha,
    descripcion: data.descripcion,
    estado: data.estado,
  }));
}

function formatModelUltimoDepartamento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folioODT: data.folioODT,
      departamento: data.departamento,
      fechaEntrada: data.fechaEntrada,
      fechaInicio: data.fechaInicio,
      fechaTermino: data.fechaTermino,
      fechaSalida: data.fechaSalida,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default withRouter(TblOrdenesTrabajo);
