import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import {
  Button,
  Badge
} from "react-bootstrap";
import { withRouter } from "../../utils/withRouter";
import { Load } from "../load/load";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import { toast } from "react-toastify";
import "./style.css";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  listarServiciosRetrabajos
} from "../../api/servicios";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

function TblRetrabajos(props) {
  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const { location, history } = props;

  const [loading, setLoading] = useState(true);

  const [listarOrdenes, setListOrdenes] = useState([]);

  const obtenerServciosRuta = () => {
    try {
      listarServiciosRetrabajos()
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (!listarOrdenes && data) {
            setListOrdenes(formatModelOrdenes(data));
          } else {
            const datosOrdenes = formatModelOrdenes(data);
            setListOrdenes(datosOrdenes);
          }
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerServciosRuta();
  }, [location]);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarOrdenes);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);

  const [mostrarPrimerDiv, setMostrarPrimerDiv] = useState(true);
  const [linksImagenes, setlinksImagenes] = useState([]);
  const [folioOT, setfolioOT] = useState("");

  const handleClick = (tableMeta) => {
    if (tableMeta && tableMeta.rowData) {
      const rowData = tableMeta.rowData;
      const links = [rowData[10], rowData[11]];
      setlinksImagenes(links);
      setfolioOT(rowData[2]);
    } else {
      console.error("tableMeta o tableMeta.rowData es undefined");
    }
    setMostrarPrimerDiv(!mostrarPrimerDiv);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "folioODS",
      label: "FOLIO",
      options: {
        customBodyRender: (value) => {
          const folio = value;

          return (
            <>
              {folio} R
            </>
          );
        },
      },
    },
    {
      name: "folio",
      label: "ODS",
    },
    {
      name: "fecha",
      label: "FECHA Y HORA",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {dayjs(value.split(" ")[0]).format("LL")}
            </>
          );
        },
      },
    },
    {
      name: "pieza",
      label: "PIEZA",
      options: {
        customBodyRender: (value) => value.split("/")[0],
      },
    },
    {
      name: "sistemaColor",
      label: "COLORIMETRIA",
    },
    {
      name: "tipoDentadura",
      label: "TIPO DE DENTADURA",
      options: {
        customBodyRender: (value) => {
          if (value === "DT") {
            return "Temporal";
          } else if (value === "DP") {
            return "Permanente";
          }
        },
      },
    },
    {
      name: "dientes",
      label: "DIENTES",
    },
    {
      name: "tonos",
      label: "TONOS",
      options: {
        customBodyRender: (value) => {
          const tonos = value.split(",");
          return (
            <>
              <span>TS: {tonos[2]}</span>
              <br />
              <span>TM: {tonos[1]}</span>
              <br />
              <span>TI: {tonos[0]}</span>
            </>
          );
        },
      },
    },
    {
      name: "departamento",
      label: "DEPARTAMENTO",
    },
    {
      name: "imagenDiente",
      label: "IMAGEN DIENTE",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "imagenEsquema",
      label: "IMAGEN ESQUEMA",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "imagenes",
      label: "IMAGENES",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button variant="link" onClick={() => handleClick(tableMeta)}>
                <FontAwesomeIcon
                  icon={faEye}
                  style={{ color: "#000080" }}
                />
              </Button>
            </>
          );
        },
      },
    },
    {
      name: "prioridad",
      label: "PRIORIDAD",
      options: {
        customBodyRender: (value, tableMeta) => {
          if (value == "") {
            const hoy = dayjs(tableMeta.rowData[3]).format("d") - 1; //numero del dia de la semana de la fecha de entrega
            const diaElegido = tableMeta.rowData[16]; //dia de la semana (en letra) del dia que le toca recoleccion a esa ruta
            // Función para determinar la prioridad del día con desplazamiento
            const determinarPrioridadDia = (desplazamiento) => {
              if (desplazamiento === 0 || desplazamiento === 1) {
                return "baja";
              } else if (desplazamiento === 2 || desplazamiento === 3) {
                return "media";
              } else {
                return "alta";
              }
            };

            const diasSemana = [
              "lunes",
              "martes",
              "miercoles",
              "jueves",
              "viernes",
              "sabado",
              "domingo", // Ignoramos el domingo, ya que estamos tratando la semana como si solo tuviera 6 días
            ];

            const indexDiaElegido =
              (hoy - diasSemana.indexOf(diaElegido) + 6) % 6; // Ajustar para que el resultado esté entre 0 y 5
            const prioridadDiaElegido = determinarPrioridadDia(indexDiaElegido);

            const fechaActual = new Date();
            const fechaa = tableMeta.rowData[3].split(" ")[0];
            const fechaIngresada = new Date(fechaa + "T23:59:59");

            return (
              <>
                <Badge
                  bg={
                    fechaIngresada <= fechaActual
                      ? "danger"
                      : prioridadDiaElegido == "alta"
                        ? "danger"
                        : prioridadDiaElegido == "media"
                          ? "warning"
                          : "info"
                  }
                  style={{ textTransform: "capitalize" }}
                >
                  {fechaIngresada <= fechaActual
                    ? "No disponibles"
                    : prioridadDiaElegido}
                </Badge>
              </>
            );
          } else {
            const estado = value;

            const fechaActual = new Date();
            const fechaa = tableMeta.rowData[3].split(" ")[0];
            const fechaIngresada = new Date(fechaa + "T23:59:59");
            return (
              <>
                <Badge
                  bg={
                    fechaIngresada <= fechaActual
                      ? "danger"
                      : estado == "alta"
                        ? "danger"
                        : estado == "media"
                          ? "warning"
                          : "info"
                  }
                  style={{ textTransform: "capitalize" }}
                >
                  {fechaIngresada <= fechaActual ? "No disponible" : estado}
                </Badge>
              </>
            );
          }
        },
      },
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "2") {
            estiloTexto = "En Proceso";
            estadoTexto = "En Proceso";
          } else if (estado == "3") {
            estiloTexto = "Pausada";
            estadoTexto = "Pausada";
          } else if (estado == "4") {
            estiloTexto = "Finalizada";
            estadoTexto = "Finalizada";
          } else {
            estiloTexto = "inhabilitado";
            estadoTexto = "Inhabilitado";
          }

          return (
            <>
              <Badge
                bg={
                  estado == "2"
                    ? "success"
                    : estado == "3"
                      ? "warning"
                      : estado == "4"
                        ? "success"
                        : "danger"
                }
              //className="estado"
              >
                {estiloTexto}
              </Badge>
            </>
          );
        },
      },
    },
    {
      name: "direccionConsultorio",
      label: "DIRECCION",
    },
    {
      name: "dia",
      label: "DIA",
    },
    {
      name: "numeroProceso",
      label: "NUMERO DEL PROCESO ACTUAL",
    },
    {
      name: "nombreProceso",
      label: "NOMBRE DEL PROCESO ACTUAL",
    },
  ];

  useEffect(() => {
    if (!showModal) {
      obtenerServciosRuta();
    }
  }, [showModal]);

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      {loading && <Load />}
      <h1>Ordenes de trabajo</h1>
      <>
        <div className="divTabla">
          <MUIDataTable
            title={"Lista de ordenes de trabajo registradas"}
            data={listarOrdenes}
            columns={columns}
            options={options}
          />
        </div>
      </>
    </>
  );
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      folioODS: data.ODT,
      fecha: data.fechaHora,
      pieza: data.pieza,
      sistemaColor: data.sistemaColor,
      tipoDentadura: data.distencion,
      tonos:
        data.tonoInferior + ", " + data.tonoMedio + ", " + data.tonoSuperior,
      dientes: data.diente,
      departamento: data.departamento,
      imagenDiente: data.imagenDiente,
      imagenEsquema: data.imagenEsquema,
      prioridad: data.prioridad,
      estado: data.estado,
      direccionConsultorio: data.direccionConsultorio,
      dia: data.dia,
      numeroProceso: data.numeroProceso,
      nombreProceso: data.nombreProceso
    });
  });
  return dataTemp;
}

export default withRouter(TblRetrabajos);
