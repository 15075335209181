import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarMovimientosMedicamentos,
    ENDPOINTObtenerMovimientosMedicamentos,
    ENDPOINTListarMovimientosMedicamentos,
    ENDPOINTListarMovimientosMedicamentosFiltroFecha,
    ENDPOINTActualizarMovimientosMedicamentos,
    ENDPOINTEliminarMovimientosMedicamentos,
    ENDPOINTDeshabilitarMovimientosMedicamentos,
    ENDPOINTListarMovimientosMedicamentosFiltroGeneralFecha
} from "./endpoints";
import axios from 'axios';

export async function listarMovimientosMedicamentos(idArticuloAMP) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarMovimientosMedicamentos + `/?idArticuloAMP=${idArticuloAMP}`, config);
}

export async function listarMovimientosMedicamentosFiltroFecha(idArticuloAMP, inicio, fin) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarMovimientosMedicamentosFiltroFecha + `/?idArticuloAMP=${idArticuloAMP}&&inicio=${inicio}&&fin=${fin}`, config);
}

export async function listarMovimientosMedicamentosFiltroGeneralFecha(inicio, fin) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarMovimientosMedicamentosFiltroGeneralFecha + `/?inicio=${inicio}&&fin=${fin}`, config);
}

export async function registraMovimientosMedicamentos(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarMovimientosMedicamentos, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerMovimientosMedicamentos(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerMovimientosMedicamentos + `/${params}`, config);
}

export async function actualizarMovimientosMedicamentos(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarMovimientosMedicamentos + `/${id}`, data, config);
}

export async function eliminarMovimientosMedicamentos(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarMovimientosMedicamentos + `/${id}`, data, config);
}

export async function actualizaDeshabilitarMovimientosMedicamentos(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarMovimientosMedicamentos + `/${id}`, data, config);
}