import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Image } from "react-bootstrap";
import imagen3 from "../../assets/img/Slider/3.jpeg";
import imagen4 from "../../assets/img/Slider/4.jpeg";
import imagen5 from "../../assets/img/Slider/5.jpeg";
import imagen6 from "../../assets/img/Slider/6.jpeg";
import imagen7 from "../../assets/img/Slider/7.jpeg";
import imagen8 from "../../assets/img/Slider/8.jpeg";


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper/modules";

export const SwiperPag = () => {
  return (
    <Swiper
    slidesPerView={1}
    spaceBetween={30}
    loop={true}
    pagination={{
      clickable: true,
    }}
    autoplay={{ delay: 3000 }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper"
  >
    {[imagen3, imagen4, imagen5, imagen6, imagen7, imagen8].map((imagen, index) => (
      <SwiperSlide key={index}>
        <Image src={imagen} alt={`Imagen ${index + 1}`} />
      </SwiperSlide>
    ))}
  </Swiper>
  
  );
};
