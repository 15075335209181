import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarVisitasMensajeros,
    ENDPOINTObtenerVisitasMensajeros,
    ENDPOINTListarVisitasMensajeros,
    ENDPOINTActualizarVisitasMensajeros,
    ENDPOINTEliminarVisitasMensajeros,
    ENDPOINTDeshabilitarVisitasMensajeros,
} from "./endpoints";
import axios from 'axios';

export async function listarVisitasMensajeros(fecha){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarVisitasMensajeros + `?fecha=${fecha}`, config);
}

export async function registraVisitasMensajeros(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarVisitasMensajeros, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerVisitasMensajeros(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerVisitasMensajeros + `/${params}`, config);
}

export async function actualizarVisitasMensajeros(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarVisitasMensajeros + `/${id}`, data, config);
}

export async function eliminarVisitasMensajeros(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarVisitasMensajeros + `/${id}`, data, config);
}

export async function actualizaDeshabilitarServicios(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarVisitasMensajeros + `/${id}`, data, config);
}