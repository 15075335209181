import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faFileExcel, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { obtenerOrdenesServicio, actualizaFacturacionOrdenes } from "../../api/ordenesServicio";
import { listarFacturacion } from "../../api/facturacion";
import { listarServiciosFolio } from "../../api/servicios";
import { obtenerDoctoresPorCorreo } from "../../api/doctores";
import { toast } from "react-toastify";
import { facturacion, consultarArchivos } from "../../api/facturas";
import queryString from "query-string";

const XMLComponent = ({ id, pdf, xml, factura, tipoUsuario, history }) => {

  const [datos, setDatos] = useState();

  const obtenerDatosOrden = () => {
    try {
      obtenerOrdenesServicio(id)
        .then((response) => {
          const { data } = response;
          console.log(data)
          setDatos(data);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosOrden();
  }, [id]);

  console.log(datos)

  const [listarFacturaciones, setListFacturacion] = useState([0]);
  console.log(datos?.correoDoctor)
  const obtenerFacturacion = () => {
    try {
      listarFacturacion()
        .then((response) => {
          const { data } = response;
          console.log(data)

          // Filtrar los datos donde idUsuarioDatos es igual a datos?.correoDoctor
          const datosFiltrados = data.filter(
            (item) => item.idUsuarioDatos == datos?.correoDoctor
          );

          // Formatear y actualizar el estado con los datos filtrados
          const datosFacturacion = formatModelFacturacion(datosFiltrados);
          setListFacturacion(datosFacturacion);
        })
        .catch((e) => {
          console.error("Error al obtener la facturación", e);
        });
    } catch (e) {
      console.error("Error en obtenerFacturacion", e);
    }
  };

  useEffect(() => {
    obtenerFacturacion();
  }, [datos?.correoDoctor]);

  console.log(listarFacturaciones)

  const [doctores, setDoctores] = useState();

  const obtenerDoctores = () => {
    try {
      obtenerDoctoresPorCorreo(datos?.correoDoctor)
        .then((response) => {
          const { data } = response;
          console.log(data)
          setDoctores(data);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDoctores();
  }, [datos]);

  console.log(doctores)

  const [listarServicios, setListServicios] = useState([]);

  const obtenerServcios = () => {
    try {
      listarServiciosFolio(datos?.folio)
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (!listarServicios && data) {
            setListServicios(formatModelServicios(data));
          } else {
            const datosServicios = formatModelServicios(data);
            setListServicios(datosServicios);
          }
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerServcios();
  }, [datos?.folio]);

  function limpiarYConvertirMayusculas(nombre) {
    // Eliminar espacios adicionales entre palabras y convertir a mayúsculas
    return nombre?.trim().replace(/\s+/g, ' ').toUpperCase();
  }


  const xmlConceptos = listarServicios.map((servicio) => `
  <cfdi:Concepto 
      NoIdentificacion="${servicio?.id}" 
      Cantidad="${servicio?.dientes.length}" 
      Unidad="PIEZA" 
      Descripcion="${servicio?.descripcionAdicional}" 
      ValorUnitario="${(Number(servicio?.precio) / (servicio?.dientes.length)).toFixed(2)}" 
      Importe="${(Number(servicio?.precio)).toFixed(2)}" 
      ClaveProdServ="42151500"
      ClaveUnidad="H87" 
      ObjetoImp="02" 
  >
  <cfdi:Impuestos>
        <cfdi:Traslados>
          <cfdi:Traslado 
            Base="${Number(servicio?.precio)}" 
            Impuesto="002" 
            TipoFactor="Tasa" 
            TasaOCuota="0.160000" 
            Importe="${(Number(servicio?.precio) * 0.16).toFixed(6)}"
          />
        </cfdi:Traslados>
      </cfdi:Impuestos>
  </cfdi:Concepto>
`).join('');

  const xmlString = `
  <cfdi:Comprobante xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:cfdi="http://www.sat.gob.mx/cfd/4"
      xsi:schemaLocation="http://www.sat.gob.mx/cfd/4 http://www.sat.gob.mx/sitio_internet/cfd/4/cfdv40.xsd"
      Version="4.0" Fecha="${datos?.fecha.replace(' ', 'T')}" Serie="A" Folio="A${datos?.folio?.split("-")[1]}" LugarExpedicion="76804"
      Moneda="MXN" SubTotal="${(Number(datos?.precioTotal)).toFixed(2)}" Total="${(Number(datos?.precioTotal) * 1.16).toFixed(2)}" TipoDeComprobante="I" Exportacion="01" 
      TipoCambio="1" MetodoPago="PUE" FormaPago="01">
      <cfdi:Emisor Rfc="HCD230213587" Nombre="HERFRAN CORPORATIVO DENTAL" RegimenFiscal="601" />
      <cfdi:Receptor Rfc="${listarFacturaciones[0]?.rfc}" Nombre="${limpiarYConvertirMayusculas(listarFacturaciones[0]?.razonSocial)}" UsoCFDI="${listarFacturaciones[0]?.usoCfdi}" DomicilioFiscalReceptor="${listarFacturaciones[0]?.codigoPostal}" RegimenFiscalReceptor="${listarFacturaciones[0]?.regimenFiscal}" />
      <cfdi:Conceptos>
          ${xmlConceptos}
      </cfdi:Conceptos>
      <cfdi:Impuestos TotalImpuestosTrasladados="${(Number(datos?.precioTotal) * 0.16).toFixed(2)}">
          <cfdi:Traslados>
              <cfdi:Traslado Base="${(Number(datos?.precioTotal)).toFixed(2)}" Impuesto="002" TipoFactor="Tasa" 
                  TasaOCuota="0.160000" Importe="${(Number(datos?.precioTotal) * 0.16).toFixed(2)}" />
          </cfdi:Traslados>
      </cfdi:Impuestos>
      <cfdi:Complemento>
          <tfd:TimbreFiscalDigital xmlns:tfd="http://www.sat.gob.mx/TimbreFiscalDigital"
              xsi:schemaLocation="http://www.sat.gob.mx/TimbreFiscalDigital http://www.sat.gob.mx/sitio_internet/cfd/TimbreFiscalDigital/TimbreFiscalDigitalv11.xsd"
              Version="1.1" UUID="" FechaTimbrado="" RfcProvCertif="" SelloCFD="" NoCertificadoSAT="" SelloSAT="" />
      </cfdi:Complemento>
  </cfdi:Comprobante>
`;

  const encodeToBase64 = (str) => {
    return btoa(unescape(encodeURIComponent(str)));
  };

  const sendXMLToSoapService = async () => {
    try {
      const xmlBase64 = encodeToBase64(xmlString); // Convertir el XML a Base64

      // Enviar el Base64 al servicio SOAP
      const response = await facturacion(xmlBase64, "A" + datos?.folio?.split("-")[1]);
      toast.success(response.data.mensaje);

      if (response.data.archivoXml && response.data.archivoPdf) {
        const dataTemp = {
          facturacion: "1"
        };

        await actualizaFacturacionOrdenes(datos?._id, dataTemp);
        history({
          search: queryString.stringify(""),
        });
      }
    } catch (error) {
      // Manejo de errores
      toast.error("Error al enviar el XML");
      console.log(error);
    }
  };

  async function downloadFileFromBase64(folio, extension) {
    try {
      // Realiza la solicitud a la API para obtener el archivo en Base64
      const response = await consultarArchivos(folio, extension);

      if (response.data.success) {
        const base64String = response.data.file; // Obtiene la cadena en Base64
        const fileName = folio + '.' + extension; // Nombre del archivo

        // Decodifica el string base64
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        // Convierte los números de bytes en un array de bytes
        const byteArray = new Uint8Array(byteNumbers);

        // Crea un blob con el array de bytes y el tipo MIME correspondiente
        const blob = new Blob([byteArray], { type: extension === 'pdf' ? 'application/pdf' : 'application/xml' });

        // Crea un enlace para descargar el archivo
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;

        // Simula un clic en el enlace para iniciar la descarga
        link.click();
      } else {
        console.error('Error al obtener el archivo:', response.data.error);
      }
    } catch (error) {
      console.error('Error al hacer la solicitud:', error.message);
    }
  }

  // Ejemplo de uso para PDF
  function downloadPDF(folio) {
    downloadFileFromBase64(folio, 'pdf'); // Llama a la función para PDF
  }

  // Ejemplo de uso para XML
  function downloadXML(folio) {
    downloadFileFromBase64(folio, 'xml'); // Llama a la función para XML
  }

  return (
    <Dropdown>
      <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
        <FontAwesomeIcon icon={faBars} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {factura == "0" ? (
          <>
            <Dropdown.Item onClick={sendXMLToSoapService}>
              <FontAwesomeIcon
                icon={faFileExcel}
                style={{ color: "#ffc107" }}
              />
              &nbsp; Facturar
            </Dropdown.Item>
          </>
        ) : (
          <>
            <Dropdown.Item onClick={() => downloadXML("A" + datos?.folio?.split("-")[1])}>
              <FontAwesomeIcon
                icon={faFileExcel}
                style={{ color: "#3B83BD" }}
              />
              &nbsp; Descargar XML
            </Dropdown.Item>
            <Dropdown.Item onClick={() => downloadPDF("A" + datos?.folio?.split("-")[1])}>
              <FontAwesomeIcon
                icon={faFilePdf}
                style={{ color: "#FF0000" }}
              />
              &nbsp; Descargar PDF
            </Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

function formatModelServicios(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      folioODS: data.ODT,
      fecha: data.fechaHora,
      pieza: data.pieza,
      sistemaColor: data.sistemaColor,
      tipoDentadura: data.distencion,
      tonos:
        data.tonoInferior + ", " + data.tonoMedio + ", " + data.tonoSuperior,
      dientes: data.diente,
      departamento: data.departamento,
      imagenDiente: data.imagenDiente,
      imagenEsquema: data.imagenEsquema,
      prioridad: data.prioridad,
      estado: data.estado,
      direccionConsultorio: data.municipio,
      dia: data.dia,
      numeroProceso: data.numeroProceso,
      nombreProceso: data.nombreProceso,
      motivoPausa: data.motivoPausa,
      imagenResultadoFinal: !data.imagenResultadoFinal ? "Sin Imagen" : data.imagenResultadoFinal,
      garantia: !data.garantia ? "1" : data.garantia,
      descripcionAdicional: data.descripcionAdicional,
      precio: data.precio
    });
  });
  return dataTemp;
}

function formatModelFacturacion(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      rfc: data.rfc,
      razonSocial: data.razonSocial,
      regimenFiscal: data?.regimenFiscal?.split("-")[0],
      usoCfdi: data?.usoCfdi?.split("-")[0],
      calle: data.calle,
      numeroExterior: data.numeroExterior,
      numeroInterior: data.numeroInterior,
      codigoPostal: data.codigoPostal,
      colonia: data.colonia,
      municipio: data.municipio,
      estadoPais: data.estadoPais,
      observaciones: data.observaciones,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default XMLComponent;
