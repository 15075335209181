import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Table,
  Button,
  Row,
  Form,
  Col,
  Dropdown,
} from "react-bootstrap";
import {
  listarFechasyEstadoPagoCompleta,
  listarOrdenesServicioPorCargoOrden,
} from "../../../../api/ordenesServicio";
import {
  listarAbonosAprobadosPorCliente,
  listarAbonosPorCargo,
} from "../../../../api/abonosOrdenes";
import { listarSaldosCorreo } from "../../../../api/saldosCliente";
import { listarCorreoPreciosOrden } from "../../../../api/preciosOrden";
import { obtenerUsuarioEmail } from "../../../../api/usuarios";
import { obtenerDoctoresPorCorreo } from "../../../../api/doctores";
import { green } from "@mui/material/colors";
import { listarPagosPorCliente } from "../../../../api/pagos";
import BasicModal from "../../../Modal/BasicModal/BasicModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCalendar,
  faDollar,
  faDollarSign,
  faExchange,
} from "@fortawesome/free-solid-svg-icons";
import PagosOrdenesM from "../../../ordenServicio/pagosOrdenesM/PagosOrdenesM";
import PagosClientes from "../../pagosClientes/PagosClientes";
import Movimientos from "../../pagosClientes/Movimientos";

const SaldoPorCliente = ({ correo, tipo, origen }) => {
  console.log("correo", correo);
  console.log("tipo", tipo);

  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const pagosOrdenes = (content) => {
    setTitulosModal("Pago de orden");
    setContentModal(content);
    setShowModal(true);
  };

  const registrarPago = (content) => {
    setTitulosModal("Agregar Saldo");
    setContentModal(content);
    setShowModal(true);
  };

  const registrarMovimiento = (content) => {
    setTitulosModal("Realizar ajuste a cuenta");
    setContentModal(content);
    setShowModal(true);
  };

  // Función para obtener la fecha actual en formato AAAA-MM-DD
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [fechaInicial, setfechaInicial] = useState(null);
  const [fechaFinal, setfechaFinal] = useState(getCurrentDate());

  // Efecto para actualizar la fecha final cuando el componente se monta

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    if (isVisible) {
      setfechaFinal(getCurrentDate());
    }
  }, [isVisible]);

  const [listOrdenes, setListOrdenes] = useState([]);

  const obtenerOrdenes = (email) => {
    try {
      listarFechasyEstadoPagoCompleta(email)
        .then((response) => {
          const { data } = response;
          console.log("data", data);
          if (!listOrdenes && data) {
            setListOrdenes(formatModelOrdenes(data));
          } else {
            const datosMovimientos = formatModelOrdenes(data);
            setListOrdenes(datosMovimientos);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  const [abonosA, setAbonosA] = useState(0);
  const [listAbonos, setListAbonos] = useState([]);

  const obtenerAbonosAprobados = (email) => {
    try {
      listarAbonosPorCargo(email)
        .then((response) => {
          const { data } = response;
          if (data.length > 0) {
            setListAbonos(data);
          } else {
            setAbonosA(0);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  const [saldoCliente, setsaldoCliente] = useState(0);
  const [listPagosCli, setlistPagosCli] = useState([]);

  const obtenerSaldoCliente = (email) => {
    try {
      listarPagosPorCliente(email)
        .then((response) => {
          const { data } = response;
          console.log("🚀 ~ .then ~ data:", data);
          if (data.length > 0) {
            setlistPagosCli(data);
          } else {
            setlistPagosCli([]);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  const [listarMovimientos, setListMovimientos] = useState([]);

  const obtenerCargosyDescuentos = (ordenServicio) => {
    try {
      listarCorreoPreciosOrden(ordenServicio)
        .then((response) => {
          const { data } = response;
          console.log("🚀 ~ .then ~ data:", data);
          if (!listarMovimientos && data) {
            setListMovimientos(formatModelMovimientos(data));
          } else {
            const datosMovimientos = formatModelMovimientos(data);
            setListMovimientos(datosMovimientos);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  const [datosCliente, setDatosCliente] = useState([]);

  const obtenerDatosCliente = (email) => {
    try {
      obtenerDoctoresPorCorreo(email)
        .then((response) => {
          const { data } = response;
          console.log("🚀 ~ .then ~ data:", data);
          setDatosCliente(data);
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerOrdenes(correo);
    obtenerAbonosAprobados(correo);
    obtenerSaldoCliente(correo);
    obtenerCargosyDescuentos(correo);
    obtenerDatosCliente(correo);
  }, [correo]);

  const [ordenesFilter, setordenesFilter] = useState([]);
  const [abonosFilter, setAbonosFilter] = useState([]);
  const [movimientosFilter, setMovimientosFilter] = useState([]);
  const [pagosFilter, setPagosFilter] = useState([]);

  useEffect(() => {
    if (!isVisible) {
      setordenesFilter(listOrdenes);
      setAbonosFilter(listAbonos);
      setMovimientosFilter(listarMovimientos);
      setPagosFilter(listPagosCli);
    } else {
      const filtrarOrdenes = () => {
        if (fechaInicial && fechaFinal) {
          const inicio = new Date(fechaInicial);
          const fin = new Date(fechaFinal);
          console.log("🚀 ~ filtrarOrdenes ~ fin:", fin);
          const resultadoOrdenes = listOrdenes.filter((orden) => {
            const fechaOrden = new Date(orden.fecha);
            return fechaOrden >= inicio && fechaOrden <= fin;
          });
          console.log("listAbonos", listAbonos);
          console.log("listarMovimientos", listarMovimientos);
          const resultadoAbonos = listAbonos.filter((abono) => {
            const fecha = new Date(abono.fecha);
            return fecha >= inicio && fecha <= fin;
          });
          const resultadoMovimientos = listarMovimientos.filter((mov) => {
            const fecha = new Date(mov.fecha);
            return fecha >= inicio && fecha <= fin;
          });
          const resultadoPagos = listPagosCli.filter((pago) => {
            const fecha = new Date(pago.fecha);
            console.log("fecha", fecha);
            return fecha >= inicio && fecha <= fin;
          });

          setordenesFilter(resultadoOrdenes);
          setAbonosFilter(resultadoAbonos);
          setMovimientosFilter(resultadoMovimientos);
          setPagosFilter(resultadoPagos);
        }
      };

      filtrarOrdenes();
    }
  }, [
    isVisible,
    listOrdenes,
    listAbonos,
    listPagosCli,
    listarMovimientos,
    fechaInicial,
    fechaFinal,
  ]);

  const [totalOrdenes, settotalOrdenes] = useState(0);
  const [balanceMoviemientos, setBalanceMovimientos] = useState(0);
  const [balanceCliente, setbalanceCliente] = useState(0);

  useEffect(() => {
    const sumaTotalOC = ordenesFilter.reduce(
      (total, orden) => total + parseFloat(orden.precioTotal),
      0
    );
    settotalOrdenes(sumaTotalOC);

    const resultado = movimientosFilter.reduce((acumulador, elemento) => {
      if (elemento.tipo === "cargo") {
        // Sumar al acumulador si el tipo es 'cargo'
        return acumulador + Number(elemento.cantidad);
      } else if (elemento.tipo === "descuento") {
        // Restar al acumulador si el tipo es 'descuento'
        return acumulador - Number(elemento.cantidad);
      } else {
        // Manejar otros tipos si es necesario
        return acumulador;
      }
    }, 0);

    setBalanceMovimientos(resultado);

    const aprobado = abonosFilter.filter((registro) => registro.estado == "1");
    const sumaTotalAp = aprobado.reduce(
      (total, pagos) => total + parseFloat(pagos.cantidadRecibida),
      0
    );
    setAbonosA(sumaTotalAp);

    const sumaTotalPagos = pagosFilter.reduce(
      (total, pagos) => total + parseFloat(pagos.cantidad),
      0
    );

    setsaldoCliente(sumaTotalPagos);

    const balance =
      parseFloat(sumaTotalOC) -
      parseFloat(sumaTotalAp) -
      parseFloat(sumaTotalPagos) +
      parseFloat(resultado);
    setbalanceCliente(balance);
  }, [
    ordenesFilter,
    abonosFilter,
    movimientosFilter,
    pagosFilter,
    listPagosCli,
  ]);

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  const estilosTablaHead = {
    textTransform: "uppercase",
    textAlign: "center",
    backgroundColor: "#282c35",
    color: "#fff",
  };

  return (
    <div>
      <Container className="mt-3">
        <h1 className="mb-4">Estado de Cuenta</h1>
        <div className="divButton">
          {origen == "conta" && (
            <>
              <Button
                variant="warning"
                onClick={() =>
                  registrarMovimiento(
                    <Movimientos
                      data={datosCliente.email}
                      setShow={setShowModal}
                    />
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faExchange}
                  style={{ color: "#000080", fontWeight: "bold" }}
                />{" "}
                Cargo/Descuento
              </Button>
              &nbsp;
              <Button
                variant="success"
                onClick={() =>
                  registrarPago(
                    <PagosClientes
                      data={datosCliente}
                      saldoPagado={saldoCliente}
                      pagosOrdenes={abonosA}
                      saldoPendiente={balanceCliente}
                      setShow={setShowModal}
                    />
                  )
                }
              >
                <FontAwesomeIcon icon={faDollarSign} />
                &nbsp; Registrar pago
              </Button>
            </>
          )}
          &nbsp;
          <Button className="btnAddTables" onClick={toggleVisibility}>
            <FontAwesomeIcon icon={faCalendar} /> Filtar por fechas
          </Button>
        </div>
        {isVisible && (
          <Row className="mb-3">
            <Col>
              <Form.Label>Fecha de inicio:</Form.Label>
              <Form.Control
                type="date"
                value={fechaInicial}
                onChange={(e) => setfechaInicial(e.target.value)}
              />
            </Col>
            <Col>
              <Form.Label>Fecha de fin:</Form.Label>
              <Form.Control
                type="date"
                value={fechaFinal}
                onChange={(e) => setfechaFinal(e.target.value)}
              />
            </Col>
          </Row>
        )}
        <Card className="mb-4">
          <Card.Header
            style={{
              backgroundColor: "#0c1b30",
              color: "#fff",
              fontSize: "larger",
            }}
          >
            Datos del Cliente
          </Card.Header>
          <Card.Body style={{ backgroundColor: "#f8f9fa" }}>
            <div className="d-flex">
              <div className="font-weight-bold">Nombre:</div>&nbsp;
              <div style={{ textTransform: "capitalize" }}>
                {datosCliente?.nombre +
                  " " +
                  datosCliente?.apellidoPaterno +
                  " " +
                  datosCliente?.apellidoMaterno}
              </div>
            </div>
            <div className="d-flex">
              <div className="font-weight-bold">Correo:</div>&nbsp;
              <div>{correo}</div>
            </div>
            <div className="d-flex">
              <div className="font-weight-bold">RFC:</div>&nbsp;
              <div>{datosCliente.rfc}</div>
            </div>
            <div className="d-flex">
              <div className="font-weight-bold">Teléfono:</div>&nbsp;
              <div>{datosCliente.telefonoCelular}</div>
            </div>
            <div className="d-flex">
              <div className="font-weight-bold">Domicilio:</div>&nbsp;
              <div>
                {datosCliente.calle +
                  " No. Ext. " +
                  datosCliente.nExterior +
                  " No. Int. " +
                  (datosCliente.nInterior ? datosCliente.nInterior : "SN") +
                  ", " +
                  datosCliente.colonia +
                  ", CP.  " +
                  datosCliente.cPostal +
                  ", " +
                  datosCliente.municipio +
                  ", " +
                  datosCliente.estadoDom +
                  "."}
              </div>
            </div>
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Header
            style={{
              backgroundColor: "#0c1b30",
              color: "#fff",
              fontSize: "larger",
            }}
          >
            Resumen
          </Card.Header>
          <Card.Body>
            <Card.Title>{"Por pagar: "}</Card.Title>&nbsp;
            <Card.Title
              style={{
                marginLeft: "5px",
                color: balanceCliente > 0 ? "red" : "green",
              }}
            >
              {formatCurrency(balanceCliente)}
            </Card.Title>
            <Card.Text style={{ fontSize: "x-small", fontStyle: "italic" }}>
              *Si la cantidad es negativa significa que tiene saldo a favor
            </Card.Text>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header
            style={{
              backgroundColor: "#0c1b30",
              color: "#fff",
              fontSize: "larger",
            }}
          >
            Transacciones
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <th colSpan={5} style={estilosTablaHead}>
                  Ordenes con cargo al cliente
                </th>
              </thead>
              <thead>
                <tr>
                  <th>FECHA</th>
                  <th>DESCRIPCION</th>
                  <th>MONTO</th>
                  {origen == "conta" && (
                    <th>ACCIONES</th>
                  )}
                  
                </tr>
              </thead>
              <tbody>
                {ordenesFilter.map((orden, index) => (
                  <tr key={index}>
                    <td>{orden.fecha}</td>
                    <td>{orden.folio}</td>
                    <td>{formatCurrency(orden.precioTotal)}</td>
                    {origen == "conta" && (
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            style={{
                              backgroundColor: "#0c1b30",
                              borderColor: "#0c1b30",
                            }}
                            id="dropdown-basic"
                          >
                            <FontAwesomeIcon icon={faBars} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                pagosOrdenes(
                                  <PagosOrdenesM data={orden} origen={"cxc"} />
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={faDollar}
                                style={{ color: "#fd7e14", fontWeight: "bold" }}
                              />
                              &nbsp;Registrar pago
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    )}
                  </tr>
                ))}
                <tr style={{ fontWeight: "bold" }}>
                  <td colSpan={2}>Total ordenes:</td>
                  <td
                    colSpan={2}
                    style={{ color: totalOrdenes > 0 ? "red" : "green" }}
                  >
                    {formatCurrency(totalOrdenes)}
                  </td>
                </tr>
              </tbody>
            </Table>

            <Table responsive className="mt-3">
              <thead>
                <th colSpan={4} style={estilosTablaHead}>
                  Cargos y descuentos aplicados
                </th>
              </thead>
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Operación</th>
                  <th>Descripción</th>
                  <th>Monto</th>
                </tr>
              </thead>
              <tbody>
                {movimientosFilter.map((operacion, index) => (
                  <tr key={index}>
                    <td>{operacion.fecha}</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {operacion.tipo}
                    </td>
                    <td>{operacion.concepto}</td>
                    <td
                      style={{
                        color: operacion.tipo == "descuento" ? "green" : "red",
                      }}
                    >
                      {formatCurrency(operacion.cantidad)}
                    </td>
                  </tr>
                ))}
                <tr style={{ fontWeight: "bold" }}>
                  <td colSpan={3}>Balance:</td>
                  <td style={{ color: balanceCliente > 0 ? "red" : "green" }}>
                    {formatCurrency(balanceMoviemientos)}
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table striped bordered hover className="mt-3">
              <thead>
                <th colSpan={5} style={estilosTablaHead}>
                  Abonos a ordenes
                </th>
              </thead>
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Orden</th>
                  <th>Cantidad</th>
                  <th>Recibió</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {abonosFilter.map((abono, index) => (
                  <tr>
                    <td>{abono.fecha}</td>
                    <td>{abono.ordenServicio}</td>
                    <td>{abono.idRecibio}</td>
                    <td>{abono.estado == "1" ? "Aprobado" : "Pendiente"}</td>
                    <td
                      style={{
                        color: abono.estado == "1" ? "green" : "inherit",
                      }}
                    >
                      {formatCurrency(abono.cantidadRecibida)}
                    </td>
                  </tr>
                ))}
                <tr>
                  <th colSpan={4}>Cantidad aprobada</th>
                  <th style={{ color: abonosA > 0 ? "green" : "red" }}>
                    {formatCurrency(abonosA)}
                  </th>
                </tr>
              </tbody>
            </Table>
            <Table responsive className="mt-3">
              <thead>
                <th colSpan={4} style={estilosTablaHead}>
                  PAGOS REALIZADOS
                </th>
              </thead>
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Forma de pago</th>
                  <th>Concepto</th>
                  <th>Cantidad</th>
                </tr>
              </thead>
              <tbody>
                {pagosFilter.map((pago, index) => (
                  <tr key={index}>
                    <td>{pago.fecha}</td>
                    <td>{pago.formaPago}</td>
                    <td>{pago.concepto}</td>
                    <td>{formatCurrency(pago.cantidad)}</td>
                  </tr>
                ))}
                <tr>
                  <th colSpan={3}>Total de pagos</th>
                  <th style={{ color: saldoCliente > 0 ? "green" : "red" }}>
                    {formatCurrency(saldoCliente)}
                  </th>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </div>
  );
};

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      fecha: data.fecha,
      localidad: data.localidad,
      precioTotal: data.precioTotal,
      cargoOrden: data.cargoOrden,
      estadoPago: data.estadoPago,
      estado: data.estado,
      diasPago: data.diasDesdeFecha,
    });
  });
  return dataTemp;
}

function formatModelAA(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      totalCantidadRecibida: data.totalCantidadRecibida,
    });
  });
  return dataTemp;
}

function formatModelMovimientos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      ordenServicio: data.ordenServicio,
      tipo: data.tipo,
      concepto: data.concepto,
      cantidad: data.cantidad,
      correoRegistro: data.correoRegistro,
      estado: data.estado,
      fecha: data.fecha,
      fechaCreacion: data.createdAt,
    });
  });
  return dataTemp;
}

export default SaldoPorCliente;
