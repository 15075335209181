import { API_HOST } from '../utils/constants';
import { ENDPOINTFacturar, ENDPOINTConsultarArchivos } from './endpoints';
import axios from 'axios';

export async function facturacion(file, folio) {
    //console.log(data)
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };

    return await axios.post(API_HOST + ENDPOINTFacturar, {
        file: file,
        folio: folio
    }, config);
}

export async function consultarArchivos(folio, extension) {
    //console.log(data)
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };

    return await axios.post(API_HOST + ENDPOINTConsultarArchivos, {
        folio: folio,
        extension: extension
    }, config);
}