import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { obtenerChatsIdChat } from "../../../api/mensajeria";
import { Image } from "react-bootstrap";
import logoSF from "../../../assets/img/isologo_1.png";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import { obtenerUsuario } from "../../../api/usuarios";
import { obtenerUltimoEvaluaciones360 } from "../../../api/evaluaciones360";
import TblEstablecimientos from "../cliente/Tabla";
import RegistroEstablecimientos from "../cliente/AltaCliente2";
import TblOrdenesServicio from "../../ordenServicio/TablaInformativa";
import TablaRetrabajos from "../../ordenServicio/TablaRetrabajos";
import TblServicios from "../../ordenServicio/TablaServiciosDepartamentos";
import TblDoctores from "../../doctores/Tabla";
import TblMovimientosGeneralAPL from "../../movimientosAlmacenMP/tablaGeneral";
import TblMovimientosGeneralAPM from "../../movimientosAlmacenPL/tablaGeneral";
import TblMovimientosMedicamentos from "../../movimientosMedicamentos/tablaGeneral";
import TablaFacturacion from "../../facturacion/TablaInformativa";
import TablaFacturasMostrador from "../../facturasMostrador/TablaInformativa";
import TblFacturacion from "../datosFacturacion/Tabla";
import TblPrecios from "../precios/Tabla";
import TblMensajeros from "../mensajeros/Tabla";
import TblVehiculos from "../vehiculos/Tabla";
import TblServiciosDentales from "../serviciosDentales/Tabla";
import TblSistemasColorimetria from "../colorimetria/Tabla";
import TblAlmacenMP from "../../almacenMateriasPrimas/tabla";
import TblAlmacenPL from "../../almacenProductosLimpieza/tabla";
import TblProductosAgotandose from "../../almacenMateriasPrimas/tablaProductosAgotandose";
import TblConfiguracionRutas from "../../configuracionRutas/Tabla";
import TblUsuarios from "../../usuarios/Tabla";
import TblDiasFestivos from "../../diasFestivos/Tabla";
import TblIncidencias from "../../incidencias/Tabla";
import TblIncidenciasRutas from "../../rutas/TablaIncidenciasRutas";
import TblDepartamentos from "../../departamentos/Tabla";
import OrdenServicioNueva from "../../ordenServicio/OrdenServicioNueva";
import TblUbicaciones from "../../ubicaciones/Tabla";
import TblCategorias from "../../categorias/Tabla";
import TblOrdenesTrabajo from "../../ordenTrabajo/TablaInformativa";
import TablaMaterialesCat from "../precios/TablaMaterialesCat";
import TablaVisitas from "../../entregas/TablaVisitas";
import TarjetasVisitas from "../../entregas/CardVisitas";
import Rutas from "../../rutas/Rutas";
import ConsultoriosRegistro from "../cliente/ConsultoriosRegistro";
import { listarDoctoresPorCorreoDoctor } from "../../../api/doctores";
import { listarEstablecimientosPorCorreo } from "../../../api/establecimientos";
import { MapaUbicacion } from "../../rutas/Mapa/MapaUbicacion";
import TblCoordenadas from "../../rutas/Mapa/Tabla";
import InicioPefil from "./InicioPefil";
import Verificación from "../../rutas/Verificacion";
import TblVerificación from "../../rutas/TablaVerificacion";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import TblViajes from "../../viajes/Tabla";
import TblComedor from "../../comedor/Tabla";
import DatosDocInicio from "../../doctores/DatosDocInicio";
import DatosDoctores2 from "../../doctores/DatosDoctores2";
import TblGastos from "../mensajeros/TablaGastos";
import DetalleOrden from "../../ordenServicio/DetalleOrden";
import MaterialEntrega from "../../ordenServicio/material/TablaMaterial";
import TblEntregas from "../../entregas/Tabla";
import TblProveedores from "../../proveedores/Tabla";
import TblRutasAignadas from "../../rutas/TablaRutasAsignadas";
import TblUnidadesMedida from "../../unidadesMedida/Tabla";
import TblSaldos from "../../finanzas/saldosClientes/tabla";
import TblGastosM from "../../finanzas/gastosClientes/tabla";
import TablaPreciosOrden from "../../ordenServicio/preciosOrden/TablaPreciosOrden";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faBoxesPacking, faCalendar, faCalendarDays, faClipboardUser, faGear, faGears, faHandHoldingDollar, faMoneyBillTransfer, faTriangleExclamation, faUsersGear, faUsersRectangle, faMoneyCheckDollar, faCashRegister, faFolder, faIndustry, faListCheck, faMoneyBillWave, faTeethOpen, faTooth, faStopwatch, faTabletAlt } from "@fortawesome/free-solid-svg-icons";
import TblOdsRutas from "../../odsrutas/Odsrutas";
import TablaPagosOrdenes from "../../ordenServicio/pagosOrdenesM/TablaPagosOrdenes";
import TablaMaterialesVale from "../../material/TablaMaterialesVale";
import TablaInsumosVale from "../../material/TablaInsumosVale";
import RegistroEmpleados from "../../RH/RegistroEmpleados";
import TablaRecuersosHummanos from "../../RH/Tabla";
import TablaAsistencia from "../../RH/TablaAsistencia";
import TablaCargos from "../../cargos/Tabla";
import Vacaciones from "../../RH/vacaciones/Vacaciones";
import TablaMaquinas from "../../maquinas/TablaMaquinas";
import ProgramaMantenimiento from "../../maquinas/mantenimiento/ProgramaMantenimiento";
import FloatingNotificationButton from "./NotificacionesFlotantes";
import TablaOC from "../../material/ordenCompra/TablaOC";
import TablaRC from "../../material/ordenCompra/TablaRC";
import TablaLogs from "../../logs/tabla";
import TablaReq from "../../requisicion/TablaReq";
import TablaMovsCaja from "../../finanzas/caja/TablaMovsCaja";
import DiasTrabajados from "../../RH/pagosEmpleados/DiasTrabajados";
import TblCursos from "../../cursos/Tabla";
import TblCapacitaciones from "../../RH/TablaCapacitaciones";
import TablaGastosMaquinas from "../../maquinas/mantenimiento/TablaGastosMaquinas";
import OpenpayIntegration from "../../payment/AddUserPay";
import OpenpayPayment from "../../payment/Card";
import OpenpayPaymentPagos from "../../payment/Cargos";
import OpenpayTransferencias from "../../payment/Transferencias";
import TblRetrabajos from "../../ordenTrabajo/TablaRetrabajos";
import MatrizAsistencia from "../../RH/MatrizAsistencia";
import ViewPDFImp from "../../ordenServicio/impresion/ImpresionOrdenes";
import PrincipalChat from "../mesaControl/PrincipalChat";
import TablaCxP from "../../finanzas/cxp/TablaCxP";
import { consultarPermisosModulos } from "../../../api/permisos";
import SaldoPorCliente from "../../finanzas/saldosClientes/clientes/SaldoPorCliente";
import Mesas from "../../comedor/disponibilidad/Mesas";
import TablaCXC from "../../finanzas/saldosClientes/TablaCXC";
import CardODS from "../../ordenServicio/CardODS";
import TarjetasEntregas from "../../entregas/CardEntregas";
import Cards from "./Cards";
import TarjetasProtesis from "../precios/CardProtesis";
import TblMedicamentos from "../../almacenMedicamentos/tabla";

export function MenuDash({ busqueda }) {
  console.log(busqueda)
  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const [fechaUltimo, setFechaUltimo] = useState("");

  const obtenerDatosEvaluacion = () => {
    try {
      obtenerUltimoEvaluaciones360()
        .then((response) => {
          const { data } = response;
          setFechaUltimo(dayjs(data.createdAt).format("LL"));
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosEvaluacion();
  }, []);

  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    // Obtenemos la fecha actual
    const now = new Date();

    // Obtenemos los componentes de la fecha (día, mes, año)
    const day = now.getDate();
    const month = now.getMonth() + 1; // Los meses en JavaScript van de 0 a 11
    const year = now.getFullYear();

    // Formateamos la fecha en dd/mm/aaaa
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

    // Actualizamos el estado con la fecha formateada
    setCurrentDate(dayjs(formattedDate).format("LL"));
  }, []); // El segundo argumento [] asegura que este efecto se ejecute solo una vez al montar el componente

  const [idUsuario, setIdeUsuario] = useState("");
  const [tipoUsuario, setTipoUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setIdeUsuario(data._id);
          setTipoUsuario(data.tipo);
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [chatsList, setChatsList] = useState([]);

  const obtenerChatsUsuario = (email) => {
    try {
      obtenerChatsIdChat(email)
        .then((response) => {
          const { data } = response;
          // Filtra los chats que tengan el campo estado en 0
          const filteredChats = data.filter(chat => chat.estado === 0);
          setChatsList(formatModelChats(filteredChats));
        })
        .catch((e) => { /* Manejar error aquí si es necesario */ });
    } catch (e) {
      // Manejar error aquí si es necesario
    }
  };


  useEffect(() => {
    const fetchChats = () => {
      obtenerChatsUsuario(correoUsuario);
    };
    fetchChats(); // Ejecuta la función al inicio

    const intervalId = setInterval(fetchChats, 60000); // Ejecuta la función cada minuto

    return () => clearInterval(intervalId); // Limpia el intervalo cuando el componente se desmonte
  }, [tipoUsuario, correoUsuario]);

  let stateEvaluacion =
    tipoUsuario === "mensajero" && fechaUltimo !== currentDate ? true : false;

  const [showComponent, setShowComponent] = useState(false);
  const [showComponent1, setShowComponent1] = useState(false);
  const [showComponent2, setShowComponent2] = useState(false);
  const [showComponent3, setShowComponent3] = useState(false);
  const [showComponent4, setShowComponent4] = useState(false);
  const [showComponent5, setShowComponent5] = useState(false);
  const [showComponent6, setShowComponent6] = useState(false);
  const [showComponent7, setShowComponent7] = useState(false);
  const [showComponent8, setShowComponent8] = useState(false);
  const [showComponent9, setShowComponent9] = useState(false);
  const [showComponent10, setShowComponent10] = useState(false);
  const [showComponent11, setShowComponent11] = useState(false);
  const [showComponent13, setShowComponent13] = useState(false);
  const [showComponent14, setShowComponent14] = useState(false);
  const [showComponent15, setShowComponent15] = useState(false);
  const [showComponent16, setShowComponent16] = useState(false);
  const [showComponent17, setShowComponent17] = useState(false);
  const [showComponent18, setShowComponent18] = useState(false);
  const [showComponent19, setShowComponent19] = useState(false);
  const [showComponent20, setShowComponent20] = useState(false);
  const [showComponent21, setShowComponent21] = useState(false);
  const [showComponent22, setShowComponent22] = useState(false);
  const [showComponent23, setShowComponent23] = useState(false);
  const [showComponent24, setShowComponent24] = useState(true);
  const [showComponent25, setShowComponent25] = useState(false);
  const [showComponent26, setShowComponent26] = useState(false);
  const [showComponent27, setShowComponent27] = useState(false);
  const [showComponent28, setShowComponent28] = useState(false);
  const [showComponent29, setShowComponent29] = useState(false);
  const [showComponent30, setShowComponent30] = useState(false);
  const [showComponent31, setShowComponent31] = useState(false);
  const [showComponent32, setShowComponent32] = useState(false);
  const [showComponent33, setShowComponent33] = useState(false);
  const [showComponent34, setShowComponent34] = useState(false);
  const [showComponent35, setShowComponent35] = useState(false);
  const [showComponent36, setShowComponent36] = useState(false);
  const [showComponent37, setShowComponent37] = useState(false);
  const [showComponent38, setShowComponent38] = useState(false);
  const [showComponent39, setShowComponent39] = useState(false);
  const [showComponent40, setShowComponent40] = useState(false);
  const [showComponent41, setShowComponent41] = useState(false);
  const [showComponent42, setShowComponent42] = useState(false);
  const [showComponent43, setShowComponent43] = useState(false);
  const [showComponent44, setShowComponent44] = useState(false);
  const [showComponent45, setShowComponent45] = useState(false);
  const [showComponent46, setShowComponent46] = useState(false);
  const [showComponent47, setShowComponent47] = useState(false);
  const [showComponent48, setShowComponent48] = useState(false);
  const [showComponent49, setShowComponent49] = useState(false);
  const [showComponent50, setShowComponent50] = useState(false);
  const [showComponent51, setShowComponent51] = useState(false);
  const [showComponent52, setShowComponent52] = useState(false);
  const [showComponent53, setShowComponent53] = useState(false);
  const [showComponent54, setShowComponent54] = useState(false);
  const [showComponent55, setShowComponent55] = useState(false);
  const [showComponent56, setShowComponent56] = useState(false);
  const [showComponent57, setShowComponent57] = useState(false);
  const [showComponent58, setShowComponent58] = useState(false);
  const [showComponent59, setShowComponent59] = useState(false);
  const [showComponent60, setShowComponent60] = useState(false);
  const [showComponent61, setShowComponent61] = useState(false);
  const [showComponent62, setShowComponent62] = useState(false);
  const [showComponent63, setShowComponent63] = useState(false);
  const [showComponent64, setShowComponent64] = useState(false);
  const [showComponent65, setShowComponent65] = useState(false);
  const [showComponent66, setShowComponent66] = useState(false);
  const [showComponent67, setShowComponent67] = useState(false);
  const [showComponent68, setShowComponent68] = useState(false);
  const [showComponent69, setShowComponent69] = useState(false);
  const [showComponent70, setShowComponent70] = useState(false);
  const [showComponent71, setShowComponent71] = useState(false);
  const [showComponent72, setShowComponent72] = useState(false);
  const [showComponent73, setShowComponent73] = useState(false);
  const [showComponent74, setShowComponent74] = useState(false);
  const [showComponent75, setShowComponent75] = useState(false);
  const [showComponent76, setShowComponent76] = useState(false);
  const [showComponent77, setShowComponent77] = useState(false);
  const [showComponent78, setShowComponent78] = useState(false);
  const [showComponent79, setShowComponent79] = useState(false);
  const [showComponent80, setShowComponent80] = useState(false);
  const [showComponent81, setShowComponent81] = useState(false);
  const [showComponent82, setShowComponent82] = useState(false);
  const [showComponent83, setShowComponent83] = useState(false);
  const [showComponent84, setShowComponent84] = useState(false);
  const [showComponent85, setShowComponent85] = useState(false);
  const [showComponent86, setShowComponent86] = useState(false);
  const [showComponent87, setShowComponent87] = useState(false);
  const [showComponent88, setShowComponent88] = useState(false);

  useEffect(() => {
    setShowComponent26(
      tipoUsuario === "mensajero" && fechaUltimo !== currentDate ? true : false
    );
  }, [tipoUsuario, fechaUltimo, currentDate]);

  const handleClick = (component) => {
    setShowComponent(component === "cliente");
    setShowComponent1(component === "almacenPL");
    setShowComponent2(component === "almacenMP");
    setShowComponent3(component === "doctores");
    setShowComponent4(component === "ordenes");
    setShowComponent5(component === "facturacion");
    setShowComponent6(component === "precios");
    setShowComponent7(component === "servicios");
    setShowComponent8(component === "colorimetria");
    setShowComponent9(component === "tablaProductosAgotandose");
    setShowComponent10(component === "mensajeros");
    setShowComponent11(component === "vehiculos");
    setShowComponent13(component === "orden2");
    setShowComponent14(component === "rutas");
    setShowComponent15(component === "configuracionRutas");
    setShowComponent16(component === "regConsulturio");
    setShowComponent17(component === "ordenDoctor");
    setShowComponent18(component === "usuarios");
    setShowComponent19(component === "departamentos");
    setShowComponent20(component === "ubicaciones");
    setShowComponent21(component === "categorias");
    setShowComponent22(component === "mapa");
    setShowComponent23(component === "coordenadas");
    setShowComponent24(component === "inicio");
    setShowComponent25(component === "ordenesTrabajo");
    setShowComponent26(component === "verificacion");
    setShowComponent27(component === "tablaVerificacion");
    setShowComponent28(component === "ordenConsultorio");
    setShowComponent29(component === "ordenDoctor");
    setShowComponent30(component === "viajes");
    setShowComponent31(component === "datosDocInicio");
    setShowComponent34(component === "gastosM");
    setShowComponent35(component === "detalleOrden");
    setShowComponent36(component === "matARecibir");
    setShowComponent37(component === "entregas");
    setShowComponent38(component === "proveedores");
    setShowComponent39(component === "unidadesMedida");
    setShowComponent40(component === "serviciosDepartamentos");
    setShowComponent41(component === "cargosyDescuentos");
    setShowComponent42(component === "saldosClientes");
    setShowComponent43(component === "gastosClientes");
    setShowComponent44(component === "odsRutas");
    setShowComponent45(component === "pagosOrdenes");
    setShowComponent46(component === "materialesVale");
    setShowComponent47(component === "registroEmpleado");
    setShowComponent48(component === "tablaEmpleado");
    setShowComponent49(component === "tablaCargos");
    setShowComponent50(component === "vacaciones");
    setShowComponent51(component === "registroMaquina");
    setShowComponent52(component === "mantenimientoMaquinas");
    setShowComponent53(component === "incidencias");
    setShowComponent54(component === "tablaAsistencia");
    setShowComponent55(component === "ordenesCompra");
    setShowComponent56(component === "tablaLogs");
    setShowComponent57(component === "requisicion");
    setShowComponent58(component === "cajaChica");
    setShowComponent59(component === "cursos");
    setShowComponent60(component === "capacitaciones");
    setShowComponent61(component === "recepcionesCompra");
    setShowComponent62(component === "dineroMantenimiento");
    setShowComponent63(component === "userPay");
    setShowComponent64(component === "cardPay");
    setShowComponent65(component === "cargosPay");
    setShowComponent66(component === "transferenciasPay");
    setShowComponent67(component === "insumosVale");
    setShowComponent68(component === "retrabajos");
    setShowComponent69(component === "asistencia");
    setShowComponent70(component === "matrizAsistencia");
    setShowComponent71(component === "materialesCat");
    setShowComponent72(component === "comedor");
    setShowComponent73(component === "rutasAsignadas");
    setShowComponent74(component === "impresionOrdenes");
    setShowComponent75(component === "chatsMC");
    setShowComponent76(component === "retrabajosODS");
    setShowComponent77(component === "incidenciasRutas");
    setShowComponent78(component === "cuentasXP");
    setShowComponent79(component === "saldosCli");
    setShowComponent80(component === "mesasComedor");
    setShowComponent81(component === "diasFestivos");
    setShowComponent82(component === "movimientosAPL");
    setShowComponent83(component === "movimientosAPM");
    setShowComponent84(component === "tablaFacturacion");
    setShowComponent85(component === "tablaFacturasMostrador");
    setShowComponent86(component === "medicamentos");
    setShowComponent87(component === "movimientosMedicamentos");
    setShowComponent88(component === "visitasMensajeros");
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCli, setIsOpenCli] = useState(false);
  const [isOpenRut, setIsOpenRut] = useState(false);
  const [isOpenBar, setIsOpenBar] = useState(false);
  const [isOpenDev, setIsOpenDev] = useState(false);
  const [isOpenFin, setIsOpenFin] = useState(false);
  const [isOpenRH, setIsOpenRH] = useState(false);
  const [isOpenMQ, setIsOpenMQ] = useState(false);
  const [isOpenOC, setIsOpenOC] = useState(false);
  const [isOpenFac, setIsOpenFac] = useState(false);
  const [isOpenCom, setIsOpenCom] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const toggleMenuCli = () => {
    setIsOpenCli(!isOpenCli);
  };
  const toggleMenuRut = () => {
    setIsOpenRut(!isOpenRut);
  };
  const toggleMenuBar = () => {
    setIsOpenBar(!isOpenBar);
  };
  const toggleMenuDev = () => {
    setIsOpenDev(!isOpenDev);
  };
  const toggleMenuFin = () => {
    setIsOpenFin(!isOpenFin);
  };
  const toggleMenuRH = () => {
    setIsOpenRH(!isOpenRH);
  };
  const toggleMenuMQ = () => {
    setIsOpenMQ(!isOpenMQ);
  };
  const toggleMenuOC = () => {
    setIsOpenOC(!isOpenOC);
  };
  const toggleMenuFac = () => {
    setIsOpenFac(!isOpenFac);
  };
  const toggleMenuCom = () => {
    setIsOpenCom(!isOpenCom);
  };

  const menuClass = isOpen ? "nav-item menu-open" : "nav-item menu-close";
  const menuClassCli = isOpenCli ? "nav-item menu-open" : "nav-item menu-close";
  const menuClassRut = isOpenRut ? "nav-item menu-open" : "nav-item menu-close";
  const menuClassBar = isOpenBar ? "nav-item menu-open" : "nav-item menu-close";
  const menuClassDev = isOpenDev ? "nav-item menu-open" : "nav-item menu-close";
  const menuClassFin = isOpenFin ? "nav-item menu-open" : "nav-item menu-close";
  const menuClassRH = isOpenRH ? "nav-item menu-open" : "nav-item menu-close";
  const menuClassMQ = isOpenMQ ? "nav-item menu-open" : "nav-item menu-close";
  const menuClassOC = isOpenOC ? "nav-item menu-open" : "nav-item menu-close";
  const menuClassFac = isOpenFac ? "nav-item menu-open" : "nav-item menu-close";
  const menuClassCom = isOpenCom ? "nav-item menu-open" : "nav-item menu-close";

  const [activeItem, setActiveItem] = useState("inicio"); // Estado para el elemento activo

  const handleItemClick = (itemName) => {
    setActiveItem(itemName); // Actualizar el estado al hacer clic en un elemento
  };

  const [listarEstablecimiento, setListEstablecimiento] = useState([]);

  const obtenerEstablecimiento = () => {
    if (tipoUsuario == "consultorio") {
      try {
        listarEstablecimientosPorCorreo(correoUsuario)
          .then((response) => {
            const { data } = response;
            if (!listarEstablecimiento && data) {
              setListEstablecimiento(formatModelEstablecimiento(data));
            } else {
              const datosEstablecimiento = formatModelEstablecimiento(data);
              setListEstablecimiento(datosEstablecimiento);
            }
          })
          .catch((e) => { });
      } catch (e) { }
    } else if (tipoUsuario == "doctor") {
      try {
        listarDoctoresPorCorreoDoctor(correoUsuario)
          .then((response) => {
            const { data } = response;
            if (!listarEstablecimiento && data) {
              setListEstablecimiento(formatModelDoctor(data));
            } else {
              const datosDoctor = formatModelDoctor(data);
              setListEstablecimiento(datosDoctor);
            }
          })
          .catch((e) => { });
      } catch (e) { }
    }
  };

  useEffect(() => {
    obtenerEstablecimiento();
  }, [correoUsuario]);

  const validarArray = () => {
    if (tipoUsuario == "consultorio") {
      if (listarEstablecimiento.length == 0) {
        setShowComponent24(false);
        setShowComponent32(true);
      } else {
        setShowComponent32(false);
        setShowComponent24(true);
      }
    } else {
      setShowComponent32(false);
      setShowComponent24(true);
    }
  };

  useEffect(() => {
    validarArray();
  }, [listarEstablecimiento]);

  const [listaDocCorreo, setlistaDocCorreo] = useState([]);
  const [verificaLista, setverificaLista] = useState(false);

  const consultaDoc = () => {
    try {
      listarDoctoresPorCorreoDoctor(correoUsuario)
        .then((response) => {
          const { data } = response;
          if (!listaDocCorreo && data) {
            setlistaDocCorreo(formatModelDoctor(data));
          } else {
            const datosDoctor = formatModelDoctor(data);
            setlistaDocCorreo(datosDoctor);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    consultaDoc();
  }, [correoUsuario]);

  useEffect(() => {
    if (tipoUsuario == "doctor") {
      if (listaDocCorreo.length == 0) {
        setShowComponent24(false);
        setShowComponent33(true);
      } else {
        setverificaLista(true);
        setShowComponent24(true);
        setShowComponent33(false);
      }
      console.log(verificaLista);
    }
  }, [listaDocCorreo]);

  const [listaPermisos, setListaPermisos] = useState([]);

  const consultaPermisos = (tipo, modulo) => {
    try {
      consultarPermisosModulos(tipo, modulo)
        .then((response) => {
          const { data } = response;
          if (!listaPermisos && data) {
            setListaPermisos([]);
          } else {
            setListaPermisos(data);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    if (tipoUsuario) {
      consultaPermisos(tipoUsuario, "pago de ordenes");
    }
  }, [tipoUsuario]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Función para actualizar el estado basado en el tamaño de la pantalla
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 1024px)").matches);
    };

    // Configuramos el listener para el cambio de tamaño de la ventana
    window.addEventListener('resize', handleResize);

    // Inicializamos el estado basado en el tamaño de la pantalla actual
    handleResize();

    // Limpiamos el listener cuando el componente se desmonte
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {idUsuario != "" && (
        <>
          <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <div className="brand-link">
              <Image
                src={logoSF}
                alt="AdminLTE Logo"
                className="brand-image"
              />
              <span className="brand-text font-weight-light">HERFRAN&#xae;</span>
            </div>
            <div className="sidebar barMenuElements">
              <nav className="mt-2">
                <ul
                  className="nav nav-pills nav-sidebar flex-column"
                  data-widget="treeview"
                  role="menu"
                  data-accordion="false"
                >
                  {tipoUsuario == "administrador" && (
                    <>
                      <li class="nav-item menu-open">
                        <Link
                          onClick={() => handleClick("inicio")}
                          style={{ textDecoration: "none" }}
                          className="custom-link"
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "inicio" ? "active inicioNav" : ""
                              }`}
                            onClick={() => handleItemClick("inicio")}
                          >
                            <i className="nav-icon fas fa-home" />
                            <p>Inicio</p>
                          </a>
                        </Link>
                      </li>
                      <li class={menuClassDev}>
                        <a className="nav-link" onClick={toggleMenuDev} style={{ cursor: "pointer" }}>
                          <i className="nav-icon fas fa-route" />
                          <p>
                            Orden cliente
                            <i class="right fas fa-angle-left" style={{ cursor: "pointer" }}></i>
                          </p>
                        </a>
                        <ul class="nav nav-treeview">
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("orden2")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "regOrden"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("regOrden")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-bell" />
                                <p>Nueva orden de cliente</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("ordenes")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "listOrdenes"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("listOrdenes")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-book" />
                                <p>Ordenes de cliente</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("detalleOrden")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "detOrden"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("detOrden")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-info" />
                                <p>Panel mesa de control</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("cargosyDescuentos")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "cargDesc"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("cargDesc")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faMoneyBillTransfer} className="nav-icon" />
                                <p>Cargos y Descuentos</p>
                              </a>
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => handleClick("impresionOrdenes")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "impresionOrdenes"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("impresionOrdenes")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-print" />
                                <p>Impresión de ordenes</p>
                              </a>
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => handleClick("retrabajosODS")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "retrabajosODS"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("retrabajosODS")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faMoneyBillTransfer} className="nav-icon" />
                                <p>Retrabajos</p>
                              </a>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li class={menuClassFac}>
                        <a className="nav-link" onClick={toggleMenuFac} style={{ cursor: "pointer" }}>
                          <i className="nav-icon fas fa-file-invoice" />
                          <p>
                            Facturas
                            <i class="right fas fa-angle-left" style={{ cursor: "pointer" }}></i>
                          </p>
                        </a>
                        <ul class="nav nav-treeview">
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("tablaFacturacion")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "Factura"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("tablaFacturacion")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-file-invoice" />
                                <p>Facturar ODC</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("tablaFacturasMostrador")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "tablaFacturasMostrador"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("tablaFacturasMostrador")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-file-invoice" />
                                <p>Facturas de mostrador</p>
                              </a>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li class={menuClassRut}>
                        <a className="nav-link" onClick={toggleMenuRut} style={{ cursor: "pointer" }}>
                          <i className="nav-icon fas fa-route" />
                          <p>
                            Mensajeria
                            <i class="right fas fa-angle-left" style={{ cursor: "pointer" }}></i>
                          </p>
                        </a>
                        <ul class="nav nav-treeview">
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("rutas")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "progRutas"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("progRutas")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-car" />
                                <p>Rutas</p>
                              </a>
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => handleClick("rutasAsignadas")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "rutasAsignadas"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("rutasAsignadas")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-info" />
                                <p>Rutas asignadas</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("mapa")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "mapa"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("mapa")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-map" />
                                <p>Mapa</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("coordenadas")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "listCoord"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("listCoord")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-map" />
                                <p>Listado de coordenadas registradas</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("verificacion")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "verif"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("verif")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-wrench" />
                                <p>Verificación</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("tablaVerificacion")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "tabVerif"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("tabVerif")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-tools" />
                                <p>Tabla de verificaciónes</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("viajes")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "viajes"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("viajesAdmin")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-car" />
                                <p>Viajes Especiales</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("entregas")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "entregasAdmin"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("entregasAdmin")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-car" />
                                <p>Entregas</p>
                              </a>
                            </Link>
                          </li>
                          <li class="nav-item menu-open inicioMenu">
                            <Link
                              onClick={() => handleClick("odsRutas")}
                              style={{ textDecoration: "none" }}
                              className="custom-link"
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "odsRutas" ? "active inicioNav" : ""
                                  }`}
                                onClick={() => handleItemClick("odsRutas")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-home" />
                                <p>Ordenes de cliente por mensajero</p>
                              </a>
                            </Link>
                          </li>
                          <li class="nav-item menu-open inicioMenu">
                            <Link
                              onClick={() => handleClick("incidenciasRutas")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "users" ? "active inicioNav" : ""
                                  }`}
                                onClick={() => handleItemClick("users")}
                              >
                                <FontAwesomeIcon icon={faTriangleExclamation} className="nav-icon" />
                                <p>Incidencias</p>
                              </a>
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li class={menuClass}>
                        <a className="nav-link" onClick={toggleMenu} style={{ cursor: "pointer" }}>
                          <i className="nav-icon fas fa-store" />
                          <p>
                            Almacen
                            <i class="right fas fa-angle-left" style={{ cursor: "pointer" }}></i>
                          </p>
                        </a>
                        <ul class="nav nav-treeview">
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("materialesVale")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "vales"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("vales")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faBoxesPacking} className="nav-icon" />
                                <p>Solicitud materias primas</p>
                              </a>
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("insumosVale")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "vale"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("vale")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faBoxesPacking} className="nav-icon" />
                                <p>Solicitud insumos</p>
                              </a>
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("almacenPL")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "insumos"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("insumos")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-box" />
                                <p>Insumos</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("almacenMP")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "mPrimas"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("mPrimas")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-store" />
                                <p>Materias Primas</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("medicamentos")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "medicamentos"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("medicamentos")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-store" />
                                <p>Medicamentos</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("movimientosAPL")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "movimientosAPL"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("movimientosAPL")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-store" />
                                <p>Movimientos MP</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("movimientosAPM")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "movimientosAPM"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("movimientosAPM")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-store" />
                                <p>Movimientos insumos</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("movimientosMedicamentos")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "movimientosMedicamentos"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("movimientosMedicamentos")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-store" />
                                <p>Movimientos Medicamentos</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("tablaProductosAgotandose")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "tProductosAgotandose"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("tProductosAgotandose")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-tasks" />
                                <p>Productos proximos a agotarse</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("unidadesMedida")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "UM"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("UM")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-store" />
                                <p>Unidades Medida</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("proveedores")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "prov" ? "active inicioNav" : ""
                                  }`}
                                onClick={() => handleItemClick("prov")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-user" />
                                <p>Proveedores</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("ubicaciones")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "ubiAlm"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("ubiAlm")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-location-arrow" />
                                <p>Ubicaciones</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("categorias")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "catAlm"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("catAlm")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-list-alt" />
                                <p>Categorias</p>
                              </a>
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li class={menuClassCli}>
                        <a className="nav-link" onClick={toggleMenuCli} style={{ cursor: "pointer" }}>
                          <i className="nav-icon fas fa-user" />
                          <p>
                            Clientes
                            <i class="right fas fa-angle-left" style={{ cursor: "pointer" }}></i>
                          </p>
                        </a>
                        <ul class="nav nav-treeview">
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("doctores")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "docCli"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("docCli")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-stethoscope" />
                                <p>Doctores</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("facturacion")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "dfCli"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("dfCli")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-file-invoice-dollar" />
                                <p>Datos de facturación</p>
                              </a>
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li class={menuClassOC}>
                        <a className="nav-link" onClick={toggleMenuOC} style={{ cursor: "pointer" }}>
                          <i className="nav-icon fa fa-money-bill" />
                          <p>
                            Compras
                            <i class="right fas fa-angle-left" style={{ cursor: "pointer" }}></i>
                          </p>
                        </a>
                        <ul class="nav nav-treeview">
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("requisicion")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "requisicion"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("requisicion")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faListCheck} className="nav-icon" />
                                <p>Requisiciones</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("ordenesCompra")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "ordenesCompra"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("ordenesCompra")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faMoneyCheckDollar} className="nav-icon" />
                                <p>Ordenes de compra</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("recepcionesCompra")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "recepcionesCompra"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("recepcionesCompra")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faMoneyCheckDollar} className="nav-icon" />
                                <p>Recepcion manual</p>
                              </a>
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li class={menuClassFin}>
                        <a className="nav-link" onClick={toggleMenuFin} style={{ cursor: "pointer" }}>
                          <i className="nav-icon fas fa-cog" />
                          <p>
                            Finanzas
                            <i class="right fas fa-angle-left" style={{ cursor: "pointer" }}></i>
                          </p>
                        </a>
                        <ul class="nav nav-treeview">
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("saldosClientes")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "altaPrec"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("altaPrec")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-money-bill" />
                                <p>Saldos</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("cuentasXP")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "cuentasXP"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("cuentasXP")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-money-bill" />
                                <p>Cuentas por Pagar</p>
                              </a>
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("gastosClientes")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "vehiculos"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("vehiculos")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-car" />
                                <p>Gastos</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("pagosOrdenes")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "paOrdenes"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("paOrdenes")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon className="nav-icon" icon={faHandHoldingDollar} />
                                <p>Pagos de ordenes</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("cajaChica")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "cajaChica"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("cajaChica")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon className="nav-icon" icon={faCashRegister} />
                                <p>Caja</p>
                              </a>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li class={menuClassRH}>
                        <a className="nav-link" onClick={toggleMenuRH} style={{ cursor: "pointer" }}>
                          <FontAwesomeIcon icon={faUsersGear} className="nav-icon" />
                          <p>
                            Recursos Humanos
                            <i class="right fas fa-angle-left" style={{ cursor: "pointer" }}></i>
                          </p>
                        </a>
                        <ul class="nav nav-treeview">
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("registroEmpleado")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "registroEmpleado"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("registroEmpleado")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-user-plus" />
                                <p>Registrar empleado</p>
                              </a>
                            </Link>
                            <Link
                              onClick={() => handleClick("tablaEmpleado")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "tablaEmpleado"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("tablaEmpleado")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faUsersRectangle} className="nav-icon" />
                                <p>Tabla empleados</p>
                              </a>
                            </Link>
                            <Link
                              onClick={() => handleClick("tablaAsistencia")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "tablaAsistencia"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("tablaAsistencia")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faMoneyBillWave} className="nav-icon" />
                                <p>Sueldos de los empleados</p>
                              </a>
                            </Link>
                            <Link
                              onClick={() => handleClick("asistencia")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "asistencia"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("asistencia")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faClock} className="nav-icon" />
                                <p>Asistencia</p>
                              </a>
                            </Link>
                            <Link
                              onClick={() => handleClick("cursos")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "cursos"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("cursos")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faIndustry} className="nav-icon" />
                                <p>Cursos</p>
                              </a>
                            </Link>
                            <Link
                              onClick={() => handleClick("capacitaciones")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "capacitaciones"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("capacitaciones")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faFolder} className="nav-icon" />
                                <p>Capacitaciones de empleados</p>
                              </a>
                            </Link>
                            <Link
                              onClick={() => handleClick("matrizAsistencia")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "matrizAsistencia"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("matrizAsistencia")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faFolder} className="nav-icon" />
                                <p>Matriz de asistencia</p>
                              </a>
                            </Link>
                            <Link
                              onClick={() => handleClick("vacaciones")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "vacaciones"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("vacaciones")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faCalendarDays} className="nav-icon" />
                                <p>Vacaciones</p>
                              </a>
                            </Link>
                            <Link
                              onClick={() => handleClick("tablaCargos")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "tablaCargos"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("tablaCargos")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faClipboardUser} className="nav-icon" />
                                <p>Tabla cargos</p>
                              </a>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li class={menuClassMQ}>
                        <a className="nav-link" onClick={toggleMenuMQ} style={{ cursor: "pointer" }}>
                          <FontAwesomeIcon icon={faGears} className="nav-icon" />
                          <p>
                            Maquinas
                            <i class="right fas fa-angle-left" style={{ cursor: "pointer" }}></i>
                          </p>
                        </a>
                        <ul class="nav nav-treeview">
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("registroMaquina")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "registroMaquina"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("registroMaquina")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faGear} className="nav-icon" />
                                <p>Registrar máquina</p>
                              </a>
                            </Link>
                            <Link
                              onClick={() => handleClick("mantenimientoMaquinas")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "mantenimientoMaquinas"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("mantenimientoMaquinas")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faCalendar} className="nav-icon" />
                                <p>Mantenimiento</p>
                              </a>
                            </Link>
                            <Link
                              onClick={() => handleClick("dineroMantenimiento")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "dineroMantenimiento"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("dineroMantenimiento")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faCalendar} className="nav-icon" />
                                <p>Gastos de mantenimiento</p>
                              </a>
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("usuarios")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "incidencias" ? "active inicioNav" : ""
                              }`}
                            onClick={() => handleItemClick("incidencias")}
                          >
                            <i className="nav-icon fas fa-user" />
                            <p>Usuarios</p>
                          </a>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("visitasMensajeros")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "visitasMensajeros" ? "active inicioNav" : ""
                              }`}
                            onClick={() => handleItemClick("visitasMensajeros")}
                          >
                            <i className="nav-icon fas fa-user-check"/>
                            <p>Visitas de mensajeros</p>
                          </a>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link onClick={() => handleClick("chatsMC")} style={{ textDecoration: "none" }}>
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "chatsMC" ? "active inicioNav" : ""}`}
                            onClick={() => handleItemClick("chatsMC")}
                          >
                            <i className="nav-icon fas fa-comments" />
                            <p>
                              Chats{" "}
                              <span
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  borderRadius: "50%",
                                  padding: "5px 10px",
                                  fontSize: "14px",
                                  display: "inline-block",
                                  marginLeft: "8px",
                                }}
                              >
                                {chatsList.length}
                              </span>
                            </p>
                          </a>
                        </Link>
                      </li>


                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("incidencias")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "users" ? "active inicioNav" : ""
                              }`}
                            onClick={() => handleItemClick("users")}
                          >
                            <FontAwesomeIcon icon={faTriangleExclamation} className="nav-icon" />
                            <p>Incidencias</p>
                          </a>
                        </Link>
                      </li>
                      <li class={menuClassCom}>
                        <a className="nav-link" onClick={toggleMenuCom} style={{ cursor: "pointer" }}>
                          <i className="nav-icon fas fa-table" />
                          <p>
                            Comedor
                            <i class="right fas fa-angle-left" style={{ cursor: "pointer" }}></i>
                          </p>
                        </a>
                        <ul class="nav nav-treeview">
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("comedor")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "comedor"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("comedor")}
                              >
                                <i className="nav-icon fas fa-tasks" />
                                <p>Gastos del comedor</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("mesasComedor")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "mesasComedor" ? "active inicioNav" : ""
                                  }`}
                                onClick={() => handleItemClick("mesasComedor")}
                              >
                                <FontAwesomeIcon icon={faTabletAlt} className="nav-icon" />
                                <p>Mesas comedor</p>
                              </a>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li class={menuClassBar}>
                        <a className="nav-link" onClick={toggleMenuBar} style={{ cursor: "pointer" }}>
                          <i className="nav-icon fas fa-book" />
                          <p>
                            Catalogos
                            <i class="right fas fa-angle-left" style={{ cursor: "pointer" }}></i>
                          </p>
                        </a>
                        <ul class="nav nav-treeview">
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("colorimetria")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "sisCol"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("sisCol")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-swatchbook" />
                                <p>Sistemas de colorimetría</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("departamentos")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "dep" ? "active inicioNav" : ""
                                  }`}
                                onClick={() => handleItemClick("dep")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-building" />
                                <p>Departamentos</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("materialesCat")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "materialesCat"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("materialesCat")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faTooth} className="nav-icon" />
                                <p>Materiales</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("precios")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "altaPrecios"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("altaPrecios")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faTooth} className="nav-icon" />
                                <p>Protesis dentales</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("vehiculos")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "vehiculos"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("vehiculos")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-car" />
                                <p>Vehiculos</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("mensajeros")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "mensajerosMenu"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() =>
                                  handleItemClick("mensajerosMenu")
                                }
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-truck" />
                                <p>Mensajeros</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("diasFestivos")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "diasFestivos"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() =>
                                  handleItemClick("diasFestivos")
                                }
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-candy-cane" />
                                <p>Dias festivos</p>
                              </a>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("tablaLogs")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "logs" ? "active inicioNav" : ""
                              }`}
                            onClick={() => handleItemClick("logs")}
                          >
                            <i className="nav-icon fas fa-history" />
                            <p>Logs</p>
                          </a>
                        </Link>
                      </li>

                    </>
                  )}

                  {tipoUsuario == "consultorio" && showComponent32 == false && (
                    <>
                      <li class="nav-item menu-open inicioMenu">
                        <Link
                          onClick={() => handleClick("inicio")}
                          style={{ textDecoration: "none" }}
                          className="custom-link"
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "inicio" ? "active inicioNav" : ""
                              }`}
                            onClick={() => handleItemClick("inicio")}
                          >
                            <i className="nav-icon fas fa-home" />
                            <p>Inicio</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("orden2")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "nOrdenCons"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("nOrdenCons")}
                          >
                            <i className="nav-icon fas fa-bell" />
                            <p>Nueva orden de Cliente</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("ordenes")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "listOrdenCons"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("listOrdenCons")}
                          >
                            <i className="nav-icon fas fa-book" />
                            <p>Ordenes de cliente</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("doctores")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "misDocCons"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("misDocCons")}
                          >
                            <i className="nav-icon fas fa-stethoscope" />
                            <p>Mis Doctores</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link onClick={() => handleClick("chatsMC")} style={{ textDecoration: "none" }}>
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "chatsMC" ? "active inicioNav" : ""}`}
                            onClick={() => handleItemClick("chatsMC")}
                          >
                            <i className="nav-icon fas fa-comments" />
                            <p>
                              Chats{" "}
                              <span
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  borderRadius: "50%",
                                  padding: "5px 10px",
                                  fontSize: "14px",
                                  display: "inline-block",
                                  marginLeft: "8px",
                                }}
                              >
                                {chatsList.length}
                              </span>
                            </p>
                          </a>
                        </Link>
                      </li>

                    </>
                  )}

                  {tipoUsuario == "finanzas" && (
                    <>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("ordenesCompra")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "ordenesCompra"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("ordenesCompra")}
                          >
                            <FontAwesomeIcon icon={faMoneyCheckDollar} className="nav-icon" />
                            <p>Ordenes de compra</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("requisicion")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "requisicion"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("requisicion")}
                          >
                            <FontAwesomeIcon icon={faListCheck} className="nav-icon" />
                            <p>Requisiciones</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("gastosClientes")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "vehiculos"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("vehiculos")}
                          >
                            <i className="nav-icon fas fa-car" />
                            <p>Gastos</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("pagosOrdenes")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "paOrdenes"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("paOrdenes")}
                          >
                            <FontAwesomeIcon className="nav-icon" icon={faHandHoldingDollar} />
                            <p>Pagos de ordenes</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("cajaChica")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "cajaChica"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("cajaChica")}
                          >
                            <FontAwesomeIcon className="nav-icon" icon={faCashRegister} />
                            <p>Caja</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link onClick={() => handleClick("chatsMC")} style={{ textDecoration: "none" }}>
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "chatsMC" ? "active inicioNav" : ""}`}
                            onClick={() => handleItemClick("chatsMC")}
                          >
                            <i className="nav-icon fas fa-comments" />
                            <p>
                              Chats{" "}
                              <span
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  borderRadius: "50%",
                                  padding: "5px 10px",
                                  fontSize: "14px",
                                  display: "inline-block",
                                  marginLeft: "8px",
                                }}
                              >
                                {chatsList.length}
                              </span>
                            </p>
                          </a>
                        </Link>
                      </li>
                    </>
                  )}

                  {tipoUsuario == "doctor" && showComponent33 == false && (
                    <>
                      <li class="nav-item menu-open inicioMenu">
                        <Link
                          onClick={() => handleClick("inicio")}
                          style={{ textDecoration: "none" }}
                          className="custom-link"
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "inicio" ? "active inicioNav" : ""
                              }`}
                            onClick={() => handleItemClick("inicio")}
                          >
                            <i className="nav-icon fas fa-home" />
                            <p>Inicio</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("orden2")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "nOrdenDoc"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("nOrdenDoc")}
                          >
                            <i className="nav-icon fas fa-bell" />
                            <p>Nueva orden de cliente</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("ordenes")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "listOS" ? "active inicioNav" : ""
                              }`}
                            onClick={() => handleItemClick("listOS")}
                          >
                            <i className="nav-icon fas fa-book" />
                            <p>Listado de ordenes de cliente</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("tablaFacturacion")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "tablaFacturacion"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("tablaFacturacion")}
                          >
                            <i className="nav-icon fas fa-file-invoice" />
                            <p>Facturacion</p>
                          </a>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("tablaFacturasMostrador")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "tablaFacturasMostrador"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("tablaFacturasMostrador")}
                          >
                            <i className="nav-icon fas fa-file-invoice" />
                            <p>Facturas de mostrador</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("saldosCli")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "saldosCli"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("saldosCli")}
                          >
                            <i className="nav-icon fas fa-money-bill" />
                            <p>Saldos</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link onClick={() => handleClick("chatsMC")} style={{ textDecoration: "none" }}>
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "chatsMC" ? "active inicioNav" : ""}`}
                            onClick={() => handleItemClick("chatsMC")}
                          >
                            <i className="nav-icon fas fa-comments" />
                            <p>
                              Chats{" "}
                              <span
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  borderRadius: "50%",
                                  padding: "5px 10px",
                                  fontSize: "14px",
                                  display: "inline-block",
                                  marginLeft: "8px",
                                }}
                              >
                                {chatsList.length}
                              </span>
                            </p>
                          </a>
                        </Link>
                      </li>
                    </>
                  )}

                  {tipoUsuario == "departamento" && (
                    <>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("serviciosDepartamentos")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "catAlmacen"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("catAlmacen")}
                          >
                            <i className="nav-icon fas fa-building" />
                            <p>Servicios</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("detalleOrden")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "detOrden"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("detOrden")}
                          >
                            <i className="nav-icon fas fa-info" />
                            <p>Panel mesa de control</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("ordenesTrabajo")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "oTrabajo"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("oTrabajo")}
                          >
                            <i className="nav-icon fas fa-tasks" />
                            <p>Ordenes de trabajo</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("incidencias")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "users" ? "active inicioNav" : ""
                              }`}
                            onClick={() => handleItemClick("users")}
                          >
                            <FontAwesomeIcon icon={faTriangleExclamation} className="nav-icon" />
                            <p>Incidencias</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("tablaEmpleado")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "tablaEmpleado"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("tablaEmpleado")}
                          >
                            <FontAwesomeIcon icon={faUsersRectangle} className="nav-icon" />
                            <p>Empleados</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("materialesVale")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "vales"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("vales")}
                          >
                            <FontAwesomeIcon icon={faBoxesPacking} className="nav-icon" />
                            <p>Solicitud materias primas</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("insumosVale")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "vale"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("vale")}
                          >
                            <FontAwesomeIcon icon={faBoxesPacking} className="nav-icon" />
                            <p>Solicitud insumos</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("precios")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "altaPrecios"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("altaPrecios")}
                          >
                            <FontAwesomeIcon icon={faTooth} className="nav-icon" />
                            <p>Protesis dentales</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link onClick={() => handleClick("chatsMC")} style={{ textDecoration: "none" }}>
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "chatsMC" ? "active inicioNav" : ""}`}
                            onClick={() => handleItemClick("chatsMC")}
                          >
                            <i className="nav-icon fas fa-comments" />
                            <p>
                              Chats{" "}
                              <span
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  borderRadius: "50%",
                                  padding: "5px 10px",
                                  fontSize: "14px",
                                  display: "inline-block",
                                  marginLeft: "8px",
                                }}
                              >
                                {chatsList.length}
                              </span>
                            </p>
                          </a>
                        </Link>
                      </li>
                    </>
                  )}

                  {tipoUsuario == "almacen" && (
                    <>
                      <li class="nav-item menu-open inicioMenu">
                        <Link
                          onClick={() => handleClick("inicio")}
                          style={{ textDecoration: "none" }}
                          className="custom-link"
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "inicio" ? "active inicioNav" : ""
                              }`}
                            onClick={() => handleItemClick("inicio")}
                          >
                            <i className="nav-icon fas fa-home" />
                            <p>Inicio</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("materialesVale")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "vales"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("vales")}
                          >
                            <FontAwesomeIcon icon={faBoxesPacking} className="nav-icon" />
                            <p>Solicitud materias primas</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("insumosVale")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "vale"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("vale")}
                          >
                            <FontAwesomeIcon icon={faBoxesPacking} className="nav-icon" />
                            <p>Solicitud insumos</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("almacenPL")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "insumosAlmacen"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("insumosAlmacen")}
                          >
                            <i className="nav-icon fas fa-box" />
                            <p>Insumos</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("almacenMP")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "mpAlmacenM"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("mpAlmacenM")}
                          >
                            <i className="nav-icon fas fa-store" />
                            <p>Materias Primas</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("tablaProductosAgotandose")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "tProductosAgotandose"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("tProductosAgotandose")}
                          >
                            <i className="nav-icon fas fa-tasks" />
                            <p>Productos proximos a agotarse</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("unidadesMedida")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "UM"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("UM")}
                          >
                            <i className="nav-icon fas fa-store" />
                            <p>Unidades Medida</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("requisicion")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "requisicion"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("requisicion")}
                          >
                            <FontAwesomeIcon icon={faMoneyCheckDollar} className="nav-icon" />
                            <p>Requisiciones</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("proveedores")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "prov" ? "active inicioNav" : ""
                              }`}
                            onClick={() => handleItemClick("prov")}
                          >
                            <i className="nav-icon fas fa-user" />
                            <p>Proveedores</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("ubicaciones")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "ubiAlmacen"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("ubiAlmacen")}
                          >
                            <i className="nav-icon fas fa-location-arrow" />
                            <p>Ubicaciones</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("categorias")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "catAlmacen"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("catAlmacen")}
                          >
                            <i className="nav-icon fas fa-building" />
                            <p>Categorias</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link onClick={() => handleClick("chatsMC")} style={{ textDecoration: "none" }}>
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "chatsMC" ? "active inicioNav" : ""}`}
                            onClick={() => handleItemClick("chatsMC")}
                          >
                            <i className="nav-icon fas fa-comments" />
                            <p>
                              Chats{" "}
                              <span
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  borderRadius: "50%",
                                  padding: "5px 10px",
                                  fontSize: "14px",
                                  display: "inline-block",
                                  marginLeft: "8px",
                                }}
                              >
                                {chatsList.length}
                              </span>
                            </p>
                          </a>
                        </Link>
                      </li>
                    </>
                  )}

                  {tipoUsuario == "mensajero" && showComponent26 == true && (
                    <></>
                  )}

                  {tipoUsuario == "mensajero" && showComponent26 == false && (
                    <>
                      <li class="nav-item menu-open inicioMenu">
                        <Link
                          onClick={() => handleClick("inicio")}
                          style={{ textDecoration: "none" }}
                          className="custom-link"
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "inicio" ? "active inicioNav" : ""
                              }`}
                            onClick={() => handleItemClick("inicio")}
                          >
                            <i className="nav-icon fas fa-home" />
                            <p>Inicio</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("entregas")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "entregasAdmin"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("entregasAdmin")}
                          >
                            <i className="nav-icon fas fa-car" />
                            <p>Entregas</p>
                          </a>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("visitasMensajeros")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "visitasMensajeros" ? "active inicioNav" : ""
                              }`}
                            onClick={() => handleItemClick("visitasMensajeros")}
                          >
                            <i className="nav-icon fas fa-user-check"/>
                            <p>Visitas de mensajeros</p>
                          </a>
                        </Link>
                      </li>
                      

                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("orden2")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "nOrdenMens"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("nOrdenMens")}
                          >
                            <i className="nav-icon fas fa-bell" />
                            <p>Nueva orden de cliente</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("ordenes")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "listOMensajero"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("listOMensajero")}
                          >
                            <i className="nav-icon fas fa-book" />
                            <p>Listado de ordenes de cliente</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("rutas")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "progRutasMens"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("progRutasMens")}
                          >
                            <i className="nav-icon fas fa-car" />
                            <p>Programa de rutas</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("mapa")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "mapaMensajero"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("mapaMensajero")}
                          >
                            <i className="nav-icon fas fa-map" />
                            <p>Mapa</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("gastosM")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "gastosMensajero"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("gastosMensajero")}
                          >
                            <i className="nav-icon fas fa-dollar-sign" />
                            <p>Gastos</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link onClick={() => handleClick("chatsMC")} style={{ textDecoration: "none" }}>
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "chatsMC" ? "active inicioNav" : ""}`}
                            onClick={() => handleItemClick("chatsMC")}
                          >
                            <i className="nav-icon fas fa-comments" />
                            <p>
                              Chats{" "}
                              <span
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  borderRadius: "50%",
                                  padding: "5px 10px",
                                  fontSize: "14px",
                                  display: "inline-block",
                                  marginLeft: "8px",
                                }}
                              >
                                {chatsList.length}
                              </span>
                            </p>
                          </a>
                        </Link>
                      </li>
                    </>
                  )}
                  {tipoUsuario == "rHumanos" && (
                    <>
                      <li class="nav-item menu-open inicioMenu">
                        <Link
                          onClick={() => handleClick("inicio")}
                          style={{ textDecoration: "none" }}
                          className="custom-link"
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "inicio" ? "active inicioNav" : ""
                              }`}
                            onClick={() => handleItemClick("inicio")}
                          >
                            <i className="nav-icon fas fa-home" />
                            <p>Inicio</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("incidenciasRutas")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "users" ? "active inicioNav" : ""
                              }`}
                            onClick={() => handleItemClick("users")}
                          >
                            <FontAwesomeIcon icon={faTriangleExclamation} className="nav-icon" />
                            <p>Incidencias</p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("rutas")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "progRutas"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("progRutas")}
                          >
                            <i className="nav-icon fas fa-car" />
                            <p>Rutas</p>
                          </a>
                        </Link>

                        <Link
                          onClick={() => handleClick("mapa")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "mapa"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("mapa")}
                          >
                            <i className="nav-icon fas fa-map" />
                            <p>Mapa</p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("coordenadas")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "listCoord"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("listCoord")}
                          >
                            <i className="nav-icon fas fa-map" />
                            <p>Listado de coordenadas registradas</p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("verificacion")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "verif"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("verif")}
                          >
                            <i className="nav-icon fas fa-wrench" />
                            <p>Verificación</p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("tablaVerificacion")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "tabVerif"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("tabVerif")}
                          >
                            <i className="nav-icon fas fa-tools" />
                            <p>Tabla de verificaciónes</p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("viajes")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "viajesAdmin"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("viajesAdmin")}
                          >
                            <i className="nav-icon fas fa-car" />
                            <p>Viajes Especiales</p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("entregas")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "entregasAdmin"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("entregasAdmin")}
                          >
                            <i className="nav-icon fas fa-car" />
                            <p>Entregas</p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("tablaEmpleado")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "tablaEmpleado"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("tablaEmpleado")}
                          >
                            <FontAwesomeIcon icon={faUsersRectangle} className="nav-icon" />
                            <p>Tabla empleados</p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("vehiculos")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "vehiculos"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("vehiculos")}
                          >
                            <i className="nav-icon fas fa-car" />
                            <p>Vehiculos</p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("mensajeros")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "mensajerosMenu"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() =>
                              handleItemClick("mensajerosMenu")
                            }
                          >
                            <i className="nav-icon fas fa-truck" />
                            <p>Mensajeros</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link onClick={() => handleClick("chatsMC")} style={{ textDecoration: "none" }}>
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "chatsMC" ? "active inicioNav" : ""}`}
                            onClick={() => handleItemClick("chatsMC")}
                          >
                            <i className="nav-icon fas fa-comments" />
                            <p>
                              Chats{" "}
                              <span
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  borderRadius: "50%",
                                  padding: "5px 10px",
                                  fontSize: "14px",
                                  display: "inline-block",
                                  marginLeft: "8px",
                                }}
                              >
                                {chatsList.length}
                              </span>
                            </p>
                          </a>
                        </Link>
                      </li>
                    </>
                  )}

                  {tipoUsuario == "cProduccion" && (
                    <>
                      <li class="nav-item menu-open inicioMenu"> <Link onClick={() => handleClick("inicio")} style={{ textDecoration: "none" }} className="custom-link" > <a href="#" className={`nav-link ${activeItem === "inicio" ? "active inicioNav" : ""}`} onClick={() => handleItemClick("inicio")} > <i className="nav-icon fas fa-home" /> <p>Inicio</p> </a> </Link> </li> <li className="nav-item"> <Link onClick={() => handleClick("ordenesTrabajo")} style={{ textDecoration: "none" }} > <a href="#" className={`nav-link ${activeItem === "oTrabajo" ? "active inicioNav" : ""}`} onClick={() => handleItemClick("oTrabajo")} > <i className="nav-icon fas fa-tasks" /> <p>Ordenes de trabajo</p> </a> </Link> </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("ordenes")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "listOrdenes"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("listOrdenes")}
                          >
                            <i className="nav-icon fas fa-book" />
                            <p>Ordenes de cliente</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item"> <Link onClick={() => handleClick("detalleOrden")} style={{ textDecoration: "none" }} > <a href="#" className={`nav-link ${activeItem === "detOrden" ? "active inicioNav" : ""}`} onClick={() => handleItemClick("detOrden")} > <i className="nav-icon fas fa-info" /> <p>Panel mesa de control</p> </a> </Link> </li> <li> <Link onClick={() => handleClick("impresionOrdenes")} style={{ textDecoration: "none" }} > <a href="#" className={`nav-link ${activeItem === "impresionOrdenes" ? "active inicioNav" : ""}`} onClick={() => handleItemClick("impresionOrdenes")} > <i className="nav-icon fas fa-print" /> <p>Impresión de ordenes</p> </a> </Link> </li> <li className="nav-item"> <Link onClick={() => handleClick("incidencias")} style={{ textDecoration: "none" }} > <a href="#" className={`nav-link ${activeItem === "users" ? "active inicioNav" : ""}`} onClick={() => handleItemClick("users")} > <FontAwesomeIcon icon={faTriangleExclamation} className="nav-icon" /> <p>Incidencias</p> </a> </Link> </li>

                      <li className="nav-item"> <Link onClick={() => handleClick("materialesVale")} style={{ textDecoration: "none" }} > <a href="#" className={`nav-link ${activeItem === "vales" ? "active inicioNav" : ""}`} onClick={() => handleItemClick("vales")} > <FontAwesomeIcon icon={faBoxesPacking} className="nav-icon" /> <p>Solicitud materias primas</p> </a> </Link> </li>
                      <li class={menuClass}>
                        <a className="nav-link" onClick={toggleMenu} style={{ cursor: "pointer" }}>
                          <i className="nav-icon fas fa-store" />
                          <p>
                            Almacen
                            <i class="right fas fa-angle-left" style={{ cursor: "pointer" }}></i>
                          </p>
                        </a>
                        <ul class="nav nav-treeview">
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("materialesVale")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "vales"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("vales")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faBoxesPacking} className="nav-icon" />
                                <p>Solicitud materias primas</p>
                              </a>
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("insumosVale")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "vale"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("vale")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faBoxesPacking} className="nav-icon" />
                                <p>Solicitud insumos</p>
                              </a>
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("almacenPL")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "insumos"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("insumos")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-box" />
                                <p>Insumos</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("almacenMP")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "mPrimas"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("mPrimas")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-store" />
                                <p>Materias Primas</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("requisicion")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "requisicion"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("requisicion")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faMoneyCheckDollar} className="nav-icon" />
                                <p>Requisiciones</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("unidadesMedida")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "UM"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("UM")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-store" />
                                <p>Unidades Medida</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("proveedores")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "prov" ? "active inicioNav" : ""
                                  }`}
                                onClick={() => handleItemClick("prov")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-user" />
                                <p>Proveedores</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("ubicaciones")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "ubiAlm"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("ubiAlm")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-location-arrow" />
                                <p>Ubicaciones</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("categorias")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "catAlm"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("catAlm")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-list-alt" />
                                <p>Categorias</p>
                              </a>
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li class={menuClassBar}>
                        <a className="nav-link" onClick={toggleMenuBar} style={{ cursor: "pointer" }}>
                          <i className="nav-icon fas fa-book" />
                          <p>
                            Catalogos
                            <i class="right fas fa-angle-left" style={{ cursor: "pointer" }}></i>
                          </p>
                        </a>
                        <ul class="nav nav-treeview">
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("colorimetria")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "sisCol"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("sisCol")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-swatchbook" />
                                <p>Sistemas de colorimetría</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("materialesCat")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "materialesCat"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("materialesCat")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faTooth} className="nav-icon" />
                                <p>Materiales</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("precios")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "altaPrecios"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("altaPrecios")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faTooth} className="nav-icon" />
                                <p>Protesis dentales</p>
                              </a>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link onClick={() => handleClick("chatsMC")} style={{ textDecoration: "none" }}>
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "chatsMC" ? "active inicioNav" : ""}`}
                            onClick={() => handleItemClick("chatsMC")}
                          >
                            <i className="nav-icon fas fa-comments" />
                            <p>
                              Chats{" "}
                              <span
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  borderRadius: "50%",
                                  padding: "5px 10px",
                                  fontSize: "14px",
                                  display: "inline-block",
                                  marginLeft: "8px",
                                }}
                              >
                                {chatsList.length}
                              </span>
                            </p>
                          </a>
                        </Link>
                      </li>
                    </>
                  )}

                  {tipoUsuario == "contabilidad" && (
                    <>
                      <li class="nav-item menu-open inicioMenu">
                        <Link
                          onClick={() => handleClick("inicio")}
                          style={{ textDecoration: "none" }}
                          className="custom-link"
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "inicio" ? "active inicioNav" : ""
                              }`}
                            onClick={() => handleItemClick("inicio")}
                          >
                            <i className="nav-icon fas fa-home" />
                            <p>Inicio</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("tablaFacturacion")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "tablaFacturacion"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("tablaFacturacion")}
                          >
                            <i className="nav-icon fas fa-file-invoice" />
                            <p>Facturacion</p>
                          </a>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("tablaFacturasMostrador")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "tablaFacturasMostrador"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("tablaFacturasMostrador")}
                          >
                            <i className="nav-icon fas fa-file-invoice" />
                            <p>Facturas de mostrador</p>
                          </a>
                        </Link>
                      </li>
                      <li class={menuClassFin}>
                        <a className="nav-link" onClick={toggleMenuFin} style={{ cursor: "pointer" }}>
                          <i className="nav-icon fas fa-cog" />
                          <p>
                            Finanzas
                            <i class="right fas fa-angle-left" style={{ cursor: "pointer" }}></i>
                          </p>
                        </a>
                        <ul class="nav nav-treeview">
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("saldosClientes")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "altaPrec"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("altaPrec")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-money-bill" />
                                <p>Saldos</p>
                              </a>
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("gastosClientes")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "vehiculos"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("vehiculos")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-car" />
                                <p>Gastos</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("pagosOrdenes")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "paOrdenes"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("paOrdenes")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon className="nav-icon" icon={faHandHoldingDollar} />
                                <p>Pagos de ordenes</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("cajaChica")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "cajaChica"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("cajaChica")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon className="nav-icon" icon={faCashRegister} />
                                <p>Caja</p>
                              </a>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li class={menuClassRH}>
                        <a className="nav-link" onClick={toggleMenuRH} style={{ cursor: "pointer" }}>
                          <FontAwesomeIcon icon={faUsersGear} className="nav-icon" />
                          <p>
                            Recursos Humanos
                            <i class="right fas fa-angle-left" style={{ cursor: "pointer" }}></i>
                          </p>
                        </a>
                        <ul class="nav nav-treeview">
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("registroEmpleado")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "registroEmpleado"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("registroEmpleado")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="nav-icon fas fa-user-plus" />
                                <p>Registrar empleado</p>
                              </a>
                            </Link>
                            <Link
                              onClick={() => handleClick("tablaEmpleado")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "tablaEmpleado"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("tablaEmpleado")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faUsersRectangle} className="nav-icon" />
                                <p>Tabla empleados</p>
                              </a>
                            </Link>
                            <Link
                              onClick={() => handleClick("tablaAsistencia")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "tablaAsistencia"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("tablaAsistencia")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faMoneyBillWave} className="nav-icon" />
                                <p>Sueldos de los empleados</p>
                              </a>
                            </Link>
                            <Link
                              onClick={() => handleClick("asistencia")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "asistencia"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("asistencia")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faClock} className="nav-icon" />
                                <p>Asistencia</p>
                              </a>
                            </Link>
                            <Link
                              onClick={() => handleClick("cursos")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "cursos"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("cursos")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faIndustry} className="nav-icon" />
                                <p>Cursos</p>
                              </a>
                            </Link>
                            <Link
                              onClick={() => handleClick("capacitaciones")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "capacitaciones"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("capacitaciones")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faFolder} className="nav-icon" />
                                <p>Capacitaciones de empleados</p>
                              </a>
                            </Link>
                            <Link
                              onClick={() => handleClick("matrizAsistencia")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "matrizAsistencia"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("matrizAsistencia")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faFolder} className="nav-icon" />
                                <p>Matriz de asistencia</p>
                              </a>
                            </Link>
                            <Link
                              onClick={() => handleClick("vacaciones")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "vacaciones"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("vacaciones")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faCalendarDays} className="nav-icon" />
                                <p>Vacaciones</p>
                              </a>
                            </Link>
                            <Link
                              onClick={() => handleClick("tablaCargos")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "tablaCargos"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("tablaCargos")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faClipboardUser} className="nav-icon" />
                                <p>Tabla cargos</p>
                              </a>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li class={menuClassOC}>
                        <a className="nav-link" onClick={toggleMenuOC} style={{ cursor: "pointer" }}>
                          <i className="nav-icon fa fa-money-bill" />
                          <p>
                            Compras
                            <i class="right fas fa-angle-left" style={{ cursor: "pointer" }}></i>
                          </p>
                        </a>
                        <ul class="nav nav-treeview">
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("requisicion")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "requisicion"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("requisicion")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faListCheck} className="nav-icon" />
                                <p>Requisiciones</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("ordenesCompra")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "ordenesCompra"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("ordenesCompra")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faMoneyCheckDollar} className="nav-icon" />
                                <p>Ordenes de compra</p>
                              </a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => handleClick("recepcionesCompra")}
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href="#"
                                className={`nav-link ${activeItem === "recepcionesCompra"
                                  ? "active inicioNav"
                                  : ""
                                  }`}
                                onClick={() => handleItemClick("recepcionesCompra")}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faMoneyCheckDollar} className="nav-icon" />
                                <p>Recepcion manual</p>
                              </a>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("comedor")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "comedor"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("comedor")}
                          >
                            <i className="nav-icon fas fa-tasks" />
                            <p>Comedor</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link onClick={() => handleClick("chatsMC")} style={{ textDecoration: "none" }}>
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "chatsMC" ? "active inicioNav" : ""}`}
                            onClick={() => handleItemClick("chatsMC")}
                          >
                            <i className="nav-icon fas fa-comments" />
                            <p>
                              Chats{" "}
                              <span
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  borderRadius: "50%",
                                  padding: "5px 10px",
                                  fontSize: "14px",
                                  display: "inline-block",
                                  marginLeft: "8px",
                                }}
                              >
                                {chatsList.length}
                              </span>
                            </p>
                          </a>
                        </Link>
                      </li>
                    </>
                  )}

                  {tipoUsuario == "mesa de control" && (
                    <>
                      <li class="nav-item menu-open inicioMenu">
                        <Link
                          onClick={() => handleClick("inicio")}
                          style={{ textDecoration: "none" }}
                          className="custom-link"
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "inicio" ? "active inicioNav" : ""
                              }`}
                            onClick={() => handleItemClick("inicio")}
                          >
                            <i className="nav-icon fas fa-home" />
                            <p>Inicio</p>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => handleClick("orden2")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "regOrden"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("regOrden")}
                          >
                            <i className="nav-icon fas fa-bell" />
                            <p>Nueva orden de cliente</p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("ordenes")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "listOrdenes"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("listOrdenes")}
                          >
                            <i className="nav-icon fas fa-book" />
                            <p>Ordenes de cliente</p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("detalleOrden")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "detOrden"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("detOrden")}
                          >
                            <i className="nav-icon fas fa-info" />
                            <p>Panel mesa de control</p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("impresionOrdenes")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "impresionOrdenes"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("impresionOrdenes")}
                          >
                            <i className="nav-icon fas fa-print" />
                            <p>Impresión de ordenes</p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("saldosCli")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "saldosCli"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("saldosCli")}
                          >
                            <i className="nav-icon fas fa-money-bill" />
                            <p>Saldos</p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("rutasAsignadas")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "rutasAsignadas"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("rutasAsignadas")}
                          >
                            <i className="nav-icon fas fa-info" />
                            <p>Rutas asignadas</p>
                          </a>
                        </Link>
                        <Link onClick={() => handleClick("chatsMC")} style={{ textDecoration: "none" }}>
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "chatsMC" ? "active inicioNav" : ""}`}
                            onClick={() => handleItemClick("chatsMC")}
                          >
                            <i className="nav-icon fas fa-comments" />
                            <p>
                              Chats{" "}
                              <span
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  borderRadius: "50%",
                                  padding: "5px 10px",
                                  fontSize: "14px",
                                  display: "inline-block",
                                  marginLeft: "8px",
                                }}
                              >
                                {chatsList.length}
                              </span>
                            </p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("ordenesTrabajo")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "oTrabajo"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("oTrabajo")}
                          >
                            <i className="nav-icon fas fa-tasks" />
                            <p>Ordenes de trabajo</p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("doctores")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "docCli"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("docCli")}
                          >
                            <i className="nav-icon fas fa-stethoscope" />
                            <p>Doctores</p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("precios")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "altaPrecios"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("altaPrecios")}
                          >
                            <FontAwesomeIcon icon={faTooth} className="nav-icon" />
                            <p>Protesis dentales</p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("materialesCat")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "materialesCat"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("materialesCat")}
                          >
                            <FontAwesomeIcon icon={faTooth} className="nav-icon" />
                            <p>Materiales</p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("departamentos")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "dep" ? "active inicioNav" : ""
                              }`}
                            onClick={() => handleItemClick("dep")}
                          >
                            <i className="nav-icon fas fa-building" />
                            <p>Departamentos</p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("mensajeros")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "mensajerosMenu"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() =>
                              handleItemClick("mensajerosMenu")
                            }
                          >
                            <i className="nav-icon fas fa-truck" />
                            <p>Mensajeros</p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("vehiculos")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "vehiculos"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("vehiculos")}
                          >
                            <i className="nav-icon fas fa-car" />
                            <p>Vehiculos</p>
                          </a>
                        </Link>
                        <Link
                          onClick={() => handleClick("colorimetria")}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href="#"
                            className={`nav-link ${activeItem === "sisCol"
                              ? "active inicioNav"
                              : ""
                              }`}
                            onClick={() => handleItemClick("sisCol")}
                          >
                            <i className="nav-icon fas fa-swatchbook" />
                            <p>Sistemas de colorimetría</p>
                          </a>
                        </Link>
                      </li>
                      {listaPermisos.length > 0 && (
                        <li className="nav-item">
                          <Link
                            onClick={() => handleClick("pagosOrdenes")}
                            style={{ textDecoration: "none" }}
                          >
                            <a
                              href="#"
                              className={`nav-link ${activeItem === "paOrdenes"
                                ? "active inicioNav"
                                : ""
                                }`}
                              onClick={() => handleItemClick("paOrdenes")}
                            >
                              <FontAwesomeIcon className="nav-icon" icon={faHandHoldingDollar} />
                              <p>Pagos de ordenes <FontAwesomeIcon icon={faStopwatch} /></p>
                            </a>
                          </Link>
                        </li>
                      )}

                    </>
                  )}

                </ul>
              </nav>
            </div>
          </aside >

          <div className="content-wrapper">
            {tipoUsuario == "almacen" && <FloatingNotificationButton />}
            {showComponent && <TblEstablecimientos />}
            {showComponent1 && <TblAlmacenPL />}
            {showComponent2 && <TblAlmacenMP />}
            {showComponent3 && <TblDoctores />}
            {showComponent4 && (isMobile ? !busqueda && <CardODS /> : !busqueda && <TblOrdenesServicio />)}
            {showComponent5 && <TblFacturacion />}
            {showComponent6 && (isMobile ? <TarjetasProtesis /> : <TblPrecios />)}
            {showComponent7 && <TblServiciosDentales />}
            {showComponent8 && <TblSistemasColorimetria />}
            {showComponent9 && <TblProductosAgotandose />}
            {showComponent10 && <TblMensajeros />}
            {showComponent11 && <TblVehiculos />}
            {showComponent13 && !busqueda && <OrdenServicioNueva />}
            {showComponent14 && <Rutas />}
            {showComponent15 && <TblConfiguracionRutas />}
            {showComponent16 && <ConsultoriosRegistro />}
            {showComponent17 && <Rutas />}
            {showComponent18 && <TblUsuarios />}
            {showComponent19 && <TblDepartamentos />}
            {showComponent20 && <TblUbicaciones />}
            {showComponent21 && <TblCategorias />}
            {showComponent22 && <MapaUbicacion />}
            {showComponent23 && <TblCoordenadas />}
            {showComponent24 && !busqueda && <InicioPefil />}
            {showComponent25 && <TblOrdenesTrabajo />}
            {showComponent26 && <Verificación />}
            {showComponent27 && <TblVerificación />}
            {showComponent28 && <TblVehiculos />}
            {showComponent29 && <Rutas />}
            {showComponent30 && <TblViajes />}
            {showComponent31 && <DatosDocInicio />}
            {showComponent32 && <RegistroEstablecimientos />}
            {showComponent33 && <DatosDoctores2 />}
            {showComponent34 && <TblGastos />}
            {showComponent35 && <DetalleOrden />}
            {showComponent36 && <MaterialEntrega />}
            {showComponent37 && (isMobile ? <TarjetasEntregas /> : <TblEntregas />)}
            {showComponent38 && <TblProveedores />}
            {showComponent39 && <TblUnidadesMedida />}
            {showComponent40 && <TblServicios />}
            {showComponent41 && <TablaPreciosOrden />}
            {showComponent42 && <TablaCXC />}
            {showComponent43 && <TblGastosM />}
            {showComponent44 && <TblOdsRutas />}
            {showComponent45 && <TablaPagosOrdenes />}
            {showComponent46 && <TablaMaterialesVale />}
            {showComponent47 && <RegistroEmpleados />}
            {showComponent48 && <TablaRecuersosHummanos />}
            {showComponent49 && <TablaCargos />}
            {showComponent50 && <Vacaciones />}
            {showComponent51 && <TablaMaquinas />}
            {showComponent52 && <ProgramaMantenimiento />}
            {showComponent53 && <TblIncidencias />}
            {showComponent54 && <DiasTrabajados />}
            {showComponent55 && <TablaOC />}
            {showComponent56 && <TablaLogs />}
            {showComponent57 && <TablaReq />}
            {showComponent58 && <TablaMovsCaja />}
            {showComponent59 && <TblCursos />}
            {showComponent60 && <TblCapacitaciones />}
            {showComponent61 && <TablaRC />}
            {showComponent62 && <TablaGastosMaquinas />}
            {showComponent63 && <OpenpayIntegration />}
            {showComponent64 && <OpenpayPayment />}
            {showComponent65 && <OpenpayPaymentPagos />}
            {showComponent66 && <OpenpayTransferencias />}
            {showComponent67 && <TablaInsumosVale />}
            {showComponent68 && <TblRetrabajos />}
            {showComponent69 && <TablaAsistencia />}
            {showComponent70 && <MatrizAsistencia />}
            {showComponent71 && <TablaMaterialesCat />}
            {showComponent72 && <TblComedor />}
            {showComponent73 && <TblRutasAignadas />}
            {showComponent74 && <ViewPDFImp />}
            {showComponent75 && !busqueda && <PrincipalChat />}
            {showComponent76 && <TablaRetrabajos />}
            {showComponent77 && <TblIncidenciasRutas />}
            {showComponent78 && <TablaCxP />}
            {showComponent79 && !busqueda && <SaldoPorCliente correo={correoUsuario} tipo={tipoUsuario} />}
            {showComponent80 && <Mesas />}
            {showComponent81 && <TblDiasFestivos />}
            {showComponent82 && <TblMovimientosGeneralAPL />}
            {showComponent83 && <TblMovimientosGeneralAPM />}
            {showComponent84 && <TablaFacturacion />}
            {showComponent85 && <TablaFacturasMostrador />}
            {showComponent86 && <TblMedicamentos />}
            {showComponent87 && <TblMovimientosMedicamentos />}
            {showComponent88 && (isMobile ? <TarjetasVisitas /> : <TablaVisitas />)}
            {/* Condición única para busqueda */}
            {busqueda && <Cards busqueda={busqueda} />}
          </div>
        </>
      )
      }
    </>
  );
}

function formatModelDoctor(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
      apellidoPaterno: data.apellidoPaterno,
      apellidoMaterno: data.apellidoMaterno,
      fechaNacimiento: data.fechaNacimiento,
      telefonoCelular: data.telefonoCelular,
      email: data.email,
      rfc: data.rfc,
      observaciones: data.observaciones,
      estado: data.estado,
      consultorio: data.consultorio,
      nombreConsultorio: data.nombreConsultorio,
    });
  });
  return dataTemp;
}

function formatModelEstablecimiento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      razonSocial: data.razonSocial,
      estadoEstablecimiento: data.estadoEstablecimiento,
      municipio: data.municipio,
      colonia: data.colonia,
      calle: data.calle,
      numeroExterior: data.numeroExterior,
      numeroInterior: data.numeroInterior,
      codigoPostal: data.codigoPostal,
      telefono: data.telefono,
      haceEsquina: data.haceEsquina,
      email: data.email,
      observaciones: data.observaciones,
      horario: data.horario,
      estado: data.estado,
      alias: data.alias,
      doctor: data.doctor,
      rfcDoctor: data.rfcDoctor,
    });
  });
  return dataTemp;
}

function formatModelChats(data) {
  const dataTemp = data.map((chat) => ({
    id: chat._id,
    correoUsuario: chat.correoUsuario,
    fechaHora: chat.fechaHora,
    idChat: chat.idChat,
    mensaje: chat.mensaje,
    nombreUsuario: chat.nombreUsuario,
    tipoMensaje: chat.tipoMensaje,
    nombreIdChat: chat.nombreIdChat,
    estado: chat.estado || "1", // Se asume que el estado es "1" por defecto (leído)
  }));

  // Ordenar los chats del más reciente al más antiguo
  dataTemp.sort((a, b) => new Date(b.fechaHora) - new Date(a.fechaHora));

  return dataTemp;
}