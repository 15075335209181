import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faPlus,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { listarColorimetria } from "../../../api/colorimetria";
import { Button, Dropdown, Badge, Image } from "react-bootstrap";
import { withRouter } from "../../../utils/withRouter";
import { useNavigate } from "react-router-dom";
import EliminarColorimetria from "./Eliminar";
import ModificarColorimetria from "./Modificar";
import SistemasColorimetria from "./SistemasColorimetria";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import { Load } from "../../load/load";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function TblColorimetrias(props) {
  const { location, history } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarColorimetrias, setListColorimetria] = useState([]);

  // Para definir el enrutamiento
  const enrutamiento = useNavigate();

  const obtenerColorimetria = () => {
    try {
      listarColorimetria()
        .then((response) => {
          const { data } = response;

          if (!listarColorimetrias && data) {
            setListColorimetria(formatModelColorimetria(data));
          } else {
            const datosColorimetria = formatModelColorimetria(data);
            setListColorimetria(datosColorimetria);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerColorimetria();
  }, [location]);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarColorimetria);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroSistemasColorimetria = (content) => {
    setTitulosModal("Registrar sistemas colorimetria");
    setContentModal(content);
    setShowModal(true);
  };

  const modificarSistemasColorimetria = (content) => {
    setTitulosModal("Modificar sistemas colorimetria");
    setContentModal(content);
    setShowModal(true);
  };

  const EliminarSistemasColorimetria = (content) => {
    setTitulosModal("Eliminar sistemas colorimetria");
    setContentModal(content);
    setShowModal(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "nombre",
      label: "NOMBRE",
    },
    {
      name: "tonos",
      label: "TONOS",
    },
    {
      name: "imagen",
      label: "IMAGEN",
      options: {
        customBodyRender: (value) => {
          const imagen = value;

          return imagen != "Sin Imagen" ?  (
            <Image
              src={imagen}
              alt={`Imagen de ${imagen}`}
              style={{ maxWidth: "100px" }}
            />
          ) : (
            <p>No hay imagen del articulo</p>
          );
        },
      },
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "true") {
            estiloTexto = "Activo";
            estadoTexto = "Activo";
          } else {
            estiloTexto = "Inhabilitado";
            estadoTexto = "Inhabilitado";
          }

          return <>
            <Badge
              bg={estado == "true" ? "success" : "danger"}
            //className="estado"
            >
              {estiloTexto}
            </Badge>
          </>;
        },
      },
    },
    {
      name: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Dropdown>
                <Dropdown.Toggle
                  className="botonDropdown"
                  id="dropdown-basic"
                >
                  <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      modificarSistemasColorimetria(
                        <ModificarColorimetria
                          history={history}
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faPen}
                      style={{ color: "#ffc107" }}
                    />
                    &nbsp; Modificar
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      EliminarSistemasColorimetria(
                        <EliminarColorimetria
                          history={history}
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{ color: "#dc3545" }}
                    />
                    &nbsp; Eliminar
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          );
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && 'standard',
    selectableRows: isSmOrDown ? 'none' : 'multiple',
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      {loading && <Load />}
      <h1>Sistemas de colorimetria</h1>
      <div>
        <div className="divButton">
          <Button
            className="btnAddTables"
            onClick={() =>
              registroSistemasColorimetria(
                <SistemasColorimetria
                  history={history}
                  setShow={setShowModal}
                />
              )
            }
          >
            <FontAwesomeIcon icon={faPlus} /> Agregar
          </Button>
        </div>
      </div>
      <div className="divTabla">
        <MUIDataTable
          title={"Lista colorimetrias registradas"}
          data={listarColorimetrias}
          columns={columns}
          options={options}
        />
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function formatModelColorimetria(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      tonos: data.tonos,
      imagen: !data.imagen ? "Sin Imagen" : data.imagen,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default withRouter(TblColorimetrias);
