import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faEye,
  faPlus,
  faBars,
  faFileCsv,
  faFileExport,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import {
  listarPiezasDetalles,
  listarPiezasPorDepartamento,
} from "../../../api/precios";
import { Button, Dropdown, Badge, Accordion, Card } from "react-bootstrap";
import { withRouter } from "../../../utils/withRouter";
import EliminarPrecio from "./Eliminar";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import { Load } from "../../load/load";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AltaPreciosAct from "./AltaPreciosAct";
import { obtenerUsuario } from "../../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import { toast } from "react-toastify";
import CargarPiezasCSV from "./cargaCSV";
import { exportCSVFile } from "../../../utils/exportCSV";

function TarjetasProtesis(props) {
  const { history, location } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [tipoUsuario, setTipoUsuario] = useState("");
  const [nombreDepartamento, setnombreDepartamento] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
          setnombreDepartamento(data.departamento);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [listarPrecio, setListPrecio] = useState([]);
  const [listarPrecio2, setListPrecio2] = useState([]);

  const obtenerPrecios = () => {
    try {
      listarPiezasDetalles()
        .then((response) => {
          const { data } = response;
          if (!listarPrecio && data) {
            setListPrecio(formatModelPrecio(data));
            setListPrecio2(formatModelPrecio2(data));
          } else {
            const datosPrecio = formatModelPrecio(data);
            setListPrecio(datosPrecio);
            const datosPrecio2 = formatModelPrecio2(data);
            setListPrecio2(datosPrecio2);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const obtenerPiezasDept = (departamento) => {
    try {
      listarPiezasPorDepartamento(departamento)
        .then((response) => {
          const { data } = response;

          if (!listarPrecio && data) {
            setListPrecio(formatModelPrecio(data));
          } else {
            const datosPrecio = formatModelPrecio(data);
            setListPrecio(datosPrecio);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    if (tipoUsuario != "departamento") {
      obtenerPrecios();
    } else {
      if (nombreDepartamento) {
        obtenerPiezasDept(nombreDepartamento);
      }
    }
  }, [tipoUsuario, nombreDepartamento, location]);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarPrecio);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  useEffect(() => {
    if (!showModal) {
      obtenerPrecios();
    }
  }, [showModal]);

  const registrarMaterialCategoria = (content) => {
    setTitulosModal("Materiales catalogo");
    setContentModal(content);
    setShowModal(true);
  };

  const registroPrecio = (content) => {
    setTitulosModal("Registro de protesis dentales");
    setContentModal(content);
    setShowModal(true);
  };

  const modificarPrecio = (content) => {
    setTitulosModal("Modificar pieza");
    setContentModal(content);
    setShowModal(true);
  };

  const eliminarPrecio = (content) => {
    setTitulosModal("Eliminar precio");
    setContentModal(content);
    setShowModal(true);
  };

  const cargarCsv = (content) => {
    setTitulosModal("Cargar registros");
    setContentModal(content);
    setShowModal(true);
  };

  const generacionCSVPiezasDentales = () => {
    try {
      toast.info("Estamos empaquetando tu respaldo, espere por favor ....")
      const timer = setTimeout(() => {
        exportCSVFile(listarPrecio2, "LISTA_PIEZAS_DENTALES");
      }, 5600);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log(e)
    }
  }

  const renderAcciones = (rowData) => {
    function convertirObjeto(obj) {
      return [
        obj.id,
        obj.folio,
        obj.nombrePieza,
        obj.nombreDepartamento,
        obj.nombreMaterial,
        obj.costoProduccion,
        obj.precioPublico,
        obj.imagen,
        obj.descripcionPieza,
        obj.departamento,
        obj.material,
        obj.estado,
        obj.Acciones
      ];
    }

    rowData = convertirObjeto(rowData);

    return (
      <>
        {tipoUsuario != "departamento" ? (
          <Dropdown>
            <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
              <FontAwesomeIcon icon={faBars} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() =>
                  modificarPrecio(
                    <AltaPreciosAct
                      history={history}
                      setShow={setShowModal}
                      data={rowData}
                      funcion={"modificar"}
                    />
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faPen}
                  style={{ color: "#ffc107" }}
                />
                &nbsp; Modificar
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  eliminarPrecio(
                    <EliminarPrecio
                      history={history}
                      setShow={setShowModal}
                      data={rowData}
                    />
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  style={{ color: "#dc3545" }}
                />
                &nbsp; Eliminar
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <span>No disponibles</span>
        )}
      </>
    )
  };

  const renderTarjetas = () => {
    return listarPrecio.map((protesis) => (
      <Card key={protesis.id} className="mb-3">
        <Card.Body>
          <Card.Title>Folio: {protesis.folio}</Card.Title><br />
          <Card.Text>
            Pieza: {protesis.nombrePieza}<br />
            Departamento: {protesis.nombreDepartamento}<br />
            Material: {protesis.nombreMaterial}<br />
            Costo de produccion: {formatCurrency(protesis.costoProduccion)}<br />
            precioPublico: {formatCurrency(protesis.motivoEntrega)}<br />
            descripcion: {protesis.descripcionPieza}<br />
            Estado: <Badge
              style={{ textTransform: "capitalize" }}
              bg={protesis.estado == "activo" ? "success" : "danger"}
            >
              {protesis.estado}
            </Badge><br />
          </Card.Text>
          <Card.Footer>
            {renderAcciones(protesis)}
          </Card.Footer>
        </Card.Body>
      </Card>
    ));
  };

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  return (
    <>
      {loading ? <Load /> : (
        <>
          <h1>Protesis Dentales</h1>
          <div>
            {tipoUsuario != "departamento" && (
              <div className="divButton">
                <Button
                  variant="warning"
                  onClick={() => {
                    generacionCSVPiezasDentales()
                  }}
                >
                  <FontAwesomeIcon icon={faDownload} /> Descargar CSV
                </Button>
                <Button
                  variant="success"
                  className="ml-1"
                  onClick={() =>
                    cargarCsv(
                      <CargarPiezasCSV history={history} setShow={setShowModal} funcion={"registro"} />
                    )
                  }
                >
                  <FontAwesomeIcon icon={faFileCsv} /> Cargar CSV
                </Button>
                <Button
                  className="btnAddTables ml-1"
                  onClick={() =>
                    registroPrecio(
                      <AltaPreciosAct history={history} setShow={setShowModal} funcion={"registro"} />
                    )
                  }
                >
                  <FontAwesomeIcon icon={faPlus} /> Agregar pieza
                </Button>
              </div>
            )}
          </div>
          <div>{renderTarjetas()}</div>
        </>
      )}
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function formatModelPrecio(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombrePieza: data.nombrePieza.replace(/,/g, ''),
      material: data.material.replace(/,/g, ''),
      departamento: data.departamento,
      costoProduccion: data.costoProduccion,
      precioPublico: data.precioPublico,
      nombreMaterial: data.nombreMaterial.replace(/,/g, ''),
      nombreDepartamento: data.nombreDepartamento.replace(/,/g, ''),
      nombreDepartamento: data.nombreDepartamento.replace(/,/g, ''),
      imagen: data.imagen,
      descripcionPieza: data.descripcion,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelPrecio2(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      nombrePieza: data.nombrePieza.replace(/,/g, ''),
      material: data.material.replace(/,/g, ''),
      departamento: data.departamento,
      costoProduccion: data.costoProduccion,
      precioPublico: data.precioPublico,
      nombreMaterial: data.nombreMaterial.replace(/,/g, ''),
      nombreDepartamento: data.nombreDepartamento.replace(/,/g, ''),
      descripcionPieza: data.descripcion.replace(/,/g, ''),
    });
  });
  return dataTemp;
}

export default withRouter(TarjetasProtesis);
