import { useState } from 'react';
import { Button, Col, Form, Row, Spinner, ProgressBar } from 'react-bootstrap';
import { toast } from 'react-toastify';
import queryString from "query-string";
import { registraAlmacenMateriasPrimas } from "../../api/almacenMateriasPrimas";
import { LogsInformativos } from "../logs/logs";

const CargarAlmacenMPCSV = ({ setShow, history }) => {


    const [loading, setLoading] = useState(false);
    const [dataFile, setDataFile] = useState([]);
    const [count, setCount] = useState(0)
    const handleCancel = () => setShow(false)

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        if (dataFile.length === 0) {
            toast.error('No hay datos para cargar');
            return;
        }

        setLoading(true);
        for (const { clave, claveAlterna, producto, minimo, maximo, existencia, unidadMedida, entrada, salida, devolucion, existenciaReal, ubicacion, departamento, categoria, precio } of dataFile) {

            const dataAlmacen = {
                clave: clave,
                claveAlterna: claveAlterna,
                producto: producto,
                minimo: minimo,
                maximo: maximo,
                existencia: existencia,
                unidadMedida: unidadMedida,
                entrada: entrada,
                salida: salida,
                devolucion: devolucion,
                existenciaReal: existenciaReal,
                ubicacion: ubicacion,
                departamento: departamento,
                categoria: categoria,
                precio: precio,
                estado: "true"
            }
            await registraAlmacenMateriasPrimas(dataAlmacen);
            LogsInformativos("Se ha registrado el movimiento del producto " + dataAlmacen.producto + " en el almacen de materias primas", dataAlmacen)
            // increment count for render value in progress bar
            setCount(oldCount => oldCount + 1);
        }
        toast.success("Materias primas registradas con exito");
        setDataFile([]);
        setLoading(false);
        history({
            search: queryString.stringify(''),
        });
        setShow(false);
    }

    console.log(dataFile);

    const handleChange = (e) => {

        const { files } = e.target;
        if (files.length > 0) {
            const [file] = files;
            const reader = new FileReader();
            reader.readAsText(file, 'UTF-8');
            reader.onload = (evt) => {
                const { result } = evt.target;
                const lines = result.split('\n'); // Cambiar el delimitador de línea a '\n'
                const data = lines.map(line => {
                    const [clave, claveAlterna, producto, minimo, maximo, existencia, unidadMedida, entrada, salida, devolucion, existenciaReal, ubicacion, departamento, categoria, precio] = line.split(',');
                    return {
                        clave: clave ? clave : "12345",
                        claveAlterna: claveAlterna ? claveAlterna : "54321",
                        producto: producto ? producto : "default",
                        minimo: minimo ? minimo : "0",
                        maximo: maximo ? maximo : "0",
                        existencia: existencia ? existencia : "0",
                        unidadMedida: unidadMedida ? unidadMedida: "No aplica",
                        entrada: entrada ? entrada : "0",
                        salida: salida ? salida : "0",
                        devolucion: devolucion ? devolucion : "0",
                        existenciaReal: existenciaReal ? existenciaReal : "0",
                        ubicacion: ubicacion ? ubicacion : "default",
                        departamento: departamento ? departamento : "default",
                        categoria: categoria ? categoria : "default",
                        precio: precio ? precio : "0"
                    };
                });
                setDataFile(data.filter(({ clave, claveAlterna, producto, minimo, maximo, existencia, unidadMedida, entrada, salida, devolucion, existenciaReal, ubicacion, departamento, categoria, precio }) => clave && claveAlterna && producto && minimo && maximo && existencia && unidadMedida && entrada && salida && devolucion && existenciaReal && ubicacion && departamento && categoria && precio));
            };
            reader.onerror = (_evt) => toast.error('Error al leer el archivo');
        }
        console.log(dataFile)
    };

    const Loading = () => (
        !loading ? 'Cargar' : <Spinner animation='border' />
    )


    return (
        <>
            <div className='contenidoFormularioPrincipal'>
                <Form>
                    <Form.Group as={Row} className='botones pt-3'>
                        <Col sm={5}>
                            <Form.Label>Seleccione el fichero:</Form.Label>
                        </Col>
                        <Col sm={7}>
                            <Form.Control onChange={handleChange}
                                className='form-control block w-full px-3 py-1.5 text-base font-normaltext-gray-700bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                                accept='.csv'
                                type='file'
                                id='formFile' />
                        </Col>
                    </Form.Group>
                    {
                        dataFile.length > 0 && (<Form.Group as={Row} className='botones pt-4'>
                            <Col sm={12}>
                                <div className='flex flex-col justify-center'>
                                    <div className='mb-3 w-100'>
                                        <span className='inline-block mb-2 text-gray-700'>Total de registros a cargar: {dataFile.length}</span>
                                    </div>
                                    {
                                        count > 0 && (<div className='mb-3 w-100'>
                                            <span className='flex justify-center mb-2 text-gray-700'>{count} de {dataFile.length}</span>
                                            <Form.Group as={Row}>
                                                <Col sm={12}>
                                                    <ProgressBar animated now={count} max={dataFile.length} variant='info' />
                                                </Col>
                                            </Form.Group>
                                        </div>)
                                    }
                                </div>
                            </Col>
                        </Form.Group>)
                    }
                    <Form.Group as={Row} className='botones pt-5'>
                        <Col>
                            <Button
                                type='submit'
                                variant='success'
                                className='registrar'
                                onClick={handleSubmit}
                                disabled={loading}
                            >
                                <Loading />
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                variant='danger'
                                className='cancelar'
                                onClick={handleCancel}
                                disabled={loading}
                            >
                                Cancelar
                            </Button>
                        </Col>
                    </Form.Group>

                </Form>
            </div>
        </>
    );
}

export default CargarAlmacenMPCSV;