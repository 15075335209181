import { useState, useEffect } from "react";
import { Form, Table } from "react-bootstrap";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import EliminarRuta from "../configuracionRutas/Eliminar";
import ModificarRuta from "../configuracionRutas/Modificar";
import RegistrarIncidenciasRutas from "./RegistrarIncidenciasRutas";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import { toast } from "react-toastify";
import RutaMensProv from "./Mapa/RutaMensProv";
import TablaOrdenesRuta from "./tablaOrdenesRuta/TablaOrdenesRuta";
import { listarRutasPorDia, listarRutasPorCorreo } from "../../api/configuracionRutas";
import RutasPDF from "../configuracionRutas/RutasPDF";
import { Load } from "../load/load";

export const TablaRutas = ({ fecha, history, location }) => {
  const [tipoUsuario, setTipoUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [listarRutass, setListRutas] = useState([]);

  const obtenerRutasPorDia = (dia) => {
    try {
      listarRutasPorDia(dia)
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (data.length > 0) {
            setListRutas(formatModelRutas(data));
          } else {
            setListRutas([]);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const obtenerRutasPorCorreo = (correo, dia) => {
    try {
      listarRutasPorCorreo(correo, dia)
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (data.length > 0) {
            setListRutas(formatModelRutas(data));
          } else {
            setListRutas([]);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [diaSemanaObt, setdiaSemanaObt] = useState("");

  useEffect(() => {
    console.log("fecha", fecha);
    const diaFecha = new Date(fecha);
    diaFecha.setDate(diaFecha.getDate() + 1); // Restamos un día

    const opciones = { timeZone: "America/Mexico_City", hour12: true };
    const fechaCDMX = diaFecha.toLocaleString("es-MX", opciones);

    const diasSemana = [
      "domingo",
      "lunes",
      "martes",
      "miercoles",
      "jueves",
      "viernes",
      "sabado",
    ];
    const diaSemana = diasSemana[diaFecha.getDay()];
    setdiaSemanaObt(diaSemana);
    if (tipoUsuario == "administrador") {
      obtenerRutasPorDia(diaSemana);
    } else {
      obtenerRutasPorCorreo(correoUsuario, diaSemana);
    }
  }, [fecha, location, correoUsuario]);

  const modificarRuta = (content) => {
    setTitulosModal("Modificar ruta");
    setContentModal(content);
    setShowModal(true);
  };

  const eliminarRuta = (content) => {
    setTitulosModal("Eliminar ruta");
    setContentModal(content);
    setShowModal(true);
  };

  const confirmarViaje = (content) => {
    setTitulosModal("Registrar incidencia");
    setContentModal(content);
    setShowModal(true);
  };

  const [mostrarMapa, setMostrarMapa] = useState(false);
  const [datosSeleccionados, setDatosSeleccionados] = useState(null);

  const handleVerRutaClick = (datos) => {
    setMostrarMapa(true);
    setDatosSeleccionados(datos);
  };

  return (
    <>
      {<Load /> && loading}
      <div className="generaltablaOrdenes">
        <div className="divButton" style={{ fontWeight: "bold" }}>
          <RutasPDF data={listarRutass} dia={diaSemanaObt} />
        </div>
        <div className="tablaServiciosOrdenes">
          <Table striped bordered hover size="sm" className="tablaServiciosOrden">
            <thead>
              <tr>
                <th>FOLIO</th>
                <th>RUTA</th>
                <th>DESCRIPCION</th>
                <th>MENSAJERO</th>
                <th>VEHICULO</th>
                <th style={{ textTransform: "capitalize" }}>DIA</th>
                <th>HORA</th>
                <th>ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              {listarRutass.map((servicios, index) => {
                return (
                  <tr key={index}>
                    <td style={{ textTransform: "capitalize" }}>
                      {servicios.folio}
                    </td>
                    <td style={{ textTransform: "capitalize" }}>
                      {servicios.ruta}
                    </td>
                    <td>{servicios.descripcion}</td>
                    <td>{servicios.datosMensajero?.nombre}</td>
                    <td>
                      {servicios.datosVehiculo
                        ? `${servicios.datosVehiculo.modeloVehiculo || ""} ${servicios.datosVehiculo.placa || ""
                        }`
                        : ""}
                    </td>
                    <td style={{ textTransform: "capitalize" }}>
                      {servicios.dia}
                    </td>
                    <td style={{ textTransform: "capitalize" }}>
                      {servicios.hora}
                    </td>
                    <td>
                      {tipoUsuario == "administrador" && (
                        <>
                          <Button
                            variant="success"
                            onClick={() =>
                              modificarRuta(
                                <ModificarRuta
                                  setShow={setShowModal}
                                  data={servicios}
                                  history={history}
                                />
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() =>
                              eliminarRuta(
                                <EliminarRuta
                                  setShow={setShowModal}
                                  data={servicios}
                                  history={history}
                                />
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </>
                      )}
                      {tipoUsuario == "mensajero" && (
                        <>
                          <Button
                            variant="primary"
                            onClick={() =>
                              confirmarViaje(
                                <RegistrarIncidenciasRutas
                                  setShow={setShowModal}
                                  data={servicios}
                                />
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
          {contentModal}
        </BasicModal>
        {mostrarMapa && (
          <div>
            <div>
              <TablaOrdenesRuta data={datosSeleccionados} />
            </div>
            <RutaMensProv data1={datosSeleccionados} />
          </div>
        )}
      </div>
    </>
  );
};

function formatModelRutas(data) {
  const dataTemp = [];
  data.forEach((data) => {
    const destinos = data.nombre
      .map((destinos) => `${destinos}`)
      .join(" - ");
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      ruta: destinos,
      dia: data.dia,
      descripcion: data.descripcion,
      datosMensajero: data.datosMensajero,
      datosVehiculo: data.datosVehiculo,
      estado: data.estado,
      hora: data.hora,
    });
  });

  // Ordenar por folio de menor a mayor
  dataTemp.sort((a, b) => a.folio - b.folio);

  return dataTemp;
}

