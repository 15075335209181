import { Button, Form, Row, Col, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { Load } from "../load/load";
import {
  registraConfiguracionRutas,
  obtenerFolioConfiguracionRutas,
} from "../../api/configuracionRutas";
import queryString from "query-string";
import axios from "axios";
import { MapaMarcaUbicacion } from "../rutas/Mapa/MapaMarcaUbicacion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faMapLocationDot,
  faPlus,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import { MapaClic } from "../rutas/Mapa/MapaClic";
import { LogsInformativos } from "../logs/logs";
import { listarMensajeros } from "../../api/mensajeros";
import { map } from "lodash";

export default function ConfiguracionRutas({ fecha, setShow, history }) {
  const [formData, setFormData] = useState(initialFormValue());

  const [selectedMessenger, setSelectedMessenger] = useState({});
  const [selectedVehicle, setSelectedVehicle] = useState({});

  const [listarMensajero, setListMensajero] = useState([]);

  const obtenerMensajeros = () => {
    try {
      listarMensajeros()
        .then((response) => {
          const { data } = response;

          if (!listarMensajero && data) {
            setListMensajero(formatModelMensajero(data));
          } else {
            const datosMensajeros = formatModelMensajero(data);
            setListMensajero(datosMensajeros);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerMensajeros();
  }, []);

  //load
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  // Para almacenar el folio actual
  const [folioActual, setFolioActual] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const diaFecha = new Date(fecha);
      diaFecha.setDate(diaFecha.getDate() + 1); // Restamos un día

      const opciones = { timeZone: "America/Mexico_City", hour12: true };
      const fechaCDMX = diaFecha.toLocaleString("es-MX", opciones);

      const diasSemana = [
        "domingo",
        "lunes",
        "martes",
        "miercoles",
        "jueves",
        "viernes",
        "sabado",
      ];
      const diaSemana = diasSemana[diaFecha.getDay()];

      const dataTemp = {
        folio: folioActual,
        nombre: fourthWords,
        descripcion: formData.descripcion,
        coordenadas: coordinates,
        dia: diaSemana,
        hora: formData.hora,
        datosMensajero: selectedMessenger,
        datosVehiculo: selectedVehicle,
        estado: "true",
      };
      registraConfiguracionRutas(dataTemp).then((response) => {
        const { data } = response;
        console.log(data);
        LogsInformativos("Se ha registrado la configuracion de ruta " + dataTemp.folio, dataTemp)
        toast.success(data.mensaje);
        history({
          search: queryString.stringify(""),
        });
        setLoading(false);
        setShow(false);
      }).catch(e => {
        console.log(e)
        if (e.message === 'Network Error') {
          //console.log("No hay internet")
          toast.error("Conexión al servidor no disponible");
          setLoading(false);
        } else {
          if (e.response && e.response.status === 401) {
            const { mensaje } = e.response.data;
            toast.error(mensaje);
            setLoading(false);
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === 'nombreMensajeroRuta') {
      const [nombre, email, telefono, id, modeloVehiculo, placa, economico] = value.split('/');
      setSelectedMessenger({ id, nombre, telefono, email });
      setSelectedVehicle({ modeloVehiculo, placa, economico });
    }
    setFormData({ ...formData, [name]: value });
  };

  //Para mapa

  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState(null);
  const [latSearch, setLatSearch] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const [showButtonAdd, setShowButtonAdd] = useState(true);
  const [mostrarDiv2, setMostrarDiv2] = useState(false);
  const [mostrarDiv3, setMostrarDiv3] = useState(false);

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const apiKey = "2eei5oDr1QNMmUGDCY8115cQclIbiAq3";

      // Itera sobre cada ciudad en el arreglo
      const coordinatesArray = await Promise.all(
        cities.map(async (city) => {
          const apiUrl = `https://www.mapquestapi.com/geocoding/v1/address?key=${apiKey}&location=${encodeURIComponent(
            city
          )}`;
          const response = await axios.get(apiUrl);
          const firstLocation = response.data.results[0].locations[0];
          const {
            latLng,
            adminArea5: ciudad,
            street: domicilio,
            adminArea1: estado,
            postalCode: codigoPostal,
          } = firstLocation;

          const direccionCompleta = `${domicilio}, ${ciudad}, ${codigoPostal}, ${estado}`;
          setMostrarDiv3(false);
          setMostrarDiv2(true);
          const temporizador = setTimeout(() => {
            setMostrarDiv2(false);
            setMostrarDiv3(true);
          }, 2000);

          return {
            lat: latLng.lat,
            lng: latLng.lng,
            title: direccionCompleta, // Usa el domicilio completo como título
          };
        })
      );

      setCoordinates(coordinatesArray);
      console.log(coordinatesArray);
      setShowButton(true);
      setShowButtonAdd(false);
      handleSaveFourthWords();
    } catch (error) {
      console.error(
        "Error al realizar la consulta a la API de MapQuest",
        error
      );
    }
  };

  const [cities, setCities] = useState([]);

  const handleAddCity = () => {
    if (address.trim() !== "") {
      setCities([...cities, address]);
      setAddress("");
      console.log("Cities:", cities);
      setMostrarDivSuccess(true);
      const temporizador = setTimeout(() => {
        setMostrarDivSuccess(false);
      }, 2000);
    }
  };

  const [fourthWords, setFourthWords] = useState([]);

  const handleSaveFourthWords = () => {
    const fourthWordsArray = cities.map((city) => {
      const cityWords = city.split(",");
      if (cityWords.length >= 3) {
        return cityWords[2];
      } else {
        return city;
      }
    });
    setFourthWords(fourthWordsArray);
    console.log("Cuartas palabras:", fourthWordsArray);
  };

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleDataFromChild = (data) => {
    setCities([...cities, data]);
  };

  const [mostrarDiv, setMostrarDiv] = useState(false);
  const [mostrarDivArr, setMostrarDivArr] = useState(true);
  const [mostrarDivSuccess, setMostrarDivSuccess] = useState(false);

  const reiniciar = () => {
    console.log("Reiniciando...");
    setCoordinates(null);
    setShowButton(false);
    setShowButtonAdd(true);
    setMostrarDiv2(false);
    console.log(coordinates);

    setMostrarDiv(true);
    setMostrarDivArr(false);

    const temporizador = setTimeout(() => {
      setMostrarDiv(false);
      setMostrarDivArr(true);
    }, 500);

    return () => clearTimeout(temporizador);
  };

  return (
    <>
      {loading && <Load />}
      <div>
        {mostrarDiv && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "9999",
            }}
          >
            <div
              style={{
                background: "white",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                textAlign: "center",
              }}
            >
              <Spinner animation="border" />
              <p>Reiniciando...</p>
            </div>
          </div>
        )}
        <div>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Form
                className="formDoctores"
                onSubmit={onSubmit}
                onChange={onChange}
              >
                <Form.Label className="lblNombreDoc">Folio: <code>*</code></Form.Label>
                <Form.Control
                  type="number"
                  className="inpNombreEst"
                  placeholder="Ingresa el numero de folio"
                  name="folioActual"
                  value={folioActual}
                  onChange={(e) => setFolioActual(e.target.value)}
                />
                <div className="form-group">
                  <Form.Label htmlFor="messengerName">Mensajero predeterminado: <code>*</code></Form.Label>
                  <Form.Control
                    as="select"
                    className="form-control"
                    id="messengerName"
                    name="nombreMensajeroRuta"
                    defaultValue={formData.nombreMensajeroRuta}
                    required
                  >
                    <option value>Seleccionar</option>
                    {map(listarMensajero, (cat, index) => (
                      <option
                        key={index}
                        value={`${cat.nombre}/${cat.email}/${cat.telefono}/${cat.id}/${cat.modeloVehiculo}/${cat.placa}/${cat.economico}`}
                      >
                        {cat.nombre}
                      </option>
                    ))}
                  </Form.Control>
                </div>
                <Form.Label className="lblNombreDoc">Hora: <code>*</code></Form.Label>
                <Form.Control
                  type="text"
                  className="inpNombreEst"
                  placeholder="Ingresa la hora en formato 00:00AM/PM"
                  name="hora"
                  defaultValue={formData.hora}
                />
                {showButtonAdd && (
                  <div>
                    <Button
                      style={{ marginLeft: "2%", marginTop: "2%" }}
                      onClick={handleShowModal}
                      variant="warning"
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      &nbsp;Agregar por nombre
                    </Button>

                    <Modal show={showModal} onHide={handleCloseModal} size="lg">
                      <Modal.Header closeButton>
                        <Modal.Title>Agregar por nombre</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form.Label className="lblNombreDoc">
                          Destino - Separar el CP con una coma: <code>*</code>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="inpNombreEst"
                          placeholder="Ingresa ciudad destino"
                          name="nombre"
                          value={address}
                          onChange={handleAddressChange}
                        />
                        {mostrarDivSuccess && (
                          <div>
                            <p style={{ color: "green", fontStyle: "italic" }}>
                              Destino agregado correctamente!!!
                            </p>
                          </div>
                        )}
                        <Button
                          style={{ marginTop: "2%" }}
                          onClick={handleAddCity}
                          variant="success"
                        >
                          <FontAwesomeIcon icon={faPlus} />
                          &nbsp; Agregar
                        </Button>

                      </Modal.Body>
                      <Modal.Footer>

                        <Button variant="danger" onClick={handleCloseModal}>
                          Cerrar
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <Modal show={show2} onHide={handleClose2} size="lg">
                      <Modal.Header closeButton>
                        <Modal.Title>Buscar en mapa</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <MapaClic onDataFromChild={handleDataFromChild} />
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="danger" onClick={handleClose2}>
                          Cerrar
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    {/*************************************************************************************** */}
                    {cities.length > 0 && (
                      <Button
                        style={{ marginLeft: "2%", marginTop: "2%" }}
                        onClick={handleSubmit}
                        variant="success"
                      >
                        <FontAwesomeIcon icon={faLocationDot} />
                        &nbsp;Ubicar
                      </Button>
                    )}
                  </div>
                )}
                <Form.Group className="formObservacionesDoc">
                  <Form.Label>Descripcion:</Form.Label>
                  <Form.Control
                    className="txtObservacionesDoc "
                    as="textarea"
                    rows={3}
                    placeholder="Descripcion"
                    defaultValue={formData.descripcion}
                    name="descripcion"
                  />
                </Form.Group>
                <br />

                {showButton && (
                  <>
                    <div>
                      <Button variant="danger" onClick={reiniciar}>
                        <FontAwesomeIcon icon={faRotateRight} />
                        &nbsp; Volver
                      </Button>
                    </div>
                    <div className="divSubmit">
                      <input className="submit" value="Enviar" type="submit" />
                    </div>
                  </>
                )}
              </Form>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div>
                {/* Puedes mostrar las ciudades en algún lugar de tu componente */}
                <h4>Destinos de la ruta</h4>
                {mostrarDivArr && (
                  <div>
                    <ul style={{ listStyleType: "decimal" }}>
                      {cities.map((city, index) => (
                        <li key={index}>
                          {city}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {coordinates && (
                <div>
                  {mostrarDiv2 && (
                    <div
                      style={{
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "9999",
                      }}
                    >
                      <div
                        style={{
                          background: "white",
                          padding: "20px",
                          borderRadius: "8px",
                          width: "100%",
                          height: "100%",
                          boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                          textAlign: "center",
                        }}
                      >
                        <Spinner animation="grow" />
                        <p>Ubicando...</p>
                      </div>
                    </div>
                  )}
                  {mostrarDiv3 && (
                    <div>
                      <MapaMarcaUbicacion markersData={coordinates} />
                    </div>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

function initialFormValue() {
  return {
    nombre: "",
    descripcion: "",
    dia: "",
    nombreMensajeroRuta: "",
    hora: ""
  };
}

function formatModelMensajero(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      telefono: data.telefono,
      email: data.email,
      modeloVehiculo: data.modeloVehiculo,
      placa: data.placa,
      economico: data.economico,
      fecha: data.fecha,
      estado: data.estado,
    });
  });
  return dataTemp;
}
