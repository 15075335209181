import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarFacturasMostrador,
    ENDPOINTObtenerFacturasMostrador,
    ENDPOINTListarFacturasMostrador,
    ENDPOINTActualizarFacturasMostrador,
    ENDPOINTEliminarFacturasMostrador,
    ENDPOINTDeshabilitarFacturasMostrador,
    ENDPOINTObtenerUltimoFacturasMostrador,
    ENDPOINTObtenerFolioFacturasMostrador,
    ENDPOINTActualizarEstadoFacturasMostrador
} from "./endpoints";
import axios from 'axios';

export async function actualizaEstadoFacturasMostrador(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarEstadoFacturasMostrador + `/${id}`, data, config);
}

// Para obtener el numero de pedido de venta actual
export async function obtenerNumeroFacturasMostrador() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFolioFacturasMostrador, config);
}

export async function listarFacturasMostrador(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarFacturasMostrador, config);
}

export async function registraFacturasMostrador(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarFacturasMostrador, data, config);
}

export async function listarUltimoFacturasMostrador(){
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
           
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerUltimoFacturasMostrador, config);
}

// Para obtener todos los datos del usuario
export async function obtenerFacturasMostrador(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFacturasMostrador + `/${params}`, config);
}

export async function actualizarFacturasMostrador(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarFacturasMostrador + `/${id}`, data, config);
}

export async function eliminarFacturasMostrador(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarFacturasMostrador + `/${id}`, data, config);
}

export async function actualizaDeshabilitarFacturasMostrador(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarFacturasMostrador + `/${id}`, data, config);
}