import React, { useEffect, useState } from "react";
import Vale from "./Vale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCalendar,
  faCancel,
  faHandHolding,
  faPlus,
  faTable,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Badge, Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import { Load } from "../load/load";
import {
  listarValesMateriales,
  obtenerValesDepartamentoMateriales,
  listarValesMaterialesRango,
} from "../../api/vales";
import EntregarMaterialVale from "./EntregarMaterialVale";
import CancelarVale from "./CancelarVale";
import { listarDepartamentos } from "../../api/departamentos";
import TablaMaterialesValeFiltro from "./TablaFiltrosVales";
import { obtenerUsuario } from "../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { toast } from "react-toastify";
import RegistrarIncidenciasVales from "./RegistrarIncidenciasVales";
import { obtenerServiciosPorFolio } from "../../api/servicios";
import Servicio from "./Servicio";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

const TablaMaterialesVale = () => {

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  // Obtener la fecha actual
  const today = new Date();
  const [mes, setMes] = useState(today.getMonth());

  // Obtener el primer día del mes introducido por el usuario
  const primerDiaMes = new Date(today.getFullYear(), parseInt(mes), 1)
    .toISOString()
    .split("T")[0];

  // Obtener el último día del mes introducido por el usuario
  const ultimoDiaMes = new Date(today.getFullYear(), parseInt(mes) + 1, 0)
    .toISOString()
    .split("T")[0];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);
  const [size, setSizeModal] = useState(null);

  const registroVale = (content) => {
    setTitulosModal("Registar prestamo");
    setContentModal(content);
    setShowModal(true);
  };

  const [area, setArea] = useState("");
  const [tipoUsuario, setTipoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setArea(data.departamento);
          setTipoUsuario(data.tipo);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [listVales, setListVales] = useState([]);

  const obtenerValesAdmin = () => {
    try {
      listarValesMaterialesRango(primerDiaMes, ultimoDiaMes)
        .then((response) => {
          const { data } = response;
          if (!listVales && data) {
            setListVales(formatModelVales(data));
          } else {
            const datosVales = formatModelVales(data);
            setListVales(datosVales);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const obtenerValesDept = (departamento) => {
    try {
      obtenerValesDepartamentoMateriales(departamento)
        .then((response) => {
          const { data } = response;
          if (!listVales && data) {
            setListVales(formatModelVales(data));
          } else {
            const datosVales = formatModelVales(data);
            setListVales(datosVales);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    if (!showModal) {
      if (tipoUsuario == "administrador" || tipoUsuario == "almacen") {
        obtenerValesAdmin();
      } else if (tipoUsuario == "departamento") {
        obtenerValesDept(area);
      }
    }
  }, [showModal, tipoUsuario, area, mes]);

  const totalMes = listVales.reduce(
    (amount, item) => amount + parseInt(item.totalCantidad),
    0
  );

  const entregarMAterialVale = (content) => {
    setTitulosModal("Entregar material vale");
    setContentModal(content);
    setShowModal(true);
  };

  const registrarIncidencia = (content) => {
    setTitulosModal("Registrar incidencia");
    setContentModal(content);
    setShowModal(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "fecha",
      label: "FECHA",
      options: {
        customBodyRender: (value, tableMeta) => {

          return (
            <>
              {dayjs(value.split(" ")[0]).format("LL")}
            </>
          );
        },
      },
    },
    {
      name: "folio",
      label: "FOLIO",
    },
    {
      name: "material",
      label: "MATERIAL SOLICITADO",
    },
    {
      name: "area",
      label: "AREA QUE SOLICITA",
    },
    {
      name: "recibio",
      label: "RECIBIÓ",
    },
    {
      name: "autorizo",
      label: "AUTORIZÓ",
    },
    {
      name: "entrego",
      label: "ENTREGÓ",
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estadoTexto = "";

          if (estado == "0") {
            estadoTexto = "CANCELADO";
          } else if (estado == "1") {
            estadoTexto = "PENDIENTE";
          } else {
            estadoTexto = "ENTREGADO";
          }

          return (
            <>
              <Badge
                bg={
                  estado == "0"
                    ? "danger"
                    : estado == "1"
                      ? "warning"
                      : "success"
                }
              //className="estado"
              >
                {estadoTexto}
              </Badge>
            </>
          );
        },
      },
    },
    {
      name: "incidencia",
      label: "INCIDENCIAS",
    },
    {
      name: "ordenTrabajo",
      label: "ORDEN DE TRABAJO",
    },
    {
      name: "pieza",
      label: "PIEZA",
      options: {
        customBodyRender: (value, tableMeta) => {
          const dentadura = tableMeta.rowData[3].toLowerCase();
    
          if (dentadura.includes("tablilla") && (tipoUsuario == "almacen" || tipoUsuario == "administrador")) {
            const folio = tableMeta.rowData[10];
            return <Servicio folio={folio} />;
          } else {
            return "No aplica";
          }
        },
      },
    },
    {
      name: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[8] == "1" ? (
            <>
              <Dropdown>
                <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                  <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      entregarMAterialVale(
                        <EntregarMaterialVale
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faHandHolding}
                      style={{ color: "#ffc107" }}
                    />
                    &nbsp; Entregar Material
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      registrarIncidencia(
                        <RegistrarIncidenciasVales
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faWarning}
                      style={{ color: "#ffff00" }}
                    />
                    &nbsp; Incidencia
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            "No disponibles"
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  const [mostrarPrimerDiv, setMostrarPrimerDiv] = useState(false);

  const handleClick = () => {
    setMostrarPrimerDiv(!mostrarPrimerDiv);
  };

  return (
    <>
      {loading && <Load />}
      <h1>
        {!mostrarPrimerDiv
          ? "Prestamos de materia prima"
          : "Consumo de material por departamento"}
      </h1>
      <div>
        <div className="mr-3 ml-3 mt-4">
          {!mostrarPrimerDiv && (
            <>
              {(tipoUsuario == "administrador" || tipoUsuario == "departamento" || tipoUsuario == "almacen") &&  (
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col sm={12} md={6} lg={6}>
                    <Row>
                      <Col sm={12} md={3} lg={3}>
                        <Form.Label>Selecciona un mes:</Form.Label>
                      </Col>
                      <Col>
                        <Form.Control
                          as="select"
                          value={mes}
                          onChange={(e) => setMes(e.target.value)}
                        >
                          <option value="">Selecciona</option>
                          <option value="0">Enero</option>
                          <option value="1">Febrero</option>
                          <option value="2">Marzo</option>
                          <option value="3">Abril</option>
                          <option value="4">Mayo</option>
                          <option value="5">Junio</option>
                          <option value="6">Julio</option>
                          <option value="7">Agosto</option>
                          <option value="8">Septiembre</option>
                          <option value="9">Octubre</option>
                          <option value="10">Noviembre</option>
                          <option value="11">Diciembre</option>
                        </Form.Control>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    className="d-flex align-items-end justify-content-end"
                  >
                    <Button
                      className="btnAddTables"
                      onClick={() =>
                        registroVale(<Vale setShow={setShowModal} />)
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} /> Agregar
                    </Button>
                    <Button
                      style={{ marginLeft: "0.5rem" }}
                      className="btnAddTables"
                      onClick={handleClick}
                    >
                      <FontAwesomeIcon
                        icon={mostrarPrimerDiv ? faTable : faCalendar}
                      />{" "}
                      {mostrarPrimerDiv ? "General" : "Filtros"}
                    </Button>
                  </Col>
                </Row>
              )}
            </>
          )}
        </div>
      </div>
      {mostrarPrimerDiv ? (
        <>
          <div className="divButton">
            <Button
              style={{ marginLeft: "0.5rem" }}
              className="btnAddTables"
              onClick={handleClick}
            >
              <FontAwesomeIcon icon={mostrarPrimerDiv ? faTable : faCalendar} />{" "}
              {mostrarPrimerDiv ? "General" : "Filtros"}
            </Button>
          </div>
          <TablaMaterialesValeFiltro />
        </>
      ) : (
        <>
          <div className="divTabla">
            <MUIDataTable
              title={"Lista de vales de material registrados"}
              data={!mostrarPrimerDiv ? listVales : []}
              columns={columns}
              options={options}
            />
          </div>
          <BasicModal
            show={showModal}
            setShow={setShowModal}
            title={titulosModal}
            size={size}
          >
            {contentModal}
          </BasicModal>
          {tipoUsuario == "administrador" && (
            <div
              className="d-flex justify-content-center"
              style={{ fontStyle: "italic" }}
            >
              <h4>Total de material prestado durante el mes: {totalMes}</h4>
            </div>
          )}
        </>
      )}
    </>
  );
};

function formatModelVales(data) {
  const dataTemp = [];
  data.forEach((item) => {
    // Suma de las cantidades de todos los materiales
    const totalCantidad = item.material.reduce(
      (acc, material) => acc + material.cantidad,
      0
    );

    // Construcción de la cadena de materiales
    const materialProductos = item.material
      .map((material, index) => `${material.cantidad}-${material.producto}-${material.unidadMedida}`)
      .join("  ➡️  ");

    // Creación del objeto con la suma de cantidades y otros campos
    dataTemp.push({
      id: item._id,
      folio: item.folio,
      fecha: item.fecha,
      material: materialProductos,
      totalCantidad: totalCantidad, // Nuevo campo con la suma de cantidades
      area: item.area,
      recibio: item.recibio,
      autorizo: item.autorizo,
      entrego: item.entrego,
      estado: item.estado,
      incidencia: item.incidencia,
      ordenTrabajo: item.ordenTrabajo
    });
  });
  return dataTemp;
}

export default TablaMaterialesVale;
