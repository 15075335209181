import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import {
  listarMovimientosAMP,
  listarMovimientosAMPFiltroFecha,
} from "../../api/movimientosAlmacenMP";
import { obtenerAlmacenMateriasPrimas } from "../../api/almacenMateriasPrimas";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Load } from "../load/load";
import { Button, Form, Row, Col } from "react-bootstrap";
import { faPlus, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ViewPDF from "./pdf";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function TblMovimientosAPL({props}) {
  const idArticuloAMP = props;
  //load
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarAlmacen, setListAlmacen] = useState([]);

  const obtenerAlmacen = () => {
    try {
      listarMovimientosAMP(idArticuloAMP)
        .then((response) => {
          const { data } = response;

          if (!listarAlmacen && data) {
            setListAlmacen(formatModelAlmacen(data));
          } else {
            const datosAlmacen = formatModelAlmacen(data);
            setListAlmacen(datosAlmacen);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerAlmacen();
  }, []);

  const [nombreProducto, setNombreProducto] = useState("");

  const obtenerNombreProducto = () => {
    try {
      obtenerAlmacenMateriasPrimas(idArticuloAMP)
        .then((response) => {
          const { data } = response;
          setNombreProducto(data.producto);
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerNombreProducto();
  }, []);

  console.log(nombreProducto);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarAlmacen);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "idArticuloAMP",
      label: "ID DEL ARTICULO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "producto",
      label: "PRODUCTO",
    },
    {
      name: "tipo",
      label: "TIPO",
    },
    {
      name: "cantidadMovimiento",
      label: "CANTIDAD MOVIMIENTO",
    },
    {
      name: "unidadMedida",
      label: "UNIDAD DE MEDIDA",
    },
    {
      name: "departamento",
      label: "DEPARTAMENTO",
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && 'standard',
    selectableRows: isSmOrDown ? 'none' : 'multiple',
  };

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroMovimiento = (content) => {
    setTitulosModal("Registrar movimiento para el producto " + nombreProducto);
    setContentModal(content);
    setShowModal(true);
  };

  const pdfMovimientos = (content) => {
    setTitulosModal("PDF de los movimientos del producto " + nombreProducto);
    setContentModal(content);
    setShowModal(true);
  };

  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");

  const handleInputChangeInicio = (e) => {
    setFechaInicio(e.target.value);
  };

  const handleInputChangeFin = (e) => {
    setFechaFin(e.target.value);
  };

  const [listarAlmacenFiltro, setListAlmacenFiltro] = useState([]);

  const obtenerAlmacenFiltroFecha = () => {
    try {
      listarMovimientosAMPFiltroFecha(idArticuloAMP, fechaInicio, fechaFin)
        .then((response) => {
          const { data } = response;

          if (!listarAlmacenFiltro && data) {
            setListAlmacenFiltro(formatModelAlmacen(data));
          } else {
            const datosAlmacen = formatModelAlmacen(data);
            setListAlmacenFiltro(datosAlmacen);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerAlmacenFiltroFecha();
  }, [fechaInicio, fechaFin]);

  return (
    <>
      {loading && <Load />}
      <h1>
        Movimientos del producto {nombreProducto} en el almacen de materias
        primas
      </h1>

      <div style={{ width: "100%", marginLeft: "2%", marginRight: "2%", marginTop: "4vh" }}>
        <Row>
          <Col sm={12} md={7}>
            <Form.Group>
              <Row xs={12} md={8} lg={8} style={{textAlign:"center"}}>
                <Col>
                  <div>
                    <Form.Label className="lblTelefono">
                      Fecha Inicio:
                    </Form.Label>
                    <Form.Control
                      placeholder="cantidad del movimiento"
                      className="inpTelefono"
                      type="date"
                      name="fechaInicio"
                      value={fechaInicio}
                      onChange={handleInputChangeInicio}
                    />
                  </div>
                </Col>
                <Col>
                  <div>
                    <Form.Label className="lblEmail">Fecha Fin:</Form.Label>
                    <Form.Control
                      placeholder="cantidad del movimiento"
                      className="inpEmail"
                      type="date"
                      name="fechaFin"
                      value={fechaFin}
                      onChange={handleInputChangeFin}
                    />
                  </div>
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col sm={12} md={5} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
            <Row>
              <Col xs={12} md={5} lg={5}>
                <Button
                  variant="danger"
                  onClick={() =>
                    pdfMovimientos(
                      <ViewPDF
                        setShow={setShowModal}
                        listAlmacen={listarAlmacenFiltro}
                      />
                    )
                  }
                >
                  <FontAwesomeIcon icon={faFilePdf} /> Descargar PDF
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="divTabla">
        <MUIDataTable
          title={
            "Lista de movimientos del producto " +
            nombreProducto +
            " en almacen de materias primas"
          }
          data={listarAlmacen}
          columns={columns}
          options={options}
        />
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function formatModelAlmacen(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      idArticuloAMP: data.idArticuloAMP,
      tipo: data.tipo,
      producto: data.producto,
      cantidadMovimiento: data.cantidadMovimiento,
      unidadMedida: data.unidadMedida,
      departamento: data.departamento,
      fecha: data.createdAt,
    });
  });
  return dataTemp;
}

export default TblMovimientosAPL;
