import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import logo from "../../assets/img/logobarra_3.png";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginLeft: 30,
    marginRight: 30,
    marginTop: 10,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    alignItems: "center",
  },
  tableCell: {
    padding: 8,
    fontSize: 12,
  },
  headerCell: {
    padding: 8,
    fontSize: 12,
  },
  firstColumn: {
    width: "40%",
    borderRightWidth: 1,
    borderColor: "black",
  },
  secondColumn: {
    width: "19%",
    borderRightWidth: 1,
    borderColor: "black",
  },
  thirdColumn: {
    width: "16%",
    borderRightWidth: 1,
    borderColor: "black",
  },
  fourthColumn: {
    width: "27%",
    borderColor: "black",
    borderRightWidth: 1,
  },
});

const PDFOrdenesTrabajo = ({ listAlmacen }) => {
  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#0c1b30",
            height: 55,
          }}
        >
          <Image src={logo} style={{ width: 200, height: 40 }} />
        </View>
        <View style={{ textAlign: "center", marginBottom: 10, marginTop: 30 }}>
          <Text style={{ fontSize: 18, fontWeight: "bold" }}>
            LISTADO DE ORDENES DE TRABAJO
          </Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View
              style={{
                ...styles.tableCell,
                ...styles.headerCell,
                ...styles.firstColumn,
                backgroundColor: "#9c182d",
                color: "white",
                textAlign: "center",
              }}
            >
              <Text>FOLIO</Text>
            </View>
            <View
              style={{
                ...styles.tableCell,
                ...styles.headerCell,
                ...styles.fourthColumn,
                backgroundColor: "#9c182d",
                color: "white",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              <Text>FECHA</Text>
            </View>
            <View
              style={{
                ...styles.tableCell,
                ...styles.headerCell,
                ...styles.fourthColumn,
                backgroundColor: "#9c182d",
                color: "white",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              <Text>PIEZA</Text>
            </View>
            <View
              style={{
                ...styles.tableCell,
                ...styles.headerCell,
                ...styles.fourthColumn,
                backgroundColor: "#9c182d",
                color: "white",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              <Text>DIENTES</Text>
            </View>
          </View>
          {listAlmacen.map((item, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={{ ...styles.tableCell, ...styles.firstColumn }}>
                <Text>{item.folioODS}</Text>
              </View>
              <View style={{ ...styles.tableCell, ...styles.fourthColumn }}>
                <Text>{dayjs(item.fecha).format("LL")}</Text>
              </View>
              <View style={{ ...styles.tableCell, ...styles.fourthColumn }}>
                <Text>{item.pieza}</Text>
              </View>
              <View style={{ ...styles.tableCell, ...styles.fourthColumn }}>
                <Text>{item.dientes}</Text>
              </View>
            </View>
          ))}
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            backgroundColor: "#0c1b30",
            padding: 10,
            height: 65,
          }}
        >
          <Text style={{ fontSize: 12, color: "white", textAlign: "center" }}>
            CALLE VICENTE RIVAPALACIO, 34, CENTRO, SAN JUAN DEL RIO, QRO, C.P.
            76800
          </Text>
          <Text style={{ fontSize: 12, color: "white", textAlign: "center" }}>
            grupodentalherfran.com
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const ViewPDF = ({ listOrdenes }) => (
  <PDFViewer width={"100%"} height={400}>
    <PDFOrdenesTrabajo
      listAlmacen={listOrdenes}
    />
  </PDFViewer>
);

export default ViewPDF;
