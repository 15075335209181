import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faArrowLeft,
  faCancel,
  faBars,
  faShippingFast,
  faHandHolding,
  faMoneyCheckDollar,
  faFileWord,
  faPlus,
  faFilePdf,
  faEye,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import {
  listarFacturasMostrador
} from "../../api/facturasMostrador";
import { listarServiciosFolio } from "../../api/servicios";
import { Button, Dropdown, Badge, Modal, Table } from "react-bootstrap";
import { withRouter } from "../../utils/withRouter";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Load } from "../load/load";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import { toast } from "react-toastify";
import "./style.css";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { listarDiasFestivos } from "../../api/diasFestivos";
import XMLComponent from "./GenerarXML";
import Registro from "./Registro";

function TablaFacturasMostrador(props) {
  const { location, history } = props;

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const [listarOrdenes2, setListOrdenes2] = useState([]);

  const obtenerOrdenesServicio2 = async () => {
    try {
      const response = await listarDiasFestivos();
      const { data } = response;
      setListOrdenes2(formatModelOrdenes2(data));
    } catch (e) {
      console.error("Error al obtener las órdenes de servicio:", e);
    }
  };

  useEffect(() => {
    obtenerOrdenesServicio2();
  }, []);

  const [loading, setLoading] = useState(true);

  const [tipoUsuario, setTipoUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarOrdenes, setListOrdenes] = useState([]);
  
  const obtenerOrdenesServicio = () => {
    try {
      listarFacturasMostrador()
        .then((response) => {
          const { data } = response;
  
          if (data) {
            let datosOrdenes = formatModelOrdenes(data);
  
            // Si el usuario es un doctor, filtra las órdenes por correoCliente
            if (tipoUsuario == 'doctor') {
              datosOrdenes = datosOrdenes.filter(orden => orden.correoCliente == correoUsuario);
            }
  
            // Guardar los datos filtrados o todos los datos, según el tipo de usuario
            setListOrdenes(datosOrdenes);
          }
        })
        .catch((e) => {
          // Manejo de errores
        });
    } catch (e) {
      // Manejo de errores
    }
  };
  
  useEffect(() => {
    obtenerOrdenesServicio();
  }, [location, correoUsuario, tipoUsuario]);
  

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarOrdenes);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const pdfOrden = (content) => {
    setTitulosModal("PDF de la orden de servicio");
    setContentModal(content);
    setShowModal(true);
  };

  const registrarFacturaMostrador = (content) => {
    setTitulosModal("Registrar factura mostrador");
    setContentModal(content);
    setShowModal(true);
  };

  const procesoCancelacionOrden = (content) => {
    setTitulosModal("Proceso cancelacion orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const modificarOrden = (content) => {
    setTitulosModal("Modificar orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const verOrden = (content) => {
    setTitulosModal("Detalles de la orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const eliminarOrden = (content) => {
    setTitulosModal("Eliminar orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const cancelarOrden = (content) => {
    setTitulosModal("Cancelar orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const transferirOrden = (content) => {
    setTitulosModal("Transferir orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const entregarOrden = (content) => {
    setTitulosModal("Entregar orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const recibirPago = (content) => {
    setTitulosModal("Pago de orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const recibirMaterial = (content) => {
    setTitulosModal("Recibir material de la orden");
    setContentModal(content);
    setShowModal(true);
  };

  const progresoOrden = (content) => {
    setTitulosModal("Progreso");
    setContentModal(content);
    setShowModal(true);
  };

  const asignarRetrabajo = (content) => {
    setTitulosModal("Asignar retrabajo");
    setContentModal(content);
    setShowModal(true);
  };

  const actualizarEstadoCambios = (content) => {
    setTitulosModal("Confirma los cambios");
    setContentModal(content);
    setShowModal(true);
  };

  const [mostrarPrimerDiv, setMostrarPrimerDiv] = useState(true);
  const [idOrdenSel, setidOrdenSel] = useState("");

  const handleClick = (tableMeta) => {
    // Verifica si tableMeta es undefined antes de acceder a rowData
    if (tableMeta && tableMeta.rowData) {
      // Accede a la información de la fila actual
      const rowData = tableMeta.rowData;

      // Obtén el valor de la primera columna (supongamos que es la posición 0 del array)
      const valorPrimeraColumna = rowData[0];

      // Actualiza la variable con el valor de la primera columna
      setidOrdenSel(valorPrimeraColumna);

      // Puedes hacer cualquier otra lógica que necesites con el valor obtenido
      console.log("Valor de la primera columna:", valorPrimeraColumna);
    } else {
      console.error("tableMeta o tableMeta.rowData es undefined");
    }
    setMostrarPrimerDiv(!mostrarPrimerDiv);
  };

  console.log(tipoUsuario);

  const [listarServicios, setListServicios] = useState([]);

  const obtenerServicios = (folio) => {
    try {
      listarFacturasMostrador()
        .then((response) => {
          const { data } = response;
          console.log(data);
  
          if (data) {
            // Filtra los datos donde el campo folio es igual al campo id
            const datosFiltrados = data.filter((item) => item._id === folio);
  
            // Si no hay servicios listados y hay datos filtrados
            if (!listarServicios && datosFiltrados.length > 0) {
              setListServicios(datosFiltrados[0].servicios);
            } else {
              const datosServicios = formatModelOrdenes(datosFiltrados);
              setListServicios(datosFiltrados[0].servicios);
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    } catch (e) {
      console.error(e);
    }
  };  

  console.log(listarServicios)

  const [showModal2, setShowModal2] = useState(false);

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "folio",
      label: "FOLIO",
    },
    {
      name: "cliente",
      label: "CLIENTE",
    },
    {
      name: "rfc",
      label: "RFC",
    },
    {
      name: "codigoPostal",
      label: "CODIGO POSTAL",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "regimenFiscal",
      label: "REGIMEN FISCAL",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "cdfi",
      label: "CDFI",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "tipoCambio",
      label: "TIPO DE CAMBIO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "moneda",
      label: "MONEDA",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "formaPago",
      label: "FORMA DE PAGO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "fechaEmision",
      label: "FECHA DE EMISION",
      options: {
        customBodyRender: (value, tableMeta) => {
          return <>{dayjs(value).format("LL")}</>;
        },
      },
    },
    {
      name: "subtotal",
      label: "SUBTOTAL",
      options: {
        customBodyRender: (value) => formatCurrency(value != null ? value : 0),
      },
    },
    {
      name: "iva",
      label: "IMPUESTOS",
      options: {
        customBodyRender: (value, tableMeta) => {
          // Supongamos que ordenServicio es el valor con el que quieres filtrar
          const IVA = tableMeta.rowData[11] * 0.16;

          const formattedIVA = formatCurrency(IVA);
          return <>{formattedIVA}</>;
        },
      },
    },
    {
      name: "total",
      label: "TOTAL",
      options: {
        customBodyRender: (value, tableMeta) => {
          // Supongamos que ordenServicio es el valor con el que quieres filtrar
          const subtotal = tableMeta.rowData[11];
          const IVA = tableMeta.rowData[11] * 0.16;
          const total = Number(subtotal) + parseFloat(IVA);

          const formattedTotal = formatCurrency(total);
          return <>{formattedTotal}</>;
        },
      },
    },
    {
      name: "servicio",
      label: "SERVICIOS",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Button
            variant="link"
            onClick={() => {
              obtenerServicios(tableMeta.rowData[0]);
              setShowModal2(true);
            }}
          >
            <FontAwesomeIcon
              icon={faEye}
              style={{ color: "#000080" }}
            />
          </Button>
        ),
      },
    },
    {
      name: "servicios",
      label: "SERVICIOS",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "pdfFacturacion",
      label: "PDFFACTURACION",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "xmlFacturacion",
      label: "XMLFACTURACION",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "estado",
      label: "FACTURACION",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "0") {
            estiloTexto = "Pendiente";
            estadoTexto = "Pendiente";
          } else if (estado == "1") {
            estiloTexto = "Facturado";
            estadoTexto = "Facturado";
          }
          return (
            <>
              <Badge
                bg={
                  estado == "1"
                    ? "success"

                    : "warning"
                }
              //className="estado"
              >
                {estiloTexto}
              </Badge>
            </>
          );
        },
      },
    },
    {
      name: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
            {(tableMeta.rowData[18] == "0" && tipoUsuario == "doctor") ? (
              "No disponibles"
              ) : (
              <XMLComponent id={tableMeta.rowData[0]} pdf={tableMeta.rowData[11]} xml={tableMeta.rowData[12]} factura={tableMeta.rowData} history={history} />
            )}
              </>
          )
        },
      },
    },
  ];

  const handleDownload = (e, url) => {
    // Puedes añadir lógica adicional aquí
    console.log(`Descargando desde: ${url}`);
    // Si no deseas prevenir el comportamiento predeterminado, no es necesario `e.preventDefault()`.
  };


  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      <div>
        {mostrarPrimerDiv ? (
          <div>
            {loading && <Load />}
            <h1>Facturas de mostrador</h1>
            {tipoUsuario == "doctor" && (
              <h5 style={{ textAlign: "center", fontStyle: "italic", fontWeight: "bold", color: "red" }}>
                Si tienes cambios pendientes por confirmar, hazlo antes de las
                12:00PM o las entregas se reprogramaran a la segunda visita
              </h5>
            )}
            <div className="divButton">
              <Button
                className="btnAddTables"
                onClick={() =>
                  registrarFacturaMostrador(
                    <Registro history={history} setShow={setShowModal} />
                  )
                }
              >
                <FontAwesomeIcon icon={faPlus} /> Agregar
              </Button>
            </div>
            <div className="divTabla">
              <MUIDataTable
                title={"Lista de facturas de mostrador"}
                data={listarOrdenes}
                columns={columns}
                options={options}
              />
            </div>
            <BasicModal
              show={showModal}
              setShow={setShowModal}
              title={titulosModal}
            >
              {contentModal}
            </BasicModal>
            <Modal
              show={showModal2}
              onHide={() => setShowModal2(false)}
              size="xl"
            >
              <Modal.Header closeButton>
                <Modal.Title>Información detallada</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Departamento</th>
                        <th>Pieza</th>
                        <th>Sistema de color</th>
                        <th>Diente</th>
                        <th>Tono Inferior</th>
                        <th>Tono Medio</th>
                        <th>Tono Superior</th>
                        <th>Descripción de protesis</th>
                        <th>Audio</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listarServicios.map((selected, index) => (
                        <tr key={index}>
                          <td>{selected.clasificacion}</td>
                          <td>{selected.pieza}</td>
                          <td>{selected.sistemaColor}</td>
                          <td>{selected.diente}</td>
                          <td>{selected.tonoInferior}</td>
                          <td>{selected.tonoMedio}</td>
                          <td>{selected.tonoSuperior}</td>
                          <td>{selected.descripcionAdicional}</td>
                          <td>
                            {/* Renderizar el audio si la URL está disponible */}
                            {selected.audio ? (
                              <audio controls src={selected.audio} />
                            ) : (
                              <span>No disponible</span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowModal2(false)}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        ) : (
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: "2px",
                left: "10px",
              }}
            >
              <Button
                variant="primary"
                style={{ color: "#fff", textDecoration: "none" }}
                onClick={handleClick}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </Button>
            </div>
            <h2 style={{ textAlign: "center", marginTop: "2%" }}>
              Imagenes de orden de cliente
            </h2>

          </div>
        )}
      </div>
    </>
  );
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      cliente: data.cliente,
      rfc: data.rfc,
      codigoPostal: data.codigoPostal,
      regimenFiscal: data.regimenFiscal.split("-")[0],
      cdfi: data.cdfi.split("-")[0],
      tipoCambio: data.tipoCambio,
      moneda: data.moneda,
      formaPago: data.formaPago,
      fechaEmision: data.fechaEmision,
      subtotal: data.subtotal,
      servicios: data.servicios,
      pdfFacturacion: data.pdfFacturacion,
      xmlFacturacion: data.xmlFacturacion,
      estado: data.estado,
      correoCliente: data.correoCliente
    });
  });
  return dataTemp;
}

function formatModelServicios(data) {
  return data.map((item) => {
    const dienteArray = item.diente;
    let diente = "";

    if (Array.isArray(dienteArray)) {
      diente = dienteArray
        .map((d) => d.match(/\d+/)) // Extract numbers from each string
        .filter(Boolean) // Remove any null values resulting from no match
        .join(", "); // Join the numbers with comma and space
    }

    return {
      id: item._id,
      folio: item.folio,
      clasificacion: item.clasificacion,
      material: item.material,
      pieza: item.pieza,
      precio: item.precio,
      descripcionAdicional: item.descripcionAdicional,
      sistemaColor: item.sistemaColor,
      distencion: item.distencion,
      diente: diente,
      tonoInferior: item.tonoInferior,
      tonoMedio: item.tonoMedio,
      tonoSuperior: item.tonoSuperior,
      imagenEsquema: item.imagenEsquema,
      imagenDiente: item.imagenDiente,
      audio: item.audio
    };
  });
}

function formatModelOrdenes2(data) {
  return data.map((data) => ({
    id: data._id,
    fecha: data.fecha,
    descripcion: data.descripcion,
    estado: data.estado,
  }));
}

export default withRouter(TablaFacturasMostrador);
