import React, { useRef, useState, useEffect } from "react";
import { listarServiciosFolio } from "../../api/servicios";
import { listarOrdenesServicio } from "../../api/ordenesServicio";
import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";
import logo from "../../assets/img/logobarra_3.png";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Button } from "react-bootstrap";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    marginTop: 10,
    marginLeft: 20,
    marginRight: 20,
    borderWidth: 1,
    borderColor: "#0c1b30",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  child: {
    width: "50%",
    padding: 10,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    borderWidth: 1,
    borderColor: "#0c1b30",
  },
  title: {
    backgroundColor: "#0c1b30",
    color: "white",
    textAlign: "center",
    fontSize: 5,
    padding: 5,
  },
  content: {
    marginTop: 0,
    marginRight: 15,
  },
  column: {
    width: "50%",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#0c1b30",
    backgroundColor: "white",
    margin: 5,
    height: 130,
  },
  blueHeader: {
    backgroundColor: "#0c1b30",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    height: 30,
  },
  headerText: {
    color: "white",
    textAlign: "center",
    fontSize: 5,
    marginTop: 2,
  },
  imageContainer: {
    alignItems: "center",
  },
  image: {
    width: 86,
    height: 120,
  },
});

const OrdenPDFTable = ({ ordenServicio }) => {
  console.log(ordenServicio)
  const pdfRef = useRef();

  const [listOrden, setListOrdenes] = useState([]);

  const obtieneOrdenID = () => {
    try {
      listarOrdenesServicio()
        .then((response) => {
          if (response && response.data) {
            const { data } = response;
            if (!listOrden && data) {
              setListOrdenes(formatModelOrdenes(data));
            } else {
              const datosOrdenes = formatModelOrdenes(data);
              const filterID = datosOrdenes.find(
                (data) => data.folio === ordenServicio
              ); // Utiliza 'find' en lugar de 'filter'
              if (filterID) {
                setListOrdenes([filterID]); // Actualiza el estado con un array que contiene el elemento filtrado
                console.log(filterID);
              } else {
                console.log(
                  "No se encontró ningún elemento con el ID especificado"
                );
              }
            }
          } else {
            console.error("Error: No se recibieron datos válidos");
          }
        })
        .catch((error) => {
          console.error("Error al obtener datos:", error);
        });
    } catch (e) {
      console.error("Error en la función obtieneOrdenID:", e);
    }
  };

  const [listServicios, setListServiciosFolio] = useState([]);

  const obtenerServiciosFolio = () => {
    try {
      listarServiciosFolio(ordenServicio)
        .then((response) => {
          const { data } = response;

          if (!listServicios && data) {
            setListServiciosFolio(formatModelServicios(data));
          } else {
            const datosServicios = formatModelServicios(data);
            setListServiciosFolio(datosServicios);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerServiciosFolio();
    obtieneOrdenID();
  }, []);

  return (
    <Document ref={pdfRef}>
      {listServicios.map((item, index) => (
        <Page size={{ width: 538.582, height: 255.118 }} orientation="landscape" key={index}>
          {listOrden.map((item1, index1) => (
            <View key={index1}>
              <View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#0c1b30",
                    height: 40,
                  }}
                >
                  <Image src={logo} style={{ width: 120, height: 30 }} />
                </View>
              </View>
              <View>
                <View
                  style={{
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "20",
                  }}
                >
                  <Text
                    style={{ fontSize: 7, fontWeight: "bolder" }}
                    wordWrap="break-word"
                  >
                    ORDEN DE CLIENTE
                  </Text>
                  <View style={{ marginTop: 5 }}>
                    <View
                      style={{ flexDirection: "row", alignItems: "flex-end" }}
                    >
                      <Text
                        style={{
                          fontSize: 6,
                          marginLeft: 5,
                          fontWeight: "bold",
                        }}
                        wordWrap="break-word"
                      >
                        Folio:
                      </Text>
                      <Text
                        style={{ fontSize: 6, marginLeft: 3 }}
                        wordWrap="break-word"
                      >
                        {item1.folio}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "flex-end",
                        marginTop: 3,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 6,
                          marginLeft: 5,
                          fontWeight: "bold",
                        }}
                        wordWrap="break-word"
                      >
                        Fecha de creacion:
                      </Text>
                      <Text
                        style={{ fontSize: 6, marginLeft: 3 }}
                        wordWrap="break-word"
                      >
                        {item1.fecha}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "flex-end",
                        marginTop: 3,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 6,
                          marginLeft: 5,
                          fontWeight: "bold",
                        }}
                        wordWrap="break-word"
                      >
                        Nombre del paciente:
                      </Text>
                      <Text
                        style={{ fontSize: 6, marginLeft: 3 }}
                        wordWrap="break-word"
                      >
                        {item1.nombrePaciente}
                      </Text>
                    </View>
                  </View>
                </View>



                <View
                  style={{
                    flexDirection: "column",
                    marginTop: 5,
                    marginLeft: 20,
                    marginRight: 20,
                    border: "1 solid #0c1b30",
                    borderRadius: 10,
                  }}
                >
                  <View
                    style={{
                      backgroundColor: "#0c1b30",
                      padding: 5,
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      DATOS GENERALES
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-center",
                      marginTop: 7,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 6,
                        marginLeft: 5,
                        fontWeight: "bold",
                      }}
                      wordWrap="break-word"
                    >
                      Dirección:
                    </Text>
                    <Text
                      style={{
                        fontSize: 6,
                        marginLeft: 7,
                        alignItems: "flex-end",
                        marginRight: 20,
                        textTransform: "uppercase"
                      }}
                      wordWrap="break-word"
                    >
                      {typeof item1.direccionConsultorio === "object"
                        ? item1.direccionConsultorio.localidad +
                        ", " +
                        item1.direccionConsultorio.CP +
                        ", " +
                        item1.direccionConsultorio.municipio
                        : item1.direccionConsultorio}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-center",
                      marginTop: 7,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 6,
                        marginLeft: 5,
                        fontWeight: "bold",
                      }}
                      wordWrap="break-word"
                    >
                      Doctor:
                    </Text>
                    <Text
                      style={{
                        fontSize: 6,
                        marginLeft: 7,
                        alignItems: "flex-end",
                        marginRight: 20,
                      }}
                      wordWrap="break-word"
                    >
                      {item1.nombreDoctor}
                    </Text>
                  </View>
                </View>

                <View style={{ flexDirection: "column", marginTop: 7 }}>
                  <View
                    style={{
                      backgroundColor: "#0c1b30",
                      padding: 5,
                      border: "1 solid #0c1b30",
                      marginLeft: 20,
                      marginRight: 20,
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      DATOS DEL SERVICIO
                    </Text>
                  </View>
                  <View
                    style={{
                      border: "1 solid #0c1b30",
                      padding: 10,
                      marginLeft: 20,
                      marginRight: 20,
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                    }}
                  >
                    <View
                      style={{ flexDirection: "row", marginTop: 0 }}
                      wordWrap="break-word"
                    >
                      <Text style={{ fontSize: 6, fontWeight: "bold" }}>
                        Pieza:
                      </Text>
                      <Text
                        style={{ fontSize: 6, marginLeft: 5, marginRight: 20 }}
                        wordWrap="break-word"
                      >
                        {item.pieza}
                      </Text>
                    </View>
                    <View style={{ flexDirection: "row", marginTop: 7 }}>
                      <Text style={{ fontSize: 6, fontWeight: "bold" }}>
                        Material:
                      </Text>
                      <Text style={{ fontSize: 6, marginLeft: 5 }}>
                        {item.material}
                      </Text>
                    </View>
                    <View style={{ flexDirection: "row", marginTop: 7, flexWrap: "wrap" }}>
                      <Text style={{ fontSize: 6, fontWeight: "bold" }}>
                        Descripcion de protesis:
                      </Text>
                      <Text style={{
                        fontWeight: "bold",
                        fontSize: 6,
                        lineHeight: 1.2,
                        flexShrink: 1 // Ajusta el tamaño del texto para que no desborde
                      }}>
                        {item.descripcionAdicional}
                      </Text>
                    </View>
                    <View style={{ flexDirection: "row", marginTop: 7 }}>
                      <Text style={{ fontSize: 6, fontWeight: "bold" }}>
                        Tipo de dentadura:
                      </Text>
                      <Text style={{ fontSize: 6, marginLeft: 5 }}>
                        {item.distencion === "DP" ? "Permanente" : "Temporal"}
                      </Text>
                    </View>
                    <View style={{ flexDirection: "row", marginTop: 7 }}>
                      <Text style={{ fontSize: 6, fontWeight: "bold" }}>
                        Sistema de Color:
                      </Text>
                      <Text style={{ fontSize: 6, marginLeft: 5 }}>
                        {item.sistemaColor}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  marginTop: 3,
                  marginLeft: 20,
                  marginRight: 20,
                }}
              >
                <View style={styles.column}>
                  <View style={styles.blueHeader}>
                    <Text style={styles.headerText}>ORGANO DENTAL {item.diente}</Text>
                  </View>
                  <View style={styles.imageContainer}>
                    {item.imagenEsquema ? (
                      <Image
                        source={{ uri: item.imagenEsquema }}
                        style={styles.image}
                      />
                    ) : // No hay imagen, puedes poner aquí algún componente o dejarlo vacío
                      null}
                  </View>
                </View>
                <View style={styles.column}>
                  <View style={styles.blueHeader}>
                    <Text style={styles.headerText}>DIAGRAMA DE TONOS</Text>
                  </View>
                  <View style={styles.imageContainer}>
                    {item.imagenDiente ? (
                      <Image
                        source={{ uri: item.imagenDiente }}
                        style={styles.image}
                      />
                    ) : // No hay imagen, puedes poner aquí algún componente o dejarlo vacío
                      null}
                  </View>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  backgroundColor: "#0c1b30",
                  padding: 10,
                  height: 31,
                }}
              >
                <Text
                  style={{ fontSize: 4, color: "white", textAlign: "center" }}
                >
                  EL ROBLE, SANTA CRUZ NIETO, 76804 SAN JUAN DEL RÍO, QRO.
                </Text>
                <Text
                  style={{ fontSize: 4, color: "white", textAlign: "center" }}
                >
                  grupodentalherfran.com
                </Text>
              </View>
            </View>
          ))}
        </Page>
      ))}
    </Document>
  );
};

const ViewPDFTable = ({ ordenServicio }) => {
  if (!ordenServicio || Object.keys(ordenServicio).length === 0) {
    return null; // Evita renderizar el enlace de descarga hasta tener los datos
  }

  return (
    <PDFDownloadLink
      document={<OrdenPDFTable ordenServicio={ordenServicio} />}
      fileName={`${ordenServicio}.pdf`} // Usa un identificador único si es posible
      style={{ textDecoration: 'none', color: 'white' }}
    >
      {({ blob, url, loading, error }) => (
        <Dropdown.Item as="button">
          <FontAwesomeIcon style={{ color: "#fd7e14" }} icon={faFilePdf} /> {loading ? 'Generando PDF...' : 'Descargar PDF'}
        </Dropdown.Item>
      )}
    </PDFDownloadLink>
  );
};


function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      fecha: data.fecha,
      localidad: data.localidad,
      nombreDoctor: data.nombreDoctor,
      nombrePaciente: data.nombrePaciente,
      precioTotal: data.precioTotal,
      tipoDentadura: data.tipoDentadura,
      otros: data.otros,
      recibio: data.recibio,
      fechaEntrada: data.fechaEntrada,
      servicios: data.servicios,
      direccionConsultorio: data.direccionConsultorio,
    });
  });
  return dataTemp;
}

function formatModelServicios(data) {
  return data.map((item) => {
    const dienteArray = item.diente;
    let diente = "";

    if (Array.isArray(dienteArray)) {
      diente = dienteArray
        .map(d => d.match(/\d+/)) // Extract numbers from each string
        .filter(Boolean)          // Remove any null values resulting from no match
        .join(", ");              // Join the numbers with comma and space
    }

    return {
      id: item._id,
      folio: item.folio,
      clasificacion: item.clasificacion,
      material: item.material,
      pieza: item.pieza,
      precio: item.precio,
      descripcionAdicional: item.descripcionAdicional,
      sistemaColor: item.sistemaColor,
      distencion: item.distencion,
      diente: diente,
      tonoInferior: item.tonoInferior,
      tonoMedio: item.tonoMedio,
      tonoSuperior: item.tonoSuperior,
      imagenEsquema: item.imagenEsquema,
      imagenDiente: item.imagenDiente,
    };
  });
}


export default ViewPDFTable;
