import { useState, useEffect } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { registraEvaluaciones360 } from "../../api/evaluaciones360";
import {
  faArrowRight,
  faArrowLeft,
  faCircleDot,
  faLightbulb,
  faCarBattery,
  faIdCard,
  faDroplet,
  faHand,
  faGasPump,
  faMapLocationDot,
  faCloudRain,
  faCamera,
} from "@fortawesome/free-solid-svg-icons";
import "./estilos.css";
import { Load } from "../load/load";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropzone from "../dropzone/Dropzone";
import { subeArchivosCloudinary } from "../../api/cloudinary";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import { listarRutasPorCorreo } from "../../api/rutas";
import { actualizarVehiculosKm, listarVehiculos } from "../../api/vehiculos";
import Select from "react-select";
import { listarMensajeros } from "../../api/mensajeros";
import { LogsInformativos } from "../logs/logs";

const Verificación = () => {
  const [pasoActual, setPasoActual] = useState(1);

  //Para almacenar la imagen del producto que se guardara a la bd
  const [imagenProducto, setImagenProducto] = useState(null);

  //load
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const siguientePaso = () => {
    setPasoActual(pasoActual + 1);
  };

  const pasoAnterior = () => {
    setPasoActual(pasoActual - 1);
  };

  const calcularProgreso = () => {
    return ((pasoActual - 1) / 9) * 100;
  };

  const [presion, setPresion] = useState("");
  const [condicionNeumatico, setCondicionNeumatico] = useState("");
  const [aceiteMotor, setAceiteMotor] = useState("");
  const [liquidoFrenos, setLiquidoFrenos] = useState("");
  const [refrigeranteMotor, setrefrigeranteMotor] = useState("");
  const [liquidoTransmision, setLiquidoTransmision] = useState("");
  const [pastillasFrenos, setpastillasFrenos] = useState("");
  const [discosFreno, setdiscosFreno] = useState("");
  const [lucesFaros, setLucesFaros] = useState("");
  const [lucesIntermitentes, setLucesIntermitentes] = useState("");
  const [lucesFrenos, setLucesFrenos] = useState("");
  const [lucesTraseras, setLucesTraseras] = useState("");
  const [lucesAtras, setLucesAtras] = useState("");
  const [estadoBateria, setEstadoBateria] = useState("");
  const [licenciaConducir, setLicenciaConducir] = useState("");
  const [tarjetaSeguro, setTarjetaSeguro] = useState("");
  const [registroVehiculo, setRegistroVehiculo] = useState("");
  const [equipoEmergencia, setEquipoEmergencia] = useState("");
  const [gatoHidraulico, setGatoHidraulico] = useState("");
  const [llaveRuedas, setLlaveRuedas] = useState("");
  const [llantaRepuesto, setLlantaRepuesto] = useState("");
  const [nivelCombustible, setNivelCombustible] = useState("");
  const [estadoGPS, setEstadoGPS] = useState("");
  const [estadoLP, setEstadoLP] = useState("");
  const [nivelDeposito, setNivelDeposito] = useState("");
  const [descripcion1, setDescripcion1] = useState("");
  const [descripcion2, setDescripcion2] = useState("");
  const [descripcion3, setDescripcion3] = useState("");
  const [descripcion4, setDescripcion4] = useState("");
  const [descripcion5, setDescripcion5] = useState("");
  const [descripcion6, setDescripcion6] = useState("");
  const [descripcion7, setDescripcion7] = useState("");
  const [descripcion8, setDescripcion8] = useState("");
  const [descripcion9, setDescripcion9] = useState("");

  const handlePresionChange = (event) => {
    setPresion(event.target.value);
  };

  const handleCondicionNeumatico = (event) => {
    setCondicionNeumatico(event.target.value);
  };

  const handleAceiteM = (event) => {
    setAceiteMotor(event.target.value);
  };

  const handleLiquidoFrenos = (event) => {
    setLiquidoFrenos(event.target.value);
  };

  const handleRefrigeranteMotor = (event) => {
    setrefrigeranteMotor(event.target.value);
  };

  const handleLiquidoTransmision = (event) => {
    setLiquidoTransmision(event.target.value);
  };

  const handlepastillasFrenos = (event) => {
    setpastillasFrenos(event.target.value);
  };

  const handlediscosFreno = (event) => {
    setdiscosFreno(event.target.value);
  };

  const handleLucesFarosChange = (e) => {
    setLucesFaros(e.target.value);
  };

  const handleLucesIntermitentesChange = (e) => {
    setLucesIntermitentes(e.target.value);
  };

  const handleLucesFrenosChange = (e) => {
    setLucesFrenos(e.target.value);
  };

  const handleLucesTraserasChange = (e) => {
    setLucesTraseras(e.target.value);
  };

  const handleLucesAtrasChange = (e) => {
    setLucesAtras(e.target.value);
  };

  const handleEstadoBateriaChange = (e) => {
    setEstadoBateria(e.target.value);
  };

  const handleLicenciaConducirChange = (e) => {
    setLicenciaConducir(e.target.value);
  };

  const handleTarjetaSeguroChange = (e) => {
    setTarjetaSeguro(e.target.value);
  };

  const handleRegistroVehiculoChange = (e) => {
    setRegistroVehiculo(e.target.value);
  };

  const handleEquipoEmergenciaChange = (e) => {
    setEquipoEmergencia(e.target.value);
  };

  const handleGatoHidraulicoChange = (e) => {
    setGatoHidraulico(e.target.value);
  };

  const handleLlaveRuedasChange = (e) => {
    setLlaveRuedas(e.target.value);
  };

  const handleLlantaRepuestoChange = (e) => {
    setLlantaRepuesto(e.target.value);
  };

  const handleNivelCombustibleChange = (e) => {
    setNivelCombustible(e.target.value);
  };

  const handleEstadoGPSChange = (e) => {
    setEstadoGPS(e.target.value);
  };

  const handleEstadoLPChange = (e) => {
    setEstadoLP(e.target.value);
  };

  const handleNivelDepositoChange = (e) => {
    setNivelDeposito(e.target.value);
  };

  const handleDescripcion1Change = (e) => {
    setDescripcion1(e.target.value);
  };

  const handleDescripcion2Change = (e) => {
    setDescripcion2(e.target.value);
  };

  const handleDescripcion3Change = (e) => {
    setDescripcion3(e.target.value);
  };

  const handleDescripcion4Change = (e) => {
    setDescripcion4(e.target.value);
  };

  const handleDescripcion5Change = (e) => {
    setDescripcion5(e.target.value);
  };

  const handleDescripcion6Change = (e) => {
    setDescripcion6(e.target.value);
  };

  const handleDescripcion7Change = (e) => {
    setDescripcion7(e.target.value);
  };

  const handleDescripcion8Change = (e) => {
    setDescripcion8(e.target.value);
  };

  const handleDescripcion9Change = (e) => {
    setDescripcion9(e.target.value);
  };

  const [listProductosCargados, setListProductosCargados] = useState([]);

  const addItems = () => {
    try {
      subeArchivosCloudinary(imagenProducto, "evaluaciones360")
        .then((response) => {
          const { data } = response;
          const dataTemp = {
            imagen: data.secure_url,
          };

          setListProductosCargados([...listProductosCargados, dataTemp]);
          setImagenProducto(null);
          toast.success("Imagen cargada con exito");
        })
        .then((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const [tipoUsuario, setTipoUsuario] = useState("");
  const [emailUsuario, setEmailUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
          setEmailUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const fechaActual = new Date();

  const año = fechaActual.getFullYear();
  const mes = fechaActual.getMonth() + 1;
  const dia = fechaActual.getDate();

  // Formatea la fecha en "aaaa/mm/dd"
  const fechaFormateada = `${año}-${mes < 10 ? "0" + mes : mes}-${dia < 10 ? "0" + dia : dia
    }`;

  const [listarRutasDiaMens, setListRutasDiaMens] = useState([]);
  const [vehiculoAsignado, setvehiculoAsignado] = useState("");
  const [numeroEconomicoRut, setnumeroEconomicoRut] = useState("");
  const [listarMensajero, setListMensajero] = useState([]);


  const obtenerMensajeros = () => {
    try {
      listarMensajeros()
        .then((response) => {
          const { data } = response;

          if (!listarMensajero && data) {
            setListMensajero(formatModelMensajero(data));
          } else {
            const datosMensajeros = formatModelMensajero(data);

            const mensajerosFiltrados = datosMensajeros.find(
              (mensajero) => mensajero.email === emailUsuario
            );
            setvehiculoAsignado(mensajerosFiltrados.modeloVehiculo)
            setnumeroEconomicoRut(mensajerosFiltrados.economico)
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerMensajeros();
  }, [emailUsuario]);

  const [rutaDia, setrutaDia] = useState("");

  const [kilActual, setKilActual] = useState(0);

  const obtenerRutasDia = () => {
    try {
      listarRutasPorCorreo(fechaFormateada, emailUsuario)
        .then((response) => {
          const { data } = response;
          console.log(data);

          if (!listarRutasDiaMens && data) {
            setListRutasDiaMens(formatModelRutas(data));
          } else {
            const datosRutas = formatModelRutas(data);
            setListRutasDiaMens(datosRutas);
            if (datosRutas.length > 0) {
              const primerObjeto = datosRutas[0];
              setvehiculoAsignado(primerObjeto.vehiculoAsignado);
              setnumeroEconomicoRut(primerObjeto.numeroEconomico);
              setrutaDia(primerObjeto.ruta.split("/")[0]);
            }
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerRutasDia();
  }, [emailUsuario]);

  console.log(numeroEconomicoRut)

  const [listarVehiculo, setListVehiculo] = useState([]);
  const [idVehiculo, setIdVehiculo] = useState("");
  const [placaVehiculo, setPlacaVehiculo] = useState("");
  const [kilometraje, setkilometraje] = useState(0);
  const [listVehiculosArr, setListVehiculosArr] = useState([]);


  const obtenerVehiculo = () => {
    try {
      listarVehiculos()
        .then((response) => {
          const { data } = response;
          console.log(data)

          if (!listarVehiculo && data) {
            setListVehiculo(formatModelVehiculos(data));
          } else {
            const datosVehiculos = formatModelVehiculos(data);
            setListVehiculo(datosVehiculos);
            funcArrVehiculos(datosVehiculos);
            const vehiculoConNumeroEconomico1 = datosVehiculos.filter(
              (vehiculo) => vehiculo.numEconomico != ""
            );
            console.log(vehiculoConNumeroEconomico1)

            if (vehiculoConNumeroEconomico1.length > 0) {
              const primerVehiculoConNumeroEconomico1 =
                vehiculoConNumeroEconomico1[0];
              setIdVehiculo(primerVehiculoConNumeroEconomico1.id);
              setkilometraje(
                primerVehiculoConNumeroEconomico1.kilometrajeActual
              );
              setPlacaVehiculo(primerVehiculoConNumeroEconomico1.placa)
            } else {
              console.log(
                "No se encontró ningún vehículo con numeroEconomico ",
                numeroEconomicoRut
              );
            }
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerVehiculo();
  }, [numeroEconomicoRut]);

  console.log(placaVehiculo)

  const funcArrVehiculos = (datosVehiculos) => {
    const newArray = datosVehiculos.map(({ modelo, placa, id, numEconomico }) => ({
      value: placa + "/" + id,
      label: modelo + "-" + placa + "-" + numEconomico,
    }));
    setListVehiculosArr(newArray);
  };

  const actualizaVehiculo = () => {
    try {
      setLoading(true);
      // Sube a cloudinary la imagen principal del producto
      const idReg = tipoUsuario === "administrador" && selectedOption ? selectedOption.split('/')[1] : idVehiculo;
      const kilNuevo = kilActual - kilometraje;
      const dataTemp = {
        kilometrajeActual: kilActual,
        kilometrajeRecorrido: kilNuevo,
      };

      if (!idReg) {
        toast.warning("Seleccona un vehiculo");
        setLoading(false);
      } else {
        actualizarVehiculosKm(idReg, dataTemp).then((response) => {
          const { data } = response;
          toast.success(data.mensaje);
          setLoading(false);
          onSubmit();
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = () => {
    try {
      setLoading(true);

      const dataTemp = {
        vehiculo: tipoUsuario == "administrador" ? selectedOption.split('/')[0] : placaVehiculo,
        neumaticos: {
          presion: presion,
          condicion: condicionNeumatico,
          descripcion: descripcion1
        },
        fluidos: {
          aceiteMotor: aceiteMotor,
          liquidoFrenos: liquidoFrenos,
          refrigeranteMotor: refrigeranteMotor,
          liquidoTransmision: liquidoTransmision,
          descripcion: descripcion2
        },
        frenos: {
          estadoPastillas: pastillasFrenos,
          estadoDiscos: discosFreno,
          descripcion: descripcion3
        },
        luces: {
          estadoLucesFrenos: lucesFrenos,
          estadoLucesAtras: lucesFaros,
          estadoLucesTraseras: lucesTraseras,
          descripcion: descripcion4
        },
        bateria: {
          estadoBateria: estadoBateria,
          descripcion: descripcion5
        },
        documentacion: {
          licenciaConducir: licenciaConducir,
          tarjetaRegistro: registroVehiculo,
          tarjetaSeguro: tarjetaSeguro,
          descripcion: descripcion6
        },
        equipoEmergencia: {
          gato: gatoHidraulico,
          llave: llaveRuedas,
          llantaRepuesto: llantaRepuesto,
          botiquin: equipoEmergencia,
          descripcion: descripcion7
        },
        combustible: {
          nivelCombustible: nivelCombustible,
        },
        gps: {
          estado: estadoGPS,
          descripcion: descripcion8
        },
        limpiaparabrisas: {
          estadoLimpiaparabrisas: estadoLP,
          nivelDeposito: nivelDeposito,
          descripcion: descripcion9
        },
        imagenes: listProductosCargados,
        estado: "true",
      };
      registraEvaluaciones360(dataTemp).then((response) => {
        const { data } = response;
        toast.success(data.mensaje);
        if (tipoUsuario == "mensajero") {
          window.location.reload();
        }
        LogsInformativos("Se ha realizado la verificacion " + dataTemp.vehiculo, dataTemp)
        //history({
        //  search: queryString.stringify(""),
        //});
        setLoading(false);
        //cancelarRegistro()
      });
    } catch (e) {
      console.log(e);
    }
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const handleSeleccionChange = (option) => {
    setSelectedOption(option.value);
  };

  const filtroVehiculos = () => {
    const vehiculoConNumeroEconomico1 = listarVehiculo.filter(
      (vehiculo) => vehiculo.id === selectedOption.split('/')[1]
    );

    if (vehiculoConNumeroEconomico1.length > 0) {
      const primerVehiculoConNumeroEconomico1 =
        vehiculoConNumeroEconomico1[0];
      setIdVehiculo(primerVehiculoConNumeroEconomico1.id);
      setkilometraje(
        primerVehiculoConNumeroEconomico1.kilometrajeActual
      );
      setPlacaVehiculo(primerVehiculoConNumeroEconomico1.placa)
    } else {
      console.log(
        "No se encontró ningún vehículo con numeroEconomico ",
        numeroEconomicoRut
      );
    }
  }

  useEffect(() => {
    if (tipoUsuario === "administrador") {
      filtroVehiculos();
    }

  }, [selectedOption]);

  return (
    <>
      {loading && <Load />}
      <div className="contenedorPrincipalOrden">
        <div className="containerOrden">
          <div>
            <h1 style={{ textAlign: "center" }}>Verificación</h1>
            <div
              style={{
                fontStyle: "italic",
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {tipoUsuario == "administrador" ? (
                <>
                  <Select
                    options={listVehiculosArr}
                    isSearchable={true}
                    placeholder="Seleccionar..."
                    onChange={(selectedOption) =>
                      handleSeleccionChange(selectedOption)
                    }
                  />
                </>
              ) : (
                <>
                  <h4>Ruta del día: {rutaDia.replace(/,/g, " - ")}</h4>
                  <h4>Vehículo Asignado: {vehiculoAsignado != "" ? vehiculoAsignado : "NE23"}</h4>
                </>
              )}
            </div>

            <div
              style={{
                width: "100%",
                margin: "10px auto", // Alinea al centro horizontalmente
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#3498db",
              }}
            >
              <div
                style={{
                  width: `${calcularProgreso().toFixed(2)}%`,
                  height: "20px",
                  backgroundColor: "#2ecc71",
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                {" "}
                {calcularProgreso().toFixed(2)}%
              </div>
            </div>
            <div className="barraProgreso">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((stepNum) => (
                <div
                  key={stepNum}
                  className={`step1 ${stepNum <= pasoActual ? "active" : ""}`}
                >
                  <div className="circle1">
                    {stepNum === 1 ? (
                      <FontAwesomeIcon icon={faCircleDot} />
                    ) : stepNum === 2 ? (
                      <FontAwesomeIcon icon={faDroplet} />
                    ) : stepNum === 3 ? (
                      <FontAwesomeIcon icon={faHand} />
                    ) : stepNum === 4 ? (
                      <FontAwesomeIcon icon={faLightbulb} />
                    ) : stepNum === 5 ? (
                      <FontAwesomeIcon icon={faCarBattery} />
                    ) : stepNum === 6 ? (
                      <FontAwesomeIcon icon={faIdCard} />
                    ) : stepNum === 7 ? (
                      <FontAwesomeIcon icon={faGasPump} />
                    ) : stepNum === 8 ? (
                      <FontAwesomeIcon icon={faMapLocationDot} />
                    ) : stepNum === 9 ? (
                      <FontAwesomeIcon icon={faCloudRain} />
                    ) : stepNum === 10 ? (
                      <FontAwesomeIcon icon={faCamera} />
                    ) : null}
                  </div>

                  <div style={{ display: "none" }}>
                    {stepNum === 1 ? (
                      <>
                        <span className="textocontaineret1">Neumáticos</span>
                      </>
                    ) : stepNum === 2 ? (
                      <>
                        <span className="textocontaineret1">
                          Fluidos del vehículo
                        </span>
                      </>
                    ) : stepNum === 3 ? (
                      <>
                        <span className="textocontaineret1">Frenos</span>
                      </>
                    ) : stepNum === 4 ? (
                      <>
                        <span className="textocontaineret1">Luces</span>
                      </>
                    ) : stepNum === 5 ? (
                      <>
                        <span className="textocontaineret1">Bateria</span>
                      </>
                    ) : stepNum === 6 ? (
                      <>
                        <span className="textocontaineret1">Documentación</span>
                      </>
                    ) : stepNum === 7 ? (
                      <>
                        <span className="textocontaineret1">Combustible</span>
                      </>
                    ) : stepNum === 8 ? (
                      <>
                        <span className="textocontaineret1">GPS</span>
                      </>
                    ) : stepNum === 9 ? (
                      <>
                        <span className="textocontaineret1">
                          Limpiaparabrisas
                        </span>
                      </>
                    ) : stepNum === 10 ? (
                      <>
                        <span className="textocontaineret1">
                          Cargar imagenes
                        </span>
                      </>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>

            {pasoActual === 1 && (
              <div className="contenidoVerificacion">
                <h2 style={{ textAlign: "center" }}>Neumáticos</h2>
                <hr />
                <Form>
                  <div className="radioVerif">
                    <Row>
                      <Col sm={12} md={6} lg={6} className="text-center">
                        <Form.Label>Presion</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Optima"
                          value="optima"
                          checked={presion === "optima"}
                          onChange={handlePresionChange}
                        />
                        <Form.Check
                          type="radio"
                          label="Baja"
                          value="baja"
                          checked={presion === "baja"}
                          onChange={handlePresionChange}
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6} className="text-center">
                        <Form.Label>Condición</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Buena"
                          value="Buena"
                          checked={condicionNeumatico === "Buena"}
                          onChange={handleCondicionNeumatico}
                        />
                        <Form.Check
                          type="radio"
                          label="Mala"
                          value="Mala"
                          checked={condicionNeumatico === "Mala"}
                          onChange={handleCondicionNeumatico}
                        />
                      </Col>
                      <Col className="text-center">
                        <Form.Label>Descripcion</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Agrega la descripcion de esta seccion"
                          value={descripcion1}
                          onChange={handleDescripcion1Change}
                        />
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            )}

            {pasoActual === 2 && (
              <div className="contenidoVerificacion">
                <h2 style={{ textAlign: "center" }}>Fluidos del vehículo</h2>
                <hr />
                <Form>
                  <div className="radioVerif">
                    <Row>
                      <Col sm={12} md={6} lg={6}>
                        <Form.Label>Nivel de aceite del motor</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Bien"
                          value="Bien"
                          checked={aceiteMotor === "Bien"}
                          onChange={handleAceiteM}
                        />
                        <Form.Check
                          type="radio"
                          label="Bajo"
                          value="bajo"
                          checked={aceiteMotor === "bajo"}
                          onChange={handleAceiteM}
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Form.Label>Líquido de frenos</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Bien"
                          value="Bien"
                          checked={liquidoFrenos === "Bien"}
                          onChange={handleLiquidoFrenos}
                        />
                        <Form.Check
                          type="radio"
                          label="Bajo"
                          value="Bajo"
                          checked={liquidoFrenos === "Bajo"}
                          onChange={handleLiquidoFrenos}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} md={6} lg={6}>
                        <Form.Label>Refrigerante del motor</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Bien"
                          value="Bien"
                          checked={refrigeranteMotor === "Bien"}
                          onChange={handleRefrigeranteMotor}
                        />
                        <Form.Check
                          type="radio"
                          label="Baja"
                          value="baja"
                          checked={refrigeranteMotor === "baja"}
                          onChange={handleRefrigeranteMotor}
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Form.Label>Líquido de transmisión</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Bien"
                          value="Bien"
                          checked={liquidoTransmision === "Bien"}
                          onChange={handleLiquidoTransmision}
                        />
                        <Form.Check
                          type="radio"
                          label="Bajo"
                          value="Bajo"
                          checked={liquidoTransmision === "Bajo"}
                          onChange={handleLiquidoTransmision}
                        />
                      </Col>
                      <Col className="text-center">
                        <Form.Label>Descripcion</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Agrega la descripcion de esta seccion"
                          value={descripcion2}
                          onChange={handleDescripcion2Change}
                        />
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            )}

            {pasoActual === 3 && (
              <div className="contenidoVerificacion">
                <h2 style={{ textAlign: "center" }}>Frenos</h2>
                <hr />
                <Form>
                  <div className="radioVerif">
                    <Row>
                      <Col sm={12} md={6} lg={6}>
                        <Form.Label>Estado de las pastillas</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Optima"
                          value="optima"
                          checked={pastillasFrenos === "optima"}
                          onChange={handlepastillasFrenos}
                        />
                        <Form.Check
                          type="radio"
                          label="Mala"
                          value="Mala"
                          checked={pastillasFrenos === "Mala"}
                          onChange={handlepastillasFrenos}
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Form.Label>Estado de discos del freno</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Optima"
                          value="optima"
                          checked={discosFreno === "optima"}
                          onChange={handlediscosFreno}
                        />
                        <Form.Check
                          type="radio"
                          label="Mala"
                          value="Mala"
                          checked={discosFreno === "Mala"}
                          onChange={handlediscosFreno}
                        />
                      </Col>
                      <Col className="text-center">
                        <Form.Label>Descripcion</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Agrega la descripcion de esta seccion"
                          value={descripcion3}
                          onChange={handleDescripcion3Change}
                        />
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            )}

            {pasoActual === 4 && (
              <div className="contenidoVerificacion">
                <h2>Luces</h2>
                <hr />
                <label>
                  Verifica que todas las luces estén funcionando correctamente
                </label>
                <Form>
                  <div className="radioVerif">
                    <Row>
                      <Col sm={12} md={4} lg={4}>
                        <Form.Label>Faros</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Bien"
                          value="Bien"
                          checked={lucesFaros === "Bien"}
                          onChange={handleLucesFarosChange}
                        />
                        <Form.Check
                          type="radio"
                          label="Falla"
                          value="Falla"
                          checked={lucesFaros === "Falla"}
                          onChange={handleLucesFarosChange}
                        />
                      </Col>
                      <Col sm={12} md={4} lg={4}>
                        <Form.Label>Intermitentes</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Bien"
                          value="Bien"
                          checked={lucesIntermitentes === "Bien"}
                          onChange={handleLucesIntermitentesChange}
                        />
                        <Form.Check
                          type="radio"
                          label="Falla"
                          value="Falla"
                          checked={lucesIntermitentes === "Falla"}
                          onChange={handleLucesIntermitentesChange}
                        />
                      </Col>
                      <Col sm={12} md={4} lg={4}>
                        <Form.Label>Luces de freno</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Bien"
                          value="Bien"
                          checked={lucesFrenos === "Bien"}
                          onChange={handleLucesFrenosChange}
                        />
                        <Form.Check
                          type="radio"
                          label="Falla"
                          value="Falla"
                          checked={lucesFrenos === "Falla"}
                          onChange={handleLucesFrenosChange}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} md={4} lg={4}>
                        <Form.Label>Luces traseras</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Bien"
                          value="Bien"
                          checked={lucesTraseras === "Bien"}
                          onChange={handleLucesTraserasChange}
                        />
                        <Form.Check
                          type="radio"
                          label="Falla"
                          value="Falla"
                          checked={lucesTraseras === "Falla"}
                          onChange={handleLucesTraserasChange}
                        />
                      </Col>
                      <Col sm={12} md={4} lg={4}>
                        <Form.Label>Luces de marcha atrás</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Bien"
                          value="Bien"
                          checked={lucesAtras === "Bien"}
                          onChange={handleLucesAtrasChange}
                        />
                        <Form.Check
                          type="radio"
                          label="Falla"
                          value="Falla"
                          checked={lucesAtras === "Falla"}
                          onChange={handleLucesAtrasChange}
                        />
                      </Col>
                      <Col sm={12} md={4} lg={4}>
                        <div></div>
                        
                      </Col>
                      <Col className="text-center">
                        <Form.Label>Descripcion</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Agrega la descripcion de esta seccion"
                          value={descripcion4}
                          onChange={handleDescripcion4Change}
                        />
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            )}
            {pasoActual === 5 && (
              <div className="contenidoVerificacion">
                <h2 style={{ textAlign: "center" }}>Batería</h2>
                <hr />
                <label>
                  Verifica que la bateria del carro este en buen estado y bien
                  sujeta
                </label>
                <Form>
                  <div className="radioVerif">
                    <Row>
                      <Col sm={12} md={12} lg={12} className="text-center">
                        <Form.Label>Estado</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Optima"
                          value="optima"
                          checked={estadoBateria === "optima"}
                          onChange={handleEstadoBateriaChange}
                        />
                        <Form.Check
                          type="radio"
                          label="Falla"
                          value="Falla"
                          checked={estadoBateria === "Falla"}
                          onChange={handleEstadoBateriaChange}
                        />
                      </Col>
                      <Col className="text-center">
                        <Form.Label>Descripcion</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Agrega la descripcion de esta seccion"
                          value={descripcion5}
                          onChange={handleDescripcion5Change}
                        />
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            )}

            {pasoActual === 6 && (
              <div className="contenidoVerificacion">
                <h2 style={{ textAlign: "center" }}>
                  Documentación del vehículo
                </h2>
                <hr />
                <label>
                  Asegurate llevar contigo todos los documentos necesarios
                </label>
                <Form>
                  <div className="radioVerif">
                    <Row>
                      <Col sm={12} md={4} lg={4} className="text-center">
                        <Form.Label>Licencia de conducir</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Si"
                          value="Si"
                          checked={licenciaConducir === "Si"}
                          onChange={handleLicenciaConducirChange}
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          value="No"
                          checked={licenciaConducir === "No"}
                          onChange={handleLicenciaConducirChange}
                        />
                      </Col>
                      <Col sm={12} md={4} lg={4} className="text-center">
                        <Form.Label>Tarjeta de seguro</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Si"
                          value="Si"
                          checked={tarjetaSeguro === "Si"}
                          onChange={handleTarjetaSeguroChange}
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          value="No"
                          checked={tarjetaSeguro === "No"}
                          onChange={handleTarjetaSeguroChange}
                        />
                      </Col>
                      <Col sm={12} md={4} lg={4} className="text-center">
                        <Form.Label>
                          Tarjeta de registro del vehículo
                        </Form.Label>
                        <Form.Check
                          type="radio"
                          label="Si"
                          value="Si"
                          checked={registroVehiculo === "Si"}
                          onChange={handleRegistroVehiculoChange}
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          value="No"
                          checked={registroVehiculo === "No"}
                          onChange={handleRegistroVehiculoChange}
                        />
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col sm={12} md={12} lg={12} className="text-center">
                        <Form.Label>Equipo de emergencia</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Si"
                          value="Si"
                          checked={equipoEmergencia === "Si"}
                          onChange={handleEquipoEmergenciaChange}
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          value="No"
                          checked={equipoEmergencia === "No"}
                          onChange={handleEquipoEmergenciaChange}
                        />
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col sm={12} md={4} lg={4} className="text-center">
                        <Form.Label>Gato hidraulico</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Si"
                          value="Si"
                          checked={gatoHidraulico === "Si"}
                          onChange={handleGatoHidraulicoChange}
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          value="No"
                          checked={gatoHidraulico === "No"}
                          onChange={handleGatoHidraulicoChange}
                        />
                      </Col>
                      <Col sm={12} md={4} lg={4} className="text-center">
                        <Form.Label>Llave de ruedas</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Si"
                          value="Si"
                          checked={llaveRuedas === "Si"}
                          onChange={handleLlaveRuedasChange}
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          value="No"
                          checked={llaveRuedas === "No"}
                          onChange={handleLlaveRuedasChange}
                        />
                      </Col>
                      <Col sm={12} md={4} lg={4} className="text-center">
                        <Form.Label>Llanta de repuesto</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Si"
                          value="Si"
                          checked={llantaRepuesto === "Si"}
                          onChange={handleLlantaRepuestoChange}
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          value="No"
                          checked={llantaRepuesto === "No"}
                          onChange={handleLlantaRepuestoChange}
                        />
                      </Col>
                      <Col className="text-center">
                        <Form.Label>Descripcion</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Agrega la descripcion de esta seccion"
                          value={descripcion6}
                          onChange={handleDescripcion6Change}
                        />
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            )}

            {pasoActual === 7 && (
              <div className="contenidoVerificacion">
                <h2 style={{ textAlign: "center" }}>Combustible</h2>
                <hr />
                <Form>
                  <div>
                    <Row>
                      <Col sm={12} md={3} lg={3}>
                        <Form.Label>Nivel del combustible</Form.Label>
                      </Col>
                      <Col sm={12} md={9} lg={9}>
                        <Form.Control
                          type="text"
                          placeholder="Escribe el nivel de combustible"
                          name="nivelCombustible"
                          value={nivelCombustible}
                          onChange={handleNivelCombustibleChange}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm={12} md={3} lg={3}>
                        <Form.Label>Kilometraje actual: </Form.Label>
                      </Col>
                      <Col sm={12} md={9} lg={9}>
                        <Form.Control
                          placeholder="Ingresa el kilometraje actual del vehiculo"
                          type="number"
                          name="kilActual"
                          value={kilActual}
                          onChange={(e) => setKilActual(e.target.value)}
                        />
                        <div style={{ textAlign: "left", fontStyle: "italic" }}>
                          <Form.Label>
                            Kilometraje actual: {kilometraje}
                          </Form.Label>
                        </div>
                      </Col>
                      <Col className="text-center">
                        <Form.Label>Descripcion</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Agrega la descripcion de esta seccion"
                          value={descripcion7}
                          onChange={handleDescripcion7Change}
                        />
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            )}
            {pasoActual === 8 && (
              <div className="contenidoVerificacion">
                <h2 style={{ textAlign: "center" }}>GPS y mapas</h2>
                <hr />
                <Form>
                  <div className="radioVerif">
                    <Row>
                      <Col sm={12} md={12} lg={12} className="text-center">
                        <Form.Label>Estado</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Bien"
                          value="Bien"
                          checked={estadoGPS === "Bien"}
                          onChange={handleEstadoGPSChange}
                        />
                        <Form.Check
                          type="radio"
                          label="Falla"
                          value="Falla"
                          checked={estadoGPS === "Falla"}
                          onChange={handleEstadoGPSChange}
                        />
                      </Col>
                      <Col className="text-center">
                        <Form.Label>Descripcion</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Agrega la descripcion de esta seccion"
                          value={descripcion8}
                          onChange={handleDescripcion8Change}
                        />
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            )}
            {pasoActual === 9 && (
              <div className="contenidoVerificacion">
                <h2 style={{ textAlign: "center" }}>Limpiaparabrisas</h2>
                <hr />
                <Form>
                  <div className="radioVerif">
                    <Row>
                      <Col sm={12} md={6} lg={6} className="text-center">
                        <Form.Label>Estado</Form.Label>
                        <Form.Check
                          type="radio"
                          label="Bien"
                          value="Bien"
                          checked={estadoLP === "Bien"}
                          onChange={handleEstadoLPChange}
                        />
                        <Form.Check
                          type="radio"
                          label="Falla"
                          value="Falla"
                          checked={estadoLP === "Falla"}
                          onChange={handleEstadoLPChange}
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6} className="text-center">
                        <Form.Label>Nivel deposito</Form.Label>
                        <Form.Control
                          placeholder="Ingresa el nivel del deposito"
                          type="text"
                          name="nivelDeposito"
                          value={nivelDeposito}
                          onChange={handleNivelDepositoChange}
                        />
                      </Col>
                      <Col className="text-center">
                        <Form.Label>Descripcion</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Agrega la descripcion de esta seccion"
                          value={descripcion9}
                          onChange={handleDescripcion9Change}
                        />
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            )}
            {pasoActual === 10 && (
              <div className="contenidoVerificacion">
                <h2 style={{ textAlign: "center" }}>imagenes</h2>
                <hr />
                <Form>
                  <div>
                    <div className="imagenPrincipal">
                      <h4 className="textoImagenPrincipal">Sube tu imagen</h4>
                      <br />
                      <br />
                      <div
                        title="Seleccionar imagen de la categoría"
                        className="imagenProducto"
                      >
                        <Dropzone setImagenFile={setImagenProducto} />

                        <Button
                          variant="success"
                          onClick={addItems}
                          disabled={imagenProducto == null}
                        >
                          Cargar
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </div>
        </div>
        <div className="buttonsOS">
          <div className="botonAntOS">
            {pasoActual > 1 && (
              <Button onClick={pasoAnterior}>
                <FontAwesomeIcon icon={faArrowLeft} />
                &nbsp;Anterior
              </Button>
            )}
          </div>
          <div className="botonSigOS">
            {pasoActual < 10 && (
              <Button onClick={siguientePaso}>
                <FontAwesomeIcon icon={faArrowRight} />
                &nbsp;Siguiente
              </Button>
            )}
          </div>
          <div className="botonSigOS">
            {pasoActual == 10 && (
              <Button onClick={actualizaVehiculo}>
                <FontAwesomeIcon icon={faArrowRight} />
                &nbsp;Guardar
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Verificación;

function formatModelRutas(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      numeroRuta: data.numeroRuta,
      ruta: data.ruta,
      nombreMensajero: data.nombreMensajero,
      correoMensajero: data.correoMensajero,
      vehiculoAsignado: data.vehiculoAsignado,
      numeroEconomico: data.numeroEconomico,
      horaInicio: data.horaInicio,
      observaciones: data.observaciones,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelMensajero(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      telefono: data.telefono,
      email: data.email,
      modeloVehiculo: data.modeloVehiculo,
      placa: data.placa,
      economico: data.economico,
      fecha: data.fecha,
      estado: data.estado,
      imagen: data.imagen
    });
  });
  return dataTemp;
}

function formatModelVehiculos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      modelo: data.modelo,
      placa: data.placa,
      numEconomico: data.numEconomico,
      kilometrajeActual: data.kilometrajeActual,
      kilometrajeRecorrido: data.kilometrajeRecorrido,
      estado: data.estado,
    });
  });
  return dataTemp;
}
