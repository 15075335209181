import React, { useEffect, useState } from "react";
import { listarAbonosAprobados } from "../../../api/abonosOrdenes";
import MUIDataTable from "mui-datatables";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  listarFechasyEstadoPago,
  listarSaldosNuevo,
} from "../../../api/ordenesServicio";
import { faBars, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown } from "react-bootstrap";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import SaldoPorCliente from "./clientes/SaldoPorCliente";
import TablaMovimientosGral from "./TablaMovimientosGral";
import { Load } from "../../load/load";
import RegistrarSaldosCliente from "./registrarSaldoCliente";
import { withRouter } from "../../../utils/withRouter";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import { obtenerUsuario } from "../../../api/usuarios";
import { toast } from "react-toastify";

const TablaCXC = (props) => {
  const { location, history } = props;
  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [tipoUsuario, setTipoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const detallesCuenta = (content) => {
    setTitulosModal("Detalles de la cuenta");
    setContentModal(content);
    setShowModal(true);
  };

  const registrarSaldo = (content) => {
    setTitulosModal("Registrar saldos de los clientes");
    setContentModal(content);
    setShowModal(true);
  };


  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros coincidentes con los filtros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    // customFooter: () => {
    //   return (
    //     <tfoot>
    //       <tr>
    //         <td colSpan={7} style={{ fontSize: "larger", fontWeight: "bold" }}>
    //           Total:{" "}
    //           {!mostrarPrimerDiv
    //             ? formatCurrency(total)
    //             : formatCurrency(resultad)}
    //         </td>{" "}
    //         {/* Cambia el número de columnas según tu tabla */}
    //       </tr>
    //     </tfoot>
    //   );
    // },
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "id",
      label: "CLIENTE",
      options: {
        customBodyRender: (value) => {
          if (value != null) {
            return value;
          } else {
            return "No especificado";
          }
        },
      },
    },
    {
      name: "totalOrdenes",
      label: "No. Trabajos",
    },
    {
      name: "totalOrdenesycargos",
      label: "TOTAL TRABAJOS",
      options: {
        customBodyRender: (value) => {
          const style = {
            color: value >= 0 ? "red" : "green",
          };

          return <span style={style}>{formatCurrency(value)}</span>;
        },
      },
    },
    {
      name: "pagado",
      label: "Total pagado",
      options: {
        customBodyRender: (value) => {
          const style = {
            color: value >= 0 ? "green" : "red",
          };

          return <span style={style}>{formatCurrency(value)}</span>;
        },
      },
    },
    {
      name: "pendiente",
      label: "Saldo por cobrar",
      options: {
        customBodyRender: (value) => {
          const style = {
            color: value >= 0 ? "red" : "green",
          };

          return <span style={style}>{formatCurrency(value)}</span>;
        },
      },
    },
    {
      name: "vencido",
      label: "Saldo vencido",
      options: {
        customBodyRender: (value) => {
          const style = {
            color: value >= 0 ? "red" : "green",
          };

          return <span style={style}>{formatCurrency(value)}</span>;
        },
      },
    },
    {
      name: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <Dropdown>
              <Dropdown.Toggle
                style={{ backgroundColor: "#0c1b30", borderColor: "#0c1b30" }}
                id="dropdown-basic"
              >
                <FontAwesomeIcon icon={faBars} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    detallesCuenta(
                      <SaldoPorCliente
                        correo={tableMeta.rowData[0]}
                        origen={"conta"}
                      />
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    style={{ color: "#fd7e14", fontWeight: "bold" }}
                  />
                  &nbsp;Detalles de cuenta
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        ),
      },
    },
  ];

  const [listAbonosA, setListAbonosA] = useState([]);

  const obtenerAA = () => {
    try {
      listarAbonosAprobados()
        .then((response) => {
          const { data } = response;
          console.log("AA: -> ", data);
          if (!listAbonosA && data) {
            setListAbonosA(formatModelAA(data));
          } else {
            setListAbonosA(formatModelAA(data));
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [listSaldos, setListSaldos] = useState([]);

  const obtenerSaldos = () => {
    try {
      listarSaldosNuevo()
        .then((response) => {
          const { data } = response;
          if (data.length > 0) {
            setListSaldos(data);
          } else {
            setListSaldos([]);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [listOS, setListOS] = useState([]);

  const obtenerOS = () => {
    try {
      listarFechasyEstadoPago()
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (data.length > 0) {
            const ordenesAdeudadas = data.filter(
              (registro) => registro.diasDesdeFecha > 15
            );
            console.log(
              "🚀 ~ .then ~ ordenesAdeudadas:",
              formatModelOrdenes(ordenesAdeudadas)
            );

            const ordenesFormat = formatModelOrdenes(ordenesAdeudadas);
            //setListOS(formatModelOrdenes(data));

            // Inicializar un objeto vacío para almacenar los resultados agrupados
            const agrupados = {};

            // Iterar sobre cada orden en el arreglo
            ordenesFormat.forEach((orden) => {
              // Obtener el valor de cargoOrden y adeudo de la orden actual
              const { cargoOrden, adeudo } = orden;

              // Si cargoOrden está definido
              if (cargoOrden) {
                // Si el grupo para este cargoOrden no existe, inicializarlo con 0
                if (!agrupados[cargoOrden]) {
                  agrupados[cargoOrden] = 0;
                }

                // Agregar el adeudo de la orden actual al grupo correspondiente
                agrupados[cargoOrden] += adeudo;
              }
            });

            // Convertir el objeto agrupado a un arreglo para facilitar su visualización
            const resultado = Object.keys(agrupados).map((cargoOrden) => ({
              cargoOrden,
              adeudoTotal: agrupados[cargoOrden],
            }));

            setListOS(resultado);
          } else {
            setListOS([]);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerAA();
    obtenerSaldos();
    obtenerOS();
  }, [location]);

  const [clientesSaldos, setClientesSaldos] = useState([]);

  useEffect(() => {
    const calcularClientesConAbonos = () => {
      const clientesConAbonosCalculados = listSaldos.map((cliente) => {
        const abono = listAbonosA.find((abono) => abono.id === cliente._id);
        return {
          ...cliente,
          totalCantidadRecibida: abono ? abono.totalCantidadRecibida : 0,
        };
      });

      const saldosCompletos = clientesConAbonosCalculados.map((cliente) => {
        const adeudo = listOS.find(
          (adeudo) => adeudo.cargoOrden === cliente._id
        );
        return {
          ...cliente,
          totalSaldoVencido: adeudo ? adeudo.adeudoTotal : 0,
        };
      });
      setClientesSaldos(formatModelSaldos(saldosCompletos));
    };

    calcularClientesConAbonos();
  }, [listSaldos, listAbonosA, listOS, location]);

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  return (
    <>
      {loading && <Load />}
      <div style={{ padding: "1%" }}>
        <h1>Saldos de clientes</h1>
        <div className="divButton">
          {tipoUsuario == "administrador" && (
            <>
              <Button onClick={() =>
                registrarSaldo(
                  <RegistrarSaldosCliente setShow={setShowModal} history={history} />
                )
              }>{"Registrar Saldo"}
              </Button>
            </>
          )}
          <Button onClick={toggleVisibility}>{!isVisible ? ("Ver movimientos") : ("Volver a tabla")}</Button>
        </div>
        {!isVisible ? (
          <MUIDataTable
            title={"Lista de saldos de clientes registrados"}
            data={clientesSaldos}
            columns={columns}
            options={options}
          />
        ) : (
          <TablaMovimientosGral activador={isVisible} />
        )}

        <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
          {contentModal}
        </BasicModal>
      </div>
    </>
  );
};

function formatModelAA(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      totalCantidadRecibida: data.totalCantidadRecibida,
    });
  });
  return dataTemp;
}

function formatModelSaldos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    console.log("data.totalPrecio", data.totalCantidadCargo);
    let totalOrdenesCarDesc =
      parseFloat(data.totalPrecio) +
      parseFloat(data.totalCantidadCargo) -
      parseFloat(data.totalCantidadDescuento);
    let saldo = 0;
    let pagado = 0;
    if (data.saldo) {
      saldo = parseFloat(data.saldo);
      pagado = saldo + parseFloat(data.totalCantidadRecibida);
    } else {
      pagado = parseFloat(data.totalCantidadRecibida);
    }
    dataTemp.push({
      id: data._id,
      totalOrdenes: data.count,
      totalOrdenesycargos: totalOrdenesCarDesc,
      pagado: pagado,
      pendiente: totalOrdenesCarDesc - pagado,
      vencido: data.totalSaldoVencido,
    });
  });
  return dataTemp;
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      fecha: data.fecha,
      cargoOrden: data.cargoOrden,
      diasDesdeFecha: data.diasDesdeFecha,
      totalAbonos: data.totalAbonos,
      precioTotal: data.precioTotal,
      adeudo: parseFloat(data.precioTotal) - parseFloat(data.totalAbonos),
    });
  });
  return dataTemp;
}

export default withRouter(TablaCXC);
