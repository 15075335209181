import { faCashRegister } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import { obtenerUsuario, validarPasswordUsuario } from "../../../api/usuarios";
import {
  obtenerAbonosPorFolioOS,
  registraAbonosOrdenes,
} from "../../../api/abonosOrdenes";
import { toast } from "react-toastify";
import { obtenerOrdenesServicioPorFolio } from "../../../api/ordenesServicio";
import { obtenerCyDPorFolioOS } from "../../../api/preciosOrden";
import { LogsInformativos } from "../../logs/logs";
import queryString from "query-string";
import { obtenerTasaCambio } from "../../../api/conversionMoneda";
import { crearCargosOpenpay, crearTarjetaOpenpay } from "../../../api/openPay";
import { obtenerDoctoresPorCorreo } from "../../../api/doctores";

const PagosOrdenesM = ({ data, setShow, origen, history }) => {
  console.log(data)
  const generateOrderId = () => {
    const timestamp = new Date().getTime();
    const randomString = Math.random().toString(36).substring(2, 15);
    return `${timestamp}-${randomString}`;
  };

  const [formaPago, setFormaPago] = useState("efectivo");
  const [currency, setCurrency] = useState("MXN");
  const [tasaCambioMXN, setTasaCambioMXN] = useState(null);
  const [contraseñaUsuario, setContraseñaUsuario] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tasa = await obtenerTasaCambio("MXN");
        setTasaCambioMXN((Math.ceil(tasa) - 2));
        console.log(`1 USD = ${tasa} MXN`);
      } catch (error) {
        console.error("Error al obtener la tasa de cambio:", error);
      }
    };

    if (formaPago === "efectivo" && currency === "USD") {
      fetchData();
    }
  }, [formaPago, currency]);
  console.log("data", data);
  console.log('origen', origen)
  const folioOS =
    origen == "tablaPagos" ? data[2] : origen === "cxc" ? data.folio : origen === "tablaEntregas" ? data[3] : data[1];
  const cargoOrden = origen == "tablaPagos" ? data[5] : origen === "cxc" ? data.cargoOrden : data[10];
  const [cargo, setCargo] = useState("");

  const [nombreUsuario, setNombreUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setNombreUsuario(data.nombre);
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const [precioOrden, setPrecioOrden] = useState(0);
  const [nombreDoctor, setNombreDoctor] = useState("")
  const [correoDoctor, setCorreoDoctor] = useState("");

  const obtenerOS = (folio) => {
    try {
      obtenerOrdenesServicioPorFolio(folio)
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (data.length > 0) {
            setPrecioOrden(data[0].precioTotal);
            setNombreDoctor(data[0].nombreDoctor)
            setCorreoDoctor(data[0].correoDoctor);
          } else {
            setPrecioOrden(0);
            setCorreoDoctor("");
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [numeroTarjeta, setNumeroTarjeta] = useState('');
  const [mesExpiracion, setMesExpiracion] = useState('');
  const [anoExpiracion, setAnoExpiracion] = useState('');
  const [cvv, setCvv] = useState('');

  const createCard = async (cardData) => {
    try {
      const newCard = await crearTarjetaOpenpay(cardData);
      console.log("Tarjeta creada:", newCard);
      return newCard;
    } catch (error) {
      console.error('Error al crear la tarjeta:', error);
      throw error; // Re-throw error to be caught in handleSubmit
    }
  };

  const [abonosOrden, setAbonosOrden] = useState([]);

  const obtenerAbonosOS = (folio) => {
    try {
      obtenerAbonosPorFolioOS(folio)
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (data.length > 0) {
            setAbonosOrden(formatModelAbonos(data));
          } else {
            setAbonosOrden([]);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [cydOrden, setCydOrden] = useState([]);

  const obtenerCydOS = (folio) => {
    try {
      obtenerCyDPorFolioOS(folio)
        .then((response) => {
          const { data } = response;
          if (data.length > 0) {
            setCydOrden(formatModelCargosyDescuentos(data));
          } else {
            setCydOrden([]);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerDatosUsuario();
    obtenerOS(folioOS);
    obtenerAbonosOS(folioOS);
    obtenerCydOS(folioOS);
  }, [folioOS]);

  const [telefonoDoctor, setTelefonoDoctor] = useState("");

  const obtenerDatosDoctor = () => {
    try {
      obtenerDoctoresPorCorreo(correoDoctor)
        .then((response) => {
          const { data } = response;
          setTelefonoDoctor(data.telefonoCelular);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosDoctor();
  }, [correoDoctor]);

  console.log(nombreDoctor)

  const [sumaPagosAprobados, setSumaPagosAprobados] = useState(0);
  const [totalPagar, setTotalPagar] = useState(0);
  const [restante, setRestante] = useState(0);

  useEffect(() => {
    let totalCalculado = 0;
    cydOrden.forEach((item) => {
      // Suma o resta según la operación
      if (item.tipoOperacion === "descuento") {
        totalCalculado -= parseFloat(item.cantidad);
      } else {
        totalCalculado += parseFloat(item.cantidad);
      }
    });
    setTotalPagar(totalCalculado);
  }, [cydOrden]);

  useEffect(() => {
    let totalCalculadoRes = 0;
    abonosOrden.forEach((item) => {
      if (item.estado === "1") {
        totalCalculadoRes += parseFloat(item.cantidadRecibida || 0);
      }
    });
    setSumaPagosAprobados(totalCalculadoRes);
  }, [abonosOrden]);

  useEffect(() => {
    setRestante(parseFloat(totalPagar) - sumaPagosAprobados);
  }, [totalPagar, sumaPagosAprobados]);

  useEffect(() => {
    const resta =
      parseFloat(totalPagar) -
      sumaPagosAprobados -
      (cargo != "" ? parseFloat(cargo) : 0);
    setRestante(resta);
  }, [cargo, sumaPagosAprobados]);

  let fecha = new Date();

  // Obtener el día, mes y año
  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
  let year = fecha.getFullYear();
  let hora = fecha.getHours(); // Obtener la hora (formato de 0 a 23)
  let minutos = fecha.getMinutes(); // Obtener los minutos
  let fechaFormat = `${year}-${mes < 10 ? ("0" + mes) : mes}-${dia < 10 ? ("0" + dia) : dia}`;

  const guardarAbono = async (e) => {
    e.preventDefault();

    if (!cargo || !contraseñaUsuario) {
      toast.warning("Completa el formulario");
    } else {
      if (parseFloat(cargo) > precioOrden) {
        console.log(parseFloat(cargo));
        console.log(parseFloat(precioOrden));
        toast.warning(
          "La cantidad recibida no puede ser mayor al precio total de la orden"
        );
      } else {
        let cantidad = parseFloat(cargo);
        if (formaPago === "efectivo" && currency === "USD") {
          cantidad *= tasaCambioMXN;
        }
        console.log()
        try {
          const dataTemp = {
            ordenServicio: folioOS,
            cantidadRecibida: (formaPago == "efectivo" && currency == "USD") ? cantidad : cargo,
            idRecibio: nombreUsuario,
            hizoCargo: "",
            aprobo: origen != "tablaPagos" ? "Pendiente" : nombreUsuario,
            estado: origen != "tablaPagos" ? "0" : "1",
            fecha: fechaFormat,
            emailCargo: origen === "tablaEntregas" ? correoDoctor : cargoOrden,
            metodoPago: formaPago
          };

          const temp = nombreDoctor.split(" ");

          const dataTemp2 = {
            customer: {
              name: temp.length === 3 ? temp[0] : temp[0] + " " + temp[1],
              last_name: temp.length === 3 ? temp[1] + " " + temp[2] : temp[2] + " " + temp[3],
              phone_number: telefonoDoctor,
              email: correoDoctor
            },
            order_id: generateOrderId(),
            amount: Number(cargo), // Si cargo es $10.00, amount debería ser 1000
            method: "bank_account",
            due_date: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(), // Set due date to 24 hours in the future
            description: "Pago de la orden de cliente " + folioOS,
          }

          if (formaPago === "transferencia") {

            const nuevoCargo = await crearCargosOpenpay(dataTemp2);

            if (nuevoCargo.status === 'in_progress') {
              console.log('El pago está en progreso:', nuevoCargo);

              const paymentDetails = nuevoCargo.payment_method;
              if (paymentDetails) {
                // Provide user with details or a link to continue with payment
                window.open(paymentDetails.url_spei);

              } else {
                console.error('No se encontraron detalles del método de pago:', nuevoCargo);
              }
            } else if (nuevoCargo.status === 'completed') {
              console.log('Pago completado:', nuevoCargo);
              // Handle completed payment
            } else {
              console.error('Estado del pago no manejado:', nuevoCargo);
            }
          } else if (formaPago == "tarjeta") {
            const cardData = {
              card_number: numeroTarjeta,
              holder_name: nombreDoctor,
              expiration_month: mesExpiracion,
              expiration_year: anoExpiracion,
              cvv2: cvv,
              device_session_id: "'kR1MiQhz2otdIuUlQkbEyitIqVMiI16f'",
            };

            const newCard = await createCard(cardData);
            if (!newCard || !newCard.id) {
              throw new Error("No se recibió un ID de tarjeta válido.");
            }

            const chargeRequest = {
              source_id: newCard.id,
              method: "card",
              currency: "MXN",
              description: "Pago de la orden de cliente " + folioOS,
              order_id: generateOrderId(),
              device_session_id: 'kR1MiQhz2otdIuUlQkbEyitIqVMiI16f',
              amount: Number(cargo),
              customer: {
                name: temp.length === 3 ? temp[0] : temp[0] + " " + temp[1],
                last_name: temp.length === 3 ? temp[1] + " " + temp[2] : temp[2] + " " + temp[3],
                phone_number: telefonoDoctor,
                email: correoDoctor
              },
            };

            const chargeResponse = await crearCargosOpenpay(chargeRequest);

            console.log('Cargo creado:', chargeResponse);
          }

          const dataTemp3 = {
            email: correoUsuario,
            password: contraseñaUsuario
          }

          // Llamada a validarPasswordUsuario para comprobar la contraseña
          validarPasswordUsuario(dataTemp3).then((response) => {
            if (response.status === 200) {
              // Si la validación es exitosa, ejecutar registraAbonosOrdenes
              registraAbonosOrdenes(dataTemp).then((response) => {
                const { data } = response;
                LogsInformativos(
                  "Se ha registrado el pago a la orden de cliente " + dataTemp.ordenServicio,
                  dataTemp
                );
                toast.success(data.mensaje);
                setShow(false);
              }).catch((error) => {
                console.error("Error al registrar el abono:", error);
                toast.error("Error al registrar el abono.");
              });
            } else if (response.status === 401) {
              // Si la validación falla, mostrar mensaje de error
              toast.error("Las contraseñas no coinciden");
            }
          }).catch((error) => {
            console.error("Error al validar la contraseña:", error);
            toast.error("Error al validar la contraseña.");
          });
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  return (
    <div>
      <div>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Orden de cliente:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control type="text" value={folioOS} disabled />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7" style={{ display: "none" }}>
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Precio inicial:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control value={precioOrden} type="text" disabled />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Precio total por pagar:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control value={totalPagar} type="text" disabled />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Cantidad recibida:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                value={cargo}
                onChange={(e) => {
                  let inputValue = e.target.value;
                  if (/^\d*\.?\d*$/.test(inputValue)) {
                    setCargo(inputValue);
                  }
                }}
                type="number"
                placeholder="Ingresa la cantidad recibida"
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Restante:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control value={restante} type="text" disabled />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>
              Forma de pago:
            </Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              as="select"
              value={formaPago}
              onChange={(e) => setFormaPago(e.target.value)}
              placeholder={"Ingresa forma de pago"}
            >
              <option value="efectivo">Efectivo</option>
              <option value="tarjeta">Tarjeta</option>
              <option value="transferencia">Transferencia</option>
              <option value="cheques">Cheques</option>
              <option value="depositos">Depositos</option>
            </Form.Control>
          </Col>
        </Row>
        {formaPago === "efectivo" && (
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>
                Moneda: <code>*</code>
              </Form.Label>
            </Col>
            <Col
              sm={12}
              md={8}
              lg={8}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Control
                type="text"
                as="select"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                placeholder={"Selecciona la moneda"}
              >
                <option value="MXN">Pesos Mexicanos</option>
                <option value="USD">Dólares</option>
              </Form.Control>
            </Col>
          </Row>
        )}
        {formaPago === "tarjeta" && (
          <>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>
                  Numero de tarjeta: <code>*</code>
                </Form.Label>
              </Col>
              <Col
                sm={12}
                md={8}
                lg={8}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Control
                  type="Number"
                  value={numeroTarjeta}
                  onChange={(e) => setNumeroTarjeta(e.target.value)}
                  placeholder={"Numero de tarjeta"}
                />
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>
                  Mes de expiracion: <code>*</code>
                </Form.Label>
              </Col>
              <Col
                sm={12}
                md={8}
                lg={8}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Control
                  type="Number"
                  value={mesExpiracion}
                  onChange={(e) => setMesExpiracion(e.target.value)}
                  placeholder={"Mes de expiracion"}
                />
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>
                  Año de expiracion: <code>*</code>
                </Form.Label>
              </Col>
              <Col
                sm={12}
                md={8}
                lg={8}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Control
                  type="Number"
                  value={anoExpiracion}
                  onChange={(e) => setAnoExpiracion(e.target.value)}
                  placeholder={"Año de expiracion"}
                />
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>
                  CVV: <code>*</code>
                </Form.Label>
              </Col>
              <Col
                sm={12}
                md={8}
                lg={8}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Control
                  type="Number"
                  value={cvv}
                  onChange={(e) => setCvv(e.target.value)}
                  placeholder={"CVV"}
                />
              </Col>
            </Row>
          </>
        )}
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>
              Contraseña del usuario: <code>*</code>
            </Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              value={contraseñaUsuario}
              onChange={(e) => setContraseñaUsuario(e.target.value)}
              placeholder={"Escribe tu contraseña para registrar el pago"}
            />
          </Col>
        </Row>
        <div style={{ textAlign: "center" }}>
          <Button variant="success" onClick={guardarAbono}>
            <FontAwesomeIcon icon={faCashRegister} /> Registrar pago
          </Button>
        </div>
      </div>
      <Row className="mt-2 mt-md-4 mt-lg-7">
        <Col>
          <h5
            className="mb-3"
            style={{ textAlign: "center", fontStyle: "italic" }}
          >
            Pagos realizados de la orden
          </h5>
          <Table
            striped
            bordered
            hover
            style={{ textAlign: "center", textTransform: "capitalize" }}
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Fecha</th>
                <th>Cantidad</th>
                <th>Metodo de pago</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {abonosOrden.map((abono, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{abono.fecha}</td>
                  <td>{abono.cantidadRecibida}</td>
                  <td>{!abono.metodoPago ? "Efectivo" : abono.metodoPago}</td>
                  <td>{abono.estado == "0" ? "Por aprobar" : "Aprobado"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col>
          <h5
            className="mb-3"
            style={{ textAlign: "center", fontStyle: "italic" }}
          >
            Cargos y descuentos aplicados
          </h5>
          <Table
            striped
            bordered
            hover
            style={{ textAlign: "center", textTransform: "capitalize" }}
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Tipo</th>
                <th>Concepto</th>
                <th>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {cydOrden.map((cyd, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{cyd.tipoOperacion}</td>
                  <td>{cyd.concepto}</td>
                  <td>{cyd.cantidad}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

function formatModelAbonos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      ordenServicio: data.ordenServicio,
      cantidadRecibida: data.cantidadRecibida,
      estado: data.estado,
      fecha: data.fecha,
      metodoPago: data.metodoPago
    });
  });
  return dataTemp;
}

function formatModelCargosyDescuentos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      tipoOperacion: data.tipo,
      concepto: data.concepto,
      cantidad: data.cantidad,
    });
  });
  return dataTemp;
}

export default PagosOrdenesM;
