import { useState, useEffect } from "react";
import { Image, Button, Navbar, Container } from "react-bootstrap";
import logo from "../../assets/img/encabezado_formulario_2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { SwiperPag } from "./Swiper";
import { useNavigate } from "react-router-dom";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import { toast } from "react-toastify";

export const Principal = () => {
  const redirecciona = useNavigate();

  const [idUsuario, setIdeUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setIdeUsuario(data._id);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  return (
    <>
      <Navbar className="barraPrincipal" data-bs-theme="dark">
        <Container>
        <Navbar.Brand>
            <Image
              alt=""
              src={logo}
              width="100%"
              height="100%"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            {idUsuario == "" && (
              <>
                <Button onClick={() => redirecciona("/login")} variant="light" style={{display:"flex", alignItems:"center"}}>
                  <FontAwesomeIcon icon={faRightToBracket} />
                  &nbsp;Ingresar
                </Button>
              </>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div>
        <SwiperPag />
      </div>
      <footer className="footerPrincipal">
        <div className="textFooter">
          <p style={{ marginBottom: 0 }}>DOMICILIO</p> EL ROBLE #38, COLONIA
          SANTA CRUZ NIETO, CP 76804, SAN JUAN DEL RÍO, QRO.
        </div>
      </footer>
    </>
  );
};
