import { Form, Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { Load } from "../load/load";
import { registraCargos } from "../../api/cargos";
import queryString from "query-string";
import { LogsInformativos } from "../logs/logs";
import { listarDepartamentos } from "../../api/departamentos";
import { map } from "lodash";

export default function Cargos({ history, setShow }) {
    const [formData, setFormData] = useState(initialFormValue());

    const [listarDepartamento, setListDepartamento] = useState([]);

    const obtenerDepartamento = () => {
        try {
            listarDepartamentos()
                .then((response) => {
                    const { data } = response;
                    if (data) {
                        setListDepartamento(formatModelDepartamento(data));
                    }
                })
                .catch((e) => { });
        } catch (e) { }
    };

    useEffect(() => {
        obtenerDepartamento();
    }, []);

    //load
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    //insert
    const onSubmit = (e) => {
        e.preventDefault();

        if (!formData.nombre) {
            toast.warning("Completa el formulario");
        } else {
            try {
                setLoading(true);

                const dataTemp = {
                    nombre: formData.nombre,
                    descripcion: formData.descripcion,
                    departamento: formData.departamento,
                    estado: "true"
                };
                registraCargos(dataTemp).then((response) => {
                    const { data } = response;
                    LogsInformativos("Se ha registrado el cargo " + dataTemp.nombre, dataTemp);
                    toast.success(data.mensaje);

                    history({
                        search: queryString.stringify(""),
                    });

                    setLoading(false);
                    setShow(false);
                });
            } catch (e) {
                console.log(e);
            }
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            {loading && <Load />}
            <div className='generalDoctores'>
                <Form className='formDoctores' onSubmit={onSubmit} onChange={onChange}>
                    <Row className="mb-3">
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Nombre: <code>*</code> </Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Ingresa Nombre del puesto'
                                    value={formData.nombre}
                                    name="nombre"
                                />
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Descripción: <code>*</code> </Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Ingresa la descripción del puesto'
                                    defaultValue={formData.descripcion}
                                    name="descripcion"
                                />
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Departamento: <code>*</code> </Form.Label>
                                <Form.Control
                                    as="select"
                                    value={formData.departamento}
                                    name="departamento"
                                >
                                    <option value="">Selecciona una opción</option>
                                    {map(listarDepartamento, (cat, index) => (
                                        <option key={index} value={cat.nombre}>
                                            {cat.nombre}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>

                    <div className="divSubmit">
                        <Button variant="primary" type="submit">
                            Enviar
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    );
}

function initialFormValue() {
    return {
        nombre: "",
        descripcion: "",
        departamento: "",
    };
}

function formatModelDepartamento(data) {
    return data.map((data) => ({
        id: data._id,
        folio: data.folio,
        nombre: data.nombre,
        tipo: data.tipo,
        estado: data.estado,
    }));
}
