import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import {
  listarMovimientosAPL,
  listarMovimientosAPLFiltroFecha,
} from "../../api/movimientosAlmacenPL";
import { obtenerAlmacenProductosLimpieza } from "../../api/almacenProductosLimpieza";
import { withRouter } from "../../utils/withRouter";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Load } from "../load/load";
import AlmacenAPL from "./insertar";
import { useParams } from "react-router-dom";
import { Button, Form, Row, Col } from "react-bootstrap";
import { faPlus, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ViewPDF from "./pdf";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function TblMovimientosAPL({props}) {
  console.log(props)

  const idArticuloAPL = props;

  //load
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarAlmacen, setListAlmacen] = useState([]);

  const obtenerAlmacen = () => {
    try {
      listarMovimientosAPL(idArticuloAPL)
        .then((response) => {
          const { data } = response;

          if (!listarAlmacen && data) {
            setListAlmacen(formatModelAlmacen(data));
          } else {
            const datosAlmacen = formatModelAlmacen(data);
            setListAlmacen(datosAlmacen);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerAlmacen();
  }, []);

  const [nombreProducto, setNombreProducto] = useState("");

  const obtenerNombreProducto = () => {
    try {
      obtenerAlmacenProductosLimpieza(idArticuloAPL)
        .then((response) => {
          const { data } = response;
          setNombreProducto(data.producto);
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerNombreProducto();
  }, []);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarAlmacen);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "idArticuloAPL",
      label: "ID DEL ARTICULO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "producto",
      label: "PRODUCTO",
    },
    {
      name: "tipo",
      label: "TIPO",
    },
    {
      name: "cantidadMovimiento",
      label: "CANTIDAD MOVIMIENTO",
    },
    {
      name: "unidadMedida",
      label: "UNIDAD DE MEDIDA",
    },
    {
      name: "departamento",
      label: "DEPARTAMENTO",
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && 'standard',
    selectableRows: isSmOrDown ? 'none' : 'multiple',
  };

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroMovimiento = (content) => {
    setTitulosModal("Registrar movimiento para el producto " + nombreProducto);
    setContentModal(content);
    setShowModal(true);
  };

  const pdfMovimientos = (content) => {
    setTitulosModal("PDF de los movimientos del producto " + nombreProducto);
    setContentModal(content);
    setShowModal(true);
  };

  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");

  const handleInputChangeInicio = (e) => {
    setFechaInicio(e.target.value);
  };

  const handleInputChangeFin = (e) => {
    setFechaFin(e.target.value);
  };

  const [listarAlmacenFiltro, setListAlmacenFiltro] = useState([]);

  const obtenerAlmacenFiltroFecha = () => {
    try {
      listarMovimientosAPLFiltroFecha(idArticuloAPL, fechaInicio, fechaFin)
        .then((response) => {
          const { data } = response;

          if (!listarAlmacenFiltro && data) {
            setListAlmacenFiltro(formatModelAlmacen(data));
          } else {
            const datosAlmacen = formatModelAlmacen(data);
            setListAlmacenFiltro(datosAlmacen);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerAlmacenFiltroFecha();
  }, [fechaInicio, fechaFin]);

  return (
    <>
      {loading && <Load />}
      <h1>
        Movimientos del producto {nombreProducto} en el almacen de insumos
      </h1>
      <div
        style={{
          width: "100%",
          marginLeft: "2%",
          marginRight: "2%",
          marginTop: "4vh",
        }}
      >
        <Form.Group>
          <Row>
            <Col xs={12} md={6} lg={6}>
              <Row style={{ textAlign: "center" }}>
                <Col xs={12} md={6} lg={6}>
                  <Form.Label>Fecha Inicio:</Form.Label>
                  <Form.Control
                    placeholder="cantidad del movimiento"
                    type="date"
                    name="fechaInicio"
                    value={fechaInicio}
                    onChange={handleInputChangeInicio}
                  />
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <Form.Label>Fecha Fin:</Form.Label>
                  <Form.Control
                    placeholder="cantidad del movimiento"
                    type="date"
                    name="fechaFin"
                    value={fechaFin}
                    onChange={handleInputChangeFin}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <Row >
                <Col xs={12} md={6} lg={6}>
                  <Button
                    variant="danger"
                    onClick={() =>
                      pdfMovimientos(
                        <ViewPDF
                          setShow={setShowModal}
                          listAlmacen={listarAlmacenFiltro}
                        />
                      )
                    }
                    style={{ marginRight: "1vw" }}
                  >
                    <FontAwesomeIcon icon={faFilePdf} /> Descargar PDF
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form.Group>
      </div>
      <div className="divTabla">
        <MUIDataTable
          title={
            "Lista de movimientos del producto " +
            nombreProducto +
            " en almacen de insumos"
          }
          data={listarAlmacen}
          columns={columns}
          options={options}
        />
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function formatModelAlmacen(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      idArticuloAPL: data.idArticuloAPL,
      tipo: data.tipo,
      producto: data.producto,
      cantidadMovimiento: data.cantidadMovimiento,
      unidadMedida: data.unidadMedida,
      departamento: data.departamento,
      fecha: data.createdAt,
    });
  });
  return dataTemp;
}

export default withRouter(TblMovimientosAPL);
