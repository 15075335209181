import { useState, useCallback, useEffect } from "react";
import { Image } from "react-bootstrap";
import ImageViewer from "react-simple-image-viewer";
import { listarOrdenesServicio } from "../../api/ordenesServicio";

const ImageVisualizer = ({ idOrdenSel }) => {
  const [listarOrdenes, setListOrdenes] = useState([]);
  const [linksimagenes, setlinksimagenes] = useState([]);
  const [folioOrden, setfolioOrden] = useState("")

  const obtieneOrdenID = () => {
    try {
      listarOrdenesServicio()
        .then((response) => {
          if (response && response.data) {
            const { data } = response;

            if (!listarOrdenes && data) {
              setListOrdenes(formatModelOrdenes(data));
            } else {
              const datosOrdenes = formatModelOrdenes(data);
              const filterID = datosOrdenes.find(
                (data) => data.id === idOrdenSel
              );

              if (filterID) {
                const selectedFields = [
                  filterID.imagen1,
                  filterID.imagen2,
                  filterID.imagen3,
                  filterID.imagen4,
                  filterID.imagen5,
                ];
                const filteredImages = selectedFields.filter(
                  (image) => image !== undefined
                );
                setfolioOrden(filterID.folio)

                setlinksimagenes(filteredImages);
                console.log(filterID);
              } else {
                console.log(
                  "No se encontró ningún elemento con el ID especificado"
                );
              }
            }
          } else {
            console.error("Error: No se recibieron datos válidos");
          }
        })
        .catch((error) => {
          console.error("Error al obtener datos:", error);
        });
    } catch (e) {
      console.error("Error en la función obtieneOrdenID:", e);
    }
  };

  useEffect(() => {
    obtieneOrdenID();
    console.log(linksimagenes);
  }, [idOrdenSel]);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = linksimagenes;

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div style={{ padding: "1rem" }}>
      <h2 style={{textAlign:"center"}}>{folioOrden}</h2>

      {linksimagenes.length > 0 ? (
        linksimagenes.map((src, index) => (
          <Image
            src={src}
            onClick={() => openImageViewer(index)}
            width="300"
            key={index}
            style={{ margin: "2px" }}
            alt=""
          />
        ))
      ) : (
        <div className="errorSesionBody">
        <div className="error-container">
        <div className="error-text">😵</div>
          <h1 className="error-title">ERROR!!!</h1>
          <p className="error-message">
            No hay imagenes para esta orden
          </p>
        </div>
      </div>
      )}

      {isViewerOpen && (
        <div style={{ position: "absolute", zIndex: "9999" }}>
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
            style={{ position: "absolute", zIndex: 9999 }}
          />
        </div>
      )}
    </div>
  );
};

export default ImageVisualizer;

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      imagen1: data.imagen1,
      imagen2: data.imagen2,
      imagen3: data.imagen3,
      imagen4: data.imagen4,
      imagen5: data.imagen5,
    });
  });
  return dataTemp;
}
