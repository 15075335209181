import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { actualizarMaterialesCatalogos } from "../../../api/materialesCat";
import { toast } from "react-toastify";
import { listarDepartamentos, listarPorTipoDepartamento } from "../../../api/departamentos";
import queryString from "query-string";

const ModificarMaterialesCat = ({ data, history, setShow }) => {
  const [folio, setfolio] = useState(data[1]);
  const [nombreMaterial, setnombreMaterial] = useState(data[2])
  const [departamento, setDepartamento] = useState(data[5]);
  const [descripcion, setDescripcion] = useState(data[3]);

  const [listarDepartamento, setListDepartamento] = useState([]);

  const obtenerDepartamento = () => {
    try {
      listarPorTipoDepartamento("Produccion")
        .then((response) => {
          const { data } = response;

          if (!listarDepartamento && data) {
            setListDepartamento(formatModelDepartamento(data));
          } else {
            const datosDepartamento = formatModelDepartamento(data);
            setListDepartamento(datosDepartamento);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerDepartamento()
  }, [])


  const registrarMaterial = async () => {
    try {
      if (!nombreMaterial, !descripcion, !departamento) {
        toast.success("Completa el formulario")
      } else {
        const dataTemp = {
          folio: folio,
          nombreMaterial: nombreMaterial,
          descripcion: descripcion,
          folioDepartamento: departamento,
          estado: "activo",
          leido: 0,
        };
        actualizarMaterialesCatalogos(data[0], dataTemp).then((response) => {
          const { data } = response;
          history({
            search: queryString.stringify(""),
          });
          toast.success(data.mensaje)
          setShow(false);
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Form>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            xs={12}
            md={3}
            lg={3}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Folio: <code>*</code></Form.Label>
          </Col>
          <Col xs={12} md={9} lg={9}>
            <Form.Control
              type="text"
              placeholder="Folio del material"
              value={folio}
              disabled
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            xs={12}
            md={3}
            lg={3}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Nombre: <code>*</code></Form.Label>
          </Col>
          <Col xs={12} md={9} lg={9}>
            <Form.Control
              type="text"
              placeholder="Nombre del material"
              value={nombreMaterial}
              onChange={(e) => setnombreMaterial(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            xs={12}
            md={3}
            lg={3}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Departamento: <code>*</code></Form.Label>
          </Col>
          <Col xs={12} md={9} lg={9}>
            <Form.Control as="select" value={departamento}
              onChange={(e) => setDepartamento(e.target.value)}>
              <option value="">Seleccionar departamento</option>
              {listarDepartamento.map((departamento) => (
                <option value={departamento.folio}>{departamento.nombre}</option>
              ))}
            </Form.Control>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            xs={12}
            md={3}
            lg={3}
            className="d-flex align-items-start justify-content-end justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Descripción:</Form.Label>
          </Col>
          <Col xs={12} md={9} lg={9}>
            <Form.Control
              as="textarea"
              placeholder="Descripción del material"
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
            />
          </Col>
        </Row>
      </Form>
      <div className="d-flex justify-content-center">
        <Button variant="success" onClick={registrarMaterial}>
          <FontAwesomeIcon icon={faFloppyDisk} /> Registrar
        </Button>
      </div>
    </div>
  );
};

function formatModelDepartamento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      folio: data.folio,
      nombre: data.nombre,
    });
  });
  return dataTemp;
}

export default ModificarMaterialesCat;
