import { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import regimenFiscalData from "../catalogosJSON/regimenFiscal.json";
import estadosPaisData from "../catalogosJSON/estadosPais.json";
import municipiosEstadoData from "../catalogosJSON/municipiosEstadosPais.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCancel,
  faFloppyDisk,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { actualizarFacturacion } from "../../../api/facturacion";
import { listarDoctores, actualizarDoctores, listarDoctoresPorCorreoDoctor } from "../../../api/doctores";

const FacturacionPerfil = ({ datos }) => {
  const [inputDisabled, setInputDisabled] = useState(true);

  const toggleInput = () => {
    setInputDisabled(!inputDisabled);
  };

  const [estadoPais, setEstadoPais] = useState(datos[0].estadoPais);

  const handleInputChangeEstado = (event) => {
    const nuevoEstado = event.target.value;
    setEstadoPais(nuevoEstado);
  };

  const [municipioSeleccionado, setMunicipioSeleccionado] = useState(
    datos[0].municipio
  );

  const handleInputChangeMunicipio = (e) => {
    setMunicipioSeleccionado(e.target.value);
  };

  const usuarioReg = datos[0].id;
  const [usuarioRFC, setUsuarioRFC] = useState(datos[0].rfc);
  const [razonSocial, setRazonSocial] = useState(datos[0].razonSocial);
  const [regimenFiscal, setRegimenFiscal] = useState(datos[0].regimenFiscal);
  const [calleDom, setcalleDom] = useState(datos[0].calle);
  const [numExtDom, setnumExtDom] = useState(datos[0].numeroExterior);
  const [numIntDom, setnumIntDom] = useState(datos[0].numeroInterior);
  const [coloniaDom, setcoloniaDom] = useState(datos[0].colonia);
  const [cpDom, setcpDom] = useState(datos[0].codigoPostal);
  const [observacionesFact, setobservacionesFact] = useState(
    datos[0].observaciones
  );
  const [tipoPersona, setTipoPersona] = useState(datos[0].tipoPersona);
  const [cfdi, setCfdi] = useState(datos[0].usoCfdi);
  const [referencia, setReferencia] = useState(datos[0].referencia);
  const [idUsuarioDatos, setIdUsuarioDatos] = useState(datos[0].idUsuarioDatos);

  const [listarOrdenes, setListOrdenes] = useState([]);

  const obtenerOrdenes = () => {
    try {
      listarDoctoresPorCorreoDoctor(idUsuarioDatos)
        .then((response) => {
          const { data } = response;

          if (!listarOrdenes && data) {
            setListOrdenes((data));
          } else {
            const datosOrdenes = (data);
            setListOrdenes(datosOrdenes);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerOrdenes();
  }, [idUsuarioDatos]);

  const actualizarRegistro = () => {
    try {
      const dataTemp = {
        regimenFiscal: regimenFiscal,
        calle: calleDom,
        numeroExterior: numExtDom,
        numeroInterior: numIntDom,
        codigoPostal: cpDom,
        colonia: coloniaDom,
        municipio: municipioSeleccionado,
        estadoPais: estadoPais,
        observaciones: observacionesFact,
        tipoPersona: tipoPersona,
        usoCfdi: cfdi,
        referencia: referencia
      };

      const dataTemp2 = {
        razonSocial: razonSocial,
        tipoPersona: tipoPersona,
        regimenFiscal: regimenFiscal,
        cdfi: cfdi,
        calleFiscal: calleDom,
        nExteriorFiscal: numExtDom,
        nInteriorFiscal: numIntDom,
        coloniaFiscal: coloniaDom,
        estadoDomFiscal: estadoPais,
        municipioFiscal: municipioSeleccionado,
        cPostalFiscal: cpDom,
        referenciaFiscal: referencia,
      }

      actualizarFacturacion(usuarioReg, dataTemp).then((response) => {
        actualizarDoctores(listarOrdenes[0]?._id, dataTemp2)
        const { data } = response;
        toast.success(data.mensaje);
        setInputDisabled(!inputDisabled);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="facturacionGeneral">
      <Form className="formFacturacion">
        <div style={{ textAlign: "right", marginBottom: "2%" }}>
          <Button
            variant={inputDisabled ? "warning" : "danger"}
            onClick={toggleInput}
          >
            {inputDisabled ? (
              <>
                <FontAwesomeIcon icon={faPen} /> Editar
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faCancel} /> Cancelar
              </>
            )}
          </Button>
        </div>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Razón Social:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="text"
              className="inpRegimen"
              placeholder="Ingresa Razón Social"
              value={razonSocial}
              onChange={(e) => setRazonSocial(e.target.value)}
              name="razonSocial"
              disabled
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>RFC:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="text"
              className="inpRegimen"
              placeholder="Ingresa RFC"
              value={usuarioRFC}
              onChange={(e) => setUsuarioRFC(e.target.value)}
              name="rfc"
              disabled
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Tipo de persona: <code>*</code></Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              as="select"
              className="inpRegimen"
              placeholder="Tipo de persona"
              name="tipoPersona"
              value={tipoPersona}
              onChange={(e) => setTipoPersona(e.target.value)}
              disabled={inputDisabled}
            >
              <option>Elige una opción</option>
              <option
                value="Fisica"
                selected={tipoPersona === "Fisica"}
              >
                Fisica
              </option>
              <option
                value="Moral"
                selected={tipoPersona === "Moral"}
              >
                Moral
              </option>
            </Form.Control>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Régimen Fiscal: <code>*</code></Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              as="select"
              className="inpRegimen"
              value={regimenFiscal}
              onChange={(e) => setRegimenFiscal(e.target.value)}
              disabled={inputDisabled}
            >
              <option>Selecciona un Régimen Fiscal</option>
              {tipoPersona === "Moral" && (
                <>
                  <option
                    value="601-General de Ley Personas Morales"
                    selected={
                      regimenFiscal ===
                      "601-General de Ley Personas Morales"
                    }
                  >
                    601-General de Ley Personas Morales
                  </option>
                  <option
                    value="603-Personas Morales con Fines no Lucrativos"
                    selected={
                      regimenFiscal ===
                      "603-Personas Morales con Fines no Lucrativos"
                    }
                  >
                    603-Personas Morales con Fines no Lucrativos
                  </option>
                  <option
                    value="607-Régimen de Enajenación o Adquisición de Bienes"
                    selected={
                      regimenFiscal ===
                      "607-Régimen de Enajenación o Adquisición de Bienes"
                    }
                  >
                    607-Régimen de Enajenación o Adquisición de Bienes
                  </option>
                  <option
                    value="609-Consolidación"
                    selected={
                      regimenFiscal === "609-Consolidación"
                    }
                  >
                    609-Consolidación
                  </option>
                  <option
                    value="610-Residentes en el Extranjero sin Establecimiento Permanente en México"
                    selected={
                      regimenFiscal === "610-Residentes en el Extranjero sin Establecimiento Permanente en México"
                    }
                  >
                    610-Residentes en el Extranjero sin Establecimiento Permanente en México
                  </option>
                  <option
                    value="620-Sociedades Cooperativas de Producción que optan por Diferir sus Ingresos"
                    selected={
                      regimenFiscal ===
                      "620-Sociedades Cooperativas de Producción que optan por Diferir sus Ingresos"
                    }
                  >
                    620-Sociedades Cooperativas de Producción que optan
                    por Diferir sus Ingresos
                  </option>
                  <option
                    value="622-Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras"
                    selected={
                      regimenFiscal ===
                      "622-Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras"
                    }
                  >
                    622-Actividades Agrícolas, Ganaderas, Silvícolas y
                    Pesqueras
                  </option>
                  <option
                    value="623-Opcional para Grupos de Sociedades"
                    selected={
                      regimenFiscal ===
                      "623-Opcional para Grupos de Sociedades"
                    }
                  >
                    623-Opcional para Grupos de Sociedades
                  </option>
                  <option
                    value="624-Coordinados"
                    selected={regimenFiscal === "624-Coordinado"}
                  >
                    624-Coordinados
                  </option>
                  <option
                    value="628-Hidrocarburos"
                    selected={
                      regimenFiscal === "628-Hidrocarburos"
                    }
                  >
                    628-Hidrocarburos
                  </option>
                </>
              )}
              {tipoPersona === "Fisica" && (
                <>
                  <option value="605-Sueldos y Salarios e Ingresos Asimilados a Salarios">
                    605-Sueldos y Salarios e Ingresos Asimilados a
                    Salarios
                  </option>
                  <option value="606-Arrendamiento">
                    606-Arrendamiento
                  </option>
                  <option value="608-Demás ingresos">
                    608-Demás ingresos
                  </option>
                  <option value="610-Residentes en el Extranjero sin Establecimiento Permanente en México"
                  >
                    610-Residentes en el Extranjero sin Establecimiento Permanente en México
                  </option>
                  <option value="611-Ingresos por Dividendos (socios y accionistas)">
                    611-Ingresos por Dividendos (socios y accionistas)
                  </option>
                  <option value="612-Personas Físicas con Actividades Empresariales y Profesionales">
                    612-Personas Físicas con Actividades Empresariales y
                    Profesionales
                  </option>
                  <option value="614-Ingresos por intereses">
                    614-Ingresos por intereses
                  </option>
                  <option value="615-Régimen de los ingresos por obtención de premios">
                    615-Régimen de los ingresos por obtención de premios
                  </option>
                  <option value="616-Sin obligaciones fiscales">
                    616-Sin obligaciones fiscales
                  </option>
                  <option value="621-Incorporación Fiscal">
                    621-Incorporación Fiscal
                  </option>
                  <option value="622-Actividades Agrícolas, Ganaderas, Silvícolas y
                              Pesqueras">
                    622-Actividades Agrícolas, Ganaderas, Silvícolas y
                    Pesqueras
                  </option>
                  <option value="625-Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas">
                    625-Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas
                  </option>
                  <option value="626 - Régimen Simplificado de Confianza">
                    626 - Régimen Simplificado de Confianza
                  </option>
                  <option value="629-De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales">
                    629-De los Regímenes Fiscales Preferentes y de las
                    Empresas Multinacionales
                  </option>
                  <option value="630-Enajenación de acciones en bolsa de valores">
                    630-Enajenación de acciones en bolsa de valores
                  </option>
                </>
              )}
            </Form.Control>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Uso CFDI: <code>*</code></Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              as="select"
              value={cfdi}
              onChange={(e) => setCfdi(e.target.value)}
              name="cfdi"
              disabled={inputDisabled}
            >
              <option>Elige una opción</option>
              {tipoPersona === "Moral" && (
                <>
                  <option value="G01-Adquisición de mercancías">
                    G01-Adquisición de mercancías
                  </option>
                  <option value="G02-Devoluciones, descuentos o bonificaciones">
                    G02-Devoluciones, descuentos o bonificaciones
                  </option>
                  <option value="G03-Gastos en general">
                    G03-Gastos en general
                  </option>
                  <option value="I01-Construcciones">
                    I01-Construcciones
                  </option>
                  <option value="I02-Mobiliario y equipo de oficina por inversiones">
                    I02-Mobiliario y equipo de oficina por inversiones
                  </option>
                  <option value="I03-Equipo de transporte">
                    I03-Equipo de transporte
                  </option>
                  <option value="I04-Equipo de computo y accesorios">
                    I04-Equipo de computo y accesorios
                  </option>
                  <option value="I05-Dados, troqueles, moldes, matrices y herramental">
                    I05-Dados, troqueles, moldes, matrices y herramental
                  </option>
                  <option value="I06-Comunicaciones telefónicas.">
                    I06-Comunicaciones telefónicas
                  </option>
                  <option value="I07-Comunicaciones satelitales">
                    I07-Comunicaciones satelitales
                  </option>
                  <option value="I08-Otra maquinaria y equipo">
                    I08-Otra maquinaria y equipo
                  </option>
                  <option value="S01-Sin efectos fiscales">
                    S01-Sin efectos fiscales
                  </option>
                  <option value="CP01-Pagos">CP01-Pagos</option>
                </>
              )}
              {tipoPersona === "Fisica" && (
                <>
                  <option value="G01-Adquisición de mercancías">
                    G01-Adquisición de mercancías
                  </option>
                  <option value="G02-Devoluciones, descuentos o bonificaciones">
                    G02-Devoluciones, descuentos o bonificaciones
                  </option>
                  <option value="G03-Gastos en general">
                    G03-Gastos en general
                  </option>
                  <option value="I01-Construcciones">
                    I01-Construcciones
                  </option>
                  <option value="I02-Mobiliario y equipo de oficina por inversiones">
                    I02-Mobiliario y equipo de oficina por inversiones
                  </option>
                  <option value="I03-Equipo de transporte">
                    I03-Equipo de transporte
                  </option>
                  <option value="I04-Equipo de computo y accesorios">
                    I04-Equipo de computo y accesorios
                  </option>
                  <option value="I05-Dados, troqueles, moldes, matrices y herramental">
                    I05-Dados, troqueles, moldes, matrices y herramental
                  </option>
                  <option value="I06-Comunicaciones telefónicas.">
                    I06-Comunicaciones telefónicas
                  </option>
                  <option value="I07-Comunicaciones satelitales">
                    I07-Comunicaciones satelitales
                  </option>
                  <option value="I08-Otra maquinaria y equipo">
                    I08-Otra maquinaria y equipo
                  </option>
                  <option value="D01-Honorarios médicos, dentales y gastos hospitalarios">
                    D01-Honorarios médicos, dentales y gastos hospitalarios
                  </option>
                  <option value="D02-Gastos médicos por incapacidad o discapacidad">
                    D02-Gastos médicos por incapacidad o discapacidad
                  </option>
                  <option value="D03-Gastos funerales">
                    D03-Gastos funerales
                  </option>
                  <option value="D04-Donativos">D04-Donativos</option>
                  <option value="D05-Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)">
                    D05-Intereses reales efectivamente pagados por créditos
                    hipotecarios (casa habitación)
                  </option>
                  <option value="D06-Aportaciones voluntarias al SAR">
                    D06-Aportaciones voluntarias al SAR
                  </option>
                  <option value="D07-Primas por seguros de gastos médicos">
                    D07-Primas por seguros de gastos médicos
                  </option>
                  <option value="D08-Gastos de transportación escolar obligatoria">
                    D08-Gastos de transportación escolar obligatoria
                  </option>
                  <option value="D09-Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones">
                    D09-Depósitos en cuentas para el ahorro, primas que
                    tengan como base planes de pensiones
                  </option>
                  <option value="D10-Pagos por servicios educativos (colegiaturas)">
                    D10-Pagos por servicios educativos (colegiaturas)
                  </option>
                  <option value="S01-Sin efectos fiscales">
                    S01-Sin efectos fiscales
                  </option>
                  <option value="CP01-Pagos">CP01-Pagos</option>
                  <option value="CN01-Nómina">CN01-Nómina</option>
                </>
              )}
            </Form.Control>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Calle:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="text"
              placeholder="Ingresa Calle"
              value={calleDom}
              onChange={(e) => setcalleDom(e.target.value)}
              name="calle"
              disabled={inputDisabled}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Número Exterior: </Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="number"
              placeholder="Ingresa número exterior"
              value={numExtDom}
              onChange={(e) => setnumExtDom(e.target.value)}
              name="numeroExterior"
              disabled={inputDisabled}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Número Interior:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="number"
              placeholder="Ingresa número interior"
              value={numIntDom}
              onChange={(e) => setnumIntDom(e.target.value)}
              name="numeroInterior"
              disabled={inputDisabled}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Colonia:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="text"
              placeholder="Ingresa Colonia"
              value={coloniaDom}
              onChange={(e) => setcoloniaDom(e.target.value)}
              name="colonia"
              disabled={inputDisabled}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Estado:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            {inputDisabled === false ? (
              <Form.Control
                as="select"
                value={estadoPais}
                onChange={handleInputChangeEstado}
              >
                <option>Selecciona un Estado</option>
                {estadosPaisData.map((estado) => (
                  <option key={estado.clave} value={estado.nombre}>
                    {estado.nombre}
                  </option>
                ))}
              </Form.Control>
            ) : (
              <Form.Control
                type="text"
                className="inpRegimen"
                style={{ textTransform: "capitalize", wordWrap: "normal" }}
                value={estadoPais}
                disabled={inputDisabled}
              />
            )}
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Municipio: </Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            {inputDisabled === false ? (
              <Form.Control
                as="select"
                value={municipioSeleccionado}
                onChange={(e) => setMunicipioSeleccionado(e.target.value)}
              >
                <option>Selecciona un Municipio</option>
                {municipiosEstadoData[estadoPais] &&
                  municipiosEstadoData[estadoPais].map((municipio) => (
                    <option key={municipio} value={municipio}>
                      {municipio}
                    </option>
                  ))}
              </Form.Control>
            ) : (
              <Form.Control
                type="text"
                className="inpRegimen"
                value={municipioSeleccionado}
                disabled={inputDisabled}
              />
            )}
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Código Postal:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              type="number"
              placeholder="Codigo Postal"
              value={cpDom}
              onChange={(e) => setcpDom(e.target.value)}
              name="codigoPostal"
              disabled={inputDisabled}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Observaciones:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control
              as="textarea"
              rows={3}
              value={observacionesFact}
              placeholder="Observaciones"
              onChange={(e) => setobservacionesFact(e.target.value)}
              name="observaciones"
              disabled={inputDisabled}
            />
          </Col>
        </Row>
        {inputDisabled === false && (
          <div className="divSubmit">
            <Button variant="success" onClick={actualizarRegistro}>
              <FontAwesomeIcon icon={faFloppyDisk} /> Guardar Cambios
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default FacturacionPerfil;
