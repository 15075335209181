import { useState } from "react";
import "./style.css";
import logo from "../../assets/img/isologo_1.png";
import { Form, Image, Button, Modal, Row, Col } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { setTokenApi, login } from "../../api/auth";
import { isEmailValid } from "../../utils/validations";
import { obtenerUsuario } from "../../api/usuarios";
import { useNavigate } from "react-router-dom";
import { SwiperPag } from "../principal/Swiper";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Registro } from "./Registro";
import { LogsInformativos } from "../logs/logs";
import { Link } from 'react-router-dom';

export const Login = () => {

  const [formData, setFormData] = useState(initialFormValue);
  const [signInLoading, setSignInLoading] = useState(false);

  const [mostrarPassword, setMostrarPassword] = useState(false);
  const togglePasswordVisiblity = () => {
    setMostrarPassword((val) => !val);
  };

  const enrutamiento = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email || !formData.password) {
      toast.warning("Completa todos los campos del formulario.");
    } else {
      if (!isEmailValid(formData.email)) {
        toast.warning("Correo no valido");
      } else {
        setSignInLoading(true);
        try {
          login(formData)
            .then((response) => {
              const {
                data: { token },
              } = response;
              setTokenApi(token);
              const { _ } = jwtDecode(token);
              const idUdsuario = _;
              try {
                obtenerUsuario(idUdsuario).then(
                  ({ data: { nombre, apellidos } }) => {
                    toast.success("Bienvenido " + nombre);
                    LogsInformativos("Se ha iniciado sesion con el usuario " + nombre)
                  }
                );
                enrutamiento("/Dashboard");
              } catch (ex) {
                toast.error("Error al obtener el usuario");
              }
            })
            .catch((ex) => {
              if (ex.message === "Network Error") {
                toast.error("Conexión al servidor no disponible");
                setSignInLoading(false);
              } else {
                if (ex.response && ex.response.status === 401) {
                  const { mensaje } = ex.response.data;
                  toast.error(mensaje);
                  setSignInLoading(false);
                }
              }
            });
        } catch (ex) {
          toast.error("Error al iniciar sesión");
          setSignInLoading(false);
        }
      }
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);
  const [size, setSizeModal] = useState(null);

  const registroUsuario = (content) => {
    setTitulosModal("Registrar usuario");
    setContentModal(content);
    setShowModal(true);
    //setSizeModal("lg");
  }

  return (
    <div className="containerInicio">
      <div className="containerIni">
        {/* Stack the columns on mobile by making one full-width and the other half-width */}
        <Row>
          <Col xs={1} md={8} className="d-none d-md-block">
            <div className="swiperDiv">
              <SwiperPag />
            </div>
          </Col>
          <Col xs={11} md={4} className="columnLogin d-md-block">
            <div className="containerLogin">
              <div className="screenLogin">
                <div className="screen__content">
                  <div className="divLogo">
                    <Image src={logo} className="imgLogo" />
                  </div>
                  <Form
                    className="login"
                    onSubmit={onSubmit}
                    onChange={onChange}
                  >
                    <div className="login__field">
                      <i className="login__icon fas fa-user" />
                      <Form.Control
                        type="text"
                        className="login__input inpUserLogin"
                        placeholder="Usuario"
                        defaultValue={formData.email}
                        name="email"
                      />
                    </div>
                    <div className="login__field divPassLogin">
                      <i className="login__icon fas fa-lock" />
                      <Form.Control
                        type={mostrarPassword ? "text" : "password"}
                        className="login__input inpPassLogin"
                        placeholder="Contraseña"
                        defaultValue={formData.password}
                        name="password"
                      />
                      <FontAwesomeIcon
                        className="cursor-pointer iconMosPass py-2 -ml-6"
                        icon={!mostrarPassword ? faEyeSlash : faEye}
                        onClick={togglePasswordVisiblity}
                        title={
                          !mostrarPassword
                            ? "Mostar contraseña"
                            : "Ocultar contraseña"
                        }
                      />
                    </div>
                    <div className="btnIniciaSL">
                      <button className="button login__submit">
                        <span className="button__text">Iniciar sesión</span>
                        <i className="button__icon fas fa-chevron-right" />
                      </button>
                    </div>
                    <div className="newUserDiv">
                      <Link to="https://app.grupodentalherfran.com/registroCliente" target="_blank" rel="noopener noreferrer">
                        <Button variant="link">
                          Registrarse
                        </Button>
                      </Link>
                    </div>
                  </Form>
                </div>
                <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
                  {contentModal}
                </BasicModal>
              </div>
            </div>
          </Col>
        </Row >
      </div >
    </div >
  );
};

function initialFormValue() {
  return {
    email: "",
    password: ""
  };
}
