import {
  faCartPlus,
  faHashtag,
  faTrash,
  faArrowRight,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { listarProveedores } from "../../api/proveedores";
import Select from "react-select";
import { toast } from "react-toastify";
import { listarProductosAgotandose, listarAlmacenMateriasPrimasNombre, totalMateriasPrimas } from "../../api/almacenMateriasPrimas";
import { listarDepartamentos } from "../../api/departamentos";
import { LogsInformativos } from "../logs/logs";
import { obtenerUsuario } from "../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MUIDataTable from "mui-datatables";
import { obtenerNumeroFacturasMostrador, registraFacturasMostrador } from "../../api/facturasMostrador";
import { listarOrdenesServicio, listarOrdenesServicioPorCorreoDoctor } from "../../api/ordenesServicio";
import { listarFacturacion } from "../../api/facturacion";
import { obtenerTasaCambio } from "../../api/conversionMoneda";
import { listarServiciosFolio } from "../../api/servicios";
import queryString from "query-string";
import { actualizaFacturacionOrdenes } from "../../api/ordenesServicio";

const Registro = ({ setShow, history }) => {

  const [nombreProd, setNombreProd] = useState("");

  const [productosAgregados, setProductosAgregados] = useState([]);

  const [listarProducto, setListProducto] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [noTotalProductos, setNoTotalProductos] = useState(1);

  const [listDoctores, setListDoctores] = useState([]);

  const obtenerDoctores = () => {
    try {
      listarFacturacion()
        .then((response) => {
          const { data } = response;

          if (!listDoctores && data) {
            setListDoctores(formatModelDoctores(data));
          } else {
            const datosViaje = formatModelDoctores(data);
            setListDoctores(datosViaje);
          }
        })
        .catch((e) => {
          // Manejo de errores si es necesario
        });
    } catch (e) {
      // Manejo de errores si es necesario
    }
  };

  const [listServicios, setListServicios] = useState([]);

  const obtenerServicios = () => {
    try {
      const promises = productosAgregados.map((producto) => {
        return listarServiciosFolio(producto.clave)
          .then((response) => {
            const { data } = response;
            return formatModelServicios(data);
          })
          .catch((e) => {
            console.error(`Error fetching data for clave: ${producto.clave}`, e);
            return null;
          });
      });

      Promise.all(promises)
        .then((results) => {
          const nuevosServicios = results
            .filter((result) => result !== null)
            .flat(); // Aplanamos los arrays en caso de datos anidados

          // Filtrar los servicios que ya existen en listServicios
          setListServicios((prevListServicios) => {
            const idsExistentes = new Set(prevListServicios.map((servicio) => servicio.id)); // Cambia 'id' por el identificador único

            // Solo agregar servicios que no estén en la lista actual
            const serviciosFiltrados = nuevosServicios.filter(
              (servicio) => !idsExistentes.has(servicio.id)
            );

            return [...prevListServicios, ...serviciosFiltrados];
          });
        })
        .catch((error) => {
          console.error("Error al procesar las solicitudes", error);
        });
    } catch (e) {
      console.error("Error en obtenerServicios", e);
    }
  };

  useEffect(() => {
    obtenerServicios();
  }, [productosAgregados]);


  console.log(listServicios)

  useEffect(() => {
    obtenerDoctores();
  }, []);

  const obtenerProductos = (nombre) => {
    try {
      totalMateriasPrimas()
        .then((response) => {
          const { data } = response;
          setNoTotalProductos(data);
        })
        .catch((e) => {
          console.log(e);
        });

      listarAlmacenMateriasPrimasNombre(nombre, page, rowsPerPage)
        .then((response) => {
          const { data } = response;
          console.log(data)
          if (!listarProducto && data) {
            setListProducto(formatModelProductos(data));
            console.log(formatModelProductos(data))
          } else {
            const datosProducto = formatModelProductos(data);
            console.log(formatModelProductos(data))
            setListProducto(datosProducto);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const handleChangePage = (page) => {
    // console.log("Nueva pagina "+ newPage)
    setPage(page);
  };

  const handleChangeRowsPerPage = (newPerPage) => {
    // console.log("Registros por pagina "+ parseInt(event.target.value, 10))
    setRowsPerPage(newPerPage);
    //setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (nombreProd != "") {
      obtenerProductos(nombreProd);
    }
  }, [nombreProd, page, rowsPerPage]);

  const [departamento, setDepartamento] = useState("");
  const [solicita, setSolicita] = useState("");
  const [observaciones, setObservaciones] = useState("");

  const [tipoFactura, setTipoFactura] = useState("");
  const [tipoCliente, setTipoCliente] = useState("");
  const [nombreDoctor, setNombreDoctor] = useState("");
  const [rfc, setRfc] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [regimenFiscal, setRegimenFiscal] = useState("");
  const [cfdi, setCfdi] = useState("");
  const [moneda, setMoneda] = useState("");
  const [metodoPago, setMetodoPago] = useState("");
  const [tipoCambio, setTipoCambio] = useState("");
  const [correoCliente, setCorreoCliente] = useState("");

  useEffect(() => {
    setNombreDoctor(tipoFactura == "factura de mostrador" ? "PÚBLICO EN GENERAL" : "");
    setRfc(tipoFactura == "factura de mostrador" && tipoCliente == "nacional" ? "XAXX010101000" : tipoFactura == "factura de mostrador" && tipoCliente == "extranjero" ? "XEXX010101000" : "");
    setCodigoPostal(tipoFactura == "factura de mostrador" ? "76804" : "");
    setRegimenFiscal(tipoFactura == "factura de mostrador" ? "616-Sin Obligaciones Fiscales" : "");
    setCfdi(tipoFactura == "factura de mostrador" ? "S01-Sin Obligaciones Fiscales" : "");
  }, [tipoFactura, tipoCliente]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tasa = await obtenerTasaCambio("MXN");
        setTipoCambio((Math.ceil(tasa) - 2));
        console.log(`1 USD = ${tasa} MXN`);
      } catch (error) {
        console.error("Error al obtener la tasa de cambio:", error);
      }
    };

    if (moneda === "USD") {
      fetchData();
    }
  }, [moneda]);

  const [selectedOption, setSelectedOption] = useState(null);
  const handleSeleccionChange = (option) => {
    setSelectedOption(option.value);
    setNombreDoctor(option.value.split("/")[0]);
    setRfc(option.value.split("/")[1]);
    setCodigoPostal(option.value.split("/")[2]);
    setRegimenFiscal(option.value.split("/")[3]);
    setCfdi(option.value.split("/")[4]);
    setCorreoCliente(option.value.split("/")[5]);
  };

  const [selectedProd, setSelectedProd] = useState(null);
  const handleSeleccionChangeProd = (option) => {
    setSelectedProd(option.value);
  };

  const [cantidadProd, setCantidadProd] = useState("");
  const [listarOrdenesMes, setListOrdenesMes] = useState([]);

  const obtenerOrdenes = () => {
    try {
      if (!correoCliente) {
        listarOrdenesServicio()
          .then((response) => {
            const { data } = response;

            // Filtrar las órdenes que tengan el campo 'facturacion' en 0
            let ordenesFiltradas = data.filter((orden) => orden.facturacion == "0");


            // Asignar las órdenes filtradas a 'setListOrdenesMes'
            if (!listarOrdenesMes && ordenesFiltradas) {
              setListOrdenesMes(formatModelOrdenes(ordenesFiltradas));
            } else {
              const datosViaje = formatModelOrdenes(ordenesFiltradas);
              setListOrdenesMes(datosViaje);
            }
          })
          .catch((e) => {
            // Manejo de errores si es necesario
          });
      } else {
        listarOrdenesServicioPorCorreoDoctor(correoCliente)
        .then((response) => {
          const { data } = response;

          // Filtrar las órdenes que tengan el campo 'facturacion' en 0
          let ordenesFiltradas = data.filter((orden) => orden.facturacion == "0");


          // Asignar las órdenes filtradas a 'setListOrdenesMes'
          if (!listarOrdenesMes && ordenesFiltradas) {
            setListOrdenesMes(formatModelOrdenes(ordenesFiltradas));
          } else {
            const datosViaje = formatModelOrdenes(ordenesFiltradas);
            setListOrdenesMes(datosViaje);
          }
        })
        .catch((e) => {
          // Manejo de errores si es necesario
        });
      }
    } catch (e) {
      // Manejo de errores si es necesario
    }
  };

  useEffect(() => {
    obtenerOrdenes();
  }, [correoCliente]);

  useEffect(() => {
    if (selectedProd != null) {
      setCantidadProd(selectedProd.split("|")[4]);
    } else {
      setCantidadProd("");
    }
  }, [selectedProd]);

  const [listarDepartamento, setListDepartamento] = useState([]);

  const obtenerDepartamento = () => {
    try {
      listarDepartamentos()
        .then((response) => {
          const { data } = response;

          if (!listarDepartamento && data) {
            setListDepartamento(formatModelDepartamento(data));
          } else {
            const datosDepartamento = formatModelDepartamento(data);
            setListDepartamento(datosDepartamento);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [listProveedores, setListProveedores] = useState([]);

  const obtenerProveedores = () => {
    try {
      listarProveedores()
        .then((response) => {
          const { data } = response;
          if (!listProveedores && data) {
            setListProveedores(formatModelProveedores(data));
          } else {
            setListProveedores(formatModelProveedores(data));
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [listarProd, setListarProd] = useState([]);

  const obtenerProd = () => {
    try {
      listarProductosAgotandose()
        .then((response) => {
          const { data } = response;
          if (!listarProd && data) {
            setListarProd(formatModelProds(data));
          } else {
            setListarProd(formatModelProds(data));
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [folioActual, setFolioActual] = useState("");

  const obtenerFolio = () => {
    try {
      obtenerNumeroFacturasMostrador()
        .then((response) => {
          const { data } = response;
          const { noFactura } = data;
          setFolioActual(noFactura);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const [nombreUsuario, setNombreUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setNombreUsuario(data.nombre);
          setDepartamento(data.departamento);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerFolio();
    obtenerProveedores();
    obtenerProd();
    obtenerDepartamento();
    obtenerDatosUsuario();
  }, []);

  console.log(listarProd)

  const [departamentosUnicos, setDepartamentosUnicos] = useState([]);

  useEffect(() => {
    const departamentos = listarProd.reduce((departamentos, producto) => {
      if (!departamentos.includes(producto.departamento)) {
        departamentos.push(producto.departamento);
      }
      return departamentos;
    }, []);
    console.log("🚀 ~ departamentos ~ listarProd:", listarProd)
    console.log("🚀 ~ departamentos ~ departamentos:", departamentos)
    setDepartamentosUnicos(departamentos);
  }, [listarProd]);

  const [listaProveedoresFil, setListaProveedoresFil] = useState([]);

  useEffect(() => {
    const proveedoresFiltrados = listProveedores.filter((proveedor) =>
      departamentosUnicos.includes(proveedor.nombre)
    );

    console.log("🚀 ~ useEffect ~ listProveedores:", listProveedores);
    console.log("🚀 ~ useEffect ~ proveedoresFiltrados:", proveedoresFiltrados);
    setListaProveedoresFil(proveedoresFiltrados);
  }, [departamentosUnicos, listProveedores]);

  const [arrProductos, setArrProductos] = useState([]);
  const [provSel, setProvSel] = useState("");
  const [listarProductoFil, setListProductoFil] = useState([]);

  /***********LLENADO DE TABLA**********/
  useEffect(() => {
    if (provSel != "") {
      console.log(listarProd);
      const filterProds = listarProducto.filter(
        (data) => data.departamento === provSel.split("/")[1]
      );
      setListProductoFil(formatModelProductos(filterProds));
      console.log(formatModelProductos(filterProds));
    }
  }, [provSel, nombreProd]);
  /***********FIN LLENADO**********/

  /***********TABLA PROD**********/
  const handleQuit = (index) => {
    const newArray = [...productosAgregados];
    newArray.splice(index, 1);
    setProductosAgregados(newArray);
  };

  /***********TABLA PROD**********/

  const obtenerFechaActual = () => {
    const fecha = new Date();
    const año = fecha.getFullYear();
    const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
    const dia = String(fecha.getDate()).padStart(2, '0');
    const horas = String(fecha.getHours()).padStart(2, '0');
    const minutos = String(fecha.getMinutes()).padStart(2, '0');
    const segundos = String(fecha.getSeconds()).padStart(2, '0');

    return `${año}-${mes}-${dia}T${horas}:${minutos}:${segundos}`;
  };

  const actualizarEstadoOrdenes = async () => {
    productosAgregados.map(async (cat, index) => {
      const dataTemp = {
        facturacion: "1",
      };
      actualizaFacturacionOrdenes(cat.id, dataTemp);
    });
  };

  const registraReq = (e) => {
    e.preventDefault();
    if (!tipoFactura) {
      toast.warning("Completa los campos");
    } else {
      if (productosAgregados.length === 0) {
        toast.warning("No se han agregado productos");
      } else {
        try {
          const dataTemp = {
            folio: folioActual,
            cliente: nombreDoctor,
            rfc: rfc,
            codigoPostal: codigoPostal,
            regimenFiscal: regimenFiscal,
            cdfi: cfdi,
            tipoCambio: moneda == "USD" ? tipoCambio : "1",
            moneda: moneda,
            formaPago: metodoPago,
            fechaEmision: obtenerFechaActual(),
            subtotal: productosAgregados.reduce((acumulador, producto) => { return Number(acumulador) + Number(producto.cantidad); }, 0),
            servicios: listServicios,
            pdfFacturacion: "",
            xmlFacturacion: "",
            estado: "0",
            correoCliente: correoCliente
          };
          registraFacturasMostrador(dataTemp).then((response) => {
            const { data } = response;
            actualizarEstadoOrdenes()
            LogsInformativos(
              "Se ha registrado la requisicion " + dataTemp.folio,
              dataTemp
            );
            history({
              search: queryString.stringify(""),
            });
            toast.success(data.mensaje);
            setShow(false);
          });
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  /************************************************ */
  const [showModal, setShowModal] = useState(false);
  const [cantidadTemp, setCantidadTemp] = useState(0);
  const [productoIndex, setProductoIndex] = useState(-1);
  const [productoNom, setProductoNom] = useState("");

  const handleOpenModal = (cantidad, index, nombre) => {
    setCantidadTemp(cantidad);
    setProductoIndex(index);
    setProductoNom(nombre);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleModificarCantidad = () => {
  };
  /************************************************ */

  const columns = [
    {
      name: "id",
      label: "#",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "folio",
      label: "FOLIO",
    },
    {
      name: "nombreDoctor",
      label: "DOCTOR",
    },
    {
      name: "precioTotal",
      label: "PRECIO",
    },
    {
      name: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                className="btnAddTables"
                onClick={() => handleAgregar(tableMeta.rowData)}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </>
          );
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    responsive: isSmOrDown && "standard",
    selectableRows: "none",
    serverSide: true,
    search: false, // Oculta la barra de búsqueda
    download: false, // Oculta el botón de descarga
    print: false, // Oculta el botón de impresión
    viewColumns: false,
    filter: false,
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        //columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
    },
  };

  const handleAgregar = (producto) => {
    const producto2 = {
      id: producto[0],
      clave: producto[1],
      producto: producto[2],
      categoria: producto[3],
      unidadMedida: producto[4],
      existencia: 0,
      cantidad: producto[3],
    };

    setProductosAgregados((prevProductos) => {
      // Busca si ya existe un producto con la misma clave
      const productoExistente = prevProductos.find(
        (p) => p.clave === producto2.clave
      );

      if (productoExistente) {
        // Si ya existe, actualiza la existencia sumando
        return prevProductos.map((p) =>
          p.clave === producto2.clave
            ? { ...p, existencia: Number(p.existencia) + Number(1) }
            : p
        );
      } else {
        // Si no existe, agrega el nuevo producto
        return [...prevProductos, producto2];
      }
    });
  };


  return (
    <>
      <div style={{ padding: "2%" }}>
        <Row>
          {/* Parte superior: formulario y tabla */}
          <Col sm={12} md={6}>
            <div style={{ padding: "1%" }}>
              <Form>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>
                      Folio: <code>*</code>
                    </Form.Label>
                  </Col>
                  <Col
                    sm={12}
                    md={8}
                    lg={8}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Folio de OC"
                      value={folioActual}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>
                      Tipo de factura: <code>*</code>
                    </Form.Label>
                  </Col>
                  <Col
                    sm={12}
                    md={8}
                    lg={8}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Control
                      as="select"
                      placeholder="Folio de OC"
                      value={tipoFactura}
                      onChange={(e) => setTipoFactura(e.target.value)}
                    >
                      <option value="">Selecciona una opcion</option>
                      <option value="factura de mostrador">Factura de mostrador</option>
                      <option value="factura para doctor">Factura para doctor </option>
                    </Form.Control>
                  </Col>
                </Row>
                {tipoFactura == "factura de mostrador" && (
                  <>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={4}
                        lg={4}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>
                          Tipo de cliente: <code>*</code>
                        </Form.Label>
                      </Col>
                      <Col
                        sm={12}
                        md={8}
                        lg={8}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Control
                          as="select"
                          placeholder="Folio de OC"
                          value={tipoCliente}
                          onChange={(e) => setTipoCliente(e.target.value)}
                        >
                          <option value="">Selecciona una opcion</option>
                          <option value="nacional">Cliente nacional</option>
                          <option value="extranjero">Cliente extranjero</option>
                        </Form.Control>
                      </Col>
                    </Row>
                  </>
                )}
                {tipoFactura == "factura de mostrador" && (
                  <>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={4}
                        lg={4}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>
                          Nombre del doctor: <code>*</code>
                        </Form.Label>
                      </Col>
                      <Col
                        sm={12}
                        md={8}
                        lg={8}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Folio de OC"
                          value={nombreDoctor}
                          disabled
                        />
                      </Col>
                    </Row>
                  </>
                )}

                {tipoFactura == "factura para doctor" && (
                  <>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                      <Col
                        sm={12}
                        md={4}
                        lg={4}
                        className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                      >
                        <Form.Label>Nombre del doctor: <code>*</code></Form.Label>
                      </Col>
                      <Col sm={12} md={8} lg={8}>
                        <Select
                          options={listDoctores}
                          isSearchable={true}
                          placeholder={"Seleccionar..."}
                          onChange={(selectedOption) =>
                            handleSeleccionChange(selectedOption)
                          }
                        />
                      </Col>
                    </Row>
                  </>
                )}
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>
                      RFC: <code>*</code>
                    </Form.Label>
                  </Col>
                  <Col
                    sm={12}
                    md={8}
                    lg={8}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Control
                      type="text"
                      placeholder="RFC del cliente"
                      value={rfc}
                      onChange={(e) => setRfc(e.target.value)}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Codigo Postal:</Form.Label>
                  </Col>
                  <Col
                    sm={12}
                    md={8}
                    lg={8}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Control
                      type="text"
                      value={codigoPostal}
                      placeholder="Codigo postal del cliente"
                      onChange={(e) => setCodigoPostal(e.target.value)}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>
                      Regimen fiscal: <code>*</code>
                    </Form.Label>
                  </Col>
                  <Col
                    sm={12}
                    md={8}
                    lg={8}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Regimen fiscal del cliente"
                      value={regimenFiscal}
                      onChange={(e) => setRegimenFiscal(e.target.value)}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>
                      CFDI: <code>*</code>
                    </Form.Label>
                  </Col>
                  <Col
                    sm={12}
                    md={8}
                    lg={8}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Control
                      type="text"
                      placeholder="CDFI del cliente"
                      value={cfdi}
                      onChange={(e) => setCfdi(e.target.value)}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>
                      Moneda: <code>*</code>
                    </Form.Label>
                  </Col>
                  <Col
                    sm={12}
                    md={8}
                    lg={8}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Control
                      as="select"
                      placeholder="Folio de OC"
                      value={moneda}
                      onChange={(e) => setMoneda(e.target.value)}
                    >
                      <option value="">Selecciona una opcion</option>
                      <option value="MXN">Peso</option>
                      <option value="USD">Dolar</option>
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>
                      Método de Pago: <code>*</code>
                    </Form.Label>
                  </Col>
                  <Col
                    sm={12}
                    md={8}
                    lg={8}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Control
                      as="select"
                      placeholder="Método de pago"
                      value={metodoPago}
                      onChange={(e) => setMetodoPago(e.target.value)}
                    >
                      <option value="">Selecciona una opción</option>
                      <option value="01">01: Efectivo</option>
                      <option value="02">02: Cheque nominativo</option>
                      <option value="03">03: Transferencia electrónica de fondos</option>
                      <option value="04">04: Tarjeta de crédito</option>
                      <option value="05">05: Monedero electrónico</option>
                      <option value="06">06: Dinero electrónico</option>
                      <option value="08">08: Vales de despensa</option>
                      <option value="28">28: Tarjeta de débito</option>
                      <option value="29">29: Tarjeta de servicios</option>
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7" style={{ display: "none" }}>
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Cantidad:</Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <div className="divCantidadButtonVale">
                      <Form.Control
                        type="text"
                        placeholder="Cantidad a solicitar"
                      />
                      &nbsp;
                      <Button variant="success">
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>

          <Col sm={12} md={6}>
            <div style={{ padding: "1%" }}>
              <h4 style={{ textAlign: "center" }}>Ordenes agregadas</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>FOLIO</th>
                    <th>DOCTOR</th>
                    <th>TOTAL</th>
                    <th>ACCIONES</th>
                  </tr>
                </thead>
                <tbody>
                  {productosAgregados.map((producto, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{producto.producto}</td>
                      <td>{producto.clave}</td>
                      <td>{producto.cantidad}</td>
                      <td>
                        <Button variant="danger" onClick={() => handleQuit(index)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>

        {/* Parte inferior: productos agregados */}
        <Row>
          <Col sm={12}>
            <div style={{ padding: "1%" }}>
              <h4 style={{ textAlign: "center" }}>Ordenes encontradas</h4>
              <MUIDataTable
                data={listarOrdenesMes}
                columns={columns}
                options={options}
              />
            </div>
          </Col>
        </Row>
        <div className="mb-3 d-flex justify-content-center">
          <Button variant="success" onClick={registraReq}>
            <FontAwesomeIcon icon={faCartPlus} />
            &nbsp;Registrar
          </Button>
        </div>
        {/* Modal */}
        <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Modificar Cantidad</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formCantidad">
              <h4 style={{ textAlign: "center", fontStyle: "italic" }}>
                {productoNom}
              </h4>
              <Form.Label>Nueva Cantidad:</Form.Label>
              <Form.Control
                type="number"
                value={cantidadTemp}
                onChange={(e) => setCantidadTemp(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={handleModificarCantidad}>
              Actualizar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

function formatModelProveedores(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
    });
  });
  return dataTemp;
}
function formatModelUM(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
    });
  });
  return dataTemp;
}

function formatModelDepartamento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
    });
  });
  return dataTemp;
}

function formatModelProds(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      clave: data.clave,
      departamento: data.departamento,
      existencia: data.existencia,
      maximo: data.maximo,
      minimo: 1,
      producto: data.producto,
      unidadMedida: data.unidadMedida,
      estado: data.estado,
      ubicacion: data.ubicacion,
    });
  });
  return dataTemp;
}

function formatModelProdsFil(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data.id,
      clave: data.clave,
      departamento: data.departamento,
      existencia: data.existencia,
      minimo: data.minimo,
      producto: data.producto,
      unidadMedida: data.unidadMedida,
      categoria: data.categoria
    });
  });
  return dataTemp;
}

function formatModelProductos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      clave: data.clave,
      producto: data.producto,
      categoria: data.categoria,
      unidadMedida: data.unidadMedida,
      minimo: 1,
      existencia: data.existencia,
      departamento: data.departamento,
    });
  });
  return dataTemp;
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      fecha: data.fecha,
      localidad: data.localidad,
      nombreDoctor: data.nombreDoctor,
      nombrePaciente: data.nombrePaciente,
      tipoDentadura: data.tipoDentadura,
      servicios: data.servicios,
      otros: data.otros,
      recibio: data.recibio,
      fechaEntrada: data.fechaEntrada,
      correoDoctor: data.correoDoctor,
      correoConsultorio: data.correoConsultorio,
      estado: data.estado,
      telefonoDoctor: data.telefonoDoctor,
      cancelacion: data.cancelacion,
      cargo: data.cargoOrden,
      cambios: !data.cambios ? "0" : data.cambios,
      aprobado: !data.aprobado ? "No disponible" : data.aprobado,
      direccionConsultorio: data?.direccionConsultorio?.municipio,
      precioTotal: data.precioTotal,
      facturacion: !data.facturacion ? "0" : data.facturacion
    });
  });
  return dataTemp;
}

const formatModelDoctores = (data) => {
  return data.map((empleado) => ({
    value: empleado.razonSocial + "/" + empleado.rfc + "/" + empleado.codigoPostal + "/" + empleado.regimenFiscal + "/" + empleado.usoCfdi + "/" + empleado.idUsuarioDatos,
    label: empleado.razonSocial,
  }));
};

function formatModelServicios(data) {
  return data.map((item) => {
    const dienteArray = item.diente;
    let diente = "";

    if (Array.isArray(dienteArray)) {
      diente = dienteArray
        .map((d) => d.match(/\d+/)) // Extract numbers from each string
        .filter(Boolean) // Remove any null values resulting from no match
        .join(", "); // Join the numbers with comma and space
    }

    return {
      id: item._id,
      folio: item.folio,
      clasificacion: item.clasificacion,
      material: item.material,
      pieza: item.pieza,
      precio: item.precio,
      descripcionAdicional: item.descripcionAdicional,
      sistemaColor: item.sistemaColor,
      distencion: item.distencion,
      diente: diente,
      tonoInferior: item.tonoInferior,
      tonoMedio: item.tonoMedio,
      tonoSuperior: item.tonoSuperior,
      imagenEsquema: item.imagenEsquema,
      imagenDiente: item.imagenDiente,
      audio: item.audio
    };
  });
}

export default Registro;
