import React, { useState, useEffect } from "react";
import { Row, Col, Button, Image, Spinner, Tab, Tabs } from "react-bootstrap";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import { listarUsuarios, obtenerUsuario } from "../../../api/usuarios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faGlobe,
  faChartLine,
  faPen,
  faArrowLeft,
  faUser,
  faFileInvoice,
  faBuildingUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  listarFechasyEstadoPago,
  listarOrdenesServicio,
  listarOrdenesServicioPorCorreoConsultorio,
  listarOrdenesServicioPorCorreoDoctor,
  obtenerNumeroOrdenServicioDireccion,
  obtenerNumeroOrdenServicioFecha,
  listarSaldosNuevo
} from "../../../api/ordenesServicio";
import LineChart from "./grafica/Grafica";
import {
  listarDoctores,
  listarDoctoresPorCorreo,
  totalDoctores,
} from "../../../api/doctores";
import PerfilConsultorio from "../cliente/PerfilConsultorio";
import DatosDocInicio from "../../doctores/DatosDocInicio";
import { listarGeneralRutas, listarRutasPorCorreo } from "../../../api/rutas";
import { listarMensajeros } from "../../../api/mensajeros";
import "./styleNotifications.css";
import DatosdeFacturacion from "../datosFacturacion/DatosdeFacturacion";
import FacturacionPerfil from "../datosFacturacion/FacturacionPerfil";
import { listarFacturacion } from "../../../api/facturacion";
import {
  listarServicios,
  listarServiciosCorreo,
  listarServiciosODT,
} from "../../../api/servicios";
import DynamicChart from "./grafica/Grafica";
import { listarRecursosHumanos } from "../../../api/recursosHumanos";
import { listarCargos } from "../../../api/cargos";
import { listarFechaAbonosOrdenes } from "../../../api/abonosOrdenes";
import { totalEstablecimientos } from "../../../api/establecimientos";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { listarAbonosAprobados } from "../../../api/abonosOrdenes";
import { Load } from "../../load/load";

const InicioPefil = () => {

  const fechaActual = new Date();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const año = fechaActual.getFullYear();
  const mes = String(fechaActual.getMonth() + 1).padStart(2, '0');  // Agrega un cero al inicio si el mes es de un solo dígito
  const dia = String(fechaActual.getDate()).padStart(2, '0');  // Agrega un cero al inicio si el día es de un solo dígito
  
  // Formatea la fecha en "aaaa/mm/dd"
  const fechaFormateada = `${año}-${mes}-${dia}`;

  const [nombreUsuario, setNombreUsuario] = useState("");
  const [tipoUsuario, setTipoUsuario] = useState("");
  const [totalUsuarios, setTotalUsuarios] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setNombreUsuario(data.nombre);
          setTipoUsuario(data.tipo);
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [listUsuarios, setListUsuarios] = useState([]);
  const [numeroRegistros, setNumeroRegistros] = useState(0);
  const [numeroRegistrosDocCons, setNumeroRegistrosDocCons] = useState(0);

  const obtenerUsuarios = () => {
    try {
      listarUsuarios()
        .then((response) => {
          const { data } = response;

          if (!listUsuarios && data) {
            setListUsuarios(formatModelUsuarios(data));
          } else {
            const datosOrdenes = formatModelUsuarios(data);
            setListUsuarios(datosOrdenes);
          }
          const registros = data.length;
          setNumeroRegistros(registros);
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerUsuarios();
  }, []);

  const [listOrdenes, setListOrdenes] = useState([]);
  const [numeroOrdenes, setNumeroOrdenes] = useState(0);

  const obtenerOrdenesServicio = () => {
    if (tipoUsuario === "administrador" || tipoUsuario == "mesa de control") {
      try {
        listarOrdenesServicio()
          .then((response) => {
            const { data } = response;

            if (!listOrdenes && data) {
              setListOrdenes(formatModelOrdenes(data));
            } else {
              const datosOrdenes = formatModelOrdenes(data);
              setListOrdenes(datosOrdenes);
            }
            const noOrdenes = data.length;
            setNumeroOrdenes(noOrdenes);
          })
          .catch((e) => {
            console.error("Error en el bloque de administrador:", e);
          });
      } catch (e) {
        console.error("Error en el bloque de administrador:", e);
      }
    } else if (tipoUsuario === "consultorio") {
      try {
        listarOrdenesServicioPorCorreoConsultorio(correoUsuario)
          .then((response) => {
            const { data } = response;
            const filteredData = data.filter(
              (item) => item.fecha === fechaFormateada
            );
            if (!listOrdenes && data) {
              setListOrdenes(formatModelOrdenes(data));
            } else {
              const datosOrdenes = formatModelOrdenes(data);
              setListOrdenes(datosOrdenes);
            }
            setNumeroRegistrosDocCons(filteredData.length);

            const noOrdenes = data.length;
            setNumeroOrdenes(noOrdenes);
          })
          .catch((e) => {
            console.error("Error en el bloque de consultorio:", e);
          });
      } catch (e) {
        console.error("Error en el bloque de consultorio:", e);
      }
    } else if (tipoUsuario === "doctor") {
      try {
        listarOrdenesServicioPorCorreoDoctor(correoUsuario)
          .then((response) => {
            const { data } = response;
            console.log(data);
            const filteredData = data.filter(
              (item) => item.fecha === fechaFormateada
            );
            if (!listOrdenes && data) {
              setListOrdenes(formatModelOrdenes(data));
            } else {
              const datosOrdenes = formatModelOrdenes(data);
              setListOrdenes(datosOrdenes);
            }
            setNumeroRegistrosDocCons(filteredData.length);

            const noOrdenes = data.length;
            setNumeroOrdenes(noOrdenes);
          })
          .catch((e) => {
            console.error("Error en el bloque de doctor:", e);
          });
      } catch (e) {
        console.error("Error en el bloque de doctor:", e);
      }
    } else {
      //console.log("Tipo de usuario no reconocido:", tipoUsuario);
    }
  };

  useEffect(() => {
    obtenerOrdenesServicio();
  }, [tipoUsuario, correoUsuario]);

  const [numeroOrdenesFecha, setNumeroOrdenesFecha] = useState([]);
  const [numeroOrdenesFechaHoy, setNumeroOrdenesFechaHoy] = useState([]);

  const obtenerPorFecha = () => {
    try {
      obtenerNumeroOrdenServicioFecha()
        .then((response) => {
          const { data } = response;
          if (data) {
            const datosOrdenesFecha = formatModelOrdenesFecha(data);

            setNumeroOrdenesFecha(datosOrdenesFecha);
            const ordenesHoy = datosOrdenesFecha.filter(
              (orden) => orden.fecha == fechaFormateada
            );
            setNumeroOrdenesFechaHoy(ordenesHoy);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [numeroOrdenesDireccion, setnumeroOrdenesDireccion] = useState([]);

  const obtenerPorDireccion = () => {
    try {
      obtenerNumeroOrdenServicioDireccion()
        .then((response) => {
          const { data } = response;
          if (data) {
            const datosOrdenesDireccion = formatModelOrdenesDireccion(data);
            setnumeroOrdenesDireccion(datosOrdenesDireccion);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerPorFecha();
    obtenerPorDireccion();
  }, [fechaFormateada]);

  const [frecuencias, setFrecuencias] = useState([]);
  const [fechas, setFechas] = useState([]);
  const [frecuenciasDom, setfrecuenciasDom] = useState([]);
  const [domiciliosDom, setDomiciliosDom] = useState([]);

  useEffect(() => {
    setFrecuencias(numeroOrdenesFecha.map((item) => item.frecuencia));
    setFechas(numeroOrdenesFecha.map((item) => item.fecha));
    setfrecuenciasDom(numeroOrdenesDireccion.map((item) => item.frecuencia));
    setDomiciliosDom(numeroOrdenesDireccion.map((item) => item.direccion));
  }, [numeroOrdenesFecha, numeroOrdenesDireccion]);


  const [listarDoctor, setListDoctor] = useState([]);
  const [numeroDoctores, setNumeroDoctores] = useState(0);

  const obtenerDoctores = () => {
    try {
      listarDoctoresPorCorreo(correoUsuario)
        .then((response) => {
          const { data } = response;
          if (!listarDoctor && data) {
            setListDoctor(formatModelDoctor(data));
          } else {
            const datosDoctor = formatModelDoctor(data);
            setListDoctor(datosDoctor);
          }

          const noDoctores = data.length;
          setNumeroDoctores(noDoctores);
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerDoctores();
  }, [correoUsuario]);

  const [imagenDoc, setimagenDoc] = useState(null);

  const [listarDoctor2, setListDoctor2] = useState([]);

  const obtenerDoctor = () => {
    try {
      listarDoctores()
        .then((response) => {
          const { data } = response;

          if (!listarDoctor && data) {
            setListDoctor2(formatModelDoctor(data));
          } else {
            const datosDoctor = formatModelDoctor(data);
            setListDoctor2(datosDoctor);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerDoctor();
  }, []);

  const obtieneImagenDoc = () => {
    if (tipoUsuario === "doctor") {
      const filterID = listarDoctor2.find(
        (data) => data.email === correoUsuario
      );
      if (filterID) {
        setimagenDoc(filterID.imagen);
      } else {
        console.log("No se encontró ningún elemento con el ID especificado");
      }
    }
  };

  useEffect(() => {
    obtieneImagenDoc();
  }, [listarDoctor]);

  const [listarMensajero, setListMensajero] = useState([]);
  const [imagenMensajero, setImagenMensajero] = useState(null); // Nueva variable de estado para la imagen

  const obtenerMensajeros = () => {
    try {
      listarMensajeros()
        .then((response) => {
          const { data } = response;

          if (!listarMensajero && data) {
            setListMensajero(formatModelMensajero(data));
          } else {
            const datosMensajeros = formatModelMensajero(data).filter(
              (mensajero) => mensajero.email == correoUsuario
            );
            if (datosMensajeros.length > 0) {
              setListMensajero(datosMensajeros);
              setImagenMensajero(datosMensajeros[0].imagen);
            } else {
              setImagenMensajero(null);
            }
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [listarRutasDiaMens, setListRutasDiaMens] = useState([]);

  const obtenerRutasDia = () => {
    try {
      listarRutasPorCorreo(fechaFormateada, correoUsuario)
        .then((response) => {
          const { data } = response;

          if (!listarRutasDiaMens && data) {
            setListRutasDiaMens(formatModelRutas(data));
          } else {
            const datosRutas = formatModelRutas(data);
            setListRutasDiaMens(datosRutas);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [listarRutasCorreo, setListRutasCorreo] = useState([]);

  const obtenerRutas = () => {
    try {
      listarGeneralRutas()
        .then((response) => {
          const { data } = response;
          if (data) {
            const datosRutas = formatModelRutas(data);
            const rutasFiltradas = datosRutas.filter(
              (ruta) => ruta.correoMensajero == correoUsuario
            );
            setListRutasCorreo(rutasFiltradas);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerMensajeros();
    obtenerRutasDia();
    obtenerRutas();
  }, [correoUsuario]);

  const [numeroRutasDia, setnumeroRutasDia] = useState(0);
  const [numeroRutasTot, setnumeroRutasTot] = useState(0);

  useEffect(() => {
    setnumeroRutasDia(listarRutasDiaMens.length);
    setnumeroRutasTot(listarRutasCorreo.length);
  }, [listarRutasDiaMens, listarRutasCorreo]);

  const [mostrarPrimerDiv, setMostrarPrimerDiv] = useState(true);

  const handleClick = () => {
    setMostrarPrimerDiv(!mostrarPrimerDiv);
  };

  const [mostrarDiv2, setMostrarDiv2] = useState(true);

  const handleClick2 = () => {
    setMostrarDiv2(!mostrarDiv2);
  };

  const [listarFacturaciones, setListFacturacion] = useState(0);
  const [listarArrFacturaciones, setListarArrFacturacion] = useState([]);

  const obtenerFacturacion = () => {
    try {
      listarFacturacion()
        .then((response) => {
          const { data } = response;

          if (data) {
            const datosFacturacion = formatModelFacturacion(data);
            const facturacionesFiltradas = datosFacturacion.filter(
              (item) => item.idUsuarioDatos === correoUsuario
            );
            setListFacturacion(facturacionesFiltradas.length);
            setListarArrFacturacion(facturacionesFiltradas);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    if (tipoUsuario != "administrador") {
      obtenerFacturacion();
    }
  }, [correoUsuario]);

  const [listServiciosGeneral, setListServiciosGeneral] = useState(0);
  const [listServiciosCorreo, setListServiciosCorreo] = useState(0);

  const obtenerServiciosCorreo = () => {
    try {
      listarServiciosCorreo(correoUsuario)
        .then((response) => {
          const { data } = response;
          console.log(data.length);
          setListServiciosCorreo(data.length);
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const obtenerServiciosGeneral = () => {
    try {
      listarServicios()
        .then((response) => {
          const { data } = response;
          console.log(data.length);
          setListServiciosGeneral(data.length);
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    if (tipoUsuario === "departamento") {
      obtenerServiciosCorreo();
      obtenerServiciosGeneral();
    }
  }, [correoUsuario]);

  const [numEmpleados, setnumEmpleados] = useState(0);
  const [numCargos, setnumCargos] = useState(0);

  const obtenerNumEmpleados = () => {
    try {
      listarRecursosHumanos()
        .then((response) => {
          const { data } = response;
          const empleadosConEstadoTrue = data.filter(empleado => empleado.estado === true);
          setnumEmpleados(empleadosConEstadoTrue.length);
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const obtenerNumCargos = () => {
    try {
      listarCargos()
        .then((response) => {
          const { data } = response;
          setnumCargos(data.length);
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    if (tipoUsuario === "rHumanos") {
      obtenerNumEmpleados();
      obtenerNumCargos();
    }
  }, [tipoUsuario]);

  const [noODT, setnoODT] = useState(0);
  const obtenerNumServicios = () => {
    try {
      listarServiciosODT()
        .then((response) => {
          const { data } = response;
          setnoODT(data.length);
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    if (tipoUsuario === "cProduccion") {
      obtenerNumServicios();
      obtenerServiciosGeneral();
    }
  }, [tipoUsuario]);

  const [fechaFormat, setFechaFormat] = useState("");

  useEffect(() => {
    if (tipoUsuario == "contabilidad") {
      let fecha = new Date();
      let dia = String(fecha.getDate()).padStart(2, '0');  // Agrega un cero al inicio si el día es de un solo dígito
      let mes = String(fecha.getMonth() + 1).padStart(2, '0');  // Agrega un cero al inicio si el mes es de un solo dígito
      let year = fecha.getFullYear();
      const fechInp = `${year}-${mes}-${dia}`;
      setFechaFormat(fechInp);
    }
  }, []);

  const [listarAbonos, setListAbonos] = useState(0);

  const obtenerAbonos = (fecha) => {
    try {
      listarFechaAbonosOrdenes(fecha)
        .then((response) => {
          const { data } = response;
          setListAbonos(data.length);
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [listOS, setListOS] = useState(0);

  const obtenerOS = () => {
    try {
      listarFechasyEstadoPago()
        .then((response) => {
          const { data } = response;
          setListOS(data.length);
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [listAbonosA, setListAbonosA] = useState([]);

  const obtenerAA = () => {
    try {
      listarAbonosAprobados()
        .then((response) => {
          const { data } = response;
          console.log("AA: -> ", data);
          if (!listAbonosA && data) {
            setListAbonosA(formatModelAA(data));
          } else {
            setListAbonosA(formatModelAA(data));
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [listSaldos, setListSaldos] = useState([]);

  const obtenerSaldos = () => {
    try {
      listarSaldosNuevo()
        .then((response) => {
          const { data } = response;
          if (data.length > 0) {
            setListSaldos(data);
          } else {
            setListSaldos([]);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [listOS2, setListOS2] = useState([]);

  const obtenerOS2 = () => {
    try {
      listarFechasyEstadoPago()
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (data.length > 0) {
            const ordenesAdeudadas = data.filter(
              (registro) => registro.diasDesdeFecha > 15
            );
            console.log(
              "🚀 ~ .then ~ ordenesAdeudadas:",
              formatModelOrdenes(ordenesAdeudadas)
            );

            const ordenesFormat = formatModelOrdenes(ordenesAdeudadas);
            //setListOS(formatModelOrdenes(data));

            // Inicializar un objeto vacío para almacenar los resultados agrupados
            const agrupados = {};

            // Iterar sobre cada orden en el arreglo
            ordenesFormat.forEach((orden) => {
              // Obtener el valor de cargoOrden y adeudo de la orden actual
              const { cargoOrden, adeudo } = orden;

              // Si cargoOrden está definido
              if (cargoOrden) {
                // Si el grupo para este cargoOrden no existe, inicializarlo con 0
                if (!agrupados[cargoOrden]) {
                  agrupados[cargoOrden] = 0;
                }

                // Agregar el adeudo de la orden actual al grupo correspondiente
                agrupados[cargoOrden] += adeudo;
              }
            });

            // Convertir el objeto agrupado a un arreglo para facilitar su visualización
            const resultado = Object.keys(agrupados)?.map((cargoOrden) => ({
              cargoOrden,
              adeudoTotal: agrupados[cargoOrden],
            }));

            setListOS2(resultado);
          } else {
            setListOS2([]);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerAA();
    obtenerSaldos();
    obtenerOS2();
  }, []);

  const [clientesSaldos, setClientesSaldos] = useState([]);

  useEffect(() => {
    const calcularClientesConAbonos = () => {
      const clientesConAbonosCalculados = listSaldos?.map((cliente) => {
        const abono = listAbonosA.find((abono) => abono.id === cliente._id);
        return {
          ...cliente,
          totalCantidadRecibida: abono ? abono.totalCantidadRecibida : 0,
        };
      });

      const saldosCompletos = clientesConAbonosCalculados?.map((cliente) => {
        const adeudo = listOS2.find(
          (adeudo) => adeudo.cargoOrden === cliente._id
        );
        return {
          ...cliente,
          totalSaldoVencido: adeudo ? adeudo.adeudoTotal : 0,
        };
      });
      setClientesSaldos(formatModelSaldos(saldosCompletos));
      console.log(formatModelSaldos(saldosCompletos));
    };

    calcularClientesConAbonos();
  }, [listSaldos, listAbonosA, listOS2]);

  useEffect(() => {
    if (tipoUsuario == "contabilidad") {
      obtenerAbonos(fechaFormat);
      obtenerOS();
    }
  }, [fechaFormat, tipoUsuario]);

  const [numDocs, setNumDocs] = useState(0);
  const [numEstab, setNumEstab] = useState(0);

  const obtenerNumDocs = () => {
    try {
      totalDoctores()
        .then((response) => {
          const { data } = response;
          setNumDocs(data);
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const obtenerNumEst = () => {
    try {
      totalEstablecimientos()
        .then((response) => {
          const { data } = response;
          setNumEstab(data);
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    if (tipoUsuario == "mesa de control") {
      obtenerNumDocs();
      obtenerNumEst();
    }
  }, [tipoUsuario]);

  return (
    <>
      {loading && <Load />}
      <div style={{ margin: "1vw" }}>
        <div>
          {tipoUsuario === "administrador" ? (
            <div>
              <div className="card card-widget widget-user shadow">
                <div
                  className="widget-user-header bg-success"
                  style={{ textTransform: "capitalize" }}
                >
                  <p
                    className="widget-user-username homeUserName"
                    style={{
                      textAlign: "center",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                  >
                    Bienvenido!!! {nombreUsuario}
                  </p>
                  <p className="widget-user-desc rolUser">{tipoUsuario}</p>
                </div>
                <div className="widget-user-image">
                  <Image
                    className="img-circle elevation-2"
                    src="../dist/img/admin.png"
                    alt="User Avatar"
                  />
                </div>
                <div className="card-footer">
                  <Row>
                    <Col sm={12} md={6} lg={6}>
                      <div class="small-box bg-info" style={{ height: "90%" }}>
                        <div class="inner">
                          <h3>Usuarios registrados</h3>
                          <h2>{numeroRegistros}</h2>
                        </div>
                        <div class="icon">
                          <FontAwesomeIcon icon={faUsers} />
                        </div>
                      </div>
                    </Col>

                    <Col sm={12} md={6} lg={6}>
                      <div class="small-box bg-danger">
                        <div class="inner">
                          <h3>Total de ordenes</h3>
                          <h2>{numeroOrdenes}</h2>
                          <h4>Registradas hoy</h4>
                          {/* Renderizar la frecuencia o "0" */}
                          {numeroOrdenesFechaHoy.length > 0 ? (
                            numeroOrdenesFechaHoy.map((orden) => (
                              <h2>{orden.frecuencia}</h2>
                            ))
                          ) : (
                            <h2>0</h2>
                          )}
                        </div>
                        <div class="icon">
                          <FontAwesomeIcon icon={faChartLine} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6} lg={6}>
                      <div style={{ maxHeight: "50vh" }}>
                        {numeroOrdenesFecha.length > 0 ? (
                          <DynamicChart
                            frecuencias={frecuencias}
                            etiquetas={fechas}
                            chartType={"line"}
                            titInfo={"Ordenes registradas por día"}
                            bgColor={"#9c182d"}
                            chartTitle={"ORDENES POR FECHA"}
                          />
                        ) : (
                          <p>Cargando...</p>
                        )}
                      </div>
                    </Col>

                    <Col sm={12} md={6} lg={6}>
                      <div style={{ maxHeight: "50vh" }}>
                        {numeroOrdenesDireccion.length > 0 ? (
                          <DynamicChart
                            frecuencias={frecuenciasDom}
                            etiquetas={domiciliosDom}
                            chartType={"bar"}
                            titInfo={"Ordenes registradas por municipio"}
                            bgColor={"#0c1b30"}
                            chartTitle={"ORDENES POR MUNICIPIO"}
                          />
                        ) : (
                          <p>Cargando...</p>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          ) : tipoUsuario === "consultorio" ? (
            <div>
              {mostrarPrimerDiv ? (
                <div>
                  <Row>
                    <Col sm={12} md={12} lg={12}>
                      <div className="card card-widget widget-user shadow">
                        <div
                          className="widget-user-header bg-danger"
                          style={{
                            textTransform: "capitalize",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                            }}
                          >
                            <Button
                              variant="link"
                              style={{
                                color: "#fff",
                                fontSize: "x-large",
                                textDecoration: "none",
                              }}
                              onClick={handleClick}
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </Button>
                          </div>
                          <p
                            className="widget-user-username homeUserName"
                            style={{
                              textAlign: "center",
                              fontStyle: "italic",
                              fontWeight: "bold",
                            }}
                          >
                            Bienvenido!!! {nombreUsuario}
                          </p>
                          <p className="widget-user-desc rolUser">
                            {tipoUsuario}
                          </p>
                        </div>
                        <div className="widget-user-image">
                          <Image
                            className="img-circle elevation-2"
                            src="../dist/img/cd.png"
                            alt="User Avatar"
                          />
                        </div>
                        <div className="card-footer">
                          <div className="row">
                            <div className="col-sm-4 border-right">
                              <div className="description-block">
                                <h5 className="description-header">
                                  {numeroRegistrosDocCons}
                                </h5>
                                <span className="description-text">
                                  ORDENES DE CLIENTE HOY
                                </span>
                              </div>
                            </div>
                            <div className="col-sm-4 border-right">
                              <div className="description-block">
                                <h5 className="description-header">
                                  {numeroOrdenes}
                                </h5>
                                <span className="description-text">
                                  ORDENES DE CLIENTE TOTALES
                                </span>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="description-block">
                                <h5 className="description-header">
                                  {numeroDoctores}
                                </h5>
                                <span className="description-text">
                                  DOCTORES REGISTRADOS
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div id="divParaMostrarOcultar">
                  <div>
                    <div
                      style={{
                        top: "2px",
                        left: "10px",
                      }}
                    >
                      <Button
                        variant="primary"
                        style={{ color: "#fff", textDecoration: "none" }}
                        onClick={handleClick}
                      >
                        <FontAwesomeIcon icon={faArrowLeft} />
                      </Button>
                    </div>
                    <Tabs
                      defaultActiveKey="personales"
                      id="justify-tab-example"
                      className="mb-3"
                      justify
                      style={{
                        fontSize: "larger",
                        fontWeight: "bold",
                        marginTop: "1%",
                      }}
                    >
                      <Tab
                        eventKey="personales"
                        title={
                          <span>
                            <FontAwesomeIcon icon={faUser} /> Datos del
                            Consultorio
                          </span>
                        }
                      >
                        <div>
                          <h2 style={{ textAlign: "center" }}>
                            Editar información del consultorio
                          </h2>
                        </div>
                        <div>
                          <PerfilConsultorio />
                        </div>
                      </Tab>
                      <Tab
                        eventKey="fiscales"
                        title={
                          <span>
                            <FontAwesomeIcon icon={faFileInvoice} /> Datos
                            Fiscales
                          </span>
                        }
                      >
                        <div style={{ textAlign: "center", marginBottom: "2%" }}>
                          <h2 style={{ fontWeight: "bold", fontStyle: "italic" }}>
                            Mis Datos Fiscales
                          </h2>
                        </div>
                        {listarFacturaciones > 0 ? (
                          <FacturacionPerfil datos={listarArrFacturaciones} />
                        ) : (
                          <DatosdeFacturacion />
                        )}
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              )}
            </div>
          ) : tipoUsuario === "doctor" ? (
            <div>
              {mostrarDiv2 ? (
                <div>
                  <Row>
                    <Col sm={12} md={12} lg={12}>
                      <div className="card card-widget widget-user shadow">
                        <div
                          className="widget-user-header bg-info"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                            }}
                          >
                            <Button
                              variant="link"
                              style={{
                                color: "#fff",
                                fontSize: "x-large",
                                textDecoration: "none",
                              }}
                              onClick={handleClick2}
                            >
                              <FontAwesomeIcon icon={faPen} /> Editar
                            </Button>
                          </div>
                          <p
                            className="widget-user-username homeUserName"
                            style={{
                              textAlign: "center",
                              fontStyle: "italic",
                              fontWeight: "bold",
                            }}
                          >
                            Bienvenido!!! {nombreUsuario}
                          </p>
                          <p className="widget-user-desc rolUser">
                            {tipoUsuario}
                          </p>
                        </div>
                        <div className="widget-user-image">
                          <Image
                            className="img-circle elevation-2"
                            src={
                              imagenDoc
                                ? imagenDoc
                                : "../dist/img/avatarDoctor.png"
                            }
                            alt="User Avatar"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                        <div className="card-footer">
                          <div className="row">
                            <div className="col-sm-4 border-right">
                              <div className="description-block">
                                <h5 className="description-header">
                                  {numeroRegistrosDocCons}
                                </h5>
                                <span className="description-text">
                                  ORDENES DE CLIENTE HOY
                                </span>
                              </div>
                            </div>
                            <div className="col-sm-4 border-right">
                              <div className="description-block">
                                <h5 className="description-header">
                                  {numeroOrdenes}
                                </h5>
                                <span className="description-text">
                                  ORDENES DE CLIENTE TOTALES
                                </span>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="description-block">
                                <h5 className="description-header">
                                  ${''}
                                  {new Intl.NumberFormat('es-MX', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format((
                                    clientesSaldos.find((dato) => dato.id === correoUsuario) || { pendiente: "0" }
                                  ).pendiente)} MXN

                                </h5>
                                <span className="description-text">
                                  SALDO DEL DOCTOR
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div id="divParaMostrarOcultar2">
                  <div>
                    <div
                      style={{
                        top: "2px",
                        left: "10px",
                      }}
                    >
                      <Button
                        variant="primary"
                        style={{ color: "#fff", textDecoration: "none" }}
                        onClick={handleClick2}
                      >
                        <FontAwesomeIcon icon={faArrowLeft} />
                      </Button>
                    </div>
                  </div>
                  <div>
                    <Tabs
                      defaultActiveKey="personales"
                      id="justify-tab-example"
                      className="mb-3"
                      justify
                      style={{ fontSize: "larger", fontWeight: "bold" }}
                    >
                      <Tab
                        eventKey="personales"
                        title={
                          <span>
                            <FontAwesomeIcon icon={faUser} /> Datos Personales
                          </span>
                        }
                      >
                        <DatosDocInicio />
                      </Tab>
                      <Tab
                        eventKey="fiscales"
                        title={
                          <span>
                            <FontAwesomeIcon icon={faFileInvoice} /> Datos
                            Fiscales
                          </span>
                        }
                      >
                        <div style={{ textAlign: "center", marginBottom: "2%" }}>
                          <h2 style={{ fontWeight: "bold", fontStyle: "italic" }}>
                            Mis Datos Fiscales
                          </h2>
                        </div>
                        {listarFacturaciones > 0 ? (
                          <FacturacionPerfil datos={listarArrFacturaciones} />
                        ) : (
                          <DatosdeFacturacion />
                        )}
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              )}
            </div>
          ) : tipoUsuario === "almacen" ? (
            <div>
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <div className="card card-widget widget-user shadow">
                    <div
                      className="widget-user-header bg-warning"
                      style={{ textTransform: "capitalize" }}
                    >
                      <p
                        className="widget-user-username homeUserName"
                        style={{
                          textAlign: "center",
                          fontStyle: "italic",
                          fontWeight: "bold",
                        }}
                      >
                        Bienvenido!!! {nombreUsuario}
                      </p>
                      <p className="widget-user-desc rolUser">{tipoUsuario}</p>
                    </div>
                    <div className="widget-user-image">
                      <Image
                        className="img-circle elevation-2"
                        src="../dist/img/almacen.png"
                        alt="User Avatar"
                      />
                    </div>
                    <div className="card-footer"></div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : tipoUsuario === "mensajero" ? (
            <div>
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <div className="card card-widget widget-user shadow">
                    <div
                      className="widget-user-header bg-dark"
                      style={{ textTransform: "capitalize" }}
                    >
                      <p
                        className="widget-user-username homeUserName"
                        style={{
                          textAlign: "center",
                          fontStyle: "italic",
                          fontWeight: "bold",
                        }}
                      >
                        Bienvenido!!! {nombreUsuario}
                      </p>
                      <p className="widget-user-desc rolUser">{tipoUsuario}</p>
                    </div>
                    <div className="widget-user-image">
                      <Image
                        className="img-circle elevation-2"
                        src={imagenMensajero}
                        alt="User Avatar"
                      />
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col-sm-6 border-right">
                          <div className="description-block">
                            <h5 className="description-header">
                              {numeroRutasDia}
                            </h5>
                            <span className="description-text">
                              RUTAS ASIGNADAS HOY
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-6 border-right">
                          <div className="description-block">
                            <h5 className="description-header">
                              {numeroRutasTot}
                            </h5>
                            <span className="description-text">
                              TOTAL DE RUTAS ASIGNADAS
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : tipoUsuario === "departamento" ? (
            <div>
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <div className="card card-widget widget-user shadow">
                    <div
                      className="widget-user-header bg-primary"
                      style={{ textTransform: "capitalize" }}
                    >
                      <p
                        className="widget-user-username homeUserName"
                        style={{
                          textAlign: "center",
                          fontStyle: "italic",
                          fontWeight: "bold",
                        }}
                      >
                        Bienvenido!!! {nombreUsuario}
                      </p>
                      <p className="widget-user-desc rolUser">{tipoUsuario}</p>
                    </div>
                    <div className="widget-user-image">
                      <Image
                        className="img-circle elevation-2"
                        src="../dist/img/department.png"
                        alt="User Avatar"
                      />
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col-sm-6 border-right">
                          <div className="description-block">
                            <h5 className="description-header">
                              {listServiciosCorreo}
                            </h5>
                            <span className="description-text">
                              SERVICIOS DEPARTAMENTO
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-6 border-right">
                          <div className="description-block">
                            <h5 className="description-header">
                              {listServiciosGeneral}
                            </h5>
                            <span className="description-text">
                              TOTAL DE SERVICIOS (GENERAL)
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : tipoUsuario === "rHumanos" ? (
            <div>
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <div className="card card-widget widget-user shadow">
                    <div
                      className="widget-user-header bg-primary"
                      style={{ textTransform: "capitalize" }}
                    >
                      <p
                        className="widget-user-username homeUserName"
                        style={{
                          textAlign: "center",
                          fontStyle: "italic",
                          fontWeight: "bold",
                        }}
                      >
                        Bienvenido!!! {nombreUsuario}
                      </p>
                      <p className="widget-user-desc rolUser">Recursos Humanos</p>
                    </div>
                    <div className="widget-user-image">
                      <Image
                        className="img-circle elevation-2"
                        src="../dist/img/department.png"
                        alt="User Avatar"
                      />
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col-sm-6 border-right">
                          <div className="description-block">
                            <h5 className="description-header">{numEmpleados}</h5>
                            <span className="description-text">
                              TOTAL DE EMPLEADOS REGISTRADOS
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-6 border-right">
                          <div className="description-block">
                            <h5 className="description-header">{numCargos}</h5>
                            <span className="description-text">
                              CARGOS REGISTRADOS
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : tipoUsuario === "cProduccion" ? (
            <div>
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <div className="card card-widget widget-user shadow">
                    <div
                      className="widget-user-header bg-dark"
                      style={{ textTransform: "capitalize" }}
                    >
                      <p
                        className="widget-user-username homeUserName"
                        style={{
                          textAlign: "center",
                          fontStyle: "italic",
                          fontWeight: "bold",
                        }}
                      >
                        Bienvenido!!! {nombreUsuario}
                      </p>
                      <p className="widget-user-desc rolUser">
                        Control de Producción
                      </p>
                    </div>
                    <div className="widget-user-image">
                      <Image
                        className="img-circle elevation-2"
                        src="../dist/img/department.png"
                        alt="User Avatar"
                      />
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col-sm-6 border-right">
                          <div className="description-block">
                            <h5 className="description-header">
                              {listServiciosGeneral}
                            </h5>
                            <span className="description-text">
                              TOTAL DE SERVICIOS REGISTRADOS
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-6 border-right">
                          <div className="description-block">
                            <h5 className="description-header">{noODT}</h5>
                            <span className="description-text">
                              TOTAL DE ORDENES DE TRABAJO
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : tipoUsuario === "contabilidad" ? (
            <div>
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <div className="card card-widget widget-user shadow">
                    <div
                      className="widget-user-header bg-success"
                      style={{ textTransform: "capitalize" }}
                    >
                      <p
                        className="widget-user-username homeUserName"
                        style={{
                          textAlign: "center",
                          fontStyle: "italic",
                          fontWeight: "bold",
                        }}
                      >
                        Bienvenido!!! {nombreUsuario}
                      </p>
                      <p className="widget-user-desc rolUser">Contabilidad</p>
                    </div>
                    <div className="widget-user-image">
                      <Image
                        className="img-circle elevation-2"
                        src="../dist/img/conta.png"
                        alt="User Avatar"
                      />
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col-sm-6 border-right">
                          <div className="description-block">
                            <h5 className="description-header">{listarAbonos}</h5>
                            <span className="description-text">
                              PAGOS POR APROBAR DEL DIA ({dayjs(fechaFormat).format("LL")})
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-6 border-right">
                          <div className="description-block">
                            <h5 className="description-header">{listOS}</h5>
                            <span className="description-text">
                              PAGOS PENDIENTES DE ORDENES
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : tipoUsuario === "mesa de control" ? (
            <div>
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <div className="card card-widget widget-user shadow">
                    <div
                      className="widget-user-header bg-danger"
                      style={{ textTransform: "capitalize" }}
                    >
                      <p
                        className="widget-user-username homeUserName"
                        style={{
                          textAlign: "center",
                          fontStyle: "italic",
                          fontWeight: "bold",
                        }}
                      >
                        Bienvenido!!! {nombreUsuario}
                      </p>
                      <p className="widget-user-desc rolUser">Mesa de control</p>
                    </div>
                    <div className="widget-user-image">
                      <Image
                        className="img-circle elevation-2"
                        src="../dist/img/control.png"
                        alt="User Avatar"
                      />
                    </div>
                    <div className="card-footer">
                      <Row>
                        <Col sm={12} md={6} lg={6}>
                          <div
                            class="small-box bg-info"
                            style={{ height: "90%" }}
                          >
                            <div class="inner">
                              <h4>Doctores registrados</h4>
                              <h3>{numDocs}</h3>
                              <h4>Consultorios registrados</h4>
                              <h3>{numEstab}</h3>
                            </div>
                            <div class="icon">
                              <FontAwesomeIcon icon={faBuildingUser} />
                            </div>
                          </div>
                        </Col>

                        <Col sm={12} md={6} lg={6}>
                          <div class="small-box bg-warning">
                            <div class="inner">
                              <h3>Total de ordenes</h3>
                              <h2>{numeroOrdenes}</h2>
                              <h4>Registradas hoy</h4>
                              {/* Renderizar la frecuencia o "0" */}
                              {numeroOrdenesFechaHoy.length > 0 ? (
                                numeroOrdenesFechaHoy.map((orden) => (
                                  <h2>{orden.frecuencia}</h2>
                                ))
                              ) : (
                                <h2>0</h2>
                              )}
                            </div>
                            <div class="icon">
                              <FontAwesomeIcon icon={faChartLine} />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={6} lg={6}>
                          <div style={{ maxHeight: "50vh" }}>
                            {numeroOrdenesFecha.length > 0 ? (
                              <DynamicChart
                                frecuencias={frecuencias}
                                etiquetas={fechas}
                                chartType={"line"}
                                titInfo={"Ordenes registradas por día"}
                                bgColor={"#9c182d"}
                                chartTitle={"ORDENES POR FECHA"}
                              />
                            ) : (
                              <p>Cargando...</p>
                            )}
                          </div>
                        </Col>

                        <Col sm={12} md={6} lg={6}>
                          <div style={{ maxHeight: "50vh" }}>
                            {numeroOrdenesDireccion.length > 0 ? (
                              <DynamicChart
                                frecuencias={frecuenciasDom}
                                etiquetas={domiciliosDom}
                                chartType={"bar"}
                                titInfo={"Ordenes registradas por municipio"}
                                bgColor={"#0c1b30"}
                                chartTitle={"ORDENES POR MUNICIPIO"}
                              />
                            ) : (
                              <p>Cargando...</p>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              <Spinner animation="grow" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InicioPefil;

function formatModelRutas(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      numeroRuta: data.numeroRuta,
      ruta: data.ruta,
      nombreMensajero: data.nombreMensajero,
      correoMensajero: data.correoMensajero,
      vehiculoAsignado: data.vehiculoAsignado,
      numeroEconomico: data.numeroEconomico,
      horaInicio: data.horaInicio,
      observaciones: data.observaciones,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelMensajero(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      telefono: data.telefono,
      email: data.email,
      modeloVehiculo: data.modeloVehiculo,
      placa: data.placa,
      economico: data.economico,
      fecha: data.fecha,
      estado: data.estado,
      imagen: data.imagen,
    });
  });
  return dataTemp;
}

function formatModelUsuarios(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      email: data.email,
      password: data.password,
      tipo: data.tipo,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
    });
  });
  return dataTemp;
}

function formatModelOrdenesFecha(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      frecuencia: data.frecuencia,
      fecha: data.fecha,
    });
  });
  return dataTemp;
}

function formatModelOrdenesDireccion(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      frecuencia: data.frecuencia,
      direccion: data.direccion,
    });
  });
  return dataTemp;
}

function formatModelDoctor(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
      apellidoPaterno: data.apellidoPaterno,
      apellidoMaterno: data.apellidoMaterno,
      fechaNacimiento: data.fechaNacimiento,
      telefonoCelular: data.telefonoCelular,
      email: data.email,
      rfc: data.rfc,
      observaciones: data.observaciones,
      estado: data.estado,
      consultorio: data.consultorio,
      nombreConsultorio: data.nombreConsultorio,
      imagen: data.imagen,
    });
  });
  return dataTemp;
}

function formatModelFacturacion(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      rfc: data.rfc,
      razonSocial: data.razonSocial,
      regimenFiscal: data.regimenFiscal,
      tipoPersona: data.tipoPersona,
      usoCfdi: data.usoCfdi,
      referencia: data.referencia,
      calle: data.calle,
      numeroExterior: data.numeroExterior,
      numeroInterior: data.numeroInterior,
      codigoPostal: data.codigoPostal,
      colonia: data.colonia,
      municipio: data.municipio,
      estadoPais: data.estadoPais,
      idUsuarioDatos: data.idUsuarioDatos,
      observaciones: data.observaciones,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelAA(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      totalCantidadRecibida: data.totalCantidadRecibida,
    });
  });
  return dataTemp;
}

function formatModelSaldos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    console.log("data.totalPrecio", data.totalCantidadCargo);
    let totalOrdenesCarDesc =
      parseFloat(data.totalPrecio) +
      parseFloat(data.totalCantidadCargo) -
      parseFloat(data.totalCantidadDescuento);
    let saldo = 0;
    let pagado = 0;
    if (data.saldo) {
      saldo = parseFloat(data.saldo);
      pagado = saldo + parseFloat(data.totalCantidadRecibida);
    } else {
      pagado = parseFloat(data.totalCantidadRecibida);
    }
    dataTemp.push({
      id: data._id,
      totalOrdenes: data.count,
      totalOrdenesycargos: totalOrdenesCarDesc,
      pagado: pagado,
      pendiente: totalOrdenesCarDesc - pagado,
      vencido: data.totalSaldoVencido,
    });
  });
  return dataTemp;
}