// Constante para desarrollo
// export const API_HOST = "http://localhost:5050";

// Constantes para puesta en produccion
export const API_HOST = "https://api.grupodentalherfran.com";

// constantes para las pruebas
// export const API_HOST = "https://demoapilab.grupodentalherfran.com"

// API para la consulta de la IP pública
export const API_IP = "https://api.ipify.org";

export const API_CLOUDINARY = "https://api.cloudinary.com/v1_1/omarlestrella/upload";

export const API_OPENPAY = "https://sandbox-api.openpay.mx/v1/mh3qxtumcdo4u0fuvbg3";

export const API_CONVERSION = "https://v6.exchangerate-api.com/v6/32ed080a972460e0a895d534";

export const API_CUCC = 'http://127.0.0.1:8000/facturar/facturar-b64';

export const TOKEN = "token";
