import { Form, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { Load } from "../../load/load";
import { registraPrecios } from "../../../api/precios";
import { registraDepartamentos, obtenerNumeroDepartamentos } from "../../../api/departamentos";
import queryString from "query-string";
import { TablaMateriales } from "./TablaMateriales";
import { TablaPiezas } from "./TablaPiezas";
import { map } from "lodash";
import { LogsInformativos } from "../../logs/logs";

export default function AltaPrecios({ history, setShow }) {
    const [formData, setFormData] = useState(initialFormValue());

    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const handleShow3 = () => setShow3(true);
    const handleClose3 = () => setShow3(false);

    //load
    const [loading, setLoading] = useState(true);

    const [materiales, setMateriales] = useState([]);
    const [material, setMaterial] = useState("");

    const agregarMaterial = () => {
        const nuevoMaterial = { material };
        setMateriales([...materiales, nuevoMaterial]);
        handleClose2();
    };

    const [piezas, setPiezas] = useState([]);
    const [pieza, setPieza] = useState("");
    const [precio, setPrecio] = useState("");
    const [materialAsociado, setMaterialAsociado] = useState("");

    const agregarPiezas = () => {
        const nuevaPieza = { pieza, precio, materialAsociado };
        setPiezas([...piezas, nuevaPieza]);
        handleClose3();
    };


    useEffect(() => {
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    // Para almacenar el folio actual
    const [folioActual, setFolioActual] = useState("");

    const obtenerFolio = () => {
        try {
            obtenerNumeroDepartamentos().then(response => {
                const { data } = response;
                // console.log(data)
                const { noDepartamento } = data;
                setFolioActual(noDepartamento)
            }).catch(e => {
                console.log(e)
            })
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        obtenerFolio();
    }, []);

    //insert
    const onSubmit = (e) => {
        e.preventDefault();

        if (!formData.clasificacion) {
            toast.warning("Completa el formulario");
        } else {
            try {
                setLoading(true);

                const dataTemp = {
                    clasificacion: formData.clasificacion,
                    materiales: materiales,
                    piezas: piezas,
                    precio: "1",
                    estado: "true",
                };
                const dataTemp2 = {
                    folio: folioActual,
                    nombre: formData.clasificacion,
                    estado: "true",
                };
                registraPrecios(dataTemp).then((response) => {
                    const { data } = response;
                    console.log(data)
                    registraDepartamentos(dataTemp2)
                    toast.success(data.mensaje);

                    history({
                        search: queryString.stringify(""),
                    });
                    LogsInformativos("Se ha registrado el precio del material" + dataTemp.clasificacion, dataTemp)
                    setFormData(initialFormValue())
                    setLoading(false);
                    setShow(false);
                    //cancelarRegistro()
                });
            } catch (e) {
                console.log(e);
            }
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            {loading && <Load />}
            <div className='generalPrecios'>
                <Form className='formPrecios' onSubmit={onSubmit} onChange={onChange}>
                    <Form.Group>
                        <Form.Label className='lblClasificacionPre'>Clasificación: </Form.Label>
                        <Form.Control
                            type='text'
                            className='inpNombreSis'
                            placeholder='Nombre'
                            name="clasificacion"
                            defaultValue={formData.clasificacion}
                        />
                        <Button className="btnModal" variant="primary" onClick={handleShow2}>
                            Agregar Material
                        </Button>
                        <Modal show={show2} onHide={handleClose2}>
                            <Modal.Header closeButton>
                                <Modal.Title>Agregar material</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Label>Material:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="inpMedida"
                                        onChange={(e) => setMaterial(e.target.value)}
                                    />
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose2}>
                                    Cerrar
                                </Button>
                                <Button variant="primary" onClick={agregarMaterial}>
                                    Agregar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Form.Group>
                            {/************************COMPONENT TABLA************************ */}
                            <TablaMateriales productos={materiales} setProductos={setMateriales} />
                        </Form.Group>

                        <Button className="btnModal" variant="primary" onClick={handleShow3}>
                            Agregar Pieza
                        </Button>
                        <Modal show={show3} onHide={handleClose3}>
                            <Modal.Header closeButton>
                                <Modal.Title>Agregar pieza</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Label>Pieza:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="inpMedida"
                                        onChange={(e) => setPieza(e.target.value)}
                                    />
                                    <Form.Label>Material:</Form.Label>
                                    <Form.Control
                                        as="select"
                                        className="inpMedida"
                                        onChange={(e) => setMaterialAsociado(e.target.value)}
                                    >
                                        <option>Elige una opción</option>
                                        {map(materiales, (cat, index) => (
                                            <option key={index} value={cat?.material}>
                                                {cat?.material}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose3}>
                                    Cerrar
                                </Button>
                                <Button variant="primary" onClick={agregarPiezas}>
                                    Agregar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Form.Group>
                            {/************************COMPONENT TABLA************************ */}
                            <TablaPiezas productos={piezas} setProductos={setPiezas} />
                        </Form.Group>
                    </Form.Group>
                    <br />
                    <label></label>
                    <div className="divSubmit">
                        <input className="submit" value="Enviar" type="submit" />
                    </div>
                </Form>
            </div>
        </>
    )
}

function initialFormValue() {
    return {
        clasificacion: "",
        tipoServicios: "",
        servicios: "",
        precio: "",
        sistemaColorPrincipal: "",
        otrosColores: ""
    };
}
