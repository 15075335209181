import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faHandHolding,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Badge, Dropdown } from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import { Load } from "../load/load";
import { listarValesEstado } from "../../api/vales";
import EntregarMaterialVale from "./EntregarMaterialVale";
import CancelarVale from "./CancelarVale";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

const TablaMaterialesValePendientes = () => {
  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(true);

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);
  const [size, setSizeModal] = useState(null);

  const registroVale = (content) => {
    setTitulosModal("Registar prestamo");
    setContentModal(content);
    setShowModal(true);
  };

  const [listVales, setListVales] = useState([]);

  const obtenerVales = () => {
    try {
      listarValesEstado()
        .then((response) => {
          const { data } = response;
          if (!listVales && data) {
            setListVales(formatModelVales(data));
          } else {
            const datosVales = formatModelVales(data);
            setListVales(datosVales);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    if (!showModal) {
      obtenerVales();
    }
  }, [showModal]);

  const entregarMAterialVale = (content) => {
    setTitulosModal("Entregar material vale");
    setContentModal(content);
    setShowModal(true);
  };

  const cancelarVale = (content) => {
    setTitulosModal("Cancelar vale");
    setContentModal(content);
    setShowModal(true);
    setSizeModal("lg");
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "fecha",
      label: "FECHA",
      options: {
        customBodyRender: (value, tableMeta) => {

          return (
            <>
              {dayjs(value).format("LL")}
            </>
          );
        },
      },
    },
    {
      name: "folio",
      label: "FOLIO",
    },
    {
      name: "material",
      label: "MATERIAL SOLICITADO",
    },
    {
      name: "area",
      label: "AREA QUE SOLICITA",
    },
    {
      name: "recibio",
      label: "RECIBIÓ",
    },
    {
      name: "autorizo",
      label: "AUTORIZÓ",
    },
    {
      name: "entrego",
      label: "ENTREGÓ",
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estadoTexto = "";

          if (estado == "0") {
            estadoTexto = "CANCELADO";
          } else if (estado == "1") {
            estadoTexto = "PENDIENTE";
          } else {
            estadoTexto = "ENTREGADO";
          }

          return (
            <>
              <Badge
                bg={
                  estado == "0"
                    ? "danger"
                    : estado == "1"
                    ? "warning"
                    : "success"
                }
                //className="estado"
              >
                {estadoTexto}
              </Badge>
            </>
          );
        },
      },
    },
    {
      name: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[8] == "1" ? (
            <>
              <Dropdown>
                <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                  <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      entregarMAterialVale(
                        <EntregarMaterialVale
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faHandHolding}
                      style={{ color: "#ffc107" }}
                    />
                    &nbsp; Entregar Material
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      cancelarVale(
                        <CancelarVale
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faWarning}
                      style={{ color: "#ffff00" }}
                    />
                    &nbsp; Cancelar
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            "No disponibles"
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  const [mostrarPrimerDiv, setMostrarPrimerDiv] = useState(false);

  const handleClick = () => {
    setMostrarPrimerDiv(!mostrarPrimerDiv);
  };

  return (
    <>
      {loading && <Load />}
      <MUIDataTable
        title={"Lista de vales de material registrados"}
        data={listVales}
        columns={columns}
        options={options}
      />
      <BasicModal
        show={showModal}
        setShow={setShowModal}
        title={titulosModal}
      >
        {contentModal}
      </BasicModal>
    </>
  );
};

function formatModelVales(data) {
  const dataTemp = [];
  data.forEach((item) => {
    const materialProductos = item.material
      .map((material, index) => `${material.cantidad}-${material.producto}`)
      .join("  ➡️  ");

    dataTemp.push({
      id: item._id,
      folio: item.folio,
      fecha: item.fecha,
      material: materialProductos,
      area: item.area,
      recibio: item.recibio,
      autorizo: item.autorizo,
      entrego: item.entrego,
      estado: item.estado,
    });
  });
  return dataTemp;
}

export default TablaMaterialesValePendientes;
