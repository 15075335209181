import { API_HOST } from "../utils/constants";
import axios from 'axios';
import { ENDPOINTModificarMaterialesCatalogos, ENDPOINTEliminarMaterialesCatalogos, ENDPOINTListarMaterialesCatalogos, ENDPOINTListarPorDepMaterialesCatalogos, ENDPOINTObtenerFolioMaterialesCatalogos, ENDPOINTRegistrarMaterialesCatalogos } from "./endpoints";

export async function eliminarMaterialCat(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarMaterialesCatalogos + `/${id}`, data, config);
}

export async function actualizarMaterialesCatalogos(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTModificarMaterialesCatalogos + `/${id}`, data, config);
}

export async function listarMaterialCat() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarMaterialesCatalogos, config);
}

export async function registraMaterialCat(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarMaterialesCatalogos, data, config);
}

export async function listarMaterialPorDep(folioDepartamento) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarPorDepMaterialesCatalogos + `?folioDepartamento=${folioDepartamento}`, config);
}

export async function obtenerNumeroMaterial() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFolioMaterialesCatalogos, config);
}