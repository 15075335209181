import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faPlus,
  faCircleInfo,
  faArrowsLeftRight,
  faArrowLeft,
  faCancel,
  faBars,
  faEye
} from "@fortawesome/free-solid-svg-icons";
import { listarRecepciones } from "../../../api/recepciones";
import { Button, Accordion, Dropdown } from "react-bootstrap";
import { withRouter } from "../../../utils/withRouter";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import { Load } from "../../load/load";
import { toast } from "react-toastify";
import "../style.css";
import ImageVisualizerMaterial from "./ImageVisualizerMaterial";
import MaterialEntrega from "./MaterialEntrega";
import { obtenerUsuario } from "../../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import ModificarMaterial from "./ModificarMaterial";
import CancelarMaterialEntrega from "./CancelarMaterialEntrega";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function TblMaterial(props) {
  const { location, history } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [tipoUsuario, setTipoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [listarOrdenes, setListOrdenes] = useState([]);

  const obtenerOrdenesServicio = () => {
    try {
      listarRecepciones()
        .then((response) => {
          const { data } = response;

          if (!listarOrdenes && data) {
            setListOrdenes(formatModelOrdenes(data));
          } else {
            const datosOrdenes = formatModelOrdenes(data);
            setListOrdenes(datosOrdenes);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerOrdenesServicio();
  }, [location]);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarOrdenes);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroOrden = (content) => {
    setTitulosModal("Registrar material");
    setContentModal(content);
    setShowModal(true);
  };

  const modificarOrden = (content) => {
    setTitulosModal("Modificar Recepción");
    setContentModal(content);
    setShowModal(true);
  };

  const verOrden = (content) => {
    setTitulosModal("Detalles de la orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const eliminarOrden = (content) => {
    setTitulosModal("Cancelar Recepción");
    setContentModal(content);
    setShowModal(true);
  };

  const cancelarOrden = (content) => {
    setTitulosModal("Cancelar orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const transferirOrden = (content) => {
    setTitulosModal("Transferir orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const [mostrarPrimerDiv, setMostrarPrimerDiv] = useState(true);
  const [idOrdenSel, setidOrdenSel] = useState("");

  const handleClick = (tableMeta) => {
    // Verifica si tableMeta es undefined antes de acceder a rowData
    if (tableMeta && tableMeta.rowData) {
      // Accede a la información de la fila actual
      const rowData = tableMeta.rowData;

      // Obtén el valor de la primera columna (supongamos que es la posición 0 del array)
      const valorPrimeraColumna = rowData[0];

      // Actualiza la variable con el valor de la primera columna
      setidOrdenSel(valorPrimeraColumna);

      // Puedes hacer cualquier otra lógica que necesites con el valor obtenido
      console.log("Valor de la primera columna:", valorPrimeraColumna);
    } else {
      console.error("tableMeta o tableMeta.rowData es undefined");
    }
    setMostrarPrimerDiv(!mostrarPrimerDiv);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "folioOrden",
      label: "FOLIO DE LA ORDEN",
    },
    {
      name: "materialesEnviados",
      label: "MATERIALES ENVIADOS",
      options: {
        customBodyRender: (listarOrdenes) => {
          // Aquí puedes acceder a las propiedades del objeto y mostrarlas como desees
          return (
            <div>
              <>
                {listarOrdenes &&
                  listarOrdenes.map((material) => (
                    <div key={material.id}>
                      <h7>Nombre: {material.servicio}</h7>
                      <br />
                      <h7>Cantidad: {material.cantidad}</h7>
                      <br />
                      <hr />
                    </div>
                  ))}
              </>
            </div>
          );
        },
      },
    },
    {
      name: "usuario",
      label: "USUARIO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "otros",
      label: "OTROS",
    },
    {
      name: "IMAGENES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button variant="link" onClick={() => handleClick(tableMeta)}>
                <FontAwesomeIcon
                  icon={faEye}
                  style={{ color: "#000080" }}
                />
              </Button>
            </>
          );
        },
      },
    },
    {
      name: "estado",
      label: "ESTADO DEL PEDIDO",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "true") {
            estiloTexto = "Activo";
            estadoTexto = "Activo";
          } else {
            estiloTexto = "inhabilitado";
            estadoTexto = "Inhabilitado";
          }

          return <div className={estiloTexto}>{estadoTexto}</div>;
        },
      },
    },
    {
      name: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            tableMeta.rowData[6] == "true" ? (
              <>
                <Dropdown>
                  <Dropdown.Toggle
                    style={{
                      backgroundColor: "#0c1b30",
                      borderColor: "#0c1b30",
                    }}
                    id="dropdown-basic"
                  >
                    <FontAwesomeIcon icon={faBars} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        modificarOrden(
                          <ModificarMaterial
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faPen}
                        style={{ color: "#fd7e14" }}
                      />
                      &nbsp;Editar Recepción
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        eliminarOrden(
                          <CancelarMaterialEntrega
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faCancel}
                        style={{ color: "#dc3545" }}
                      />
                      &nbsp; Cancelar Recepción
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
              <>
                No disponibles
              </>
            )
          );
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && 'standard',
    selectableRows: isSmOrDown ? 'none' : 'multiple',
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      <div>
        {mostrarPrimerDiv ? (
          <div style={{ marginTop: "2%" }}>
            {loading && <Load />}
            <h1>Recepción de Material</h1>
            <div>
              <div className="divButton">
                <Button
                  className="btnAddTables"
                  onClick={() =>
                    registroOrden(
                      <MaterialEntrega
                        history={history}
                        setShow={setShowModal}
                      />
                    )
                  }
                >
                  <FontAwesomeIcon icon={faPlus} /> Agregar
                </Button>
              </div>
            </div>
            <div className="divTabla">
              <MUIDataTable
                title={"Listado de recepciones de materiales"}
                data={listarOrdenes}
                columns={columns}
                options={options}
              />
            </div>
            <BasicModal
              show={showModal}
              setShow={setShowModal}
              title={titulosModal}
            >
              {contentModal}
            </BasicModal>
          </div>
        ) : (
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: "2px",
                left: "10px",
              }}
            >
              <Button
                variant="primary"
                style={{ color: "#fff", textDecoration: "none" }}
                onClick={handleClick}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </Button>
            </div>
            <h2 style={{ textAlign: "center", marginTop: "2%" }}>
              Imagenes de orden de cliente
            </h2>
            <ImageVisualizerMaterial idOrdenSel={idOrdenSel} />
          </div>
        )}
      </div>
    </>
  );
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folioOrden: data.folioOrden,
      materialesEnviados: data.materialesEnviados,
      usuario: data.usuario,
      otros: data.otros,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default withRouter(TblMaterial);
