import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarOrdenesServicio,
    ENDPOINTGenerarFolioOrdenesServicio,
    ENDPOINTObtenerOrdenesServicio,
    ENDPOINTListarOrdenesServicio,
    ENDPOINTListarOrdenesServicioRetrabajo,
    ENDPOINTActualizarOrdenesServicio,
    ENDPOINTEliminarOrdenesServicio,
    ENDPOINTDeshabilitarOrdenesServicio,
    ENDPOINTActualizarCambiosOrdenesServicio,
    ENDPOINTObtenerFolioOrdenesServicio,
    ENDPOINTListarOrdenesServicioPorCorreoDoctor,
    ENDPOINTListarOrdenesServicioPorCorreoConsultorio,
    ENDPOINTListarOrdenesServicioPorCorreoMensajero,
    ENDPOINTObtenerNoRegistrosPorFecha,
    ENDPOINTAsignarMensajerosOrdenesServicio,
    ENDPOINTRecibirMaterialOrdenesServicio,
    ENDPOINTActualizaMaterialRecepcion,
    ENDPOINTObtenerNoRegistrosPorDireccion,
    ENDPOINTListarUltimoOrdenesServicio,
    ENDPOINTDeshabilitarOrdenesServicioFolio,
    ENDPOINTOrdenesySaldos,
    ENDPOINTActualizarTotalOrdenesServicio,
    ENDPOINTListarPaginandoOrdenesServicio,
    ENDPOINTTotalOrdenesServicio,
    ENDPOINTFiltrarSaldosGeneral,
    ENDPOINTFiltrarSaldosPeriodo,
    ENDPOINTProcesoCancelacionOrdenesServicio,
    ENDPOINTListaFechasyEstadoPago,
    ENDPOINTEnviarEmailRecordatorio,
    ENDPOINTObtenerOSPorFolio,
    ENDPOINTActulizarPagoOSPorFolio,
    ENDPOINTListarOrdenesConServicios,
    ENDPOINTDuplicarOrdenes,
    ENDPOINTListarOrdenesPorCargoOrden,
    ENDPOINTListarOrdenesConServiciosSep,
    ENDPOINTListarSaldosNuevo,
    ENDPOINTListaFechasyEstadoPagoCompleta,
    ENDPOINTFacturacionOrdenes,
    ENDPOINTListarOrdenesServicioPorMes
} from "./endpoints";
import axios from 'axios';

export async function listarUltimoOrdenesServicio() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarUltimoOrdenesServicio, config);
}

export async function listarOrdenesServicioPorMes() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarOrdenesServicioPorMes, config);
}

export async function listarOrdenesServicio() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarOrdenesServicio, config);
}

export async function listarOrdenesServicioRetrabajo() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarOrdenesServicioRetrabajo, config);
}

export async function listarPaginandoOrdenesServicio(pagina, limite) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarPaginandoOrdenesServicio +
        `/?pagina=${pagina}&&limite=${limite}`
        , config);
}

export async function totalOrdenesServicio() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
    return await axios.get(API_HOST + ENDPOINTTotalOrdenesServicio, config);
}

export async function listarOrdenesServicioPorCorreoDoctor(correoDoctor) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarOrdenesServicioPorCorreoDoctor + `?correoDoctor=${correoDoctor}`, config);
}

export async function generarFolioOrdenesServicio(prefijo) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTGenerarFolioOrdenesServicio + `?prefijo=${prefijo}`, config);
}

export async function listarOrdenesServicioPorCorreoMensajero(correoMensajero) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarOrdenesServicioPorCorreoMensajero + `?correoMensajero=${correoMensajero}`, config);
}

export async function listarOrdenesServicioPorCorreoConsultorio(correoConsultorio) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarOrdenesServicioPorCorreoConsultorio + `?correoConsultorio=${correoConsultorio}`, config);
}

export async function listarOrdenesServicioPorCargoOrden(email) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarOrdenesPorCargoOrden + `?email=${email}`, config);
}

export async function registraOrdenesServicio(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarOrdenesServicio, data, config);
}

// Para obtener todos los datos del usuario
export async function obtenerOrdenesServicio(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerOrdenesServicio + `/${params}`, config);
}

// Para obtener por folio
export async function obtenerOrdenesServicioPorFolio(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerOSPorFolio + `/${params}`, config);
}

export async function actualizarOrdenesServicio(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarOrdenesServicio + `/${id}`, data, config);
}

export async function duplicarOrdenesServicio(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTDuplicarOrdenes + `/${id}`, data, config);
}

export async function actualizarTotalOrdenesServicio(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarTotalOrdenesServicio + `/${id}`, data, config);
}

export async function recibirMaterialOrdenesServicio(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTRecibirMaterialOrdenesServicio + `/${id}`, data, config);
}

export async function asignarMensajeroOrdenesServicio(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTAsignarMensajerosOrdenesServicio + `/${id}`, data, config);
}

export async function eliminarOrdenesServicio(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarOrdenesServicio + `/${id}`, data, config);
}

export async function actualizaDeshabilitarOrdenesServicio(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarOrdenesServicio + `/${id}`, data, config);
}

export async function actualizaFacturacionOrdenes(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTFacturacionOrdenes + `/${id}`, data, config);
}

export async function actualizaEstadoCambiosOrdenesServicio(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: Bearer ${getTokenApi()}
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarCambiosOrdenesServicio + `/${id}`, data, config);
}

export async function actualizaDeshabilitarOrdenesServicioFolio(folio, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarOrdenesServicioFolio + `/${folio}`, data, config);
}

export async function actualizaPagoOrdenesServicioFolio(folio, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActulizarPagoOSPorFolio + `/${folio}`, data, config);
}

export async function actualizaProcesoCancelacionOrdenesServicio(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTProcesoCancelacionOrdenesServicio + `/${id}`, data, config);
}

// Para obtener el numero de pedido de venta actual
export async function obtenerNumeroOrdenServicio() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerFolioOrdenesServicio, config);
}

// Para obtener el numero de registros por fecha
export async function obtenerNumeroOrdenServicioFecha() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerNoRegistrosPorFecha, config);
}

// Para obtener el numero de registros por direccion
export async function obtenerNumeroOrdenServicioDireccion() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerNoRegistrosPorDireccion, config);
}

export async function actualizaMaterialesRecepcion(id, data) {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizaMaterialRecepcion + `/${id}`, data, config);
}

export async function listarSaldosNuevo() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarSaldosNuevo, config);
}

export async function listarOrdenesySaldos() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTOrdenesySaldos, config);
}

export async function ObtenerSaldosPeriodo(date1, date2) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTFiltrarSaldosPeriodo +
        `/?date1=${date1}&&date2=${date2}`, config);
}

export async function ObtenerSaldosGeneral() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTFiltrarSaldosGeneral, config);
}

export async function listarFechasyEstadoPago() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListaFechasyEstadoPago, config);
}

export async function listarFechasyEstadoPagoCompleta(correo) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListaFechasyEstadoPagoCompleta + `?email=${correo}`, config);
}

export async function enviaEmailRecordatorio(data) {
    //console.log(data)

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTEnviarEmailRecordatorio, data, config);
}

export async function listarOrdenesConServicios(folios) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarOrdenesConServicios + `?folios=${folios}`, config);
}

export async function listarOrdenesConServiciosSep(folios) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'

        }
    };
    return await axios.get(API_HOST + ENDPOINTListarOrdenesConServiciosSep + `?folios=${folios}`, config);
}