import { useRef, useState, useEffect } from "react";
import "./style.css";
import { Button, Table } from "react-bootstrap";
import { Form, Row, Col, Dropdown, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faPaperPlane,
  faMessage,
  faQuestion,
  faTriangleExclamation,
  faEye,
  faCheck,
  faBellConcierge,
  faBan,
  faBars,
  faCog,
  faTruck,
  faFileCircleCheck,
  faCircleCheck,
  faMoneyBillTransfer,
} from "@fortawesome/free-solid-svg-icons";
import MUIDataTable from "mui-datatables";
import {
  listarOrdenesServicio,
  obtenerOrdenesServicio,
  listarUltimoOrdenesServicio,
} from "../../api/ordenesServicio";
import { listarDoctores } from "../../api/doctores";
import {
  listarServiciosFolio,
  listarServiciosFolioDepartamento,
} from "../../api/servicios";
import { Timeline, TimelineEvent } from "react-event-timeline";
import { listarEvento, registraEvento } from "../../api/eventos";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CancelarServicio from "./CancelarServicio";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { map } from "lodash";
import { Load } from "../load/load";
import { obtenerUsuario } from "../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import EntregarOrdenServicio from "./EntregarOrdenServicio";
import FinalizarOrdenServicio from "./FinalizarOrdenServicio";
import RecibirOrdenServicio from "./RecibirOrdenServicio";
import CrearODT from "./CrearODT";
import { listarEstablecimientos } from "../../api/establecimientos";
import PreciosOrden from "./preciosOrden/PreciosOrden";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { listarPreciosOrden } from "../../api/preciosOrden";
import MaterialEntrega from "./material/MaterialEntrega";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat"; 

export default function DetalleOrden({ history, setShow }) {
  const [listarDoctor, setListDoctor] = useState([]);
  const [idUsuario, setIdeUsuario] = useState("");
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [tipoUsuario, setTipoUsuario] = useState("");
  const [departamentoUsuario, setDepartamentoUsuario] = useState("");
  const [listarServicios, setListServicios] = useState([]);

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setIdeUsuario(data._id);
          setNombreUsuario(data.nombre);
          setTipoUsuario(data.tipo);
          setDepartamentoUsuario(data.departamento);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [idOrden, setIdOrden] = useState();
  const [dataTemp, setDataTemp] = useState();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [formData, setFormData] = useState(initialFormValue());

  const [data1, setData1] = useState([]);

  const [contador, setContador] = useState(0);

  const [listarOrdenServicio, setListOrdenServicio] = useState([]);

  const [listarOrdenes, setListOrdenes] = useState([]);

  const obtenerOrdenesServicios = () => {
    try {
      listarOrdenesServicio()
        .then((response) => {
          const { data } = response;

          if (!listarOrdenes && data) {
            setListOrdenServicio(formatModelOrdenes(data));
          } else {
            const datosOrdenes = formatModelOrdenes(data);
            setListOrdenServicio(datosOrdenes);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerOrdenesServicios();
  }, []);

  const obtenerDatosOrden = () => {
    try {
      obtenerOrdenesServicio(
        !formData.ordenServicio
          ? listarOrdenServicio[0]?.id
          : formData.ordenServicio
      )
        .then((response) => {
          const { data } = response;
          setIdOrden(data._id);
          setData1(data);
          setDataTemp({
            folio: data.folio,
            fecha: data.fecha,
            localidad: data.localidad,
            nombreDoctor: data.nombreDoctor,
            nombrePaciente: data.nombrePaciente,
            tipoDentadura: data.tipoDentadura,
            servicios: data.servicios,
            otros: data.otros,
            recibio: data.recibio,
            fechaEntrada: data.fechaEntrada,
            emailDoctor: data.correoDoctor,
            cantidadMaterialEntregado: data.cantidadMaterialEntregado,
            status: data.estado,
          });
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerDatosOrden();
  }, [formData.ordenServicio, listarOrdenServicio[0]]);

  const [correoConsOrden, setcorreoConsOrden] = useState("");
  const [precioTotal, setPrecioTotal] = useState(0);

  const obtieneOrdenID = () => {
    try {
      listarOrdenesServicio()
        .then((response) => {
          if (response && response.data) {
            const { data } = response;
            if (!listarOrdenes && data) {
              setListOrdenes(formatModelOrdenes(data));
            } else {
              const datosOrdenes = formatModelOrdenes(data);
              const filterID = datosOrdenes.find(
                (data) => data.folio === dataTemp?.folio
              ); // Utiliza 'find' en lugar de 'filter'
              if (filterID) {
                setListOrdenes([filterID]); // Actualiza el estado con un array que contiene el elemento filtrado
                setcorreoConsOrden(filterID.correoConsultorio);
                setPrecioTotal(filterID.precioTotal);
              } else {
                console.log(
                  "No se encontró ningún elemento con el ID especificado"
                );
              }
            }
          } else {
            console.error("Error: No se recibieron datos válidos");
          }
        })
        .catch((error) => {
          console.error("Error al obtener datos:", error);
        });
    } catch (e) {
      console.error("Error en la función obtieneOrdenID:", e);
    }
  };

  useEffect(() => {
    obtieneOrdenID();
  }, [formData.ordenServicio, dataTemp?.folio, listarServicios]);

  const [listarEstablecimiento, setListEstablecimiento] = useState([]);
  const [domSel, setdomSel] = useState("");
  const [nombreConsultorio, setnombreConsultorio] = useState("");

  const obtenerEstablecimiento = () => {
    try {
      listarEstablecimientos()
        .then((response) => {
          const { data } = response;

          if (!listarEstablecimiento && data) {
            setListEstablecimiento(formatModelEstablecimiento(data));
          } else {
            const datosEstablecimiento = formatModelEstablecimiento(data);
            const filterID = datosEstablecimiento.find(
              (data) => data.email === correoConsOrden
            );
            if (filterID) {
              setnombreConsultorio(filterID.razonSocial);
              setdomSel(
                "C. " +
                filterID.calle +
                ", Ext. " +
                (filterID.numeroExterior !== undefined
                  ? filterID.numeroExterior
                  : "SN") +
                ", Int. " +
                (filterID.numeroInterior !== undefined
                  ? filterID.numeroInterior
                  : "SN") +
                ", Col. " +
                filterID.colonia +
                ", CP. " +
                filterID.codigoPostal +
                ", " +
                filterID.municipio +
                ", " +
                filterID.estadoEstablecimiento
              );
            } else {
              console.log(
                "No se encontró ningún elemento con el ID especificado"
              );
            }
            setListEstablecimiento(datosEstablecimiento);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerEstablecimiento();
  }, [correoConsOrden]);

  const [listEventos, setListEventos] = useState([]);

  const obtieneEventos = () => {
    try {
      listarEvento()
        .then((response) => {
          if (response && response.data) {
            const { data } = response;
            if (!listEventos && data) {
              setListEventos(formatModelOrdenes(data));
            } else {
              const datosEvento = formatModelEventos(data);
              const filterEvents = datosEvento.filter(
                (data) => data.folioOrden === dataTemp?.folio
              );

              if (filterEvents.length > 0) {
                setListEventos(filterEvents);
              } else {
                console.log(
                  "No se encontró ningún elemento con el ID especificado"
                );
              }
            }
          } else {
            console.error("Error: No se recibieron datos válidos");
          }
        })
        .catch((error) => {
          console.error("Error al obtener datos:", error);
        });
    } catch (e) {
      console.error("Error en la función obtieneOrdenID:", e);
    }
  };

  useEffect(() => {
    obtieneEventos();
  }, [listarDoctor]);

  const obtieneDoctor = () => {
    try {
      listarDoctores()
        .then((response) => {
          if (response && response.data) {
            const { data } = response;

            if (!listarDoctor && data) {
              setListDoctor(formatModelDoctor(data));
            } else {
              const datosDoctor = formatModelDoctor(data);
              const filterDoc = datosDoctor.filter(
                (data) => data.email == dataTemp?.emailDoctor
              );
              setListDoctor(filterDoc);
            }
          } else {
            console.error("Error: No se recibieron datos válidos");
          }
        })
        .catch((error) => {
          console.error("Error al obtener datos:", error);
        });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      obtieneDoctor();
    }, 0);
  }, [listarDoctor]);

  console.log();

  const [numeroRegistros, setNumeroRegistros] = useState(0);

  const obtenerServicios = () => {
    try {
      if (tipoUsuario == "departamento") {
        listarServiciosFolioDepartamento(dataTemp?.folio, departamentoUsuario)
          .then((response) => {
            const { data } = response;

            if (!listarServicios && data) {
              setListServicios(formatModelServicios(data));
            } else {
              const datosServicios = formatModelServicios(data);
              setListServicios(datosServicios);
              setNumeroRegistros(datosServicios.length);
            }
          })
          .catch((e) => { });
      } else {
        listarServiciosFolio(dataTemp?.folio)
          .then((response) => {
            const { data } = response;

            if (!listarServicios && data) {
              setListServicios(formatModelServicios(data));
            } else {
              const datosServicios = formatModelServicios(data);
              setListServicios(datosServicios);
              setNumeroRegistros(datosServicios.length);
            }
          })
          .catch((e) => { });
      }
    } catch (e) { }
  };

  useEffect(() => {
    obtenerServicios();
  }, [listarServicios]);

  const [nombreMensajero, setNombreMensajero] = useState("");
  const [correoMensajero, setCorreoMensajero] = useState("");
  const [telefonoMensajero, setTelefonoMensajero] = useState("");
  const [fechaProgramada, setFechaProgramada] = useState("");
  const [fechaReal, setFechaReal] = useState("");

  const obtenerDatosMensajero = () => {
    try {
      obtenerOrdenesServicio(idOrden)
        .then((response) => {
          const { data } = response;
          setNombreMensajero(data.mensajero);
          setCorreoMensajero(data.correoMensajero);
          setTelefonoMensajero(data.telefonoMensajero);
          setFechaProgramada(data.fechaProgramadaRecoleccion);
          setFechaReal(data.fechaRealProgramacion);
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerDatosMensajero();
  }, [listarServicios, idOrden]);


  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const cancelarServicio = (content) => {
    setTitulosModal("Cancelar servicio");
    setContentModal(content);
    setShowModal(true);
  };

  const asignarMensajero = (content) => {
    setTitulosModal("Asignar mensajero");
    setContentModal(content);
    setShowModal(true);
  };

  const entregarOrden = (content) => {
    setTitulosModal("Entregar orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const recibirOrden = (content) => {
    setTitulosModal("Recibir orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const finalizarOrden = (content) => {
    setTitulosModal("Finalizar orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const precioOrden = (content) => {
    setTitulosModal("Actualizar precio de orden");
    setContentModal(content);
    setShowModal(true);
  };

  const asignarDepartamentoOrden = (content) => {
    setTitulosModal("Asignar un departamento a la orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const registroOrden = (content) => {
    setTitulosModal("Registrar material");
    setContentModal(content);
    setShowModal(true);
  };

  const columns = [
    {
      name: "id",
      label: "No.",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "pieza",
      label: "Descripción de la pieza",
    },
    {
      name: "clasificacion",
      label: "Material",
    },
    {
      name: "descripcionAdicional",
      label: "Descripcion de protesis",
    },
    {
      name: "departamento",
      label: "Departamento",
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "1") {
            estiloTexto = "Nuevo";
            estadoTexto = "Nuevo";
          } else if (estado == "5") {
            estiloTexto = "Asignado";
            estadoTexto = "Asignado";
          } else if (estado == "0") {
            estiloTexto = "Cancelado";
            estadoTexto = "Cancelado";
          }

          return (
            <>
              <Badge
                bg={
                  estado == "1"
                    ? "info"
                    : estado == "5"
                      ? "warning"
                      : estado == "0"
                        ? "danger"
                        : ""
                }
              //className="estado"
              >
                {estiloTexto}
              </Badge>
            </>
          );
        },
      },
    },
    {
      name: "ODT",
      label: "ODT",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "folio",
      label: "Folio",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "acciones",
      label: "ACCIONES",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const estado = value;
          // En este método, puedes retornar el JSX del botón para cada fila de la tabla
          return tableMeta.rowData[6] != "0" &&
            tipoUsuario != "departamento" ? (
            <>
              <Dropdown>
                <Dropdown.Toggle
                  style={{ backgroundColor: "#0c1b30", borderColor: "#0c1b30" }}
                  id="dropdown-basic"
                >
                  <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {(!tableMeta.rowData[6] && Number(dataTemp?.status)) > 3 && (
                    <>
                      <Dropdown.Item
                        variant="success"
                        title="Asignar"
                        onClick={() =>
                          asignarDepartamentoOrden(
                            <CrearODT
                              setShow={setShowModal}
                              data={tableMeta.rowData}
                            />
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faCog} /> &nbsp; Crear orden de
                        trabajo
                      </Dropdown.Item>
                    </>
                  )}
                  <Dropdown.Item
                    variant="danger"
                    title="Cancelar"
                    onClick={() =>
                      cancelarServicio(
                        <CancelarServicio
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faBan} /> &nbsp; Cancelar servicio
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            "No disponible"
          );
        },
      },
    },
  ];

  const data = [];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    filterType: "checkbox",
    pagination: false,
  };

  const labelRef = useRef(null);

  const llamarTelefono = () => {
    if (labelRef.current) {
      const numeroTelefono = labelRef.current.textContent;
      // Aquí puedes hacer lo que desees con el número de teléfono
      // Por ejemplo, podrías usar window.open para llamar al número
      window.open(`tel:${numeroTelefono}`);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  let fecha = new Date();
  let dia = fecha.getDate(); // Obtener el día (del 1 al 31)
  let mes = fecha.getMonth() + 1; // Obtener el mes (del 0 al 11, por eso se suma 1)
  let anio = fecha.getFullYear(); // Obtener el año (ej. 2023)
  let hora = fecha.getHours(); // Obtener la hora (formato de 0 a 23)
  let minutos = fecha.getMinutes(); // Obtener los minutos

  let datosFecha = `${dia}/${mes}/${anio} ${hora}:${minutos}`;

  const guardarEvento = async (e) => {
    e.preventDefault();

    if (!formData.mensajeEvento || !formData.tipoEvento) {
      toast.warning("Completa el formulario");
    } else {
      try {
        const dataTemp2 = {
          fecha: datosFecha,
          folioOrden: dataTemp?.folio,
          usuario: nombreUsuario,
          tipoEvento: formData.tipoEvento,
          motivoEvento: formData.mensajeEvento,
          estado: "activo",
          leido: 0,
          vistaCliente: formData.vistaCliente == "" ? "2" : formData.vistaCliente
        };
        registraEvento(dataTemp2).then((response) => {
          const { data } = response;
          toast.success(data.mensaje);
          obtieneEventos();
          setFormData("");
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <>
      {loading && <Load />}
      <div className="generalDetalle">
        <div>
          {/* Stack the columns on mobile by making one full-width and the other half-width */}
          <Form onChange={onChange}>
            <div style={{ marginTop: "1%" }}>
              <Row>
                <Col
                  md={{ span: 5, offset: 3 }}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  <label style={{ width: "75%", fontSize: "larger" }}>
                    ORDEN DE CLIENTE:{" "}
                  </label>
                  <Form.Control
                    as="select"
                    type="text"
                    onChange={onChange}
                    className="slcOrdenDetalle"
                    name="ordenServicio"
                    defaultValue={formData.ordenServicio}
                    placeholder="Nombre del Doctor"
                  >
                    {map(listarOrdenServicio, (cat, index) => (
                      <option
                        key={index}
                        value={cat?.id}
                        selected={cat?.id == listarOrdenServicio[0]?.id}
                      >
                        {cat?.folio}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            </div>
          </Form>
          {(!formData.ordenServicio
            ? listarOrdenServicio[0]?.id
            : formData.ordenServicio) && (
              <>
                <Row style={{ marginRight: "1%" }}>
                  {tipoUsuario != "departamento" && (
                    <Col
                      xs={12}
                      md={
                        tipoUsuario != "departamento" &&
                          tipoUsuario != "mesa de control"
                          ? 7
                          : 12
                      }
                      lg={
                        tipoUsuario != "departamento" &&
                          tipoUsuario != "mesa de control"
                          ? 7
                          : 12
                      }
                    >
                      <div className="generalDetalleOrden">
                        <div className="datosDetalleOrden">
                          <div className="encabezadoDetalle">
                            <div className="ordenDiv">
                              <h4 style={{ marginLeft: "1vw" }}></h4>
                              ORDEN DE CLIENTE NO. &nbsp;
                              <label>{dataTemp?.folio}</label>
                            </div>
                            <div
                              className="statusDiv"
                              style={{
                                backgroundColor:
                                  dataTemp?.status !== "0" ? "green" : "red",
                              }}
                            >
                              <label className="lbltituloDetalle">STATUS: </label>
                              &nbsp;
                              <label>
                                {dataTemp?.status !== "0" ? "ACTIVO" : "INACTIVO"}
                              </label>
                            </div>
                          </div>
                          <div className="bodyDetalles">
                            {listarDoctor &&
                              listarDoctor.map((doctor) => (
                                <div>
                                  <div className="doctorDetalleOrden">
                                    <label className="lbltituloDetalle">
                                      Doctor:{" "}
                                    </label>
                                    &nbsp;
                                    <label className="lblDetalle">
                                      {doctor.nombre} {doctor.apellidoPaterno}{" "}
                                      {doctor.apellidoMaterno}
                                    </label>
                                  </div>
                                  <h5 style={{ marginTop: "2vh" }}>
                                    INFORMACIÓN DE CONTACTO
                                  </h5>
                                  <div className="contacto1Div">
                                    <div className="telefonoDetalle">
                                      <label className="lbltituloDetalle">
                                        Teléfono:{" "}
                                      </label>
                                      &nbsp;
                                      <label
                                        ref={labelRef}
                                        className="lblDetalle"
                                      >
                                        {doctor.telefonoCelular}
                                      </label>
                                      &nbsp; &nbsp;
                                      <Button
                                        variant="info"
                                        onClick={llamarTelefono}
                                      >
                                        <FontAwesomeIcon icon={faPhone} />
                                      </Button>
                                      <br />
                                      <label className="lbltituloDetalle">
                                        Email:
                                      </label>
                                      &nbsp;
                                      <label className="lblDetalle">
                                        {doctor.email}
                                      </label>
                                      <br />
                                      <label className="lbltituloDetalle">
                                        RFC:
                                      </label>
                                      &nbsp;
                                      <label className="lblDetalle">
                                        {doctor.rfc}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            <div className="detalleDiv">
                              <div className="detalles1">
                                <h5>DETALLES DE LA ORDEN</h5>
                                <div className="detalleNuevo">
                                  <label className="lbltituloDetalle">
                                    No. Piezas:
                                  </label>
                                  &nbsp;
                                  <label className="lblDetalle">
                                    {numeroRegistros}
                                  </label>
                                  <div className="detalles2">
                                    <label className="lbltituloDetalle">
                                      Fecha Alta:
                                    </label>
                                    &nbsp;
                                    <label className="lblDetalle">
                                      {dayjs(dataTemp?.fecha).format("LL")}
                                    </label>
                                    <br />
                                    <label className="lbltituloDetalle">
                                      Total de orden:
                                    </label>
                                    &nbsp;
                                    <label className="lblDetalle">
                                      {"$" +
                                        new Intl.NumberFormat("es-MX", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }).format(precioTotal) +
                                        "MXN"}
                                    </label>{" "}
                                    <Button
                                      variant="success"
                                      onClick={() =>
                                        precioOrden(
                                          <PreciosOrden
                                            setShow={setShowModal}
                                            orden={dataTemp?.folio}
                                            totalOrden={precioTotal}
                                            ordenServicio={idOrden}
                                          />
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faMoneyBillTransfer}
                                      />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}
                  {tipoUsuario != "mesa de control" &&
                    tipoUsuario != "departamento" && (
                      <Col
                        xs={12}
                        md={tipoUsuario != "departamento" ? 5 : 12}
                        lg={tipoUsuario != "departamento" ? 5 : 12}
                      >
                        <div className="divRecoleccion">
                          <div className="encabezadoRecoleccion">
                            <h4 style={{ marginLeft: "1vw" }}>
                              PROGRAMA DE RECOLECCIÓN
                            </h4>
                            <div className="statusRecoleccionD">
                              <div
                                className={
                                  dataTemp?.status === "1"
                                    ? "recCompletaDetalle"
                                    : dataTemp?.status === "2"
                                      ? "recPendDetalle"
                                      : dataTemp?.status === "3"
                                        ? "recExDetalle"
                                        : dataTemp?.status === "4"
                                          ? "recExDetalle"
                                          : dataTemp?.status === "5"
                                            ? "recExDetalle"
                                            : dataTemp?.status === "6"
                                              ? "recPendDetalle"
                                              : dataTemp?.status === "7"
                                                ? "recEntDetalle"
                                                : dataTemp?.status === "9"
                                                  ? "recEntDetalle"
                                                  : "recExDetalle"
                                }
                              >
                                <label>
                                  {dataTemp?.status === "1"
                                    ? "Pendiente de asignación"
                                    : dataTemp?.status === "2"
                                      ? "Recolección asignada"
                                      : dataTemp?.status === "3"
                                        ? "Orden entregada"
                                        : dataTemp?.status === "4"
                                          ? "Material Recolectado"
                                          : dataTemp?.status === "5"
                                            ? "Orden Finalizada"
                                            : dataTemp?.status === "6"
                                              ? "En Proceso"
                                              : dataTemp?.status === "7"
                                                ? "En ruta de entrega"
                                                : dataTemp?.status === "9"
                                                  ? "Retrabajo"
                                                  : "Entregado"}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="bodyRecoleccion">
                            <div className="mensajeroRecoleccion">
                              <h5>INFORMACIÓN DE CONTACTO</h5>
                              <hr />
                              <div>
                                <div className="telefonoDetalle">
                                  <div style={{ display: "flex" }}>
                                    <label>Mensajero asignado: </label>
                                    &nbsp;
                                    <label
                                      className="lblDetalle"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {nombreMensajero}
                                    </label>
                                  </div>
                                  <div style={{ display: "flex" }}>
                                    <label className="lbltituloDetalle">
                                      Teléfono:{" "}
                                    </label>
                                    &nbsp;
                                    <label ref={labelRef}>
                                      {telefonoMensajero}
                                    </label>
                                    &nbsp; &nbsp;
                                    <Button
                                      variant="info"
                                      onClick={llamarTelefono}
                                    >
                                      <FontAwesomeIcon icon={faPhone} />
                                    </Button>
                                  </div>
                                  <label className="lbltituloDetalle">
                                    Email:
                                  </label>
                                  &nbsp;
                                  <label className="lblDetalle">
                                    {correoMensajero}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="fechaRecoleccion">
                              <label>Fecha programada de recoleccion: </label>
                              &nbsp;
                              <label className="lblDetalle">
                                {fechaProgramada}
                              </label>
                              <br />
                              <label>Fecha real de recoleccion:</label>
                              <label className="lblDetalle">{fechaReal}</label>
                              <label>Cantidad de material recolectado:</label>
                              <label className="lblDetalle">
                                {dataTemp?.cantidadMaterialEntregado}
                              </label>
                            </div>
                            <div className="desRecoleccion">
                              <h5 style={{ marginTop: "2%" }}>
                                DESCRIPCIÓN DEL MATERIAL A ENVIAR:
                              </h5>
                              <hr />
                              {listarOrdenes &&
                                Array.isArray(listarOrdenes) &&
                                listarOrdenes.length > 0 ? (
                                listarOrdenes.map((orden, index) => (
                                  <div key={index} style={{ marginTop: "0%" }}>
                                    {orden.servicios &&
                                      Array.isArray(orden.servicios) &&
                                      orden.servicios.length > 0 ? (
                                      <Table striped bordered hover>
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Nombre</th>
                                            <th>Cantidad</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {orden.servicios.map(
                                            (servicio, servicioIndex) => (
                                              <tr key={servicioIndex}>
                                                <td>{servicioIndex + 1}</td>
                                                <td>{servicio.servicio}</td>
                                                <td>{servicio.cantidad}</td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </Table>
                                    ) : (
                                      <label>No hay servicios disponibles</label>
                                    )}
                                  </div>
                                ))
                              ) : (
                                <label>No hay órdenes disponibles</label>
                              )}
                            </div>
                          </div>
                          <div className="botonesRecoleccion">
                            <div className="botones1">
                              {tipoUsuario == "mensajero" &&
                                dataTemp?.status == "2" && (
                                  <>
                                    <Button
                                      variant="primary"
                                      className="botonesRec"
                                      onClick={() =>
                                        registroOrden(
                                          <MaterialEntrega
                                            history={history}
                                            setShow={setShowModal}
                                            data={data1}
                                          />
                                        )
                                      }
                                    >
                                      RECOLECTAR MATERIAL
                                    </Button>
                                  </>
                                )}
                              {tipoUsuario == "mensajero" &&
                                dataTemp?.status == "4" && (
                                  <>
                                    <Button
                                      variant="warning"
                                      className="botonesRec"
                                      onClick={() =>
                                        entregarOrden(
                                          <EntregarOrdenServicio
                                            setShow={setShowModal}
                                            data={data1}
                                          />
                                        )
                                      }
                                    >
                                      ENTREGAR
                                    </Button>
                                  </>
                                )}
                              {tipoUsuario == "administrador" &&
                                dataTemp?.status == "3" && (
                                  <>
                                    <Button
                                      variant="secondary"
                                      className="botonesRec"
                                      onClick={() =>
                                        recibirOrden(
                                          <RecibirOrdenServicio
                                            setShow={setShowModal}
                                            data={data1}
                                          />
                                        )
                                      }
                                    >
                                      RECIBIR
                                    </Button>
                                  </>
                                )}
                              {tipoUsuario == "administrador" &&
                                dataTemp?.status == "6" && (
                                  <>
                                    <Button
                                      variant="success"
                                      className="botonesRec"
                                      onClick={() =>
                                        finalizarOrden(
                                          <FinalizarOrdenServicio
                                            setShow={setShowModal}
                                            data={data1}
                                            setContador={setContador}
                                            contador={contador}
                                          />
                                        )
                                      }
                                    >
                                      FINALIZAR ORDEN
                                    </Button>
                                  </>
                                )}
                            </div>
                          </div>
                        </div>
                      </Col>
                    )}
                </Row>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <MUIDataTable
                      title={"Ordenes de trabajo"}
                      data={listarServicios}
                      columns={columns}
                      options={options}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <div className="divRegistro">
                      <div className="encabezadoRegistro">
                        <h4 style={{ marginLeft: "1vw" }}>
                          REGISTRO DE ACTIVIDAD
                        </h4>
                      </div>
                      <div className="mensajeArea">
                        <Form.Control
                          as="select"
                          aria-label="Default select example"
                          onChange={onChange}
                          name="vistaCliente"
                          defaultValue={formData.vistaCliente}
                        >
                          <option>Seleccionar si el cliente lo puede visualizar</option>
                          <option value="2">No</option>
                          <option value="1">Si</option>
                        </Form.Control>
                        <Form.Control
                          as="select"
                          aria-label="Default select example"
                          onChange={onChange}
                          name="tipoEvento"
                          defaultValue={formData.tipoEvento}
                        >
                          <option>Seleccionar tipo de evento</option>
                          <option value="Mensaje">Mensaje</option>
                          <option value="Advertencia">Advertencia</option>
                          <option value="Observación">Observación</option>
                          <option value="Otro">Otro</option>
                        </Form.Control>
                        <Form.Control
                          as="textarea"
                          placeholder="Escribe el mensaje"
                          style={{ height: "30%", marginTop: "2vh" }}
                          onChange={onChange}
                          name="mensajeEvento"
                          defaultValue={formData.mensajeEvento}
                        />
                        <div className="btnSendRegistro">
                          <Button variant="info" onClick={guardarEvento}>
                            <FontAwesomeIcon icon={faPaperPlane} />
                            &nbsp; Enviar
                          </Button>
                        </div>
                        <div>
                          <Timeline>
                            {listEventos.map((event, index) => (
                              <>
                                {(tipoUsuario == "administrador" || tipoUsuario == "mesa de control" || event.vistaCliente == "1") && (
                                  <>
                                    <TimelineEvent
                                      key={index}
                                      title={event.usuario}
                                      createdAt={event.fecha}
                                      icon={
                                        event.tipoEvento === "Otro" ? (
                                          <FontAwesomeIcon icon={faQuestion} />
                                        ) : event.tipoEvento === "Mensaje" ? (
                                          <FontAwesomeIcon icon={faMessage} />
                                        ) : event.tipoEvento === "Advertencia" ? (
                                          <FontAwesomeIcon
                                            icon={faTriangleExclamation}
                                          />
                                        ) : event.tipoEvento === "Observación" ? (
                                          <FontAwesomeIcon icon={faEye} />
                                        ) : event.tipoEvento === "Registro" ? (
                                          <FontAwesomeIcon icon={faBellConcierge} />
                                        ) : event.tipoEvento === "Entrega" ? (
                                          <FontAwesomeIcon icon={faTruck} />
                                        ) : event.tipoEvento === "Entregada" ? (
                                          <FontAwesomeIcon icon={faCircleCheck} />
                                        ) : (
                                          <FontAwesomeIcon icon={faQuestion} />
                                        )
                                      }
                                    >

                                      <h6>{event.tipoEvento}</h6>
                                      {event.motivoEvento}
                                    </TimelineEvent>
                                  </>
                                )}
                              </>
                            ))}
                          </Timeline>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}
        </div>
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function initialFormValue() {
  return {
    ordenServicio: "",
  };
}

function formatModelCargosyDescuentos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      ordenServicio: data.ordenServicio,
      tipoOperacion: data.tipo,
      concepto: data.concepto,
      cantidad: data.cantidad,
      correoRegistro: data.correoRegistro,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      fecha: data.fecha,
      localidad: data.localidad,
      nombreDoctor: data.nombreDoctor,
      nombrePaciente: data.nombrePaciente,
      precioTotal: data.precioTotal,
      tipoDentadura: data.tipoDentadura,
      otros: data.otros,
      recibio: data.recibio,
      fechaEntrada: data.fechaEntrada,
      servicios: data.servicios,
      cantidadRecbida: data.cantidadMaterialEntregado,
      correoConsultorio: data.correoConsultorio,
      precioTotal: data.precioTotal,
    });
  });
  return dataTemp;
}

function formatModelDoctor(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      apellidoPaterno: data.apellidoPaterno,
      apellidoMaterno: data.apellidoMaterno,
      fechaNacimiento: data.fechaNacimiento,
      telefonoCelular: data.telefonoCelular,
      email: data.email,
      rfc: data.rfc,
      observaciones: data.observaciones,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelEventos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      fecha: data.fecha,
      folioOrden: data.folioOrden,
      usuario: data.usuario,
      tipoEvento: data.tipoEvento,
      motivoEvento: data.motivoEvento,
      vistaCliente: !data.vistaCliente ? "0" : data.vistaCliente
    });
  });
  return dataTemp;
}

function formatModelServicios(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      clasificacion: data.clasificacion,
      material: data.material,
      pieza: data.pieza,
      precio: data.precio,
      descripcionAdicional: data.descripcionAdicional,
      sistemaColor: data.sistemaColor,
      distencion: data.distencion,
      diente: data.diente,
      tonoInferior: data.tonoInferior,
      tonoMedio: data.tonoMedio,
      tonoSuperior: data.tonoSuperior,
      departamento: !data.departamento ? data.clasificacion : data.departamento,
      ODT: data.ODT,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelEstablecimiento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      razonSocial: data.razonSocial,
      estadoEstablecimiento: data.estadoEstablecimiento,
      municipio: data.municipio,
      colonia: data.colonia,
      calle: data.calle,
      numeroExterior: data.numeroExterior,
      numeroInterior: data.numeroInterior,
      codigoPostal: data.codigoPostal,
      telefono: data.telefono,
      haceEsquina: data.haceEsquina,
      email: data.email,
      observaciones: data.observaciones,
      horario: data.horario,
      estado: data.estado,
      alias: data.alias,
      doctor: data.doctor,
      rfcDoctor: data.rfcDoctor,
    });
  });
  return dataTemp;
}
