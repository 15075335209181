import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faEye,
  faPlus,
  faBars,
  faFileCsv,
  faFileExport,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import {
  listarPiezasDetalles,
  listarPiezasPorDepartamento,
} from "../../../api/precios";
import { Button, Dropdown, Badge, Accordion } from "react-bootstrap";
import { withRouter } from "../../../utils/withRouter";
import EliminarPrecio from "./Eliminar";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import { Load } from "../../load/load";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AltaPreciosAct from "./AltaPreciosAct";
import { obtenerUsuario } from "../../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import { toast } from "react-toastify";
import CargarPiezasCSV from "./cargaCSV";
import { exportCSVFile } from "../../../utils/exportCSV";

function TblPrecios(props) {
  const { location, history } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [tipoUsuario, setTipoUsuario] = useState("");
  const [nombreDepartamento, setnombreDepartamento] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
          setnombreDepartamento(data.departamento);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [listarPrecio, setListPrecio] = useState([]);
  const [listarPrecio2, setListPrecio2] = useState([]);

  const obtenerPrecios = () => {
    try {
      listarPiezasDetalles()
        .then((response) => {
          const { data } = response;
          if (!listarPrecio && data) {
            setListPrecio(formatModelPrecio(data));
            setListPrecio2(formatModelPrecio2(data));
          } else {
            const datosPrecio = formatModelPrecio(data);
            setListPrecio(datosPrecio);
            const datosPrecio2 = formatModelPrecio2(data);
            setListPrecio2(datosPrecio2);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const obtenerPiezasDept = (departamento) => {
    try {
      listarPiezasPorDepartamento(departamento)
        .then((response) => {
          const { data } = response;

          if (!listarPrecio && data) {
            setListPrecio(formatModelPrecio(data));
          } else {
            const datosPrecio = formatModelPrecio(data);
            setListPrecio(datosPrecio);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    if (tipoUsuario != "departamento") {
      obtenerPrecios();
    } else {
      if (nombreDepartamento) {
        obtenerPiezasDept(nombreDepartamento);
      }
    }
  }, [tipoUsuario, nombreDepartamento, location]);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarPrecio);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  useEffect(() => {
    if (!showModal) {
      obtenerPrecios();
    }
  }, [showModal]);

  const registrarMaterialCategoria = (content) => {
    setTitulosModal("Materiales catalogo");
    setContentModal(content);
    setShowModal(true);
  };

  const registroPrecio = (content) => {
    setTitulosModal("Registro de protesis dentales");
    setContentModal(content);
    setShowModal(true);
  };

  const modificarPrecio = (content) => {
    setTitulosModal("Modificar pieza");
    setContentModal(content);
    setShowModal(true);
  };

  const eliminarPrecio = (content) => {
    setTitulosModal("Eliminar precio");
    setContentModal(content);
    setShowModal(true);
  };

  const cargarCsv = (content) => {
    setTitulosModal("Cargar registros");
    setContentModal(content);
    setShowModal(true);
  };

  const generacionCSVPiezasDentales = () => {
    try {
      toast.info("Estamos empaquetando tu respaldo, espere por favor ....")
      const timer = setTimeout(() => {
        exportCSVFile(listarPrecio2, "LISTA_PIEZAS_DENTALES");
      }, 5600);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log(e)
    }
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "folio",
      label: "FOLIO",
    },
    {
      name: "nombrePieza",
      label: "PIEZA",
    },
    {
      name: "nombreDepartamento",
      label: "DEPARTAMENTO",
    },
    {
      name: "nombreMaterial",
      label: "MATERIAL",
    },
    {
      name: "costoProduccion",
      label: "COSTO DE PRODUCCIÓN",
      options: {
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: "precioPublico",
      label: "PRECIO PARA EL PÚBLICO",
      options: {
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: "imagen",
      label: "IMAGEN",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const isValidURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value);

          if (isValidURL) {
            return (
              <a href={value} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon
                  icon={faEye}
                  style={{ color: "#000080" }}
                />
              </a>
            );
          } else {
            return "No hay imagen para visualizar";
          }
        },
      },
    },
    {
      name: "descripcionPieza",
      label: "DESCRIPCIÓN",
    },
    {
      name: "departamento",
      label: "FOLIO DEP",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "material",
      label: "FOLIO MAT",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          return (
            <>
              <Badge
                style={{ textTransform: "capitalize" }}
                bg={value == "activo" ? "success" : "danger"}
              >
                {value}
              </Badge>
            </>
          );
        },
      },
    },
    {
      name: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {tipoUsuario != "departamento" ? (
                <Dropdown>
                  <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                    <FontAwesomeIcon icon={faBars} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        modificarPrecio(
                          <AltaPreciosAct
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                            funcion={"modificar"}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faPen}
                        style={{ color: "#ffc107" }}
                      />
                      &nbsp; Modificar
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        eliminarPrecio(
                          <EliminarPrecio
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{ color: "#dc3545" }}
                      />
                      &nbsp; Eliminar
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <span>No disponibles</span>
              )}
            </>
          );
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  return (
    <>
      {loading && <Load />}
      <h1>Protesis dentales</h1>
      <div>
        {tipoUsuario != "departamento" && (
          <div className="divButton">
            <Button
              variant="warning"
              onClick={() => {
                generacionCSVPiezasDentales()
              }}
            >
              <FontAwesomeIcon icon={faDownload} /> Descargar CSV
            </Button>
            <Button
              variant="success"
              className="ml-1"
              onClick={() =>
                cargarCsv(
                  <CargarPiezasCSV history={history} setShow={setShowModal} funcion={"registro"} />
                )
              }
            >
              <FontAwesomeIcon icon={faFileCsv} /> Cargar CSV
            </Button>
            <Button
              className="btnAddTables ml-1"
              onClick={() =>
                registroPrecio(
                  <AltaPreciosAct history={history} setShow={setShowModal} funcion={"registro"} />
                )
              }
            >
              <FontAwesomeIcon icon={faPlus} /> Agregar pieza
            </Button>
          </div>
        )}
      </div>
      <div className="divTabla">
        <MUIDataTable
          title={"Lista de piezas registradas"}
          data={listarPrecio}
          columns={columns}
          options={options}
        />
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function formatModelPrecio(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombrePieza: data.nombrePieza.replace(/,/g, ''),
      material: data.material.replace(/,/g, ''),
      departamento: data.departamento,
      costoProduccion: data.costoProduccion,
      precioPublico: data.precioPublico,
      nombreMaterial: data.nombreMaterial.replace(/,/g, ''),
      nombreDepartamento: data.nombreDepartamento.replace(/,/g, ''),
      nombreDepartamento: data.nombreDepartamento.replace(/,/g, ''),
      imagen: data.imagen,
      descripcionPieza: data.descripcion,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelPrecio2(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      nombrePieza: data.nombrePieza.replace(/,/g, ''),
      material: data.material.replace(/,/g, ''),
      departamento: data.departamento,
      costoProduccion: data.costoProduccion,
      precioPublico: data.precioPublico,
      nombreMaterial: data.nombreMaterial.replace(/,/g, ''),
      nombreDepartamento: data.nombreDepartamento.replace(/,/g, ''),
      descripcionPieza: data.descripcion.replace(/,/g, ''),
    });
  });
  return dataTemp;
}

export default withRouter(TblPrecios);
