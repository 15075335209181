import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import {
  listarMovimientosMedicamentosFiltroGeneralFecha,
} from "../../api/movimientosMedicamentos";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Load } from "../load/load";
import { Button, Form, Row, Col } from "react-bootstrap";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { exportCSVFile } from "../../utils/exportCSV";
import { toast } from "react-toastify";
import {
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function TblMovimientosGeneralAPL({ props }) {
  const idArticuloAMP = props;
  //load
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarAlmacenFiltro);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "idArticuloAMP",
      label: "ID DEL ARTICULO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "producto",
      label: "PRODUCTO",
    },
    {
      name: "tipo",
      label: "TIPO",
    },
    {
      name: "cantidadMovimiento",
      label: "CANTIDAD MOVIMIENTO",
    },
    {
      name: "unidadMedida",
      label: "UNIDAD DE MEDIDA",
    },
    {
      name: "departamento",
      label: "DEPARTAMENTO",
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && 'standard',
    selectableRows: isSmOrDown ? 'none' : 'multiple',
  };

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const obtenerFechaHoy = () => {
    const hoy = new Date();
    const año = hoy.getFullYear();
    const mes = String(hoy.getMonth() + 1).padStart(2, '0'); // Los meses son 0-indexados
    const día = String(hoy.getDate()).padStart(2, '0');

    return `${año}-${mes}-${día}`;
  };

  const [fechaInicio, setFechaInicio] = useState(obtenerFechaHoy());
  const [fechaFin, setFechaFin] = useState(obtenerFechaHoy());

  const handleInputChangeInicio = (e) => {
    setFechaInicio(e.target.value);
  };

  const handleInputChangeFin = (e) => {
    setFechaFin(e.target.value);
  };

  const [listarAlmacenFiltro, setListAlmacenFiltro] = useState([]);

  const obtenerAlmacenFiltroFecha = () => {
    try {
      listarMovimientosMedicamentosFiltroGeneralFecha(fechaInicio, fechaFin)
        .then((response) => {
          const { data } = response;

          if (!listarAlmacenFiltro && data) {
            setListAlmacenFiltro(formatModelAlmacen(data));
          } else {
            const datosAlmacen = formatModelAlmacen(data);
            setListAlmacenFiltro(datosAlmacen);
          }
        }).catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerAlmacenFiltroFecha();
  }, [fechaInicio, fechaFin]);

  const csvMovimientos = () => {
    try {
      toast.info("Estamos empaquetando tu respaldo, espere por favor ....")
      const timer = setTimeout(() => {
        exportCSVFile(listarAlmacenFiltro, "MOVIMIENTOS_MEDICAMENTOS_" + fechaInicio + "-" + fechaFin);
      }, 5600);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      {loading && <Load />}

      <div style={{ width: "100%", marginLeft: "2%", marginRight: "2%", marginTop: "4vh" }}>
        <Row>
          <Col sm={12} md={7}>
            <Form.Group>
              <Row xs={12} md={8} lg={8} style={{ textAlign: "center" }}>
                <Col>
                  <div>
                    <Form.Label className="lblTelefono">
                      Fecha Inicio:
                    </Form.Label>
                    <Form.Control
                      placeholder="cantidad del movimiento"
                      className="inpTelefono"
                      type="date"
                      name="fechaInicio"
                      value={fechaInicio}
                      onChange={handleInputChangeInicio}
                    />
                  </div>
                </Col>
                <Col>
                  <div>
                    <Form.Label className="lblEmail">Fecha Fin:</Form.Label>
                    <Form.Control
                      placeholder="cantidad del movimiento"
                      className="inpEmail"
                      type="date"
                      name="fechaFin"
                      value={fechaFin}
                      onChange={handleInputChangeFin}
                    />
                  </div>
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col sm={12} md={5} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
            <Row>
              <Col xs={12} md={5} lg={5}>
                <Button
                  style={{ marginRight: "1vw" }}
                  variant="primary"
                  onClick={() => {
                    csvMovimientos()
                  }}
                >
                  <FontAwesomeIcon icon={faDownload} /> CSV periodo
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="divTabla">
        <MUIDataTable
          title={
            "Lista de movimientos del almacen"
          }
          data={listarAlmacenFiltro}
          columns={columns}
          options={options}
        />
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function formatModelAlmacen(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      idArticuloAMP: data.idArticuloAMP,
      tipo: data.tipo,
      producto: data.producto,
      cantidadMovimiento: data.cantidadMovimiento,
      unidadMedida: data.unidadMedida,
      departamento: data.departamento,
      fecha: data.createdAt,
    });
  });
  return dataTemp;
}

export default TblMovimientosGeneralAPL;
