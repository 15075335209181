import { useState, useEffect, useRef } from "react";
import { Form, Button, Col, Row, InputGroup } from "react-bootstrap";
import { Tabla } from "./Tabla";
import imagenDos from "../../assets/img/color.png";
import imagenDT from "../../assets/img/TEMPORAL2.png";
import imagen from "../../assets/img/PERMANENTE2.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import {
  actualizarOrdenesServicio,
  obtenerOrdenesServicio,
  obtenerNumeroOrdenServicio,
  listarOrdenesServicio,
} from "../../api/ordenesServicio";
import { withRouter } from "../../utils/withRouter";
import queryString from "query-string";
import { subeArchivosCloudinary } from "../../api/cloudinary";
import { listarServiciosFolio, registraServicios } from "../../api/servicios";
import { listarMensajeros } from "../../api/mensajeros";
import { Load } from "../load/load";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFloppyDisk,
  faArrowRight,
  faArrowLeft,
  faPlus,
  faCloudArrowUp,
  faFile,
  faTrash,
  faMicrophone
} from "@fortawesome/free-solid-svg-icons";
import { listarPiezasPorMaterial, listarPrecios } from "../../api/precios";
import {
  listarColorimetria,
  obtenerColorimetria,
} from "../../api/colorimetria";
import { map } from "lodash";
import { TablaServicios } from "./TablaServicios";
import { listarDoctores, listarDoctoresPorCorreo, listarDoctoresPorCorreoDoctor } from "../../api/doctores";
import { listarEstablecimientos } from "../../api/establecimientos";
import html2canvas from "html2canvas";
import { registraEvento } from "../../api/eventos";
import Dropzone from "../dropzone/Dropzone";
import { obtenerUsuario } from "../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { registraPreciosOrden } from "../../api/preciosOrden";
import { LogsInformativos } from "../logs/logs";
import { listarDepartamentos, listarPorTipoDepartamento } from "../../api/departamentos";
import { listarMaterialPorDep } from "../../api/materialesCat";
import estadosPaisData from '../administrador/catalogosJSON/estadosPais.json';
import municipiosEstadoData from '../administrador/catalogosJSON/municipiosEstadosPais.json'

export default function ModificarOrdenServicio({ data, history, setShow }) {
  const idODS = data[0];
  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState('');
  const [audioURLCloudinary, setAudioURLCloudinary] = useState('');
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const [municipioSeleccionado, setMunicipioSeleccionado] = useState(data[19]);

  const startRecording = async () => {
    setIsRecording(true);
    audioChunksRef.current = [];

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };
      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioURL(audioUrl);

        // Subir a Cloudinary
        await subeArchivosCloudinary(audioBlob, "laboratorio").then((response) => {
          const { data } = response;
          const { secure_url } = data;
          console.log(secure_url);
          setAudioURLCloudinary(secure_url);
        })
      };
      mediaRecorderRef.current.start();
    } catch (err) {
      console.error('Error accessing microphone: ', err);
      setIsRecording(false);
    }
  };

  const stopRecording = () => {
    setIsRecording(false);
    mediaRecorderRef.current.stop();
  };

  console.log(audioURLCloudinary)

  const [formData, setFormData] = useState(initialFormValue());
  const [formData2, setFormData2] = useState(initialFormValue2());

  const [nombreUsuario, setNombreUsuario] = useState("");
  const [tipoUsuario, setTipoUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState(data[10]);

  const [listarMensajero, setListMensajero] = useState([]);

  const obtenerMensajeros = () => {
    try {
      listarMensajeros()
        .then((response) => {
          const { data } = response;

          if (!listarMensajero && data) {
            setListMensajero(formatModelMensajero(data));
          } else {
            const datosMensajeros = formatModelMensajero(data);
            setListMensajero(datosMensajeros);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerMensajeros();
  }, []);

  const canvasRef = useRef(null);
  const [markerX, setMarkerX] = useState(0);
  const [markerY, setMarkerY] = useState(0);

  const [listarDoctor, setListDoctor] = useState([]);

  const obtenerDoctor = () => {
    try {
      listarDoctores()
        .then((response) => {
          const { data } = response;

          if (!listarDoctor && data) {
            setListDoctor(formatModelDoctor(data));
          } else {
            const datosDoctor = formatModelDoctor(data);
            setListDoctor(datosDoctor);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const obtenerDoctorConsultorio = () => {
    try {
      listarDoctoresPorCorreo(formData.consultorio.split("/")[1])
        .then((response) => {
          const { data } = response;

          if (!listarDoctor && data) {
            setListDoctor(formatModelDoctor(data));
          } else {
            const datosDoctor = formatModelDoctor(data);
            setListDoctor(datosDoctor);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    if (formData.consultorio == "Sin consultorio/NA") {
      obtenerDoctor();
    } else {
      obtenerDoctorConsultorio();
    }
  }, [formData.consultorio]);

  const [listServiciosFolio, setListServiciosFolio] = useState([]);

  const obtenerServiciosFolio = () => {
    try {
      listarServiciosFolio(data[1])
        .then((response) => {
          const { data } = response;

          if (!listServiciosFolio && data) {
            setListServiciosFolio(formatModelServicios(data));
            //setServiciosAgg(formatModelServicios(data));
          } else {
            const datosServicios = formatModelServicios(data);
            setListServiciosFolio(datosServicios);
            //setServiciosAgg(datosServicios);
            console.log(listServiciosFolio);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerServiciosFolio();
  }, [])

  console.log(listServiciosFolio)

  const [sumaPrecios, setSumaPrecios] = useState(0);

  useEffect(() => {
    const calcularSumaPrecios = () => {
      if (listServiciosFolio && listServiciosFolio.length > 0) {
        const nuevaSumaPrecios = listServiciosFolio.reduce(
          (acumulador, orden) => acumulador + parseFloat(orden.precio),
          0
        );
        setSumaPrecios(nuevaSumaPrecios);
        console.log(sumaPrecios);
      } else {
        setSumaPrecios(0);
      }
    };

    calcularSumaPrecios();
  }, [listServiciosFolio]);

  const [listarEstablecimiento, setListEstablecimiento] = useState([]);

  const obtenerEstablecimiento = () => {
    try {
      listarEstablecimientos()
        .then((response) => {
          const { data } = response;

          if (!listarEstablecimiento && data) {
            setListEstablecimiento(formatModelEstablecimiento(data));
          } else {
            const datosEstablecimiento = formatModelEstablecimiento(data);
            setListEstablecimiento(datosEstablecimiento);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerEstablecimiento();
  }, []);

  const [nombreConsultorio, setnombreConsultorio] = useState("");
  const [municipioConsultorio, setMunicipioConsultorio] = useState("");
  const [cpConsultorio, setcpConsultorio] = useState("");
  const [localidadConsultorio, setLocalidadConsultorio] = useState("");
  const [listDoctorFilter, setlistDoctorFilter] = useState([]);
  const [nombreDocFilter, setnombreDocFilter] = useState("");
  const [listConsultoriosFilter, setlistConsultoriosFilter] = useState([]);
  const [emailConsDoc, setemailConsDoc] = useState("");

  const obtenerDoctorConsultorioPerf = (correo) => {
    try {
      listarDoctoresPorCorreo(correo)
        .then((response) => {
          const { data } = response;

          if (!listarDoctor && data) {
            setlistDoctorFilter(formatModelDoctor(data));
          } else {
            const datosDoctor = formatModelDoctor(data);
            setlistDoctorFilter(datosDoctor);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const obtenerDoctorPorCorreo = (correo) => {
    try {
      listarDoctoresPorCorreoDoctor(correo)
        .then((response) => {
          const { data } = response;
          if (data.length > 0) {
            const filterDoc = data[0]
            const nombreDoc = filterDoc.nombre +
              " " +
              filterDoc.apellidoPaterno +
              " " +
              filterDoc.apellidoMaterno;
            setnombreDocFilter(nombreDoc)
            setemailConsDoc(filterDoc.correoConsultorio)
            //setListDoctor(formatModelDoctor(data));
          } else {

            //setListDoctor(datosDoctor);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    if (tipoUsuario === "consultorio") {
      obtenerDoctorConsultorioPerf(correoUsuario)
    } else if (tipoUsuario === "doctor") {
      obtenerDoctorPorCorreo(correoUsuario)
    }
  }, [tipoUsuario, correoUsuario])


  useEffect(() => {
    if (tipoUsuario === "consultorio") {
      const filterID = listarEstablecimiento.find(
        (data) => data.email === correoUsuario
      );
      if (filterID) {
        setnombreConsultorio(filterID.razonSocial);
        setMunicipioConsultorio(filterID.municipio);
        setcpConsultorio(filterID.codigoPostal);
        setLocalidadConsultorio(filterID.colonia);
      } else {
        console.log("No se encontró ningún elemento con el ID especificado");
      }
    }
  }, [listarEstablecimiento, correoUsuario, tipoUsuario]);

  useEffect(() => {
    const filterID = listarEstablecimiento.filter(
      (data) => data.email === emailConsDoc
    );
    if (filterID) {
      setlistConsultoriosFilter(filterID);
    } else {
      console.log("No se encontró ningún elemento con el ID especificado");
    }
  }, [emailConsDoc, listarEstablecimiento]);

  const [opcion, setOpcion] = useState("DP");
  const cambiaImagen = (event) => {
    setOpcion(event.target.value);
  };

  const [selectedCoordinate, setSelectedCoordinate] = useState("");
  const [nombreDiente, setNombreDiente] = useState("");

  const [selectedElements, setSelectedElements] = useState([]);
  const [selectedElementsArr, setSelectedElementsArr] = useState([]);

  const handleCoordinateChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedElements((prevSelectedElements) => [
      ...prevSelectedElements,
      selectedValue,
    ]);
    setSelectedElementsArr((prevSelectedElements1) => [
      ...prevSelectedElements1,
      selectedValue.split(",")[selectedValue.split(",").length - 1],
    ]);
  };

  const handleDeleteSelected = (index, event) => {
    event.preventDefault();
    const newSelectedElements = [...selectedElements];
    newSelectedElements.splice(index, 1);
    setSelectedElements(newSelectedElements);
  };


  useEffect(() => {
    selectedElements.forEach((selectedValue) => {
      const [x, y, nombreDiente] = selectedValue.split(",").map(Number);
      setMarkerX(x);
      setMarkerY(y);
      setNombreDiente(nombreDiente);
    });
  }, [selectedElements]);

  console.log(selectedElements);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");

      // Borrar el contenido anterior del canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Cargar la imagen de fondo
      const backgroundImage = new Image();
      backgroundImage.src =
        opcion == "DP" ? imagen : opcion == "DT" ? imagenDT : null;

      backgroundImage.onload = () => {
        // Obtén el contenedor del canvas
        const container = document.querySelector(".containerCanvasMap");

        if (container) {
          // Establece el fondo del contenedor para mostrar la imagen seleccionada
          container.style.backgroundImage = `url('${backgroundImage.src}')`;
          // Elimina cualquier imagen de fondo anterior
          const containerChildren = container.children;
          for (let i = 0; i < containerChildren.length; i++) {
            if (
              containerChildren[i] !== backgroundImage &&
              containerChildren[i] !== canvas
            ) {
              containerChildren[i].style.display = "none";
            }
          }
        }

        // Iterar sobre todos los elementos en selectedElements y dibujar un marcador para cada uno
        selectedElements.forEach((selectedValue) => {
          const [x, y, nombreDiente] = selectedValue.split(",").map(Number);

          ctx.fillStyle = "red";
          ctx.beginPath();
          ctx.arc(x, y, 6, 0, Math.PI * 2);
          ctx.fill();
        });
      };
    }
  }, [selectedElements]);

  useEffect(() => {
    setSelectedElements([]);
    setSelectedElementsArr([]);
  }, [opcion]);

  const [listarDepartamento, setListDepartamento] = useState([]);

  const obtenerDepartamento = () => {
    try {
      listarPorTipoDepartamento("Produccion")
        .then((response) => {
          const { data } = response;

          if (!listarDepartamento && data) {
            setListDepartamento(formatModelDepartamento(data));
          } else {
            const datosDepartamento = formatModelDepartamento(data);
            setListDepartamento(datosDepartamento);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerDepartamento();
  }, []);

  const [listarMateriales, setListMateriales] = useState([]);

  const obtenerMaterialesFolio = (folio) => {
    try {
      listarMaterialPorDep(folio)
        .then((response) => {
          const { data } = response;

          if (!listarMateriales && data) {
            setListMateriales(formatModelMateriales(data));
          } else {
            setListMateriales(formatModelMateriales(data));
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [listarPiezas, setListPiezas] = useState([]);
  const [precio, setPrecio] = useState("");
  const [piezaSelect, setPiezaSelect] = useState("");

  useEffect(() => {
    setPiezaSelect("");
    setPrecio("");
    if (formData2.clasificacion) {
      obtenerMaterialesFolio(formData2.clasificacion.split("|")[0]);
    }
  }, [formData2.clasificacion]);

  const obtenerPiezas = (material) => {
    try {
      listarPiezasPorMaterial(material)
        .then((response) => {
          const { data } = response;

          if (!listarPiezas && data) {
            setListPiezas(formatModelPieza(data));
          } else {
            setListPiezas(formatModelPieza(data));
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  console.log(listarPiezas)

  useEffect(() => {
    setPiezaSelect("");
    setPrecio("");
    if (formData2.material) {
      obtenerPiezas(formData2.material.split("|")[0]);
    }
  }, [formData2.material]);

  useEffect(() => {
    if (piezaSelect != "") {
      setPrecio(piezaSelect.split("|")[1]);
    } else {
      setPrecio("");
    }
  }, [piezaSelect]);

  const [listarColorimetrias, setListColorimetrias] = useState([]);

  useEffect(() => {
    if (piezaSelect) {
      const selectedPieza = listarPiezas.find(
        (pieza) => pieza.nombrePieza === piezaSelect.split('|')[0]
      );
      if (selectedPieza) {
        setListColorimetrias(selectedPieza.colorimetrias);
      }
    }
  }, [piezaSelect]);

  console.log(listarColorimetrias)

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const dataTemp = {
        folio: data[1],
        correoDoctor: data[10],
        cambios: "2"
      };
      actualizarOrdenesServicio(idODS, dataTemp).then((response) => {
        const { data } = response;

        {
          serviciosAgg.map((servicios, index) => {
            registraServicios(servicios);
          });
        }

        toast.success(data.mensaje);
        LogsInformativos(
          "Se ha registrado la orden de cliente " + dataTemp.folio,
          dataTemp
        );
        history({
          search: queryString.stringify(""),
        });
        setLoading(false);
        setShow(false);
        //cancelarRegistro()
      }).catch(e => {
        console.log(e)
        if (e.message === 'Network Error') {
          //console.log("No hay internet")
          toast.error("Conexión al servidor no disponible");
          setLoading(false);
        } else {
          if (e.response && e.response.status === 401) {
            const { mensaje } = e.response.data;
            toast.error(mensaje);
            setLoading(false);
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  async function ejecutarCapturasYMostrarLogs() {
    if (
      !formData2.clasificacion ||
      !piezaSelect ||
      !formData2.material ||
      !formData2.descripcionServicio ||
      !formData2.sistemaColor
    ) {
      toast.warning("Faltan datos por ingresar");
    } else {
      try {
        await Promise.all([
          ejecutarCapturaEImagen(),
          ejecutarCapturaEImagenCanvas(),
        ]);
      } catch (error) {
        console.error("Error en ejecutarCapturasYMostrarLogs:", error);
      }
    }
  }

  const [linkImagenEt, setLinkImagenEt] = useState("");

  const capturarPantalla = () => {
    return new Promise((resolve, reject) => {
      const elemento = document.getElementById("imgGenera");

      html2canvas(elemento, { useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        cargarImagen1(imgData).then(() => {
          resolve(); // Resuelve la promesa después de cargar la imagen
        });
      });
    });
  };

  const cargarImagen1 = (imgData) => {
    return new Promise((resolve, reject) => {
      try {
        subeArchivosCloudinary(imgData, "laboratorio")
          .then((response) => {
            const { data } = response;
            const { secure_url } = data;
            setLinkImagenEt(secure_url);
            resolve(); // Resuelve la promesa después de establecer el enlace de la imagen
          })
          .catch((e) => {
            console.log(e);
            reject(e); // Rechaza la promesa si hay un error
          });
      } catch (e) {
        console.log(e);
        reject(e); // Rechaza la promesa si hay un error
      }
    });
  };

  const [linkImagenCanvas, setLinkImagenCanvas] = useState("");

  const capturarPantallaCanvas = () => {
    return new Promise((resolve, reject) => {
      const elementoCanvas = document.getElementById("canvasDiag");

      html2canvas(elementoCanvas, { useCORS: true }).then((canvas) => {
        const imgDataCanvas = canvas.toDataURL("image/png");
        cargarImagenCanvas(imgDataCanvas).then(() => {
          resolve(); // Resuelve la promesa después de cargar la imagen
        });
      });
    });
  };

  const cargarImagenCanvas = (imgDataCanvas) => {
    return new Promise((resolve, reject) => {
      try {
        subeArchivosCloudinary(imgDataCanvas, "laboratorio")
          .then((response) => {
            const { data } = response;
            const { secure_url } = data;
            setLinkImagenCanvas(secure_url);
            resolve(); // Resuelve la promesa después de establecer el enlace de la imagen
          })
          .catch((e) => {
            console.log(e);
            reject(e); // Rechaza la promesa si hay un error
          });
      } catch (e) {
        console.log(e);
        reject(e); // Rechaza la promesa si hay un error
      }
    });
  };

  const ejecutarCapturaEImagen = async () => {
    try {
      await capturarPantalla();
      console.log("Enlace de la imagen: " + linkImagenEt); // Mover el console.log aquí
    } catch (error) {
      console.error("Error al capturar o cargar la imagen:", error);
    }
  };

  const ejecutarCapturaEImagenCanvas = async () => {
    try {
      await capturarPantallaCanvas();
      console.log("Nuevo enlace de la imagen del canvas:", linkImagenCanvas); // Mover el console.log aquí
    } catch (error) {
      console.error("Error al capturar o cargar la imagen del canvas:", error);
    }
  };

  const [serviciosAgg, setServiciosAgg] = useState([]);

  const agregarServicios = () => {
    console.log("enlaces funcion ", linkImagenCanvas, linkImagenEt);

    const temp2 = formData2.sistemaColor.split("/");
    const temp = formData.nombreDoctor.split("/");
    const temp3 = formData.consultorio.split("/");
    const dataTemp = {
      folio: data[1],
      clasificacion: formData2.clasificacion.split("|")[1],
      material: formData2.material.split("|")[1],
      pieza: piezaSelect.split("|")[0],
      precio: Number(precio) * Number(selectedElements.length),
      descripcionAdicional: formData2.descripcionServicio,
      sistemaColor: temp2[1],
      distencion: formData2.tipoDentadura,
      diente: selectedElements,
      tonoInferior: formData2.tonoInferior,
      tonoMedio: formData2.tonoMedio,
      tonoSuperior: formData2.tonoSuperior,
      imagenEsquema: linkImagenCanvas,
      imagenDiente: linkImagenEt,
      departamento: formData2.clasificacion.split("|")[1],
      fechaHora: fechaTresDiasDespues,
      estado: "1",
      municipio: municipioSeleccionado,
      emailDoctor: correoUsuario,
      audio: audioURLCloudinary
    };
    setServiciosAgg([...serviciosAgg, dataTemp]);
    console.log(dataTemp);
    toast.success("Servicio agregado");
    setLinkImagenCanvas("");
    setLinkImagenEt("");
    setSelectedElements([]);
    setSelectedElementsArr([]);
    setIsRecording(false);
    setAudioURL('');
    setAudioURLCloudinary('');
    setFormData2(prevState => ({
      ...prevState,
      clasificacion: '',
      material: '',
      descripcionServicio: '',
      tipoDentadura: '',
      tonoInferior: '',
      tonoMedio: '',
      tonoSuperior: '',
      sistemaColor: ''
    }));
  };

  useEffect(() => {
    if (linkImagenCanvas != "" && linkImagenEt != "") {
      agregarServicios();
    } else {
      console.log("enlaces vacios");
    }
  }, [linkImagenCanvas, linkImagenEt]);

  const precioTotal = serviciosAgg.reduce(
    (amount, item) => amount + (item.precio ? parseFloat(item.precio) : 0),
    0
  );

  const [clasificacionMat, setClasificacionMat] = useState([]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormData2({ ...formData2, [e.target.name]: e.target.value });
  }

  const [colores, setColores] = useState([]);

  const obtenerColores = () => {
    const temp = formData2.sistemaColor.split("/");
    console.log(temp);
    try {
      obtenerColorimetria(temp[0])
        .then((response) => {
          const { data } = response;
          const temp2 = data.tonos.split(",");
          const resultado = temp2.map((tonos) => ({ tonos }));
          setColores(resultado);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerColores();
  }, [formData2.sistemaColor]);

  // Convertir la cadena en un objeto Date
  let fecha = new Date();

  // Obtener el día, mes y año
  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
  let year = fecha.getFullYear();

  // Formatear día y mes para asegurar que tengan dos dígitos
  let diaFormat = dia < 10 ? '0' + dia : dia;
  let mesFormat = mes < 10 ? '0' + mes : mes;

  // Formar la cadena en formato "aaaa-mm-dd"
  let datosFecha = `${year}-${mesFormat}-${diaFormat}`;

  // Luego, si necesitas usar esto en React con useState:
  const [fechaNueva, setFechaNueva] = useState(datosFecha);

  // Función para manejar los cambios en el campo de fecha
  const handleFechaChange = (event) => {
    setFechaNueva(event.target.value);
  };

  const [fechaTresDiasDespues, setFechaTresDiasDespues] = useState('');

  useEffect(() => {
    if (fechaNueva) {
      const fecha = new Date(fechaNueva);
      fecha.setDate(fecha.getDate() + 4);
      let fecha2 = new Date();

      // Obtener año, mes y día con dos dígitos
      const year = fecha.getFullYear();
      const month = String(fecha.getMonth() + 1).padStart(2, '0');
      const day = String(fecha.getDate()).padStart(2, '0');
      let hora = fecha2.getHours(); // Obtener la hora (formato de 0 a 23)
      let minutos = fecha2.getMinutes();
      let segundos = fecha2.getSeconds(); // Obtener los minutos

      // Formatear la fecha en formato aaaa-mm-dd
      const formattedFecha = `${year}-${month}-${day} ${hora}:${minutos < 10 ? "0" + minutos : minutos}:${segundos < 10 ? "0" + segundos : segundos}`;

      setFechaTresDiasDespues(formattedFecha);
    }
  }, [fechaNueva]);

  console.log(selectedElements)

  console.log(serviciosAgg)

  return (
    <>
      {loading && <Load />}
      <div className="contenedorPrincipalOrden">
        <div className="containerOrden">
          <div className="containerDatosOS">
            <h1 className="ordenTituloP">Modificar orden de cliente</h1>

            <div>
              <hr />
              <Form onChange={onChange}>
                <Form.Label className="lblClaSerOS">Departamento: <code>*</code></Form.Label>
                <Form.Control
                  as="select"
                  className="inpClasifSerOS"
                  placeholder="Clasificación"
                  //onChange={(e) => setClasificacion(e.target.value)}
                  name="clasificacion"
                  value={formData2.clasificacion}
                >
                  <option>Elige una opción</option>
                  {listarDepartamento.map((departamento) => (
                    <option
                      value={departamento.folio + "|" + departamento.nombre}
                    >
                      {departamento.nombre}
                    </option>
                  ))}
                </Form.Control>
                <Form.Label className="lblTipoSerOS">Material: <code>*</code></Form.Label>
                <Form.Control
                  name="material"
                  as="select"
                  className="inpMaterialOS"
                  placeholder="Material (Servicio)"
                  value={formData2.material}
                >
                  <option>Elige una opción</option>
                  {listarMateriales.map((material) => (
                    <option value={material.folio + "|" + material.nombre}>
                      {material.nombre}
                    </option>
                  ))}
                </Form.Control>
                <Form.Label className="lblPiezaOS">Pieza: <code>*</code></Form.Label>
                <Form.Control
                  //name="pieza"
                  as="select"
                  className="inpPiezaOS"
                  placeholder="Pieza (Tipo de material)"
                  value={piezaSelect}
                  onChange={(e) => setPiezaSelect(e.target.value)}
                >
                  <option value="">Elige una opción</option>
                  {listarPiezas.map((pieza) => (
                    <option
                      value={pieza.nombrePieza + "|" + pieza.precioPublico}
                    >
                      {pieza.nombrePieza}
                    </option>
                  ))}
                </Form.Control>
                <Form.Label className="lblPrecioSerOS">Precio: <code>*</code> </Form.Label>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    name="precioServicio"
                    type="text"
                    className="inpPrecioSerOS"
                    placeholder="Seleccionar pieza para obtener precio"
                    value={precio}
                    disabled
                  />
                </InputGroup>

                <div>
                  <Row>
                    <Col xs={12} md={4}>
                      <Form.Label className="lblSisColOS">
                        Descripcion de protesis: <code>*</code>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        className="txtDescripcion"
                        rows={4}
                        placeholder="Escribe los detalles adicionales del servicio, para comprender mejor el servicio que pide"
                        //onChange={(e) => setDescripcionAd(e.target.value)}
                        name="descripcionServicio"
                        value={formData2.descripcionServicio}
                      />
                      <br />
                      <div>
                        <Form.Label className="lblSisColOS">
                          Si la descripcion es muy extensa lo puede agregar mediante audio:
                        </Form.Label>
                        <Button onClick={isRecording ? stopRecording : startRecording}>
                          {isRecording ? <FontAwesomeIcon
                            icon={faMicrophone}
                            style={{ color: "#008000" }}
                          /> : <FontAwesomeIcon
                            icon={faMicrophone}
                            style={{ color: "#FF0000" }}
                          />}
                        </Button>
                        {audioURL && (
                          <div>
                            <Form.Label className="lblSisColOS">
                              Escuchar audio:
                            </Form.Label>
                            <audio controls src={audioURL} />
                          </div>
                        )}
                      </div>
                      <Form.Label>Tipo de dentadura: <code>*</code></Form.Label>
                      <Form.Control
                        name="tipoDentadura"
                        value={formData2.tipoDentadura}
                        onChange={cambiaImagen}
                        as="select"
                      >
                        <option value="">Selecciona una opcion</option>
                        <option value="DP">Dentinción Permanente</option>
                        <option value="DT">Dentinción Temporal</option>
                      </Form.Control>
                      {formData2.tipoDentadura == "DP" && (
                        <>
                          <Form.Label>Diente: <code>*</code></Form.Label>
                          <Form.Control
                            as="select"
                            name="diente"
                            defaultValue={formData2.diente}
                            value={selectedCoordinate}
                            onChange={handleCoordinateChange}
                          >
                            <option value="">Selecciona una opcion</option>
                            <option value="70,60, 16-Primer molar superior derecho">
                              16-Primer molar superior derecho
                            </option>
                            <option value="65, 70, 17-Segundo molar superior derecho">
                              17-Segundo molar superior derecho
                            </option>
                            <option value="65, 80, 18-Tercer molar superior derecho (muela del juicio)">
                              18-Tercer molar superior derecho (muela del
                              juicio)
                            </option>
                            <option value="125, 30, 11-Incisivo central superior derecho">
                              11-Incisivo central superior derecho
                            </option>
                            <option value="106, 33, 12-Incisivo lateral superior derecho">
                              12-Incisivo lateral superior derecho
                            </option>
                            <option value="90, 37, 13-Canino superior derecho">
                              13-Canino superior derecho
                            </option>
                            <option value="85, 45, 14-Primer premolar superior derecho">
                              14-Primer premolar superior derecho
                            </option>
                            <option value="78, 50, 15-Segundo premolar superior derecho">
                              15-Segundo premolar superior derecho
                            </option>
                            <option value="201, 56, 26-Primer molar superior izquierdo">
                              26-Primer molar superior izquierdo
                            </option>
                            <option value="206, 69, 27-Segundo molar superior izquierdo">
                              27-Segundo molar superior izquierdo
                            </option>
                            <option value="210, 78, 28-Tercer molar superior izquierdo (muela del juicio)">
                              28-Tercer molar superior izquierdo (muela del
                              juicio)
                            </option>
                            <option value="148, 31, 21-Incisivo central superior izquierdo">
                              21-Incisivo central superior izquierdo
                            </option>
                            <option value="167, 34, 22-Incisivo lateral superior izquierdo">
                              22-Incisivo lateral superior izquierdo
                            </option>
                            <option value="181, 38, 23-Canino superior izquierdo">
                              23-Canino superior izquierdo
                            </option>
                            <option value="187, 45, 24-Primer premolar superior izquierdo">
                              24-Primer premolar superior izquierdo
                            </option>
                            <option value="189, 45, 25-Segundo premolar superior izquierdo">
                              25-Segundo premolar superior izquierdo
                            </option>
                            <option value="202, 110, 36-Primer molar inferior izquierdo">
                              36-Primer molar inferior izquierdo
                            </option>
                            <option value="206, 100, 37-Segundo molar inferior izquierdo">
                              37-Segundo molar inferior izquierdo
                            </option>
                            <option value="206, 90, 38-Tercer molar inferior izquierdo (muela del juicio)">
                              38-Tercer molar inferior izquierdo (muela del
                              juicio)
                            </option>
                            <option value="144, 136, 31-Incisivo central inferior izquierdo">
                              31-Incisivo central inferior izquierdo
                            </option>
                            <option value="158, 136, 32-Incisivo lateral inferior izquierdo">
                              32-Incisivo lateral inferior izquierdo
                            </option>
                            <option value="172, 131, 33-Canino inferior izquierdo">
                              33-Canino inferior izquierdo
                            </option>
                            <option value="184, 2127, 34-Primer premolar inferior izquierdo">
                              34-Primer premolar inferior izquierdo
                            </option>
                            <option value="193, 120, 35-Segundo premolar inferior izquierdo">
                              35-Segundo premolar inferior izquierdo
                            </option>
                            <option value="73, 112, 46-Primer molar inferior derecho">
                              46-Primer molar inferior derecho
                            </option>
                            <option value="66, 100, 47-Segundo molar inferior derecho">
                              47-Segundo molar inferior derecho
                            </option>
                            <option value="65, 91, 48-Tercer molar inferior derecho (muela del juicio)">
                              48-Tercer molar inferior derecho (muela del
                              juicio)
                            </option>
                            <option value="128, 135, 41-Incisivo lateral inferior derecho">
                              41-Incisivo lateral inferior derecho
                            </option>
                            <option value="115, 135, 42-Incisivo central inferior derecho">
                              42-Incisivo central inferior derecho
                            </option>
                            <option value="101, 132, 43-Canino inferior derecho">
                              43-Canino inferior derecho
                            </option>
                            <option value="88, 127, 44-Primer premolar inferior derecho">
                              44-Primer premolar inferior derecho
                            </option>
                            <option value="80, 120, 45-Segundo premolar inferior derecho">
                              45-Segundo premolar inferior derecho
                            </option>
                          </Form.Control>
                        </>
                      )}

                      {formData2.tipoDentadura == "DT" && (
                        <>
                          <Form.Label>Diente: <code>*</code></Form.Label>
                          <Form.Control
                            as="select"
                            name="diente"
                            defaultValue={formData2.diente}
                            value={selectedCoordinate}
                            onChange={handleCoordinateChange}
                          >
                            <option value="">Selecciona una opcion</option>
                            <option value="63, 66, 54-Primer molar temporal superior derecho">
                              54-Primer molar temporal superior derecho
                            </option>
                            <option value="60, 80, 55-Segundo molar temporal superior derecho">
                              55-Segundo molar temporal superior derecho
                            </option>
                            <option value="210, 66, 64-Primer molar temporal superior izquierdo">
                              64-Primer molar temporal superior izquierdo
                            </option>
                            <option value="215, 79, 65-Segundo molar temporal superior izquierdo">
                              65-Segundo molar temporal superior izquierdo
                            </option>
                            <option value="200, 111, 74-Primer molar temporal inferior izquierdo">
                              74-Primer molar temporal inferior izquierdo
                            </option>
                            <option value="208, 96, 75-Segundo molar temporal inferior izquierdo">
                              75-Segundo molar temporal inferior izquierdo
                            </option>
                            <option value="72, 111, 84-Primer molar temporal inferior derecho">
                              84-Primer molar temporal inferior derecho
                            </option>
                            <option value="66, 196, 85-Segundo molar temporal inferior derecho">
                              85-Segundo molar temporal inferior derecho
                            </option>
                            <option value="120, 48, 51-Incisivo central temporal superior derecho">
                              51-Incisivo central temporal superior derecho
                            </option>
                            <option value="98, 50, 52-Incisivo lateral temporal superior derecho">
                              52-Incisivo lateral temporal superior derecho
                            </option>
                            <option value="80, 57, 53-Canino temporal superior derecho">
                              53-Canino temporal superior derecho
                            </option>
                            <option value="151, 48, 61-Incisivo central temporal superior izquierdo">
                              61-Incisivo central temporal superior izquierdo
                            </option>
                            <option value="177, 51, 62-Incisivo lateral temporal superior izquierdo">
                              62-Incisivo lateral temporal superior izquierdo
                            </option>
                            <option value="194, 56, 63-Canino temporal superior izquierdo">
                              63-Canino temporal superior izquierdo
                            </option>
                            <option value="146, 127, 71-Incisivo central temporal inferior izquierdo">
                              71-Incisivo central temporal inferior izquierdo
                            </option>
                            <option value="165, 125, 72-Incisivo lateral temporal inferior izquierdo">
                              72-Incisivo lateral temporal inferior izquierdo
                            </option>
                            <option value="183, 121, 73-Canino temporal inferior izquierdo">
                              73-Canino temporal inferior izquierdo
                            </option>
                            <option value="128, 128, 81-Incisivo central temporal inferior derecho">
                              81-Incisivo central temporal inferior derecho
                            </option>
                            <option value="109, 126, 82-Incisivo lateral temporal inferior derecho">
                              82-Incisivo lateral temporal inferior derecho
                            </option>
                            <option value="91, 121, 83-Canino temporal inferior derecho">
                              83-Canino temporal inferior derecho
                            </option>
                          </Form.Control>
                        </>
                      )}
                      <Form.Label className="lblSisColOS">
                        Sistema de color: <code>*</code>
                      </Form.Label>
                      <Form.Control
                        name="sistemaColor"
                        as="select"
                        className="inpSisColOS"
                        placeholder="Sistema de color"
                        //onChange={(e) => setSistemaColor(e.target.value)}
                        value={formData2.sistemaColor}
                      >
                        <option>Elige una opción</option>
                        {map(listarColorimetrias, (cat, index) => (
                          <option
                            key={index}
                            value={cat?.id + "/" + cat?.nombre}
                          >
                            {cat?.nombre}
                          </option>
                        ))}
                      </Form.Control>
                      {formData2.sistemaColor != "" && (
                        <>
                          <div className="divTonos">
                            <Form.Label className="lblTonoOS">
                              Tonos:
                            </Form.Label>
                            <br />
                            <Form.Label className="lblTonoOS">
                              Cervical: <code>*</code>
                            </Form.Label>
                            <Form.Control
                              name="tonoInferior"
                              as="select"
                              className="inpTono"
                              placeholder="Tono Inferior"
                              //onChange={(e) => setTonoInferior(e.target.value)}
                              value={formData2.tonoInferior}
                            >
                              <option value="">Elige una opción</option>
                              {map(colores, (cat, index) => (
                                <option key={index} value={cat?.tonos}>
                                  {cat?.tonos}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Label className="lblTonoOS">
                              Media: <code>*</code>
                            </Form.Label>
                            <Form.Control
                              name="tonoMedio"
                              as="select"
                              className="inpTono"
                              placeholder="Tono Medio"
                              //onChange={(e) => setTonoMedio(e.target.value)}
                              value={formData2.tonoMedio}
                            >
                              <option value="">Elige una opción</option>
                              {map(colores, (cat, index) => (
                                <option key={index} value={cat?.tonos}>
                                  {cat?.tonos}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Label className="lblTonoOS">
                              Incisal: <code>*</code>
                            </Form.Label>
                            <Form.Control
                              name="tonoSuperior"
                              as="select"
                              className="inpTono"
                              placeholder="Tono Superior"
                              //onChange={(e) => setTonoSuperior(e.target.value)}
                              value={formData2.tonoSuperior}
                            >
                              <option value="">Elige una opción</option>
                              {map(colores, (cat, index) => (
                                <option key={index} value={cat?.tonos}>
                                  {cat?.tonos}
                                </option>
                              ))}
                            </Form.Control>
                          </div>
                        </>
                      )}
                    </Col>
                    {(formData2.tipoDentadura == "" || formData2.diente == "") ? (
                      <>
                        <Col xs={12} md={4}>
                          <div className="imgTipoOS">
                            <div className="containerCanvasMap">
                              <img src={formData2.tipoDentadura != "DT" ? imagen : imagenDT} className="canvas-map" />
                            </div>
                          </div>
                        </Col>
                      </>
                    ) : (
                      <Col xs={12} md={4}>
                        <div id="canvasDiag" className="imgTipoOS">
                          <div className="containerCanvasMap">
                            <canvas
                              ref={canvasRef}
                              className="canvas-map"
                            ></canvas>
                          </div>
                        </div>
                        <div>
                          <h5>Dientes Seleccionados:</h5>
                          <ul>
                            {selectedElements.map((element, index) => (
                              <li key={index}>
                                {
                                  element.split(",")[
                                  element.split(",").length - 1
                                  ]
                                }
                                <Button variant="link" style={{ textDecoration: "none", color: "red" }} onClick={(event) => handleDeleteSelected(index, event)}><FontAwesomeIcon icon={faTrash} /></Button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Col>
                    )}

                    <Col xs={12} md={4}>
                      <div id="imgGenera" className="containerImgDiente">
                        <div className="divImgModal">
                          <img
                            src={imagenDos}
                            alt="Diagrama diente"
                            className="imagenDiagrama"
                          />
                        </div>
                        <div className="divOverlay overlay1">
                          {formData2.tonoInferior}
                        </div>
                        <div className="divOverlay overlay2">
                          {formData2.tonoMedio}
                        </div>
                        <div className="divOverlay overlay3">
                          {formData2.tonoSuperior}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Form>
            </div>

            <div>
              <hr />
              <Tabla
                productos={serviciosAgg}
                setProductos={setServiciosAgg}
              />
            </div>
          </div>
        </div >
        <div className="buttonsOS">
          <div className="botonGuardarServOS">
            <>
              <Button
                variant="success"
                onClick={ejecutarCapturasYMostrarLogs}
                disabled={selectedElements.length == 0}
              >
                <FontAwesomeIcon icon={faFloppyDisk} />
                &nbsp;Agregar Servicio
              </Button>
            </>
          </div>
          <div className="botonSigOS">
            <Button variant="success" onClick={onSubmit}>
              <FontAwesomeIcon icon={faCloudArrowUp} />
              &nbsp; Enviar
            </Button>
          </div>
        </div>
      </div >
    </>
  );
}

function initialFormValue(data) {
  return {
    status: "",
    nombreSolicitante: "",
    departamento: "",
    observaciones: "",
    clasificacion: "",
    sistemaColor: "",
    descripcionServicio: "",
    consultorio: "",
    nombreDoctor: "",
    nombrePaciente: "",
    consultorio: ""
  };
}

function initialFormValue2() {
  return {
    clasificacion: "",
    material: "",
    pieza: "",
    descripcionServicio: "",
    diente: "",
    tipoDentadura: "",
    sistemaColor: "",
    tonoInferior: "",
    tonoMedio: "",
    tonoSuperior: "",
    observaciones1: "",
    observaciones2: "",
    observaciones3: "",
    observaciones4: "",
    observaciones5: "",
    observaciones6: "",
    consultorio: "",
    sistemaColor: ""
  };
}

function formatModelDepartamento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      folio: data.folio,
      nombre: data.nombre,
    });
  });
  return dataTemp;
}

function formatModelMateriales(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      folio: data.folio,
      nombre: data.nombreMaterial,
    });
  });
  return dataTemp;
}

function formatModelPieza(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombrePieza: data.nombrePieza,
      material: data.material,
      departamento: data.departamento,
      precioPublico: data.precioPublico,
      colorimetrias: data.colorimetrias,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelDoctor(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      apellidoPaterno: data.apellidoPaterno,
      apellidoMaterno: data.apellidoMaterno,
      fechaNacimiento: data.fechaNacimiento,
      telefonoCelular: data.telefonoCelular,
      email: data.email,
      correoConsultorio: data.correoConsultorio,
      rfc: data.rfc,
      observaciones: data.observaciones,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelEstablecimiento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      razonSocial: data.razonSocial,
      estadoEstablecimiento: data.estadoEstablecimiento,
      municipio: data.municipio,
      colonia: data.colonia,
      calle: data.calle,
      numeroExterior: data.numeroExterior,
      numeroInterior: data.numeroInterior,
      codigoPostal: data.codigoPostal,
      telefono: data.telefono,
      haceEsquina: data.haceEsquina,
      email: data.email,
      observaciones: data.observaciones,
      horario: data.horario,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      fecha: data.fecha,
      localidad: data.localidad,
      nombreDoctor: data.nombreDoctor,
      nombrePaciente: data.nombrePaciente,
      precioTotal: data.precioTotal,
      tipoDentadura: data.tipoDentadura,
      otros: data.otros,
      recibio: data.recibio,
      fechaEntrada: data.fechaEntrada,
      servicios: data.servicios,
      direccionConsultorio: data.direccionConsultorio,
    });
  });
  return dataTemp;
}

function formatModelServicios(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      clasificacion: data.clasificacion,
      material: data.material,
      pieza: data.pieza,
      precio: data.precio,
      descripcionAdicional: data.descripcionAdicional,
      sistemaColor: data.sistemaColor,
      distencion: data.distencion,
      diente: data.diente,
      tonoInferior: data.tonoInferior,
      tonoMedio: data.tonoMedio,
      tonoSuperior: data.tonoSuperior,
      imagenEsquema: data.imagenEsquema,
      imagenDiente: data.imagenDiente,
    });
  });
  return dataTemp;
}

function formatModelMensajero(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      telefono: data.telefono,
      email: data.email,
      modeloVehiculo: data.modeloVehiculo,
      placa: data.placa,
      economico: data.economico,
      fecha: data.fecha,
      estado: data.estado,
      imagen: data.imagen,
    });
  });
  return dataTemp;
}

