import { faCashRegister } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { obtenerUsuario } from "../../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import {
  actualizaDeshabilitarAbonosOrdenes,
  obtenerAbonosPorFolioOS,
} from "../../../api/abonosOrdenes";
import { validarPasswordUsuario } from "../../../api/usuarios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { obtenerCyDPorFolioOS } from "../../../api/preciosOrden";
import { actualizaPagoOrdenesServicioFolio } from "../../../api/ordenesServicio";
import { LogsInformativos } from "../../logs/logs";
import { registraCajaChica } from "../../../api/cajaChica";

const AprobarPago = ({ dataOrden, setShowModal }) => {
  const [formaPago, setFormaPago] = useState(dataOrden[10].toLowerCase());
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");

  const [contraseñaUsuario, setContraseñaUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setNombreUsuario(data.nombre);
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const [abonosOrden, setAbonosOrden] = useState([]);

  const obtenerAbonosOS = (folio) => {
    try {
      obtenerAbonosPorFolioOS(folio)
        .then((response) => {
          const { data } = response;
          if (data.length > 0) {
            setAbonosOrden(formatModelAbonos(data));
          } else {
            setAbonosOrden([]);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [cydOrden, setCydOrden] = useState([]);

  const obtenerCydOS = (folio) => {
    try {
      obtenerCyDPorFolioOS(folio)
        .then((response) => {
          const { data } = response;
          if (data.length > 0) {
            setCydOrden(formatModelCargosyDescuentos(data));
          } else {
            setCydOrden([]);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerDatosUsuario();
    obtenerAbonosOS(dataOrden[2]);
    obtenerCydOS(dataOrden[2]);
  }, [dataOrden]);

  const [sumaPagosAprobados, setSumaPagosAprobados] = useState(0);
  const [totalPagar, setTotalPagar] = useState(0);
  const [restante, setRestante] = useState(0);

  useEffect(() => {
    let totalCalculado = 0;
    cydOrden.forEach((item) => {
      if (item.tipoOperacion === "descuento") {
        totalCalculado -= parseFloat(item.cantidad);
      } else {
        totalCalculado += parseFloat(item.cantidad);
      }
    });
    setTotalPagar(totalCalculado);
  }, [cydOrden]);

  useEffect(() => {
    let totalCalculadoRes = 0;
    abonosOrden.forEach((item) => {
      if (item.estado === "1") {
        totalCalculadoRes += parseFloat(item.cantidadRecibida || 0);
      }
    });

    setSumaPagosAprobados(totalCalculadoRes);
  }, [abonosOrden]);

  useEffect(() => {
    let restante =
      parseFloat(totalPagar) - (sumaPagosAprobados + parseFloat(dataOrden[3]));
    setRestante(restante);
  }, [totalPagar, sumaPagosAprobados, dataOrden]);

  const pagoTerminado = async () => {
    try {
      const dataTemp = {
        estadoPago: "1",
      };
      actualizaPagoOrdenesServicioFolio(dataOrden[2], dataTemp).then(
        (response) => {
          const { data } = response;
          LogsInformativos("Se actualizó el estado del pago" + nombreUsuario, dataTemp)
          toast.success(data.mensaje);
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const obtenerFechaActual = () => {
    const fecha = new Date();
    const year = fecha.getFullYear();
    let mes = fecha.getMonth() + 1;
    let dia = fecha.getDate();
    let hora = fecha.getHours();
    let minutos = fecha.getMinutes();
    let segundos = fecha.getSeconds();

    mes = mes < 10 ? `0${mes}` : mes;
    dia = dia < 10 ? `0${dia}` : dia;
    hora = hora < 10 ? `0${hora}` : hora;
    minutos = minutos < 10 ? `0${minutos}` : minutos;
    segundos = segundos < 10 ? `0${segundos}` : segundos;

    return `${year}-${mes}-${dia} ${hora}:${minutos}:${segundos}`;
  };

  function obtenerNumeroDeSemana(fecha) {
    // Copiamos la fecha para no modificar la original
    let fechaCopia = new Date(
      Date.UTC(fecha.getFullYear(), fecha.getMonth(), fecha.getDate())
    );
    // Establecemos la fecha al primer día de la semana (Domingo)
    fechaCopia.setUTCDate(
      fechaCopia.getUTCDate() + 4 - (fechaCopia.getUTCDay() || 7)
    );
    // Obtenemos el primer día del año
    let primerDiaDelAno = new Date(Date.UTC(fechaCopia.getUTCFullYear(), 0, 1));
    // Calculamos el número de la semana
    let numeroDeSemana = Math.ceil(
      ((fechaCopia - primerDiaDelAno) / 86400000 + 1) / 7
    );
    return numeroDeSemana;
  }

  const guardarCajaChica = async (e) => {
    //e.preventDefault();
    const fechaActual = obtenerFechaActual();
    const semana = obtenerNumeroDeSemana(new Date);
    try {
      const dataTemp2 = {
        idUsuario: nombreUsuario,
        fechayHora: fechaActual,
        concepto: "Abono de la Orden de cliente " + dataOrden[2],
        cantidad: dataOrden[3],
        tipo: "Entrada",
        semana: semana + "-" + fechaActual.split('-')[0],
        tipoGasto: "dia",
        fecha: fechaActual.split(' ')[0],
        comprobante: "",
        estado: "1",
      };
      registraCajaChica(dataTemp2).then((response) => {
        const { data } = response;
        toast.success(data.mensaje);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const aprobarPago = async () => {
    try {
      const dataTemp = {
        aprobo: nombreUsuario,
        estado: "1",
      };

      const dataTemp3 = {
        email: correoUsuario,
        password: contraseñaUsuario
      }

      validarPasswordUsuario(dataTemp3).then((response) => {
        if (response.status === 200) {
          actualizaDeshabilitarAbonosOrdenes(dataOrden[0], dataTemp).then(
            (response) => {
              const { data } = response;
              LogsInformativos("Se ha aprobo el pago" + dataTemp.aprobo, dataTemp)
              if (dataOrden[10].toLowerCase() == "efectivo") {
                guardarCajaChica();
              }
              if (restante <= 0) {
                pagoTerminado();
              } else {
              }
              toast.success(data.mensaje);
              setShowModal(false);
            }
          );
        } else if (response.status === 401) {
          // Si la validación falla, mostrar mensaje de error
          toast.error("Las contraseñas no coinciden");
        }
      }).catch((error) => {
        console.error("Error al validar la contraseña:", error);
        toast.error("Error al validar la contraseña.");
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div style={{ padding: "1%" }}>
      <Form>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Orden:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Control type="text" value={dataOrden[2]} disabled />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Total a pagar de la orden:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                value={parseFloat(totalPagar).toFixed(2)}
                type="number"
                disabled
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Cantidad para aprobar:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                value={parseFloat(dataOrden[3]).toFixed(2)}
                type="number"
                disabled
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Total de abonos aprobados:</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                value={parseFloat(sumaPagosAprobados).toFixed(2)}
                type="number"
                disabled
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Restante (después de la aprobación):</Form.Label>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                value={parseFloat(restante).toFixed(2)}
                type="number"
                disabled
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>
              Forma de pago:
            </Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              as="select"
              value={formaPago}
              onChange={(e) => setFormaPago(e.target.value)}
              placeholder={"Ingresa forma de pago"}
              disabled
            >
              <option value="efectivo">Efectivo</option>
              <option value="tarjeta">Tarjeta</option>
              <option value="transferencia">Transferencia</option>
              <option value="cheques">Cheques</option>
              <option value="depositos">Depositos</option>
            </Form.Control>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>
              Contraseña del usuario: <code>*</code>
            </Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              value={contraseñaUsuario}
              onChange={(e) => setContraseñaUsuario(e.target.value)}
              placeholder={"Escribe tu contraseña para registrar el pago"}
            />
          </Col>
        </Row>
      </Form>
      <div style={{ textAlign: "center" }}>
        <Button variant="success" onClick={aprobarPago}>
          <FontAwesomeIcon icon={faCashRegister} /> Aprobar
        </Button>
      </div>
      <Row className="mt-2 mt-md-4 mt-lg-7">
        <Col>
          <h5
            className="mb-3"
            style={{ textAlign: "center", fontStyle: "italic" }}
          >
            Pagos realizados de la orden
          </h5>
          <Table
            striped
            bordered
            hover
            style={{ textAlign: "center", textTransform: "capitalize" }}
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Fecha</th>
                <th>Cantidad</th>
                <th>Metodo de pago</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {abonosOrden.map((abono, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{abono.fecha}</td>
                  <td>{abono.cantidadRecibida}</td>
                  <td>{!abono.metodoPago ? "Efectivo" : abono.metodoPago}</td>
                  <td>{abono.estado == "0" ? "Por aprobar" : "Aprobado"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col>
          <h5
            className="mb-3"
            style={{ textAlign: "center", fontStyle: "italic" }}
          >
            Cargos y descuentos aplicados
          </h5>
          <Table
            striped
            bordered
            hover
            style={{ textAlign: "center", textTransform: "capitalize" }}
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Tipo</th>
                <th>Concepto</th>
                <th>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {cydOrden.map((cyd, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{cyd.tipoOperacion}</td>
                  <td>{cyd.concepto}</td>
                  <td>{cyd.cantidad}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

function formatModelAbonos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      ordenServicio: data.ordenServicio,
      cantidadRecibida: data.cantidadRecibida,
      estado: data.estado,
      fecha: data.fecha,
      metodoPago: data.metodoPago
    });
  });
  return dataTemp;
}

function formatModelCargosyDescuentos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      tipoOperacion: data.tipo,
      concepto: data.concepto,
      cantidad: data.cantidad,
    });
  });
  return dataTemp;
}

export default AprobarPago;
