import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistrarMedicamento,
    ENDPOINTObtenerMedicamento,
    ENDPOINTListarMedicamentos,
    ENDPOINTListarMedicamentosProveedor,
    ENDPOINTListarPaginandoMedicamentos,
    ENDPOINTTotalMedicamentos,
    ENDPOINTActualizarMedicamento,
    ENDPOINTEliminarMedicamento,
    ENDPOINTDeshabilitarMedicamento,
    ENDPOINTObtenerMedicamentosPorNombre,
    ENDPOINTListarProductosAgotandose,
    ENDPOINTBuscarMedicamentosPorIds
} from "./endpoints";
import axios from 'axios';

export async function listarMedicamentos() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarMedicamentos, config);
}

export async function listarMedicamentosProveedores(proveedor) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarMedicamentosProveedor + `?departamento=${proveedor}`, config);
}

export async function listarProductosAgotandose() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarProductosAgotandose, config);
}

export async function listarPaginandoMedicamentos(pagina, limite) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarPaginandoMedicamentos +
        `/?pagina=${pagina}&&limite=${limite}`, config);
}

export async function totalMedicamentos() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
    return await axios.get(API_HOST + ENDPOINTTotalMedicamentos, config);
}

export async function registrarMedicamento(data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistrarMedicamento, data, config);
}

export async function obtenerMedicamento(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerMedicamento + `/${params}`, config);
}

export async function actualizarMedicamento(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarMedicamento + `/${id}`, data, config);
}

export async function eliminarMedicamento(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarMedicamento + `/${id}`, data, config);
}

export async function deshabilitarMedicamento(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitarMedicamento + `/${id}`, data, config);
}

export async function listarMedicamentosPorNombre(searchTerm, pagina, limite) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    };

    return await axios.get(API_HOST + ENDPOINTObtenerMedicamentosPorNombre + `/?searchTerm=${searchTerm}&&pagina=${pagina}&&limite=${limite}`, config);
}

export async function buscarMedicamentosPorIds(arrayIds) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
    return await axios.get(API_HOST + ENDPOINTBuscarMedicamentosPorIds + `?ids=${arrayIds}`, config);
}
