import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { listarRecursosHumanos } from "../../../api/recursosHumanos";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { registraVacaciones } from "../../../api/vacaciones";
import queryString from "query-string";

const AgregarVacaciones = ({ setShow, history }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [observaciones, setObservaciones] = useState("");

  const [listaEmpleados, setListaEmpleados] = useState([]);

  const obtenerEmpleados = () => {
    try {
      listarRecursosHumanos()
        .then((response) => {
          const { data } = response;
          console.log(data)

          if (!listaEmpleados && data) {
            const empleadosConEstadoTrue = data.filter(empleado => empleado.estado == "true");
            setListaEmpleados(formatModelEmpleados(empleadosConEstadoTrue));
        } else {
            const empleadosConEstadoTrue = data.filter(empleado => empleado.estado == "true");
            const datosOrdenes = formatModelEmpleados(empleadosConEstadoTrue);
            setListaEmpleados(datosOrdenes);
        }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerEmpleados();
  }, []);

  const handleSeleccionChange = (option) => {
    setSelectedOption(option.value);
  };

  const registraEmpleado = (e) => {
    e.preventDefault();

    if (!selectedOption || !fechaInicio || !fechaFin) {
      toast.warning("Datos incompletos");
    } else {
      try {
        const dataTemp = {
          empleado: selectedOption,
          fecha: {
            inicio: fechaInicio,
            fin: fechaFin,
          },
          motivo: observaciones,
          estado: "true",
        };
        registraVacaciones(dataTemp).then((response) => {
          const { data } = response;
          toast.success("Vacaciones registradas");
          history({
            search: queryString.stringify(""),
          });
          setShow(false)
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <div style={{ padding: "2%" }}>
      <Form>
        <div>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Nombre del empleado: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Select
                options={listaEmpleados}
                isSearchable={true}
                placeholder={"Seleccionar..."}
                onChange={(selectedOption) =>
                  handleSeleccionChange(selectedOption)
                }
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Fecha de inicio: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="date"
                value={fechaInicio}
                onChange={(e) => setFechaInicio(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Fecha de fin: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="date"
                value={fechaFin}
                onChange={(e) => setFechaFin(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-start justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Observaciones:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                as="textarea"
                placeholder="Ingresa observaciones en caso de ser necesarias"
                value={observaciones}
                onChange={(e) => setObservaciones(e.target.value)}
              />
            </Col>
          </Row>
        </div>
        <div className="divSubmit">
          <Button variant="success" onClick={registraEmpleado}>
            <FontAwesomeIcon icon={faCalendarCheck} /> Registrar
          </Button>
        </div>
      </Form>
    </div>
  );
};

const formatModelEmpleados = (data) => {
  return data.map((empleado) => ({
    value: empleado.nombreCompleto + " - " + empleado.cargo,
    label: empleado.nombreCompleto,
  }));
};

export default AgregarVacaciones;
