import React, { useRef } from "react";
import { QRCodeCanvas } from "qrcode.react";

const QRGenerator = ({ id, setShow }) => {
  const qrRef = useRef(null);

  const handleDownload = () => {
    try {
      const canvas = qrRef.current.querySelector("canvas"); // Obtener el canvas generado por QRCodeCanvas
      if (!canvas) throw new Error("No se encontró el código QR.");

      const qrSize = canvas.width; // Tamaño original del QR
      const margin = 19; // Margen de medio centímetro en píxeles (aproximadamente)
      
      // Crear un nuevo canvas para agregar el margen
      const finalCanvas = document.createElement("canvas");
      finalCanvas.width = qrSize + margin * 2; // Tamaño del QR + márgenes izquierdo y derecho
      finalCanvas.height = qrSize + margin * 2; // Tamaño del QR + márgenes arriba y abajo

      // Dibujar el QR en el centro del nuevo canvas con el margen
      const ctx = finalCanvas.getContext("2d");
      ctx.fillStyle = "#ffffff"; // Color blanco para el margen
      ctx.fillRect(0, 0, finalCanvas.width, finalCanvas.height); // Rellenar el fondo con blanco
      ctx.drawImage(canvas, margin, margin); // Dibujar el QR en el centro

      // Convertir el canvas final a una imagen y descargarla
      const dataURL = finalCanvas.toDataURL("image/jpeg", 1.0);
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = `codigo-qr-${id || "default"}.jpg`; // Nombre del archivo
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setShow(false);
    } catch (error) {
      console.error("Error al descargar la imagen:", error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
        {id ? (
          <div
            id="qrcode-content"
            ref={qrRef}
            className="p-2 bg-white rounded-lg shadow-md inline-block"
          >
            <QRCodeCanvas value={id} size={256} level="H" className="shadow-lg" />
          </div>
        ) : (
          <p className="text-gray-500">No hay folio disponible</p>
        )}

        <button
          className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition"
          onClick={handleDownload}
        >
          Descargar QR
        </button>
      </div>
    </div>
  );
};

export default QRGenerator;
