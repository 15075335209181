import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faFloppyDisk, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import {
  actualizarPrecios,
  obtenerNumeroPieza,
  registraPrecios,
} from "../../../api/precios";
import {
  listarDepartamentos,
  listarPorTipoDepartamento,
} from "../../../api/departamentos";
import { listarMaterialPorDep } from "../../../api/materialesCat";
import { listarColorimetria } from "../../../api/colorimetria";
import { subeArchivosCloudinary } from "../../../api/cloudinary";
import { LogsInformativos } from "../../logs/logs";

const AltaPreciosAct = ({ setShow, data, funcion }) => {
  const [folio, setFolio] = useState("");
  const [nombrePieza, setNombrePieza] = useState("");
  const [materialSelect, setMaterialSelect] = useState("");
  const [departamentoSelect, setDepartamentoSelect] = useState("");
  const [costoProduccion, setCostoProduccion] = useState(0);
  const [precioPublico, setPrecioPublico] = useState(0);
  const [descripcionPieza, setDescripcionPieza] = useState("");
  const [colorimetriasSeleccionadas, setColorimetriasSeleccionadas] = useState([]);
  const [listarDepartamento, setListDepartamento] = useState([]);
  const [listarColorimetrias, setListColorimetrias] = useState([]);
  const [idPieza, setIdPieza] = useState("");
  const [listarMateriales, setListMateriales] = useState([]);
  const [archivo, setArchivo] = useState(null);

  useEffect(() => {
    obtenerDepartamento();
    obtenerColorimetrias();
    if (funcion === "registro") {
      obtenerFolio();
    } else {
      setIdPieza(data[0]);
      setFolio(data[1]);
      setNombrePieza(data[2]);
      setDepartamentoSelect(data[3]);
      setMaterialSelect(data[4]);
      setCostoProduccion(data[5]);
      setPrecioPublico(data[6]);
      setDescripcionPieza(data[8]);
      setDepartamentoSelect(data[9]);
      setMaterialSelect(data[10]);
    }
  }, [funcion]);

  useEffect(() => {
    if (departamentoSelect) {
      obtenerMaterialesPorDepartamento(departamentoSelect);
    }
  }, [departamentoSelect]);

  const obtenerFolio = async () => {
    try {
      const response = await obtenerNumeroPieza();
      const { noPieza } = response.data;
      setFolio(noPieza);
    } catch (e) {
      console.error(e);
    }
  };

  const obtenerDepartamento = async () => {
    try {
      const response = await listarPorTipoDepartamento("Produccion");
      const datosDepartamento = formatModelDepartamento(response.data);
      setListDepartamento(datosDepartamento);
    } catch (e) {
      console.error(e);
    }
  };

  const obtenerColorimetrias = async () => {
    try {
      const response = await listarColorimetria();
      const datosColorimetria = formatModelColorimetria(response.data);
      setListColorimetrias(datosColorimetria);
    } catch (e) {
      console.error(e);
    }
  };

  const obtenerMaterialesPorDepartamento = async (departamento) => {
    try {
      const response = await listarMaterialPorDep(departamento);
      const datosMateriales = formatModelMateriales(response.data);
      setListMateriales(datosMateriales);
    } catch (e) {
      console.error(e);
    }
  };

  const handleFileChange = (e) => {
    setArchivo(e.target.files[0]);
  };

  const handleColorimetriaChange = (e) => {
    const selectedId = e.target.value;
    const selectedColorimetria = listarColorimetrias.find(
      (colorimetria) => colorimetria.id === selectedId
    );

    if (selectedColorimetria) {
      setColorimetriasSeleccionadas((prev) => [
        ...prev,
        {
          id: selectedColorimetria.id,
          nombre: selectedColorimetria.nombre,
          tonos: selectedColorimetria.tonos,
        },
      ]);
    }
  };

  const handleRemoveColorimetria = (id) => {
    setColorimetriasSeleccionadas((prev) => prev.filter((colorimetria) => colorimetria.id !== id));
  };

  const registro = async () => {
    if (!nombrePieza || !departamentoSelect || !materialSelect || !precioPublico) {
      toast.warning("Datos incompletos");
    } else {
      if (archivo) {
        try {
          const response = await subeArchivosCloudinary(archivo, "laboratorio");
          const { secure_url } = response.data;
          registraPieza(secure_url);
        } catch (e) {
          console.error(e);
        }
      } else {
        registraPieza("");
      }
    }
  };

  const registraPieza = async (linkArchivo) => {
    try {
      const dataTempIns = {
        folio,
        nombrePieza,
        material: materialSelect,
        departamento: departamentoSelect,
        costoProduccion: "1",
        precioPublico,
        descripcion: descripcionPieza,
        imagen: linkArchivo,
        estado: "activo",
        colorimetrias: colorimetriasSeleccionadas,
      };
      const response = await registraPrecios(dataTempIns);
      LogsInformativos("Se ha registrado la pieza" + dataTempIns.nombrePieza, dataTempIns);
      toast.success(response.data.mensaje);
      setShow(false);
    } catch (e) {
      console.error(e);
    }
  };

  const actualiza = async (e) => {
    e.preventDefault();
    try {
      const dataTemp = {
        nombrePieza,
        costoProduccion,
        precioPublico,
        descripcion: descripcionPieza,
        departamento: departamentoSelect,
        material: materialSelect,
        colorimetrias: colorimetriasSeleccionadas,
      };
      const response = await actualizarPrecios(idPieza, dataTemp);
      LogsInformativos("Se ha modificado el precio del material" + dataTemp.nombrePieza, dataTemp);
      toast.success(response.data.mensaje);
      setShow(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <Form>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col xs={12} md={3} lg={3} className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end">
            <Form.Label>Folio: <code>*</code></Form.Label>
          </Col>
          <Col xs={12} md={9} lg={9}>
            <Form.Control type="text" value={folio} disabled />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col xs={12} md={3} lg={3} className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end">
            <Form.Label>Pieza: <code>*</code></Form.Label>
          </Col>
          <Col xs={12} md={9} lg={9}>
            <Form.Control
              type="text"
              placeholder="Ingresa el nombre de la pieza"
              value={nombrePieza}
              onChange={(e) => setNombrePieza(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col xs={12} md={3} lg={3} className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end">
            <Form.Label>Departamento: <code>*</code></Form.Label>
          </Col>
          <Col xs={12} md={9} lg={9}>
            <Form.Control
              as="select"
              value={departamentoSelect}
              onChange={(e) => setDepartamentoSelect(e.target.value)}
            >
              <option value="">Seleccionar departamento</option>
              {listarDepartamento.map((departamento, index) => (
                <option key={index} value={departamento.folio}>
                  {departamento.nombre}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col xs={12} md={3} lg={3} className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end">
            <Form.Label>Material: <code>*</code></Form.Label>
          </Col>
          <Col xs={12} md={9} lg={9}>
            <Form.Control
              as="select"
              value={materialSelect}
              onChange={(e) => setMaterialSelect(e.target.value)}
            >
              <option value="">Seleccionar material</option>
              {listarMateriales.map((material, index) => (
                <option key={index} value={material.folio}>
                  {material.nombre}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col xs={12} md={3} lg={3} className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end">
            <Form.Label>Precio público: <code>*</code></Form.Label>
          </Col>
          <Col xs={12} md={9} lg={9}>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                type="number"
                value={precioPublico}
                onChange={(e) => setPrecioPublico(e.target.value)}
                min={0}
              />
            </InputGroup>
          </Col>
        </Row>
        {funcion !== "modificar" && (
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col xs={12} md={3} lg={3} className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end">
              <Form.Label>Imagen: </Form.Label>
            </Col>
            <Col xs={12} md={9} lg={9}>
              <Form.Control
                type="file"
                onChange={handleFileChange}
                accept="image/png, image/jpeg, .jpg"
              />
            </Col>
          </Row>
        )}
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col xs={12} md={3} lg={3} className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end">
            <Form.Label>Colorimetrias: <code>*</code></Form.Label>
          </Col>
          <Col xs={12} md={9} lg={9}>
            <Form.Control
              as="select"
              value=""
              onChange={handleColorimetriaChange}
            >
              <option value="">Seleccionar colorimetria</option>
              {listarColorimetrias.map((colorimetria, index) => (
                <option key={index} value={colorimetria.id}>
                  {colorimetria.nombre}
                </option>
              ))}
            </Form.Control>
            <Table striped bordered hover className="mt-3">
              <thead>
                <tr>
                  <th>NOMBRE</th>
                  <th>TONOS</th>
                  <th>ACCIONES</th>
                </tr>
              </thead>
              <tbody>
                {colorimetriasSeleccionadas.map((item, index) => (
                  <tr key={index}>
                    <td>{item.nombre}</td>
                    <td>{item.tonos}</td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => handleRemoveColorimetria(item.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col xs={12} md={3} lg={3} className="d-flex align-items-start justify-content-start justify-content-md-end justify-content-lg-end">
            <Form.Label>Descripción: </Form.Label>
          </Col>
          <Col xs={12} md={9} lg={9}>
            <Form.Control
              as="textarea"
              placeholder="Ingresa una descripción de la pieza"
              value={descripcionPieza}
              onChange={(e) => setDescripcionPieza(e.target.value)}
            />
          </Col>
        </Row>
      </Form>
      <div className="d-flex justify-content-center">
        {funcion === "registro" ? (
          <Button variant="success" onClick={registro}>
            <FontAwesomeIcon icon={faFloppyDisk} /> Registrar
          </Button>
        ) : (
          <Button variant="warning" onClick={actualiza}>
            <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
          </Button>
        )}
      </div>
    </div>
  );
};

function formatModelDepartamento(data) {
  return data.map((item) => ({
    folio: item.folio,
    nombre: item.nombre,
  }));
}

function formatModelMateriales(data) {
  return data.map((item) => ({
    folio: item.folio,
    nombre: item.nombreMaterial,
  }));
}

function formatModelColorimetria(data) {
  return data.map((item) => ({
    id: item._id,
    nombre: item.nombre,
    tonos: item.tonos,
    imagen: item.imagen || "Sin Imagen",
    estado: item.estado,
  }));
}

export default AltaPreciosAct;
