import { useEffect, useState } from "react";
import Select from "react-select";
import {
  listarFechasyEstadoPago,
  listarOrdenesConServicios,
  listarOrdenesConServiciosSep,
  listarOrdenesServicio,
  listarSaldosNuevo,
} from "../../../api/ordenesServicio";
import {
  Document,
  Font,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import logo from "../../../assets/img/logobarra_3.png";
import logoTicket from "../../../assets/img/logoTicket.png";
import { Col, Form, Row, Button } from "react-bootstrap";
import { Load } from "../../load/load";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import FechaEntrega from "../FechaEntrega";
import { listarAbonosAprobados } from "../../../api/abonosOrdenes";
import { listarDiasFestivos } from "../../../api/diasFestivos";

const styles = StyleSheet.create({
  container: {
    fontWeight: "bold",
    flexDirection: "row",
    marginTop: 10,
    marginLeft: 20,
    marginRight: 20,
    borderWidth: 1,
    borderColor: "#0c1b30",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  child: {
    fontWeight: "bold",
    width: "50%",
    padding: 10,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    borderWidth: 1,
    borderColor: "#0c1b30",
  },
  title: {
    fontWeight: "bold",
    backgroundColor: "#0c1b30",
    color: "white",
    textAlign: "center",
    fontSize: 6,
    padding: 5,
  },
  content: {
    fontWeight: "bold",
    marginTop: 0,
    marginRight: 15,
  },
  column: {
    fontWeight: "bold",
    width: "50%",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#0c1b30",
    backgroundColor: "white",
    margin: 2,
    height: 130,
  },
  imageContainer: {
    alignItems: "center",
  },
  image: {
    width: 86,
    height: 120,
  },
  column2: {
    fontWeight: "bold",
    width: "50%",
    borderRadius: 0,
    padding: 5,
    backgroundColor: "white",
    margin: 2,
    height: 180,
  },
  blueHeader: {
    fontWeight: "bold",
    backgroundColor: "#0c1b30",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    height: 30,
  },
  headerText: {
    fontWeight: "bold",
    color: "white",
    textAlign: "center",
    fontSize: 6,
    marginTop: 2,
  },
  imageContainer2: {
    imageContainer: {
      width: 300, // Ajusta el ancho según sea necesario
      height: 300, // Ajusta la altura según sea necesario
      margin: "auto", // Centra el contenedor
    },
  },
  imageP1: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  imageP2: {
    position: "absolute",
    top: "34%",
    left: "37%",
    width: "30%",
    height: "35%",
  },
  table: {
    display: 'table',
    width: 'auto',
    margin: 'auto',
  },
  tableRow: {
    flexDirection: 'row',
    marginBottom: 2
  },
  tableCol: {
    width: '85%',
  },
  tableCol2: {
    width: '15%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'black',
  },
  tableCell: {
    margin: 4,
    fontSize: 8,
    fontWeight: 'bolder',

    wordWrap: 'break-word',
  },
});

const stylesTicket = StyleSheet.create({
  page: {
    fontWeight: "bold",
    padding: 10,
    backgroundColor: "#E4E4E4",
  },
  section: {
    fontWeight: "bold",
    margin: 5,
    padding: 7,
    border: "1px solid #000",
    flex: 9,
  },
  header: {
    fontWeight: "bold",
    fontSize: 20,
    textAlign: "center",
    fontFamily: "Courier-Bold",
    textTransform: "uppercase",
  },
  headerSub: {
    fontWeight: "bold",
    fontSize: 9,
    textAlign: "center",
    fontFamily: "Courier-Bold",
    textTransform: "uppercase",
    marginBottom: 3,
  },
  headerDom: {
    fontWeight: "bold",
    fontSize: 9,
    textAlign: "center",
    textTransform: "uppercase",
    marginBottom: 10,
  },
  headerTitle: {
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center",
    textTransform: "uppercase",
    marginBottom: 5,
  },
  content: {
    fontWeight: "bold",
    textTransform: "uppercase",
    fontSize: 9,
    marginBottom: 3,
  },
  footer: {
    fontWeight: "bold",
    marginTop: 20,
    textAlign: "center",
    fontSize: 10,
    color: "black",
  },
  footer2: {
    fontWeight: "bold",
    marginTop: 20,
    textAlign: "center",
    fontSize: 7,
    color: "black",
  },
});

const ImpresionOrdenes = ({ datosImp, listarOrdenes2 }) => {
  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  return (
    <>
      <Document>
        {datosImp?.map((pag, index) => (
          <Page size="A4" orientation="landscape" key={index}>
            <View
              style={{
                fontWeight: "bold",
                flexDirection: "row",
                flexWrap: "wrap",
                height: "100%",
              }}
            >
              {pag?.map((servicio, index2) => (
                <View
                  style={{
                    fontWeight: "bold",
                    width: "32%",
                    height: "98%",
                    margin: 5,
                  }}
                  key={index2}
                >
                  <View>
                    <View
                      style={{
                        fontWeight: "bold",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#0c1b30",
                        height: 40,
                      }}
                    >
                      <Image src={logo} style={{ width: 120, height: 30 }} />
                    </View>
                  </View>

                  <View>
                    <View
                      style={{
                        fontWeight: "bold",
                        marginTop: 10,
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: 7,
                      }}
                    >
                      <Text
                        style={{ fontSize: 8, fontWeight: "bolder" }}
                        wordWrap="break-word"
                      >
                        ORDEN DE CLIENTE: {servicio.folio}
                      </Text>
                      <View style={{ marginTop: 5 }}>
                        <View
                          style={{
                            fontWeight: "bold",
                            flexDirection: "row",
                            alignItems: "flex-end",
                            marginTop: 3,
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 8,
                              marginLeft: 5,
                              fontWeight: "bold",
                            }}
                            wordWrap="break-word"
                          >
                            Fecha de creacion:
                          </Text>
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 8,
                              marginLeft: 3,
                            }}
                            wordWrap="break-word"
                          >
                            {dayjs(servicio.fecha).format("LL")}
                          </Text>
                        </View>
                        <View
                          style={{
                            fontWeight: "bold",
                            flexDirection: "row",
                            alignItems: "flex-end",
                            marginTop: 3,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 8,
                              marginLeft: 5,
                              fontWeight: "bold",
                            }}
                            wordWrap="break-word"
                          >
                            Fecha de salida:
                          </Text>
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 8,
                              marginLeft: 3,
                            }}
                            wordWrap="break-word"
                          >
                            {
                              <FechaEntrega
                                id={servicio.id}
                                fecha={servicio.fecha}
                                ruta={servicio.direccionConsultorio2}
                                listarOrdenes={listarOrdenes2}
                              />
                            }
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        fontWeight: "bold",
                        flexDirection: "column",
                        marginTop: 5,
                        marginLeft: 7,
                        marginRight: 7,
                        border: "1 solid #0c1b30",
                        borderRadius: 10,
                      }}
                    >
                      <View
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "#0c1b30",
                          padding: 3,
                          borderTopLeftRadius: 10,
                          borderTopRightRadius: 10,
                        }}
                      >
                        <Text
                          style={{
                            fontWeight: "bold",
                            fontSize: 8,
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          DATOS GENERALES
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "flex-center",
                          marginTop: 5,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 8,
                            marginLeft: 5,
                            fontWeight: "bold",
                          }}
                          wordWrap="break-word"
                        >
                          Ruta:
                        </Text>
                        <Text
                          style={{
                            fontSize: 8,
                            marginLeft: 7,
                            alignItems: "flex-end",
                            marginRight: 10,
                            textTransform: "uppercase",
                          }}
                          wordWrap="break-word"
                        >
                          {typeof servicio.direccionConsultorio === "object"
                            ? servicio.direccionConsultorio.localidad +
                            ", " +
                            servicio.direccionConsultorio.CP +
                            ", " +
                            servicio.direccionConsultorio.municipio
                            : servicio.direccionConsultorio}
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "flex-center",
                          marginTop: 7,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 8,
                            marginLeft: 5,
                            fontWeight: "bold",
                          }}
                          wordWrap="break-word"
                        >
                          Doctor:
                        </Text>
                        <Text
                          style={{
                            fontSize: 8,
                            marginLeft: 7,
                            alignItems: "flex-end",
                            marginRight: 10,
                          }}
                          wordWrap="break-word"
                        >
                          {servicio.nombreDoctor}
                        </Text>
                      </View>
                    </View>

                    <View style={{ flexDirection: "column", marginTop: 7 }}>
                      <View
                        style={{
                          backgroundColor: "#0c1b30",
                          padding: 5,
                          border: "1 solid #0c1b30",
                          marginLeft: 7,
                          marginRight: 7,
                          borderTopLeftRadius: 10,
                          borderTopRightRadius: 10,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 8,
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          DATOS DEL SERVICIO
                        </Text>
                      </View>
                      <View
                        style={{
                          border: "1 solid #0c1b30",
                          padding: 10,
                          marginLeft: 7,
                          marginRight: 7,
                          borderBottomLeftRadius: 10,
                          borderBottomRightRadius: 10,
                        }}
                      >
                        <View
                          style={{ flexDirection: "row", marginTop: 0 }}
                          wordWrap="break-word"
                        >
                          <Text style={{ fontSize: 8, fontWeight: "bold" }}>
                            Pieza:
                          </Text>
                          <Text
                            style={{
                              fontSize: 8,
                              marginLeft: 5,
                              marginRight: 20,
                            }}
                            wordWrap="break-word"
                          >
                            {servicio.det_pieza}
                          </Text>
                        </View>
                        <View style={{ flexDirection: "row", marginTop: 7 }}>
                          <Text style={{ fontSize: 8, fontWeight: "bold" }}>
                            Material:
                          </Text>
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 8,
                              marginLeft: 5,
                            }}
                          >
                            {servicio.det_material}
                          </Text>
                        </View>
                        <View style={{ flexDirection: "row", marginTop: 7 }}>
                          <Text style={{ fontSize: 8, fontWeight: "bold" }}>
                            Descripcion adicional:
                          </Text>
                          <View style={{ width: 150, flexWrap: "wrap", marginLeft: 5 }}>
                            <Text
                              style={{
                                fontWeight: "bold",
                                fontSize: 8,
                                lineHeight: 1.2,
                              }}
                            >
                              {servicio.det_descripcionAdicional}
                            </Text>
                          </View>

                        </View>
                        <View style={{ flexDirection: "row", marginTop: 7 }}>
                          <Text style={{ fontSize: 8, fontWeight: "bold" }}>
                            Tipo de dentadura:
                          </Text>
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 8,
                              marginLeft: 5,
                            }}
                          >
                            {servicio.det_distencion === "DP"
                              ? "Permanente"
                              : "Temporal"}
                          </Text>
                        </View>
                        <View style={{ flexDirection: "row", marginTop: 7 }}>
                          <Text style={{ fontSize: 8, fontWeight: "bold" }}>
                            Sistema de Color:
                          </Text>
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 8,
                              marginLeft: 5,
                            }}
                          >
                            {servicio.det_sistemaColor}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View style={{ flexDirection: "column", marginTop: 7 }}>
                      <View
                        style={{
                          backgroundColor: "#0c1b30",
                          padding: 5,
                          border: "1 solid #0c1b30",
                          marginLeft: 7,
                          marginRight: 7,
                          borderTopLeftRadius: 10,
                          borderTopRightRadius: 10,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 8,
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          DATOS DE LAS PROTESIS
                        </Text>
                      </View>
                      <View
                        style={{
                          border: "1 solid #0c1b30",
                          padding: 10,
                          marginLeft: 7,
                          marginRight: 7,
                          borderBottomLeftRadius: 10,
                          borderBottomRightRadius: 10,
                        }}
                      >
                        <View
                          style={{ flexDirection: "row", marginTop: 0 }}
                          wordWrap="break-word"
                        >
                          <Text style={{ fontSize: 8, fontWeight: "bold" }}>
                            Protesis:
                          </Text>
                          <Text
                            style={{
                              fontSize: 8,
                              marginLeft: 5,
                              marginRight: 20,
                            }}
                            wordWrap="break-word"
                          >
                            {servicio.servicios}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      marginTop: 3,
                      marginLeft: 7,
                      marginRight: 7,
                    }}
                  >
                    <View style={styles.column}>
                      <View style={styles.blueHeader}>
                        <Text style={styles.headerText}>
                          ORGANO DENTAL: {servicio.det_diente}
                        </Text>
                      </View>
                      <View style={styles.imageContainer}>
                        {servicio.det_imagenEsquema ? (
                          <Image
                            source={{ uri: servicio?.det_imagenEsquema }}
                            style={styles.image}
                          />
                        ) : // No hay imagen, puedes poner aquí algún componente o dejarlo vacío
                          null}
                      </View>
                    </View>
                    <View style={styles.column}>
                      <View style={styles.blueHeader}>
                        <Text style={styles.headerText}>DIAGRAMA DE TONOS</Text>
                      </View>
                      <View style={styles.imageContainer}>
                        {servicio.det_imagenDiente ? (
                          <Image
                            source={{ uri: servicio.det_imagenDiente }}
                            style={styles.image}
                          />
                        ) : // No hay imagen, puedes poner aquí algún componente o dejarlo vacío
                          null}
                      </View>
                    </View>
                  </View>

                  <View
                    style={{
                      position: "absolute", // Posición absoluta
                      bottom: 0, // Alineado al fondo
                      width: "100%",
                      backgroundColor: "#0c1b30",
                      padding: 10,
                      height: 31,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 5,
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      EL ROBLE #38, SANTA CRUZ NIETO, 76804 SAN JUAN DEL RÍO,
                      QRO.
                    </Text>
                    <Text
                      style={{
                        fontSize: 5,
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      grupodentalherfran.com
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </Page>
        ))}
      </Document>
    </>
  );
};

const ImpresionTicket = ({ datosImp, saldos }) => {
  console.log(datosImp);
  // Multiplicar el campo precio por la longitud del campo diente
  const multiplicarPrecioPorLongitudDiente = datosImp[0]?.serviciosOS?.map(
    (item) => item.precio * item.diente.length
  );
  console.log(multiplicarPrecioPorLongitudDiente);
  // Sumar los resultados de la multiplicación
  const sumaMultiplicacion = multiplicarPrecioPorLongitudDiente?.reduce(
    (acc, curr) => acc + curr,
    0
  );
  console.log(sumaMultiplicacion);

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  function numberToWords(num) {
    const ones = [
      '', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve',
      'diez', 'once', 'doce', 'trece', 'catorce', 'quince', 'dieciséis', 'diecisiete', 'dieciocho', 'diecinueve'
    ];

    const tens = [
      '', '', 'veinte', 'treinta', 'cuarenta', 'cincuenta', 'sesenta', 'setenta', 'ochenta', 'noventa'
    ];

    const hundreds = [
      '', 'cien', 'doscientos', 'trescientos', 'cuatrocientos', 'quinientos', 'seiscientos', 'setecientos', 'ochocientos', 'novecientos'
    ];

    const thousands = [
      '', 'mil', 'millón', 'mil millones', 'billón', 'mil billones', 'trillón'
    ];

    function convert(num) {
      if (num === 0) return 'cero';

      if (num < 20) {
        return ones[num];
      }

      if (num < 100) {
        return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' y ' + ones[num % 10] : '');
      }

      if (num < 1000) {
        if (num === 100) return 'cien';
        return hundreds[Math.floor(num / 100)] + (num % 100 !== 0 ? ' ' + convert(num % 100) : '');
      }

      for (let i = 0, base = 1000; base <= Number.MAX_SAFE_INTEGER; i++, base *= 1000) {
        if (num < base * 1000) {
          return convert(Math.floor(num / base)) + ' ' + thousands[i] + (num % base !== 0 ? ' ' + convert(num % base) : '');
        }
      }

      return 'Número fuera de rango';
    }

    return convert(num);
  }

  return (
    <>
      <Document>
        <Page size={{ width: 249.45, height: "auto" }}>
          {datosImp?.map((ticket, index) => (
            <>
              <View
                style={{ display: "flex", flexDirection: "row" }}
                key={index}
              >
                <View style={stylesTicket.section}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 40,
                    }}
                  >
                    <Image
                      src={logoTicket}
                      style={{ width: 180, height: 50 }}
                    />
                  </View>
                  <Text style={stylesTicket.headerDom}>
                    El Roble, #38, Colonia Santa Cruz Nieto, CP 76804, San Juan
                    del Río, Qro.
                  </Text>
                  <Text style={stylesTicket.headerTitle}>
                    FOLIO: {ticket.consecutivo}
                  </Text>
                  <Text style={stylesTicket.headerTitle}>
                    ORDEN DE CLIENTE - {ticket.folio}
                  </Text>
                  <Text style={stylesTicket.content}>
                    Doctor: {ticket.nombreDoctor}
                  </Text>
                  <Text style={stylesTicket.content}>
                    Domicilio:{" "}
                    {typeof ticket.direccionConsultorio === "object"
                      ? ticket.direccionConsultorio.localidad +
                      ", " +
                      ticket.direccionConsultorio.CP +
                      ", " +
                      ticket.direccionConsultorio.municipio
                      : ticket.direccionConsultorio}
                  </Text>
                  <Text style={stylesTicket.content}>
                    Precio Total: ${sumaMultiplicacion} ({numberToWords(sumaMultiplicacion)?.toUpperCase()} PESOS)
                  </Text>
                  <Text style={stylesTicket.headerTitle}>
                    Detalles del Servicio
                  </Text>
                  {ticket?.serviciosOS?.map((servicio, index) => (
                    <View key={index} style={stylesTicket.section}>
                      <Text style={stylesTicket.content}>
                        Pieza: {servicio.pieza} - {servicio.material}
                      </Text>
                      <Text style={stylesTicket.content}>
                        Precio: ${servicio.precio} ({numberToWords(servicio.precio)?.toUpperCase()} PESOS)
                      </Text>
                      <Text style={stylesTicket.content}>
                        Diente: {servicio.diente.join(", ")}
                      </Text>
                      <Text style={stylesTicket.content}>
                        Garantia:{" "}
                        {!servicio.garantia || servicio.garantia == "1"
                          ? "Sin garantia"
                          : "En garantia"}
                      </Text>
                    </View>
                  ))}
                  <View
                    style={{
                      display: "flex",
                      fontSize: 12,
                      textAlign: "center",
                    }}
                  >
                    <Text>
                      SALDO PENDIENTE: $
                      {
                        (
                          saldos.find((dato) => dato.id === ticket.cargoOrden) || { pendiente: "0" }
                        ).pendiente
                      }
                      {` (${numberToWords(
                        (
                          saldos.find((dato) => dato.id === ticket.cargoOrden) || { pendiente: "0" }
                        ).pendiente
                      )?.toUpperCase()} PESOS)`}
                    </Text>
                  </View>
                  <Text style={stylesTicket.footer2}>
                    DEBO Y PAGARE INCONDICIONALMENTE A LA ORDEN DE HERFRAN CORPORATIVO DENTAL SA DE CV EN ESTA CIUDAD O EN CUALQUIER OTRA QUE SE ME REQUIERA EL DIA {dayjs(ticket.fecha).format("LL")?.toUpperCase()} LA CANTIDAD DE ${sumaMultiplicacion} ({numberToWords(sumaMultiplicacion)?.toUpperCase()}) PESOS VALOR DE LAS MERCANCIAS O SERVICIOS RECIBIDOS A MI ENTERA CONFORMIDAD. ESTE PAGARE ES MERCANTIL Y ESTA REGIDO POR LA LEY GENERAL DE TITULOS Y OPERACIONES DE CREDITO EN SUS ARTICULOS 172 Y 173 PARTE FINAL POR NO SER PAGARE DOMICILIADO Y ARTICULOS CORRELATIVOS QUEDA CONVENIDO QUE EN CASO DE MORA, EL PRESENTE TITULO CAUSARA UN INTERES DEL 8% MENSUAL.
                  </Text>
                  <Text style={stylesTicket.footer}>Firma de recibido:</Text>
                  <Text style={stylesTicket.footer}>ORIGINAL CLIENTE</Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    fontSize: 8,
                    justifyContent: "center",
                    alignItems: "center",
                    transform: "rotate(90deg)",
                    width: 200,
                  }}
                >
                  <Text style={{ width: 200 }}>
                    Este documento funge como recibo de pago
                  </Text>
                </View>
              </View>
            </>
          ))}
        </Page>
        <Page size={{ width: 249.45, height: "auto" }}>
          {datosImp?.map((ticket, index) => (
            <View style={{ display: "flex", flexDirection: "row" }} key={index}>
              <View style={stylesTicket.section}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 40,
                  }}
                >
                  <Image src={logoTicket} style={{ width: 180, height: 50 }} />
                </View>
                <Text style={stylesTicket.headerDom}>
                  El Roble, #38, Colonia Santa Cruz Nieto, CP 76804, San Juan
                  del Río, Qro.
                </Text>
                <Text style={stylesTicket.headerTitle}>
                  FOLIO: {ticket.consecutivo}
                </Text>
                <Text style={stylesTicket.headerTitle}>
                  ORDEN DE CLIENTE - {ticket.folio}
                </Text>
                <Text style={stylesTicket.content}>
                  Doctor: {ticket.nombreDoctor}
                </Text>
                <Text style={stylesTicket.content}>
                  Domicilio:{" "}
                  {typeof ticket.direccionConsultorio === "object"
                    ? ticket.direccionConsultorio.localidad +
                    ", " +
                    ticket.direccionConsultorio.CP +
                    ", " +
                    ticket.direccionConsultorio.municipio
                    : ticket.direccionConsultorio}
                </Text>
                <Text style={stylesTicket.content}>
                  Precio Total: ${sumaMultiplicacion} ({numberToWords(sumaMultiplicacion)?.toUpperCase()} PESOS)
                </Text>
                <Text style={stylesTicket.headerTitle}>
                  Detalles del Servicio
                </Text>
                {ticket?.serviciosOS?.map((servicio, index) => (
                  <View key={index} style={stylesTicket.section}>
                    <Text style={stylesTicket.content}>
                      Pieza: {servicio.pieza} - {servicio.material}
                    </Text>
                    <Text style={stylesTicket.content}>
                      Precio: ${servicio.precio} ({numberToWords(servicio.precio)?.toUpperCase()} PESOS)
                    </Text>
                    <Text style={stylesTicket.content}>
                      Diente: {servicio.diente.join(", ")}
                    </Text>
                    <Text style={stylesTicket.content}>
                      Garantia:{" "}
                      {!servicio.garantia || servicio.garantia == "1"
                        ? "Sin garantia"
                        : "En garantia"}
                    </Text>
                  </View>
                ))}
                <View
                  style={{
                    display: "flex",
                    fontSize: 12,
                    textAlign: "center",
                  }}
                >
                  <Text>
                    SALDO PENDIENTE: $
                    {
                      (
                        saldos.find((dato) => dato.id === ticket.cargoOrden) || { pendiente: "0" }
                      ).pendiente
                    }
                    {` (${numberToWords(
                      (
                        saldos.find((dato) => dato.id === ticket.cargoOrden) || { pendiente: "0" }
                      ).pendiente
                    )?.toUpperCase()} PESOS)`}
                  </Text>
                </View>
                <Text style={stylesTicket.footer2}>
                  DEBO Y PAGARE INCONDICIONALMENTE A LA ORDEN DE HERFRAN CORPORATIVO DENTAL SA DE CV EN ESTA CIUDAD O EN CUALQUIER OTRA QUE SE ME REQUIERA EL DIA {dayjs(ticket.fecha).format("LL")?.toUpperCase()} LA CANTIDAD DE ${sumaMultiplicacion} ({numberToWords(sumaMultiplicacion)?.toUpperCase()}) PESOS VALOR DE LAS MERCANCIAS O SERVICIOS RECIBIDOS A MI ENTERA CONFORMIDAD. ESTE PAGARE ES MERCANTIL Y ESTA REGIDO POR LA LEY GENERAL DE TITULOS Y OPERACIONES DE CREDITO EN SUS ARTICULOS 172 Y 173 PARTE FINAL POR NO SER PAGARE DOMICILIADO Y ARTICULOS CORRELATIVOS QUEDA CONVENIDO QUE EN CASO DE MORA, EL PRESENTE TITULO CAUSARA UN INTERES DEL 8% MENSUAL.
                </Text>
                <Text style={stylesTicket.footer}>Firma de recibido:</Text>
                <Text style={stylesTicket.footer}>MENSAJERO COPIA</Text>
              </View>
              <View
                style={{
                  flex: 1,
                  fontSize: 8,
                  justifyContent: "center",
                  alignItems: "center",
                  transform: "rotate(90deg)",
                  width: 200,
                }}
              >
                <Text style={{ width: 200 }}>No válida para cobro</Text>
              </View>
            </View>
          ))}
        </Page>
      </Document>
    </>
  );
};

const ImpresionOrdenes2 = ({ datosImp, listarOrdenes2 }) => {
  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  return (
    <>
      <Document>
        {datosImp?.map((pag, index) => (
          <Page size={{ width: 567, height: 765.45 }} orientation="landscape" key={index}>
            <View
              style={{
                fontWeight: "bold",
                flexDirection: "row",
                flexWrap: "wrap",
                height: "100%",
              }}
            >
              {pag?.map((servicio, index2) => (
                <View
                  style={{
                    fontWeight: "bold",
                    width: "32%",
                    height: "98%",
                    margin: 5,
                  }}
                  key={index2}
                >
                  <View>
                    <View
                      style={{
                        fontWeight: "bold",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#0c1b30",
                        height: 40,
                      }}
                    >
                      <Image src={logo} style={{ width: 120, height: 30 }} />
                    </View>
                  </View>

                  <View>
                    <View
                      style={{
                        fontWeight: "bold",
                        marginTop: 10,
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: 7,
                      }}
                    >
                      <Text
                        style={{ fontSize: 8, fontWeight: "bolder" }}
                        wordWrap="break-word"
                      >
                        FECHA DE SALIDA:{" "}
                        {
                          <FechaEntrega
                            id={servicio.id}
                            fecha={servicio.fecha}
                            ruta={servicio.direccionConsultorio2}
                            listarOrdenes={listarOrdenes2}
                          />
                        }
                      </Text>

                      <Text
                        style={{ fontSize: 8, fontWeight: "bolder" }}
                        wordWrap="break-word"
                      >
                        FOLIO: {servicio.folio}
                      </Text>

                      <Text
                        style={{ fontSize: 8, fontWeight: "bolder" }}
                        wordWrap="break-word"
                      >
                        RUTA:{" "}
                        {typeof servicio.direccionConsultorio === "object"
                          ? servicio.direccionConsultorio.municipio
                          : servicio.direccionConsultorio}
                      </Text>
                    </View>

                    <View
                      style={{
                        padding: 10,
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                      }}
                    >
                      <View style={{ flexDirection: "row", marginTop: 7 }}>
                        <Text style={{ fontSize: 8, fontWeight: "bold" }}>
                          DESCRIPCION:
                        </Text>
                      </View>

                      <View style={{ flexDirection: "row", marginTop: 7 }}>
                        <Text
                          style={{
                            fontWeight: "bold",
                            fontSize: 8,
                            marginLeft: 5,
                          }}
                        >
                          {servicio?.det_pieza?.toUpperCase() +
                            ", " +
                            servicio?.det_material?.toUpperCase() +
                            ", " +
                            servicio?.det_clasificacion?.toUpperCase() +
                            ",\n" +
                            servicio?.det_diente?.toUpperCase() +
                            ", " +
                            servicio?.det_sistemaColor?.toUpperCase() +
                            ",\n" +
                            servicio?.det_descripcionAdicional?.toUpperCase()}
                        </Text>
                      </View>
                    </View>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      marginTop: 3,
                      marginLeft: 7,
                      marginRight: 7,
                    }}
                  >
                    <View style={styles.column2}>
                      <View style={styles.imageContainer2}>
                        {servicio.det_imagenEsquema && (
                          <Image
                            source={{ uri: servicio.det_imagenEsquema }}
                            style={styles.imageP1}
                          />
                        )}
                        {servicio.det_imagenDiente && (
                          <Image
                            source={{ uri: servicio.det_imagenDiente }}
                            style={styles.imageP2}
                          />
                        )}
                      </View>
                    </View>
                    <View style={styles.column2}>
                      <Text style={{ fontSize: 10, textAlign: "center", marginBottom: 3 }}>ENVÍA</Text>
                      <View style={styles.table}>
                        {servicio.servicios2?.map((item, index) => (
                          <View style={styles.tableRow} key={index}>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {item.servicio}
                              </Text>
                            </View>
                            <View style={styles.tableCol2}>
                              <Text style={styles.tableCell}>
                                {item.cantidad}
                              </Text>
                            </View>
                          </View>
                        ))}
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      fontWeight: "bold",
                      marginTop: 10,
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: 7,
                    }}
                  >
                    <Text
                      style={{ fontSize: 8, fontWeight: "bolder" }}
                      wordWrap="break-word"
                    >
                      OTROS: {servicio.otros}
                    </Text>
                  </View>
                  <View
                    style={{
                      fontWeight: "bold",
                      marginTop: 10,
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: 7,
                    }}
                  >
                    <Text
                      style={{ fontSize: 8, fontWeight: "bolder" }}
                      wordWrap="break-word"
                    >
                      RECIBIO: {servicio.recibio}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </Page>
        ))}
      </Document>
    </>
  );
};

const ViewPDFImp = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [listOrdenes, setListOrdenes] = useState([]);
  const [listOrdenesSeleccionadas, setListOrdenesSeleccionadas] = useState([]);
  const [listOrdenesServicios, setListOrdenesServicios] = useState([]);
  const [arregloPDF, setArregloPDF] = useState([]);
  const [loading, setLoading] = useState(true);
  const [optionSelect, setOptionSelect] = useState("pdf");
  const [clientesSaldos, setClientesSaldos] = useState([]);

  const [contador, setContador] = useState(0);

  const actualizarContador = () => {
    setLoading(true);
    setContador((prevContador) => prevContador + 1);
  };

  // Fetch initial list of orders
  useEffect(() => {
    obtenerOrdenes();
  }, []);

  // Fetch orders with services when selectedOption or optionSelect changes
  useEffect(() => {
    if (selectedOption) {
      if (optionSelect === "pdf") {
        obtenerOrdenesConServicios(selectedOption);
      } else if (optionSelect === "ticket") {
        obtenerOrdenesConServiciosSep(selectedOption);
      } else if (optionSelect === "papeleta") {
        obtenerOrdenesConServicios(selectedOption);
      }
    }
  }, [contador, optionSelect]);

  // Update PDF chunks when listOrdenesServicios changes
  useEffect(() => {
    let chunkedData = chunkArray(listOrdenesServicios, 3);
    setArregloPDF(chunkedData);
  }, [listOrdenesServicios]);

  // Clear selected options and reset states when optionSelect changes
  useEffect(() => { }, [optionSelect]);

  // Fetch initial list of orders
  const obtenerOrdenes = () => {
    try {
      listarOrdenesServicio()
        .then((response) => {
          const { data } = response;
          if (data) {
            const ordenesFiltradas = data.filter(orden => orden.estado != 0);
            setListOrdenes(formatModelOrdenes(ordenesFiltradas));
          }
        })
        .catch((e) => {
          console.error("Error fetching orders:", e);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 500);
        });
    } catch (e) {
      console.error("Exception fetching orders:", e);
    }
  };

  const [listarOrdenes2, setListOrdenes2] = useState([]);

  const obtenerOrdenesServicio2 = async () => {
    try {
      const response = await listarDiasFestivos();
      const { data } = response;
      setListOrdenes2(formatModelOrdenes2(data));
    } catch (e) {
      console.error('Error al obtener las órdenes de servicio:', e);
    }
  };

  useEffect(() => {
    obtenerOrdenesServicio2();
  }, []);

  // Fetch orders with services based on selected folios
  const obtenerOrdenesConServicios = (folios) => {
    try {
      listarOrdenesConServicios(folios)
        .then((response) => {
          const { data } = response;
          if (data) {
            setListOrdenesServicios(formatModelOrdenesyServicios(data));
            setLoading(false);
          }
        })
        .catch((e) => {
          console.error("Error fetching orders with services:", e);
        })
    } catch (e) {
      console.error("Exception fetching orders with services:", e);
    }
  };

  // Fetch orders with services (alternative version) based on selected folios
  const obtenerOrdenesConServiciosSep = (folios) => {
    try {
      listarOrdenesConServiciosSep(folios)
        .then((response) => {
          const { data } = response;
          if (data) {
            setListOrdenesServicios(formatModelOrdenesyServiciosSep(data));
            setLoading(false);
          }
        })
        .catch((e) => {
          console.error("Error fetching orders with services (sep):", e);
        })
    } catch (e) {
      console.error("Exception fetching orders with services (sep):", e);
    }
  };

  // Utility function to chunk an array into smaller arrays
  function chunkArray(array, chunkSize) {
    let result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  // Handles changes in document type selection
  const handleDocumentTypeChange = (e) => {
    setOptionSelect(e.target.value);
  };

  // Handles changes in selected options
  const handleSelectChange = (selectedOptions) => {
    setListOrdenesSeleccionadas(selectedOptions || []);
    const values = selectedOptions
      ? selectedOptions?.map((option) => option.value).join(",")
      : "";
    setSelectedOption(values);
  };

  const [listAbonosA, setListAbonosA] = useState([]);

  const obtenerAA = () => {
    try {
      listarAbonosAprobados()
        .then((response) => {
          const { data } = response;
          console.log("AA: -> ", data);
          if (!listAbonosA && data) {
            setListAbonosA(formatModelAA(data));
          } else {
            setListAbonosA(formatModelAA(data));
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [listSaldos, setListSaldos] = useState([]);

  const obtenerSaldos = () => {
    try {
      listarSaldosNuevo()
        .then((response) => {
          const { data } = response;
          if (data.length > 0) {
            setListSaldos(data);
          } else {
            setListSaldos([]);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [listOS, setListOS] = useState([]);

  const obtenerOS = () => {
    try {
      listarFechasyEstadoPago()
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (data.length > 0) {
            const ordenesAdeudadas = data.filter(
              (registro) => registro.diasDesdeFecha > 15
            );
            console.log(
              "🚀 ~ .then ~ ordenesAdeudadas:",
              formatModelOrdenes(ordenesAdeudadas)
            );

            const ordenesFormat = formatModelOrdenes(ordenesAdeudadas);
            //setListOS(formatModelOrdenes(data));

            // Inicializar un objeto vacío para almacenar los resultados agrupados
            const agrupados = {};

            // Iterar sobre cada orden en el arreglo
            ordenesFormat.forEach((orden) => {
              // Obtener el valor de cargoOrden y adeudo de la orden actual
              const { cargoOrden, adeudo } = orden;

              // Si cargoOrden está definido
              if (cargoOrden) {
                // Si el grupo para este cargoOrden no existe, inicializarlo con 0
                if (!agrupados[cargoOrden]) {
                  agrupados[cargoOrden] = 0;
                }

                // Agregar el adeudo de la orden actual al grupo correspondiente
                agrupados[cargoOrden] += adeudo;
              }
            });

            // Convertir el objeto agrupado a un arreglo para facilitar su visualización
            const resultado = Object.keys(agrupados)?.map((cargoOrden) => ({
              cargoOrden,
              adeudoTotal: agrupados[cargoOrden],
            }));

            setListOS(resultado);
          } else {
            setListOS([]);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerAA();
    obtenerSaldos();
    obtenerOS();
  }, []);

  useEffect(() => {
    const calcularClientesConAbonos = () => {
      const clientesConAbonosCalculados = listSaldos?.map((cliente) => {
        const abono = listAbonosA.find((abono) => abono.id === cliente._id);
        return {
          ...cliente,
          totalCantidadRecibida: abono ? abono.totalCantidadRecibida : 0,
        };
      });

      const saldosCompletos = clientesConAbonosCalculados?.map((cliente) => {
        const adeudo = listOS.find(
          (adeudo) => adeudo.cargoOrden === cliente._id
        );
        return {
          ...cliente,
          totalSaldoVencido: adeudo ? adeudo.adeudoTotal : 0,
        };
      });
      setClientesSaldos(formatModelSaldos(saldosCompletos));
      console.log(formatModelSaldos(saldosCompletos));
    };

    calcularClientesConAbonos();
  }, [listSaldos, listAbonosA, listOS]);

  return (
    <>
      {loading && <Load />}
      <div className="ml-2 mr-2">
        <h1>Impresión de órdenes de cliente</h1>
        <Row className="align-items-end">
          <Col sm={12} md={5}>
            <label>Selecciona el tipo de documento</label>
            <Form.Control
              as="select"
              value={optionSelect}
              onChange={handleDocumentTypeChange}
            >
              <option value="pdf">Documento PDF</option>
              <option value="ticket">Ticket</option>
              <option value="papeleta">Papeleta</option>
            </Form.Control>
          </Col>
          <Col sm={12} md={5}>
            <label>Selecciona las órdenes a imprimir</label>
            <Select
              options={listOrdenes}
              isSearchable={true}
              placeholder={"Seleccionar..."}
              isMulti
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              onChange={handleSelectChange}
              value={listOrdenesSeleccionadas}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </Col>
          <Col sm={12} md={2}>
            <Button
              variant="primary"
              onClick={actualizarContador}
              className="mt-3 w-100"
            >
              Generar
            </Button>
          </Col>
        </Row>

        <div className="divTabla">
          {optionSelect === "pdf" &&
            (arregloPDF.length > 0 ? (
              <PDFViewer width={"100%"} height={700}>
                <ImpresionOrdenes datosImp={arregloPDF} listarOrdenes2={listarOrdenes2} />
              </PDFViewer>
            ) : (
              <div style={{ height: "80vh" }}>
                <h3 style={{ marginTop: "3%", fontStyle: "italic" }}>
                  Selecciona primero alguna orden de cliente para poder generar
                  el archivo para impresión
                </h3>
              </div>
            ))}
          {optionSelect === "ticket" &&
            (listOrdenesServicios.length > 0 ? (
              <PDFViewer width={"100%"} height={700}>
                <ImpresionTicket
                  datosImp={listOrdenesServicios}
                  saldos={clientesSaldos}
                />
              </PDFViewer>
            ) : (
              <div style={{ height: "80vh" }}>
                <h3 style={{ marginTop: "3%", fontStyle: "italic" }}>
                  Selecciona primero alguna orden de cliente para poder generar
                  el archivo para impresión
                </h3>
              </div>
            ))}
          {optionSelect === "papeleta" &&
            (arregloPDF.length > 0 ? (
              <PDFViewer width={"100%"} height={700}>
                <ImpresionOrdenes2 datosImp={arregloPDF} listarOrdenes2={listarOrdenes2} />
              </PDFViewer>
            ) : (
              <div style={{ height: "80vh" }}>
                <h3 style={{ marginTop: "3%", fontStyle: "italic" }}>
                  Selecciona primero alguna orden de cliente para poder generar
                  el archivo para impresión
                </h3>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

const formatModelOrdenes = (data) => {
  return data?.map((orden) => ({
    value: orden.folio,
    label: `Orden ${orden.folio}`,
  }));
};

function formatModelOrdenesyServicios(data) {
  return data?.map((item) => {
    const det_dienteArray = item.detalles_servicio.diente;
    const det_diente = Array.isArray(det_dienteArray)
      ? det_dienteArray
        ?.map((d) => d.match(/\d+/)) // Extract numbers from each string
        .filter(Boolean) // Remove any null values resulting from no match
        .join(", ") // Join the numbers with commas
      : "";

    // Parse the original date
    const fechaOriginal = new Date(item.fecha);
    // Add 3 days to the original date
    const fechaTresDiasDespues = new Date(fechaOriginal);
    fechaTresDiasDespues.setDate(fechaTresDiasDespues.getDate() + 3);

    // Format the new date as 'YYYY-MM-DD'
    const formattedFechaTresDiasDespues = fechaTresDiasDespues
      .toISOString()
      .split("T")[0];

    return {
      id: item._id,
      folio: item.folio,
      fecha: item.fecha,
      fechaTresDiasDespues: formattedFechaTresDiasDespues,
      localidad: item.localidad,
      nombreDoctor: item.nombreDoctor,
      nombrePaciente: item.nombrePaciente,
      edadPaciente: item.edadPaciente,
      precioTotal: item.precioTotal,
      tipoDentadura: item.tipoDentadura,
      otros: item.otros,
      recibio: item.recibio,
      fechaEntrada: item.fechaEntrada,
      direccionConsultorio2: item.direccionConsultorio.municipio,
      servicios: item.servicios
        ?.map((obj) => `${obj.servicio} x ${obj.cantidad}`)
        .join(", "),
      servicios2: item.servicios,
      direccionConsultorio: item.direccionConsultorio.municipio,
      det_clasificacion: item.detalles_servicio.clasificacion,
      det_material: item.detalles_servicio.material,
      det_pieza: item.detalles_servicio.pieza,
      det_precio: item.detalles_servicio.precio,
      det_descripcionAdicional: item.detalles_servicio.descripcionAdicional,
      det_sistemaColor: item.detalles_servicio.sistemaColor,
      det_distencion: item.detalles_servicio.distencion,
      det_tonoInferior: item.detalles_servicio.tonoInferior,
      det_tonoMedio: item.detalles_servicio.tonoMedio,
      det_tonoSuperior: item.detalles_servicio.tonoSuperior,
      det_imagenEsquema: item.detalles_servicio?.imagenEsquema,
      det_imagenDiente: item.detalles_servicio.imagenDiente,
      det_diente: det_diente,
    };
  });
}

function formatModelOrdenesyServiciosSep(data) {
  return data?.map((item, index) => {
    const det_dienteArray = item.detalles_servicio.diente;
    let det_diente = "";

    if (Array.isArray(det_dienteArray)) {
      det_diente = det_dienteArray
        ?.map((d) => d.match(/\d+/)) // Extraer números de cada string
        .filter(Boolean) // Eliminar cualquier valor nulo resultante de no haber coincidencia
        .join(", "); // Unir los números con comas
    }

    return {
      id: item._id,
      folio: item.folio,
      fecha: item.fecha,
      localidad: item.localidad,
      nombreDoctor: item.nombreDoctor,
      nombrePaciente: item.nombrePaciente,
      edadPaciente: item.edadPaciente,
      precioTotal: item.precioTotal,
      tipoDentadura: item.tipoDentadura,
      cargoOrden: item.cargoOrden,
      otros: item.otros,
      recibio: item.recibio,
      fechaEntrada: item.fechaEntrada,
      servicios: item.servicios,
      direccionConsultorio: item.direccionConsultorio,
      serviciosOS: item.detalles_servicio,
      det_diente: det_diente, // Añadir det_diente al objeto retornado
      consecutivo: index + 1, // Añadir el campo consecutivo
    };
  });
}

function formatModelAA(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      totalCantidadRecibida: data.totalCantidadRecibida,
    });
  });
  return dataTemp;
}

function formatModelSaldos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    console.log("data.totalPrecio", data.totalCantidadCargo);
    let totalOrdenesCarDesc =
      parseFloat(data.totalPrecio) +
      parseFloat(data.totalCantidadCargo) -
      parseFloat(data.totalCantidadDescuento);
    let saldo = 0;
    let pagado = 0;
    if (data.saldo) {
      saldo = parseFloat(data.saldo);
      pagado = saldo + parseFloat(data.totalCantidadRecibida);
    } else {
      pagado = parseFloat(data.totalCantidadRecibida);
    }
    dataTemp.push({
      id: data._id,
      totalOrdenes: data.count,
      totalOrdenesycargos: totalOrdenesCarDesc,
      pagado: pagado,
      pendiente: totalOrdenesCarDesc - pagado,
      vencido: data.totalSaldoVencido,
    });
  });
  return dataTemp;
}

function formatModelOrdenes2(data) {
  return data?.map(data => ({
    id: data._id,
    fecha: data.fecha,
    descripcion: data.descripcion,
    estado: data.estado,
  }));
}

export default ViewPDFImp;
