import { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import queryString from "query-string";
import { toast } from "react-toastify";
import { eliminarUnidadMedida } from "../../api/unidadesMedida";
import { LogsInformativos } from "../logs/logs";

export default function EliminarUnidadesMedida({ data, history, setShow }) {

  const idDocumental = data[0];

  const dataTemp = {
    folio: data[1],
    nombre: data[2],
    conversion: data[3],
  };

  const [formData, setFormData] = useState(initialFormValue(dataTemp));
  const [signInLoading, setSignInLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!formData.nombre) {
      toast.warning("Completa todos los campos del formulario.");
    } else {
      setSignInLoading(true);
      try {
        eliminarUnidadMedida(data[0])
          .then((response) => {
            const { data } = response;
            console.log(data);

            toast.success(data.mensaje);

            history({
              search: queryString.stringify(""),
            });
            LogsInformativos("Se ha eliminado la unidad de medida " + dataTemp)
            setSignInLoading(false);
            setShow(false);
            //cancelarRegistro()
          })
          .catch((ex) => {
            if (ex.message === "Network Error") {
              toast.error("Conexión al servidor no disponible");
              setSignInLoading(false);
            } else {
              if (ex.response && ex.response.status === 401) {
                const { mensaje } = ex.response.data;
                toast.error(mensaje);
                setSignInLoading(false);
              }
            }
          });
      } catch (ex) {
        toast.error("Error al iniciar sesión");
        setSignInLoading(false);
      }
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div style={{ padding: "2%" }}>
      <Form onChange={onChange} onSubmit={onSubmit}>
        <div>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Folio:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                value={formData.folio}
                disabled
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Nombre:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                value={formData.nombre}
                disabled
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Conversion:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                value={formData.conversion}
                disabled
              />
            </Col>
          </Row>
        </div>
        <br />
        <label></label>
        <div className="divSubmit">
          <input className="submit" value="Enviar" type="submit" />
        </div>
      </Form>
    </div>
  );
}

function initialFormValue(data) {
  return {
    folio: data.folio,
    nombre: data.nombre,
    conversion: data.conversion
  };
}
