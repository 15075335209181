import { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import queryString from "query-string";
import { toast } from "react-toastify";
import {
    registraOrdenesServicio,
    obtenerNumeroOrdenServicio,
    listarOrdenesServicio,
    generarFolioOrdenesServicio
} from "../../../api/ordenesServicio";
import { LogsInformativos } from "../../logs/logs";
import { listarDoctores } from "../../../api/doctores";
import Select from 'react-select';

export default function RegistrarSaldosCliente({ history, setShow }) {
    const [formData, setFormData] = useState(initialFormValue());
    const [signInLoading, setSignInLoading] = useState(false);

    const [listarDoctor, setListDoctor] = useState([]);
    const [listarDoctor2, setListDoctor2] = useState([]);

    const obtenerDoctor = () => {
        try {
            listarDoctores()
                .then((response) => {
                    const { data } = response;

                    if (!listarDoctor && data) {
                        funcArrProductos(formatModelDoctor(data));
                        setListDoctor(formatModelDoctor(data))
                    } else {
                        const datosDoctor = formatModelDoctor(data);
                        funcArrProductos(datosDoctor);
                        setListDoctor(datosDoctor);
                    }
                })
                .catch((e) => { });
        } catch (e) { }
    };

    useEffect(() => {
        obtenerDoctor();
    }, []);

    const funcArrProductos = (almacen) => {
        const newArray = almacen.map(({ nombre, apellidoMaterno, apellidoPaterno, email, telefonoCelular, municipio, colonia, codigoPostal, rfc }) => ({
            value: nombre + " " + apellidoPaterno + " " + apellidoMaterno + "/" + email + "/" + telefonoCelular + "/" + municipio + "/" + colonia + "/" + codigoPostal + "/" + rfc,
            label: nombre + " " + apellidoPaterno + " " + apellidoMaterno
        }));
        setListDoctor2(newArray);
    };

    const [consecutivo, setConsecutivo] = useState("");
  
    const obtenerFolio = () => {
      try {
        obtenerNumeroOrdenServicio()
          .then((response) => {
            const { data } = response;
            // console.log(data)
            const { noOrden } = data;
            setConsecutivo(noOrden);
          })
          .catch((e) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    };
  
    useEffect(() => {
      obtenerFolio();
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();

        setSignInLoading(true);
        try {
            const temp = selectedOption.split("/");
            const dataTemp = {
                consecutivo: consecutivo,
                nombreDoctor:temp[0],
                cargoOrden: temp[1],
                precioTotal: formData.saldo,
                estado: "0",
                correoDoctor: temp[1],
                rfcDoctor: temp[6],
                telefonoDoctor: temp[2],
                estadoPago: "0",
                direccionConsultorio: {
                    municipio: temp[3],
                    CP: temp[5],
                    localidad: temp[4],
                },
            };
            registraOrdenesServicio(dataTemp)
                .then((response) => {
                    const { data } = response;
                    console.log(data);

                    toast.success(data.mensaje);

                    history({
                        search: queryString.stringify(""),
                    });
                    LogsInformativos(
                        "Se ha registrado la orden de servicio " + dataTemp.nombre,
                        dataTemp
                    );
                    setSignInLoading(false);
                    setShow(false);
                    //cancelarRegistro()
                })
                .catch((ex) => {
                    if (ex.message === "Network Error") {
                        toast.error("Conexión al servidor no disponible");
                        setSignInLoading(false);
                    } else {
                        if (ex.response && ex.response.status === 401) {
                            const { mensaje } = ex.response.data;
                            toast.error(mensaje);
                            setSignInLoading(false);
                        }
                    }
                });
        } catch (ex) {
            toast.error("Error al iniciar sesión");
            setSignInLoading(false);
        }
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const [selectedOption, setSelectedOption] = useState("");

    const handleSeleccionChange = (option) => {
        setSelectedOption(option.value);
    };

    return (
        <div style={{ padding: "2%" }}>
            <Form onChange={onChange} onSubmit={onSubmit}>
                <div>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                        <Col
                            xs={12}
                            md={3}
                            lg={3}
                            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                        >
                            <Form.Label className="folioOS2">
                                Nombre del Doctor: <code>*</code>
                            </Form.Label>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                            <Select
                                name="nombreDoctor"
                                defaultValue={formData.nombreDoctor}
                                options={listarDoctor2}
                                isSearchable={true}
                                placeholder="Selecciona una opción"
                                onChange={(selectedOption) => handleSeleccionChange(selectedOption)}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-2 mb-md-4 mb-lg-7">
                        <Col
                            sm={12}
                            md={4}
                            lg={4}
                            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                        >
                            <Form.Label>
                                Saldo: <code>*</code>
                            </Form.Label>
                        </Col>
                        <Col sm={12} md={8} lg={8}>
                            <Form.Control
                                type="text"
                                placeholder="Saldo del cliente"
                                name="saldo"
                                defaultValue={formData.saldo}
                            />
                        </Col>
                    </Row>
                </div>
                <div className="divSubmit">
                    <input className="submit" value="Enviar" type="submit" />
                </div>
            </Form>
        </div>
    );
}

function initialFormValue() {
    return {
        nombre: "",
        umPrimaria: "",
        umAdquisicion: "",
    };
}

function formatModelDoctor(data) {
    const dataTemp = [];
    data.forEach((data) => {
        dataTemp.push({
            id: data._id,
            nombre: data.nombre,
            apellidoPaterno: data.apellidoPaterno,
            apellidoMaterno: data.apellidoMaterno,
            fechaNacimiento: data.fechaNacimiento,
            telefonoCelular: data.telefonoCelular,
            email: data.email,
            correoConsultorio: data.correoConsultorio,
            rfc: data.rfc,
            observaciones: data.observaciones,
            estado: data.estado,
            estadoEstablecimiento: data.estadoDom,
            municipio: data.municipio,
            colonia: data.colonia,
            calle: data.calle,
            numeroExterior: data.nExterior,
            numeroInterior: data.nInterior,
            codigoPostal: data.cPostal,
            direcciones: data.direcciones || []
        });
    });
    return dataTemp;
}