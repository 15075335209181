import { useState, useEffect } from "react";
import { Html5QrcodeScanner } from "html5-qrcode";
import { registraVisitasMensajeros } from "../../api/visitasMensajeros";
import {
  getTokenApi,
  obtenidusuarioLogueado
} from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import queryString from "query-string";
import { LogsInformativos } from "../logs/logs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const QRScannerModal = ({ setShow, history }) => {
  const [scanner, setScanner] = useState(null);
  const [folioEscaneado, setFolioEscaneado] = useState(null);
  const [showScanner, setShowScanner] = useState(false);
  const [loading, setLoading] = useState(true);
  const [idUsuario, setIdeUsuario] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const obtenerDatosUsuario = async () => {
    try {
      const response = await obtenerUsuario(
        obtenidusuarioLogueado(getTokenApi())
      );
      const { data } = response;
      setIdeUsuario(data._id);
    } catch (e) {
      if (e.message === "Network Error") {
        toast.error("Conexión al servidor no disponible");
      } else {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  useEffect(() => {
    if (showScanner && !scanner) {
      const scannerContainer = document.getElementById("reader");

      if (scannerContainer) {
        // Configuración para usar la cámara trasera
        const newScanner = new Html5QrcodeScanner("reader", {
          fps: 10,
          qrbox: { width: 250, height: 250 },
          videoConstraints: {
            facingMode: "environment", // Esto fuerza el uso de la cámara trasera
          },
        });

        newScanner.render(
          (decodedText) => {
            const dataTemp = {
              idMensajero: idUsuario,
              idDoctor: decodedText,
              estado: "true",
            };

            registraVisitasMensajeros(dataTemp).then((response) => {
              const { data } = response;
              LogsInformativos("Se ha la visita del mensajero " + idUsuario, dataTemp);
              toast.success(data.mensaje);

              history({
                search: queryString.stringify(""),
              });

              setLoading(false);
              setShow(false);
            });

            console.log("QR Escaneado:", decodedText);
            setFolioEscaneado(decodedText);
            setShowScanner(false);
            newScanner.clear();
          },
          (errorMessage) => {
            console.error("Error al escanear:", errorMessage);
          }
        );

        setScanner(newScanner);
      }
    }

    return () => {
      if (scanner) {
        scanner.clear();
      }
    };
  }, [showScanner, scanner]);

  return (
    <div className="flex flex-col items-center p-4 bg-gray-900 text-white rounded-lg shadow-md">
      <h2 className="text-lg font-semibold mb-2">Escanear QR del Doctor</h2>

      {!showScanner && (
        <button
          onClick={() => setShowScanner(true)}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Iniciar Escáner
        </button>
      )}

      {showScanner && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h2 className="text-black text-lg font-semibold">Escaneando...</h2>
            <div id="reader"></div>
          </div>
        </div>
      )}

      {folioEscaneado && (
        <div className="mt-4 p-3 bg-gray-800 rounded-md">
          <h2 className="text-lg font-semibold">Folio Escaneado:</h2>
          <p className="text-green-400 font-mono">{folioEscaneado}</p>
        </div>
      )}
    </div>
  );
};

export default QRScannerModal;
