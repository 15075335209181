import { Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { Load } from "../load/load";
import { actualizaAlmacenMateriasPrimas } from "../../api/almacenMateriasPrimas";
import { listarCategorias } from "../../api/categorias";
import { listarUbicaciones } from "../../api/ubicaciones";
import { listarUnidadMedida } from "../../api/unidadesMedida";
import queryString from "query-string";
import { map } from "lodash";
import Dropzone from "../dropzone/Dropzone";
import { subeArchivosCloudinary } from "../../api/cloudinary";
import { listarProveedores } from "../../api/proveedores";
import { LogsInformativos } from "../logs/logs";

export default function EditarAlmacenMP({ data, history, setShow }) {
  const dataTemp = {
    clave: data[1],
    claveAlterna: data[2],
    producto: data[3],
    unidadMedida: data[16],
    ubicacion: data[11],
    departamento: data[12],
    categoria: data[13],
    precio: data[17]
  };
  const [formData, setFormData] = useState(initialFormValue(dataTemp));
  const almacen = data;

  //Para almacenar la imagen del producto que se guardara a la bd
  const [imagenProducto, setImagenProducto] = useState(null);

  //load
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarUnidadesMedida, setListUnidadesMedida] = useState([]);

  const obtenerUnidadesMedida = () => {
    try {
      listarUnidadMedida()
        .then((response) => {
          const { data } = response;

          if (!listarUnidadesMedida && data) {
            setListUbicacion(formatModelUnidadesMedida(data));
          } else {
            const datosUnidadesMedida = formatModelUnidadesMedida(data);
            setListUnidadesMedida(datosUnidadesMedida);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerUnidadesMedida();
  }, []);

  const [listarUbicacion, setListUbicacion] = useState([]);

  const obtenerUbicacion = () => {
    try {
      listarUbicaciones()
        .then((response) => {
          const { data } = response;

          if (!listarUbicacion && data) {
            setListUbicacion(formatModelUbicacion(data));
          } else {
            const datosUbicacion = formatModelUbicacion(data);
            setListUbicacion(datosUbicacion);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerUbicacion();
  }, []);

  const [listarDepartamento, setListDepartamento] = useState([]);

  const obtenerDepartamento = () => {
    try {
      listarProveedores()
        .then((response) => {
          const { data } = response;

          if (!listarDepartamento && data) {
            setListDepartamento(formatModelDepartamento(data));
          } else {
            const datosDepartamento = formatModelDepartamento(data);
            setListDepartamento(datosDepartamento);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerDepartamento();
  }, []);

  const [listarCategoria, setListCategoria] = useState([]);

  const obtenerCategoria = () => {
    try {
      listarCategorias()
        .then((response) => {
          const { data } = response;

          if (!listarCategoria && data) {
            setListCategoria(formatModelCategoria(data));
          } else {
            const datosCategoria = formatModelCategoria(data);
            setListCategoria(datosCategoria);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerCategoria();
  }, []);

  //insert
  const onSubmit = (e) => {
    e.preventDefault();

    if (!formData.producto) {
      toast.warning("Completa el formulario");
    } else {
      try {
        setLoading(true);
        const temp = formData.unidadMedida.split("/");

        const dataTemp = {
          clave: formData.clave,
          claveAlterna: formData.claveAlterna,
          producto: formData.producto,
          minimo: formData.minimo,
          maximo: formData.maximo,
          existencia: Number(formData.existencia),
          unidadMedida: temp[0],
          entrada: "0",
          salida: "0",
          devolucion: "0",
          existenciaReal: "0",
          ubicacion: formData.ubicacion,
          departamento: formData.departamento,
          categoria: formData.categoria,
          precio: formData.precio 
        };
        actualizaAlmacenMateriasPrimas(almacen[0], dataTemp).then((response) => {
          const { data } = response;
          //notificacion
          LogsInformativos("Se ha registrado el movimiento del producto " + dataTemp.producto + " en el almacen de materias primas", dataTemp)
          toast.success(data.mensaje);

          history({
            search: queryString.stringify(""),
          });
          setLoading(false);
          setShow(false);
          //cancelarRegistro()
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading && <Load />}
      <div className="contact-form">
        <Form onSubmit={onSubmit} onChange={onChange}>
          <div>
            <Row className="mb-2">
              <Col sm={12} md={3} lg={3} className="text-md-right text-lg-right align-self-end">
                <Form.Label className="nombreProdMP">Clave: <code>*</code></Form.Label>
              </Col>
              <Col sm={12} md={9} lg={9}>
                <Form.Control
                  placeholder="Clave"
                  type="text"
                  name="clave"
                  defaultValue={formData.clave}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm={12} md={3} lg={3} className="text-md-right text-lg-right align-self-end">
                <Form.Label className="nombreProdMP">Clave alterna: <code>*</code></Form.Label>
              </Col>
              <Col sm={12} md={9} lg={9}>
                <Form.Control
                  placeholder="Clave alterna"
                  type="text"
                  name="claveAlterna"
                  defaultValue={formData.claveAlterna}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm={12} md={3} lg={3} className="text-md-right text-lg-right align-self-end">
                <Form.Label className="nombreProdMP">
                  Nombre del producto:
                </Form.Label>
              </Col>
              <Col sm={12} md={9} lg={9}>
                <Form.Control
                  placeholder="Producto"
                  type="text"
                  name="producto"
                  defaultValue={formData.producto}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm={12} md={3} lg={3} className="text-md-right text-lg-right align-self-end">
                <Form.Label className="nombreProdMP">Precio: <code>*</code></Form.Label>
              </Col>
              <Col sm={12} md={9} lg={9}>
                <Form.Control
                  placeholder="Precio"
                  type="number"
                  name="precio"
                  defaultValue={formData.precio}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm={12} md={3} lg={3} className="text-md-right text-lg-right align-self-end">
                <Form.Label className="nombreProdMP">Unidad de medida: <code>*</code></Form.Label>
              </Col>
              <Col sm={12} md={9} lg={9}>
                <Form.Control
                  placeholder="Unidad medida"
                  as="select"
                  name="unidadMedida"
                  defaultValue={formData.unidadMedida}
                >
                  <option>Elige una opción</option>
                  {map(listarUnidadesMedida, (cat, index) => (
                    <option key={index} value={cat?.nombre + "/" + cat?.conversion}>
                      {cat?.nombre}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm={12} md={3} lg={3} className="text-md-right text-lg-right align-self-end">
                <Form.Label className="nombreProdMP">Ubicacion: <code>*</code></Form.Label>
              </Col>
              <Col sm={12} md={9} lg={9}>
                <Form.Control
                  placeholder="Ubicacion"
                  as="select"
                  name="ubicacion"
                  defaultValue={formData.ubicacion}
                >
                  <option>Elige una opción</option>
                  <option value="no aplica">No aplica</option>
                  {map(listarUbicacion, (cat, index) => (
                    <option key={index} value={cat?.nombre}>
                      {cat?.nombre}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm={12} md={3} lg={3} className="text-md-right text-lg-right align-self-end">
                <Form.Label className="nombreProdMP">
                  Proveedor: <code>*</code>
                </Form.Label>
              </Col>
              <Col sm={12} md={9} lg={9}>
                <Form.Control
                  placeholder="Departamento"
                  as="select"
                  name="departamento"
                  defaultValue={formData.departamento}
                >
                  <option>Elige una opción</option>
                  <option value="no aplica">No aplica</option>
                  {map(listarDepartamento, (cat, index) => (
                    <option key={index} value={cat?.nombre}>
                      {cat?.folio}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm={12} md={3} lg={3} className="text-md-right text-lg-right align-self-end">
                <Form.Label className="nombreProdMP">Categoria:</Form.Label>
              </Col>
              <Col sm={12} md={9} lg={9}>
                <Form.Control
                  placeholder="Categoria"
                  as="select"
                  name="categoria"
                  defaultValue={formData.categoria}
                >
                  <option>Elige una opción</option>
                  <option value="no aplica">No aplica</option>
                  {map(listarCategoria, (cat, index) => (
                    <option key={index} value={cat?.nombre}>
                      {cat?.nombre}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Row>
          </div>
          <div className="divSubmit">
            <input className="submit" value="Enviar" type="submit" />
          </div>
        </Form>
      </div>
    </>
  );
}

function initialFormValue(data) {
  return {
    clave: data.clave,
    claveAlterna: data.claveAlterna,
    producto: data.producto,
    ubicacion: data.ubicacion,
    departamento: data.departamento,
    categoria: data.categoria,
    unidadMedida: data.unidadMedida,
    precio: data.precio
  };
}

function formatModelUbicacion(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelCategoria(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelDepartamento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
    });
  });
  return dataTemp;
}

function formatModelUnidadesMedida(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
      conversion: data.conversion,
      estado: data.estado,
    });
  });
  return dataTemp;
}
