import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faTruck, faBars, faMoneyCheckDollar, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { listarEntregas, listarEntregasCorreoMensajero } from "../../api/entregas";
import { Button, Card, Dropdown, Badge } from "react-bootstrap";
import BasicModal from "../Modal/BasicModal/BasicModal";
import ModificarEntregas from "./ModificarEntregas";
import EliminarEntregas from "./EliminarEntregas";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import { toast } from "react-toastify";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { Load } from "../load/load";
import { withRouter } from "../../utils/withRouter";
import PagosOrdenesM from "../ordenServicio/pagosOrdenesM/PagosOrdenesM";
import QRScannerModal from "./scanner_qr";

function TarjetasEntregas(props) {
  const { history, location } = props;

  dayjs.locale("es");
  dayjs.extend(localizedFormat);

  const [loading, setLoading] = useState(true);
  const [tipoUsuario, setTipoUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");
  const [listarViaje, setListViaje] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  useEffect(() => {
    obtenerViajes();
  }, [correoUsuario, location]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const obtenerViajes = () => {
    try {
      if (tipoUsuario === "administrador") {
        listarEntregas()
          .then((response) => {
            const { data } = response;
            setListViaje(formatModelViaje(data));
          })
          .catch((e) => { });
      } else if (tipoUsuario === "mensajero") {
        listarEntregasCorreoMensajero(correoUsuario)
          .then((response) => {
            const { data } = response;
            setListViaje(formatModelViaje(data));
          })
          .catch((e) => { });
      }
    } catch (e) { }
  };

  const formatModelViaje = (data) => {
    return data.map((item) => ({
      id: item._id,
      folio: item.folio,
      mensajero: item.mensajero,
      fechaEntrega: item.fechayHoraEntrega,
      ubicacion: item.ubicacion,
      ordenTrabajo: item.ordenTrabajo,
      motivoEntrega: item.motivoEntrega,
      comentarios: item.comentarios,
      tipo: item.tipo || "No disponible",
      estado: item.estado,
    }));
  };

  const modificarViajes = (content) => {
    setTitulosModal(tipoUsuario !== "mensajero" ? "Modificar entrega" : "Realizar entrega");
    setContentModal(content);
    setShowModal(true);
  };

  const eliminarViajes = (content) => {
    setTitulosModal("Eliminar entrega");
    setContentModal(content);
    setShowModal(true);
  };

  const recibirPago = (content) => {
    setTitulosModal("Pago de orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const escanearQR = (content) => {
    setTitulosModal("Escanear QR");
    setContentModal(content);
    setShowModal(true);
  };

  const renderAcciones = (rowData) => {
    function convertirObjeto(obj) {
      return [
        obj.id,
        obj.folio,
        obj.mensajero,
        obj.ordenTrabajo,
        obj.fechaEntrega,
        obj.ubicacion,
        obj.motivoEntrega,
        obj.comentarios,
        obj.estado,
        obj.tipo,
        obj.Acciones
      ];
    }

    rowData = convertirObjeto(rowData);

    return rowData[8] == "0" ? (
      <>
        <Dropdown>
          <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
            <FontAwesomeIcon icon={faBars} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {tipoUsuario == "administrador" && (
              <>
                <Dropdown.Item
                  onClick={() =>
                    modificarViajes(
                      <ModificarEntregas
                        history={history}
                        setShow={setShowModal}
                        data={rowData}
                      />
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faPen}
                    style={{ color: "#ffc107" }}
                  />
                  &nbsp; Modificar
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    eliminarViajes(
                      <EliminarEntregas
                        history={history}
                        setShow={setShowModal}
                        data={rowData}
                      />
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{ color: "#dc3545" }}
                  />
                  &nbsp; Eliminar
                </Dropdown.Item>
              </>
            )}
            {tipoUsuario == "mensajero" && (
              <>
                <Dropdown.Item
                  onClick={() =>
                    modificarViajes(
                      <ModificarEntregas
                        history={history}
                        setShow={setShowModal}
                        data={rowData}
                      />
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faTruck}
                    style={{ color: "#ffc107" }}
                  />
                  &nbsp; Entregar
                </Dropdown.Item>
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </>
    ) : (
      <>
        {tipoUsuario == "mensajero" ? (
          <Dropdown>
            <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
              <FontAwesomeIcon icon={faBars} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() =>
                  recibirPago(
                    <PagosOrdenesM
                      history={history}
                      setShow={setShowModal}
                      data={rowData}
                      origen={"tablaEntregas"}
                    />
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faMoneyCheckDollar}
                  style={{ color: "#28a745" }}
                />
                &nbsp;Recibir pago
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          "No disponibles"
        )}
      </>
    );
  };

  const renderTarjetas = () => {
    return listarViaje.map((viaje) => (
      <Card key={viaje.id} className="mb-3">
        <Card.Body>
          <Card.Title>Folio: {viaje.folio}</Card.Title><br />
          <Card.Text>
            Mensajero: {viaje.mensajero}<br />
            Orden de Trabajo: {viaje.ordenTrabajo}<br />
            Fecha de Entrega: {dayjs(viaje.fechaEntrega).format('LLL')}<br />
            Ubicación: {viaje.ubicacion}<br />
            Motivo: {viaje.motivoEntrega}<br />
            Comentarios: {viaje.comentarios}<br />
            Estado: <Badge bg={viaje.estado === "0" ? "warning" : "success"}>
              {viaje.estado === "0" ? "Listo para entrega" : "Entregado"}
            </Badge><br />
            Tipo: {viaje.tipo}
          </Card.Text>
          <Card.Footer>
            {renderAcciones(viaje)}
          </Card.Footer>
        </Card.Body>
      </Card>
    ));
  };

  return (
    <>
      {loading ? <Load /> : (
        <>
          <h1>Entregas</h1>
          <Button
            className="btnAddTables"
            onClick={() =>
              escanearQR(
                <QRScannerModal history={history} setShow={setShowModal} />
              )
            }
          >
            <FontAwesomeIcon icon={faQrcode} /> Escanear QR
          </Button>
          <div>{renderTarjetas()}</div>
        </>
      )}
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

export default withRouter(TarjetasEntregas);
