import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faEye,
  faFileCsv,
  faPlus,
  faArrowRightArrowLeft,
  faBars,
  faArrowLeft,
  faDeleteLeft,
  faTrashAlt,
  faDownload,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import {
  listarPaginandoAlmacenMateriasPrimas,
  totalMateriasPrimas,
} from "../../api/almacenMateriasPrimas";
import EliminarAlmacenMP from "./eliminar";
import { withRouter } from "../../utils/withRouter";
import { useNavigate } from "react-router-dom";
import { Button, Dropdown, Image, Badge } from "react-bootstrap";
import AlmacenMP from "./insertar";
import CargarAlmacenMPCSV from "./cargaCSV";
import InsertarMovimientosAMP from "./insertarMovimiento";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Load } from "../load/load";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TblMovimientosAPL from "../movimientosAlmacenMP/tabla";
import VaciarTabla from "./vaciarTabla";
import { exportCSVFile } from "../../utils/exportCSV";
import EditarAlmacenMP from "./editar";
import { toast } from "react-toastify";

function TblAlmacenMP(props) {
  const { location, history } = props;

  const [listarPrecio2, setListPrecio2] = useState([{
    clave: "CLAVE DEL PRODUCTO",
    claveAlterna: "CLAVE ALTERNA DEL PRODUCTO",
    producto: "NOMBRE DEL PRODUCTO",
    minimo: "CANTIDAD MINIMA DE STOCK PERMITIDO",
    maximo: "CANTIDAD MAXIMA DE STOCK PERMITIDO",
    existencia: "CANTIDAD DE EXISTENCIA DEL PRODUCTO",
    unidadMedida: "UNIDAD DE MEDIDA DEL PRODUCTO",
    entrada: "CANTIDAD DE PRODUCTO QUE HA ENTRADO",
    salida: "CANTIDAD DE PRODUCTO QUE HA SALIDO",
    devolucion: "CANTIDAD DE PRODUCTO QUE HA SIDO DEVUELTO",
    existenciaReal: "CANTIDAD DE EXISTENCIA REAL QUE HAY DEL PRODUCTO",
    ubicacion: "UBICACION DEL PRODUCTO",
    departamento: "DEPARTAMENTO A LA QUE PERTENECE EL PRODUCTO",
    categoria: "CATEGORIA A LA QUE PERTENECE EL PRODUCTO",
    precio: "PRECIO DEL PRODUCTO"
  }]);

  const ejemploCSV = () => {
    try {
      toast.info("Estamos empaquetando tu respaldo, espere por favor ....")
      const timer = setTimeout(() => {
        exportCSVFile(listarPrecio2, "CSV_EJEMPLO");
      }, 5600);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log(e)
    }
  }

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarAlmacen, setListAlmacen] = useState([]);

  // Para definir el enrutamiento
  const enrutamiento = useNavigate();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [noTotalMateriasPrimas, setNoTotalMateriasPrimas] = useState(1);

  const obtenerAlmacen = () => {
    try {
      totalMateriasPrimas()
        .then((response) => {
          const { data } = response;
          setNoTotalMateriasPrimas(data);
        })
        .catch((e) => {
          console.log(e);
        });

      listarPaginandoAlmacenMateriasPrimas(page, rowsPerPage)
        .then((response) => {
          const { data } = response;
          if (!listarAlmacen && data) {
            setListAlmacen(formatModelAlmacen(data));
          } else {
            const datosAlmacen = formatModelAlmacen(data);
            setListAlmacen(datosAlmacen);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangePage = (page) => {
    // console.log("Nueva pagina "+ newPage)
    setPage(page);
  };

  const handleChangeRowsPerPage = (newPerPage) => {
    // console.log("Registros por pagina "+ parseInt(event.target.value, 10))
    setRowsPerPage(newPerPage);
    //setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    obtenerAlmacen();
  }, [location, page, rowsPerPage]);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarAlmacen);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroAlmacenMP = (content) => {
    setTitulosModal("Registrar articulo en el almacen de materias primas");
    setContentModal(content);
    setShowModal(true);
  };

  const registroMovimiento = (content) => {
    setTitulosModal("Registrar movimiento");
    setContentModal(content);
    setShowModal(true);
  };

  const cargarCsvAlmacenMP = (content) => {
    setTitulosModal("Registrar articulo en el almacen de materias primas");
    setContentModal(content);
    setShowModal(true);
  };

  const eliminarAlmacenMP = (content) => {
    setTitulosModal("Eliminar articulo en el almacen de materias primas");
    setContentModal(content);
    setShowModal(true);
  };

  const editarAlmacenMP = (content) => {
    setTitulosModal("Editar articulo en el almacen de materias primas");
    setContentModal(content);
    setShowModal(true);
  };

  const vaciarTabla = (content) => {
    setTitulosModal("Vaciar tabla");
    setContentModal(content);
    setShowModal(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "clave",
      label: "CLAVE",
    },
    {
      name: "claveAlterna",
      label: "CLAVE ALTERNA",
    },
    {
      name: "producto",
      label: "PRODUCTO",
    },
    {
      name: "minimo",
      label: "MINIMO",
    },
    {
      name: "maximo",
      label: "MAXIMO",
    },
    {
      name: "existencia",
      label: "EXISTENCIA",
    },
    {
      name: "entrada",
      label: "ENTRADA",
    },
    {
      name: "salida",
      label: "SALIDA",
    },
    {
      name: "devolucion",
      label: "DEVOLUCION",
    },
    {
      name: "existenciaReal",
      label: "EXISTENCIA REAL",
    },
    {
      name: "ubicacion",
      label: "UBICACION",
    },
    {
      name: "departamento",
      label: "PROVEEDOR",
    },
    {
      name: "categoria",
      label: "CATEGORIA",
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "true") {
            estiloTexto = "Activo";
            estadoTexto = "Activo";
          } else {
            estiloTexto = "Inhabilitado";
            estadoTexto = "Inhabilitado";
          }

          return (
            <>
              <Badge
                bg={estado == "true" ? "success" : "danger"}
              //className="estado"
              >
                {estiloTexto}
              </Badge>
            </>
          );
        },
      },
    },
    {
      name: "imagen",
      label: "IMAGEN",
      options: {
        customBodyRender: (value) => {
          const imagen = value;

          return imagen != "Sin Imagen" ? (
            <Image
              src={imagen}
              alt={`Imagen de ${imagen}`}
              style={{ maxWidth: "100px" }}
            />
          ) : (
            <p>No hay imagen del articulo</p>
          );
        },
      },
    },
    {
      name: "unidadMedida",
      label: "UNIDAD DE MEDIDA",
    },
    {
      name: "precio",
      label: "PRECIO UNITARIO",
      options: {
        customBodyRender: (value) => {

          return (
            <>
              ${''}
              {
                new Intl.NumberFormat('es-MX', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(value)
              } MXN
            </>
          );
        },
      },
    },
    {
      name: "total",
      label: "TOTAL",
      options: {
        customBodyRender: (value) => {

          return (
            <>
              ${''}
              {
                new Intl.NumberFormat('es-MX', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(value)
              } MXN
            </>
          );
        },
      },
    },
    {
      name: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Dropdown>
                <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                  <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleClick(tableMeta.rowData[0])}>
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ color: "#dc3545" }}
                    />
                    &nbsp; Movimientos
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      eliminarAlmacenMP(
                        <EliminarAlmacenMP
                          history={history}
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{ color: "#dc3545" }}
                    />
                    &nbsp; Eliminar
                  </Dropdown.Item>
                   <Dropdown.Item
                    onClick={() =>
                      editarAlmacenMP(
                        <EditarAlmacenMP
                          history={history}
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faPen}
                      style={{ color: "#ffc107" }}
                    />
                    &nbsp; Editar
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          );
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    pagination: true,
    serverSide: true,
    onChangeRowsPerPage: handleChangeRowsPerPage,
    onChangePage: handleChangePage,
    count: noTotalMateriasPrimas,
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  const [mostrarPrimerDiv, setMostrarPrimerDiv] = useState(true);
  const [idColumna, setIdColumna] = useState("")

  const handleClick = (valorColumna1) => {
    setMostrarPrimerDiv(!mostrarPrimerDiv);
    console.log(valorColumna1)
    setIdColumna(valorColumna1)
  };

  return (
    <>
      {loading && <Load />}
      {mostrarPrimerDiv ? (
        <div>
          <h1 style={{ marginTop: "2vh" }}>
            Articulos en el almacen de materias primas
          </h1>
          <div style={{ marginTop: "3vh" }}>
            <div className="divButton">
              <Button
                variant="success"
                style={{ marginRight: "1vw" }}
                onClick={() =>
                  cargarCsvAlmacenMP(
                    <CargarAlmacenMPCSV
                      history={history}
                      setShow={setShowModal}
                    />
                  )
                }
              >
                <FontAwesomeIcon icon={faFileCsv} /> Cargar CSV
              </Button>
              <Button
                style={{ marginRight: "1vw" }}
                variant="primary"
                onClick={() => {
                  ejemploCSV()
                }}
              >
                <FontAwesomeIcon icon={faDownload} /> Ejemplo datos CSV
              </Button>
              <Button
                variant="warning"
                style={{ marginRight: "1vw" }}
                onClick={() =>
                  registroMovimiento(
                    <InsertarMovimientosAMP
                      history={history}
                      setShow={setShowModal}
                    />
                  )
                }
              >
                <FontAwesomeIcon icon={faArrowRightArrowLeft} /> Registrar
                movimientos
              </Button>
              <Button
                variant="danger"
                style={{ marginRight: "1vw" }}
                onClick={() =>
                  vaciarTabla(
                    <VaciarTabla
                      datos={listarAlmacen}
                      history={history}
                      setShow={setShowModal}
                    />
                  )
                }
              >
                <FontAwesomeIcon icon={faTrashAlt} /> Vaciar Tabla
              </Button>
              <Button
                className="btnAddTables"
                onClick={() =>
                  registroAlmacenMP(
                    <AlmacenMP history={history} setShow={setShowModal} />
                  )
                }
              >
                <FontAwesomeIcon icon={faPlus} /> Alta de materias primas
              </Button>
            </div>
          </div>
          <div className="divTabla">
            <MUIDataTable
              title={"Lista articulos en almacen de materias primas"}
              data={listarAlmacen}
              columns={columns}
              options={options}
              pagination
            />
          </div>
          <BasicModal
            show={showModal}
            setShow={setShowModal}
            title={titulosModal}
          >
            {contentModal}
          </BasicModal>
        </div>
      ) : (
        <div id="divParaMostrarOcultar">
          <div>
            <div
              style={{
                padding: "1%",
              }}
            >
              <Button
                variant="primary"
                style={{ color: "#fff", textDecoration: "none" }}
                onClick={handleClick}
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Regresar
              </Button>
            </div>
          </div>
          <TblMovimientosAPL props={idColumna} />
        </div>
      )}
    </>
  );
}

function formatModelAlmacen(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      clave: data.clave,
      claveAlterna: data.claveAlterna,
      producto: data.producto,
      minimo: data.minimo,
      maximo: data.maximo,
      existencia: data.existencia,
      unidadMedida: data.unidadMedida,
      entrada: data.entrada,
      salida: data.salida,
      devolucion: data.devolucion,
      existenciaReal:
        Number(data.existencia) +
        Number(data.entrada) +
        Number(data.devolucion) -
        Number(data.salida),
      ubicacion: data.ubicacion,
      departamento: data.departamento,
      categoria: data.categoria,
      estado: data.estado,
      imagen: !data.imagen ? "Sin Imagen" : data.imagen,
      precio: !data.precio ? "0" : data.precio,
      total: (Number(data.existencia) +
        Number(data.entrada) +
        Number(data.devolucion) -
        Number(data.salida)) * (!data.precio ? 0 : Number(data.precio))
    });
  });
  return dataTemp;
}

export default withRouter(TblAlmacenMP);
