// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
    .pdf-viewer {
      width: 100vw;
      height: 100vh;
    }
  }
  
`, "",{"version":3,"sources":["webpack://./src/components/Modal/BasicModal/BasicModal.css"],"names":[],"mappings":"AAAA;IACI;MACE,YAAY;MACZ,aAAa;IACf;EACF","sourcesContent":["@media (max-width: 768px) {\n    .pdf-viewer {\n      width: 100vw;\n      height: 100vh;\n    }\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
