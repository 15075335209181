import React, { useEffect, useState } from "react";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import RegistroES from "./RegistroES";
import ActualizarEstadoES from "./ActualizarEstadoES";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faBars, faMoneyBill, faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { Load } from "../../load/load";
import { Badge, Button, Dropdown, Table, Row, Col, Form } from "react-bootstrap";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { listarCajaChica } from "../../../api/cajaChica";
import MUIDataTable from "mui-datatables";
import { toast } from "react-toastify";
import { exportCSVFile } from "../../../utils/exportCSV";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

const TablaMovsCaja = () => {
  const [loading, setLoading] = useState(true);

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [fechaInicio, setFechaInicio] = useState(getCurrentDate());
  const [fechaFin, setFechaFin] = useState(getCurrentDate());

  const handleInputChangeInicio = (e) => {
    setFechaInicio(e.target.value);
  };

  const handleInputChangeFin = (e) => {
    setFechaFin(e.target.value);
  };

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroES = (content) => {
    setTitulosModal("Registrar entrada o salida de efectivo");
    setContentModal(content);
    setShowModal(true);
  };

  const actualizarEstadoES = (content) => {
    setTitulosModal("Actualizar el estado de la salida de efectivo");
    setContentModal(content);
    setShowModal(true);
  };

  /****************************************PARA TABLA******************************************* */
  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },

    {
      name: "fechayHora",
      label: "FECHA Y HORA",
      options: {
        customBodyRender: (value, tableMeta) => {

          return (
            <>
              {dayjs(value.split(" ")[0]).format("LL")}
            </>
          );
        },
      },
    },
    {
      name: "tipo",
      label: "TIPO DE OPERACIÓN",
    },
    {
      name: "concepto",
      label: "CONCEPTO",
    },
    {
      name: "cantidad",
      label: "CANTIDAD",
      options: {
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: "comprobante",
      label: "COMPROBANTE",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const isValidURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value);

          if (isValidURL) {
            return (
              <a href={value} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon
                  icon={faEye}
                  style={{ color: "#000080" }}
                />
              </a>
            );
          } else {
            return "No hay comprobante para visualizar";
          }
        },
      },
    },
    {
      name: "idUsuario",
      label: "REALIZÓ OPERACION",
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "1") {
            estiloTexto = "Activa";
            estadoTexto = "Activa";
          } else if (estado == "2") {
            estiloTexto = "Pendiente";
            estadoTexto = "Pendiente";
          } else {
            estiloTexto = "Cancelado";
            estadoTexto = "Cancelado";
          }

          return (
            <>
              <Badge
                bg={estado == "1" ? "success" : estado == "2" ? "warning" : "danger"}
              //className="estado"
              >
                {estiloTexto}
              </Badge>
            </>
          );
        },
      },
    },
    {
      name: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[7] == "2" ? (
            <>
              <Dropdown>
                <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                  <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      actualizarEstadoES(
                        <ActualizarEstadoES
                          setShow={setShowModal}
                          balance={balance}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faMoneyBill}
                      style={{ color: "#28a745" }}
                    />
                    &nbsp; Aprobar gasto
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            "No disponibles"
          );
        },
      },
    }, ,
    ,
  ];

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue;
  };

  /*********************************************** */
  const [listRegistros, setListRegistros] = useState([]);

  const obtenerReq = async () => {
    try {
      const response = await listarCajaChica();
      const { data } = response;
      console.log(data);

      if (data) {
        const formattedData = formatModelESCaja(data);

        const start = new Date(fechaInicio);
        const end = new Date(fechaFin);

        const filteredData = formattedData.filter(item => {
          const itemDate = new Date(item.fechayHora.split(" ")[0]);
          return itemDate >= start && itemDate <= end;
        });

        setListRegistros(filteredData);
      }
    } catch (e) {
      console.error('Error fetching data', e);
    }
  };

  useEffect(() => {
    if (!showModal) {
      obtenerReq();
    }
  }, [showModal, fechaInicio, fechaFin]);

  /*********************************************** */
  const [sumEntradas, setSumEntradas] = useState(0);
  const [sumSalidas, setSumSalidas] = useState(0);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    console.log(listRegistros);
    let sumaSalidas = 0;
    let sumaEntradas = 0;

    listRegistros.forEach((registro) => {
      if (registro.tipo === "Salida" && registro.estado == "1") {
        sumaSalidas += parseFloat(registro.cantidad);
      } else if (registro.tipo === "Entrada" && registro.estado == "1") {
        sumaEntradas += parseFloat(registro.cantidad);
      }
      setSumEntradas(sumaEntradas);
      setSumSalidas(sumaSalidas);
      setBalance(sumaEntradas - sumaSalidas);
    });
  }, [listRegistros]);

  const generacionCSVCaja = () => {
    try {
      toast.info("Estamos empaquetando tu respaldo, espere por favor ....")
      const timer = setTimeout(() => {
        exportCSVFile(listRegistros, "LISTA_MOVIMIENTOS_CAJA_CHICA");
      }, 5600);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log(e)
    }
  }

  /****************************************FIN TABLA******************************************* */

  const cellStyles = {
    backgroundColor: "#9c182d",
    color: "#fff",
    fontWeight: "bold"
  };

  const cellStyles2 = {
    backgroundColor: "#f3f3f3",
    fontWeight: "bold"
  };


  return (
    <div>
      {loading && <Load />}
      <h1>Entradas y salidas de efectivo</h1>
      <Form.Group>
        <Row xs={12} md={8} lg={8} style={{ textAlign: "center" }}>
          <Col>
            <div>
              <Form.Label className="lblTelefono">
                Fecha Inicio:
              </Form.Label>
              <Form.Control
                placeholder="cantidad del movimiento"
                className="inpTelefono"
                type="date"
                name="fechaInicio"
                value={fechaInicio}
                onChange={handleInputChangeInicio}
              />
            </div>
          </Col>
          <Col>
            <div>
              <Form.Label className="lblEmail">Fecha Fin:</Form.Label>
              <Form.Control
                placeholder="cantidad del movimiento"
                className="inpEmail"
                type="date"
                name="fechaFin"
                value={fechaFin}
                onChange={handleInputChangeFin}
              />
            </div>
          </Col>
        </Row>
      </Form.Group>
      <div className="divButton">
        <Button
          variant="warning"
          onClick={() => {
            generacionCSVCaja()
          }}
        >
          <FontAwesomeIcon icon={faDownload} /> Descargar CSV
        </Button>
        <Button
          className="btnAddTables"
          onClick={() => registroES(<RegistroES setShow={setShowModal} balance={balance} />)}
        >
          <FontAwesomeIcon icon={faPlus} /> Agregar
        </Button>
      </div>
      <div className="divTabla">
        <MUIDataTable
          title={"Lista de entradas y salidas de efectivo"}
          data={listRegistros}
          columns={columns}
          options={options}
        />
        <Row className="mt-2 mt-md-2 mt-lg-2">
          <Col>
            <Table >
              <tbody>
                <tr>
                  <td colSpan="2" style={{ padding: '10px' }}>
                    <div style={{
                      display: 'flex', // Cambiado a flex para asegurar que todos los elementos estén en una línea
                      justifyContent: 'space-between', // Espacio uniforme entre los elementos
                      width: '100%',
                      flexWrap: 'wrap' // Permite que los elementos se ajusten si el espacio es insuficiente
                    }}>
                      <div style={{ flex: '1 1 16%', textAlign: 'center', padding: '5px' }}>
                        <div style={cellStyles}>Total Entradas:</div>
                        <div style={cellStyles2}>
                          {formatCurrency(sumEntradas || 0)}
                        </div>
                      </div>
                      <div style={{ flex: '1 1 16%', textAlign: 'center', padding: '5px' }}>
                        <div style={cellStyles}>Total Salidas:</div>
                        <div style={cellStyles2}>
                          {formatCurrency(sumSalidas || 0)}
                        </div>
                      </div>
                      <div style={{ flex: '1 1 16%', textAlign: 'center', padding: '5px' }}>
                        <div style={cellStyles}>Balance:</div>
                        <div style={cellStyles2}>
                          {formatCurrency(balance || 0)}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </div>
  );
};

function formatModelESCaja(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      idUsuario: data.idUsuario.split("/")[0],
      fechayHora: data.fechayHora,
      concepto: data.concepto,
      tipo: data.tipo,
      cantidad: data.cantidad,
      comprobante: data.comprobante,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default TablaMovsCaja;
