import { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import queryString from "query-string";
import { toast } from "react-toastify";
import { actualizarProveedores } from "../../api/proveedores";
import { LogsInformativos } from "../logs/logs";

export default function ModificarProveedores({ data, history, setShow }) {

  const idDocumental = data[0];

  const dataTemp = {
    folio: data[1],
    nombre: data[2],
    telefono: data[3],
    email: data[4],
    rfc: data[5],
    diasCredito: data[6],
    direccion: data[7],
    nombreContacto: data[8],
    tiempoEntrega: data[9],
  };

  const [formData, setFormData] = useState(initialFormValue(dataTemp));
  const [signInLoading, setSignInLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!formData.nombre || !formData.telefono || !formData.email || !formData.rfc || !formData.diasCredito || !formData.direccion || !formData.nombreContacto || !formData.tiempoEntrega) {
      toast.warning("Completa todos los campos del formulario.");
    } else {
      setSignInLoading(true);
      try {
        const dataTemp = {
          nombre: formData.nombre,
          telefono: formData.telefono,
          email: formData.email,
          rfc: formData.rfc,
          diasCredito: formData.diasCredito,
          direccion: formData.direccion,
          nombreContacto: formData.nombreContacto,
          tiempoEntrega: formData.tiempoEntrega,
        };
        actualizarProveedores(idDocumental, dataTemp)
          .then((response) => {
            const { data } = response;
            console.log(data);

            toast.success(data.mensaje);

            history({
              search: queryString.stringify(""),
            });
            LogsInformativos("Se ha modificado el proveedor " + dataTemp.nombre, dataTemp)
            setSignInLoading(false);
            setShow(false);
            //cancelarRegistro()
          })
          .catch((ex) => {
            if (ex.message === "Network Error") {
              toast.error("Conexión al servidor no disponible");
              setSignInLoading(false);
            } else {
              if (ex.response && ex.response.status === 401) {
                const { mensaje } = ex.response.data;
                toast.error(mensaje);
                setSignInLoading(false);
              }
            }
          });
      } catch (ex) {
        toast.error("Error al iniciar sesión");
        setSignInLoading(false);
      }
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div style={{ padding: "2%" }}>
      <Form onChange={onChange} onSubmit={onSubmit}>
        <div>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Folio:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                value={formData.folio}
                disabled
              />
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Proveedor:</Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                placeholder="Nombre del proveedor"
                defaultValue={formData.nombre}
                name="nombre"
              >
              </Form.Control>
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Telefono: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="number"
                placeholder="Telefono"
                defaultValue={formData.telefono}
                name="telefono"
              >
              </Form.Control>
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Email: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                placeholder="Email"
                defaultValue={formData.email}
                name="email"
              >
              </Form.Control>
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>RFC: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                placeholder="RFC"
                defaultValue={formData.rfc}
                name="rfc"
              >
              </Form.Control>
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Dias de credito: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                placeholder="Dias de credito"
                defaultValue={formData.diasCredito}
                name="diasCredito"
              >
              </Form.Control>
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Direccion: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                placeholder="Direccion"
                defaultValue={formData.direccion}
                name="direccion"
              >
              </Form.Control>
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Nombre del contacto: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                placeholder="Nombre del contacto"
                defaultValue={formData.nombreContacto}
                name="nombreContacto"
              >
              </Form.Control>
            </Col>
          </Row>
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>Tiempo aproximado de entrega: <code>*</code></Form.Label>
            </Col>
            <Col sm={12} md={8} lg={8}>
              <Form.Control
                type="text"
                placeholder="Tiempo aproximado de entrega"
                defaultValue={formData.tiempoEntrega}
                name="tiempoEntrega"
              >
              </Form.Control>
            </Col>
          </Row>
        </div>
        <br />
        <label></label>
        <div className="divSubmit">
          <input className="submit" value="Enviar" type="submit" />
        </div>
      </Form>
    </div>
  );
}

function initialFormValue(data) {
  return {
    folio: data.folio,
    nombre: data.nombre,
    telefono: data.telefono,
    email: data.email,
    rfc: data.rfc,
    diasCredito: data.diasCredito,
    direccion: data.direccion,
    nombreContacto: data.nombreContacto,
    tiempoEntrega: data.tiempoEntrega,
  };
}
