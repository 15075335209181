import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faArrowsLeftRight,
  faArrowLeft,
  faCancel,
  faBars,
  faShippingFast,
  faHandHolding,
  faMoneyCheckDollar,
  faFilePdf,
  faFileWord,
  faEye
} from "@fortawesome/free-solid-svg-icons";
import {
  listarOrdenesServicio,
  listarOrdenesServicioPorCorreoConsultorio,
  listarOrdenesServicioPorCorreoDoctor,
  listarOrdenesServicioPorCorreoMensajero,
} from "../../api/ordenesServicio";
import { listarServiciosFolio } from "../../api/servicios";
import { Button, Dropdown, Badge, Modal, Table } from "react-bootstrap";
import { withRouter } from "../../utils/withRouter";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Load } from "../load/load";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import { toast } from "react-toastify";
import "./style.css";
import ImageVisualizer from "./ImageVisualizer";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProgresoOrden from "./progreso/ProgresoOrden";
import RetrabajoOrden from "./RetrabajoOrden";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

function TblRetrabajosODS(props) {
  const { location, history } = props;

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const [loading, setLoading] = useState(true);

  const [tipoUsuario, setTipoUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarOrdenes, setListOrdenes] = useState([]);

  const obtenerOrdenesServicio = () => {
    let listarOrdenesServicioFunc;
  
    if (tipoUsuario === "administrador" || tipoUsuario === "mesa de control") {
      listarOrdenesServicioFunc = listarOrdenesServicio;
    } else if (tipoUsuario === "consultorio") {
      listarOrdenesServicioFunc = () =>
        listarOrdenesServicioPorCorreoConsultorio(correoUsuario);
    } else if (tipoUsuario === "doctor") {
      listarOrdenesServicioFunc = () =>
        listarOrdenesServicioPorCorreoDoctor(correoUsuario);
    } else if (tipoUsuario === "mensajero") {
      listarOrdenesServicioFunc = () =>
        listarOrdenesServicioPorCorreoMensajero(correoUsuario);
    }
  
    try {
      listarOrdenesServicioFunc()
        .then((response) => {
          const { data } = response;
          // Filtrar los datos por estado igual a '5'
          const datosFiltrados = data.filter((orden) => orden.estado === "5");
          setListOrdenes(formatModelOrdenes(datosFiltrados));
        })
        .catch((e) => {
          // Manejar errores aquí
        });
    } catch (e) {
      // Manejar errores aquí
    }
  };
  
  useEffect(() => {
    obtenerOrdenesServicio();
  }, [location, correoUsuario]);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarOrdenes);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);


  const progresoOrden = (content) => {
    setTitulosModal("Progreso");
    setContentModal(content);
    setShowModal(true);
  };

  const asignarRetrabajo = (content) => {
    setTitulosModal("Asignar retrabajo");
    setContentModal(content);
    setShowModal(true);
  };

  const [mostrarPrimerDiv, setMostrarPrimerDiv] = useState(true);
  const [idOrdenSel, setidOrdenSel] = useState("");

  const handleClick = (tableMeta) => {
    // Verifica si tableMeta es undefined antes de acceder a rowData
    if (tableMeta && tableMeta.rowData) {
      // Accede a la información de la fila actual
      const rowData = tableMeta.rowData;

      // Obtén el valor de la primera columna (supongamos que es la posición 0 del array)
      const valorPrimeraColumna = rowData[0];

      // Actualiza la variable con el valor de la primera columna
      setidOrdenSel(valorPrimeraColumna);

      // Puedes hacer cualquier otra lógica que necesites con el valor obtenido
      console.log("Valor de la primera columna:", valorPrimeraColumna);
    } else {
      console.error("tableMeta o tableMeta.rowData es undefined");
    }
    setMostrarPrimerDiv(!mostrarPrimerDiv);
  };

  console.log(tipoUsuario);

  const [listarServicios, setListServicios] = useState([]);

  const obtenerServicios = (folio) => {
    try {
      listarServiciosFolio(folio)
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (!listarServicios && data) {
            setListServicios(formatModelServicios(data));
          } else {
            const datosServicios = formatModelServicios(data);
            setListServicios(datosServicios);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  const [showModal2, setShowModal2] = useState(false);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "folio",
      label: "FOLIO",
    },
    {
      name: "fecha",
      label: "FECHA DE ALTA",
      options: {
        customBodyRender: (value, tableMeta) => {

          return (
            <>
              {dayjs(value).format("LL")}
            </>
          );
        },
      },
    },
    {
      name: "localidad",
      label: "CONSULTORIO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "nombreDoctor",
      label: "NOMBRE DEL DOCTOR",
    },
    {
      name: "nombrePaciente",
      label: "NOMBRE DEL PACIENTE",
    },
    {
      name: "tipoDentadura",
      label: "TIPO DE DENTADURA",
      options: {
        customBodyRender: (value) => {
          const dentadura = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (dentadura == "DP") {
            estiloTexto = "Permanente";
            estadoTexto = "Permanente";
          } else {
            estiloTexto = "Temporal";
            estadoTexto = "Temporal";
          }

          return <div className={estiloTexto}>{estadoTexto}</div>;
        },
      },
    },
    {
      name: "servicios",
      label: "TRABAJOS",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Button
            variant="link"
            onClick={() => {
              obtenerServicios(tableMeta.rowData[1]);
              setShowModal2(true);
            }}
          >
           <FontAwesomeIcon
              icon={faEye}
              style={{ color: "#000080" }}
            />
          </Button>
        ),
      },
    },
    {
      name: "recibio",
      label: "RECIBIO MATERIAL",
    },
    {
      name: "fechaEntrada",
      label: "FECHA DE RECEPCION",
      options: {
        customBodyRender: (value, tableMeta) => {

          return (
            <>
              {dayjs(value).format("LL")}
            </>
          );
        },
      },
    },
    {
      name: "correoDoctor",
      label: "EMAIL DOCTOR",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "correoConsultorio",
      label: "CORREO CONSULTORIO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "Imagenes",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button variant="link" onClick={() => handleClick(tableMeta)}>
              <FontAwesomeIcon
              icon={faEye}
              style={{ color: "#000080" }}
            />
              </Button>
            </>
          );
        },
      },
    },
    {
      name: "estado",
      label: "ESTADO DEL PEDIDO",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "1") {
            estiloTexto = "Orden nueva";
            estadoTexto = "Orden nueva";
          } else if (estado == "2") {
            estiloTexto = "Recoleccion asignada";
            estadoTexto = "Recoleccion asignada";
          } else if (estado == "3") {
            estiloTexto = "Orden entregada";
            estadoTexto = "Orden entregada";
          } else if (estado == "4") {
            estiloTexto = "Fabricando";
            estadoTexto = "Fabricando";
          } else if (estado == "5") {
            estiloTexto = "Orden finalizada";
            estadoTexto = "Orden finalizada";
          } else if (estado == "0") {
            estiloTexto = "Orden cancelada";
            estadoTexto = "Orden cancelada";
          } else if (estado == "6") {
            estiloTexto = "En proceso";
            estadoTexto = "En proceso";
          } else if (estado == "7") {
            estiloTexto = "En ruta de entrega";
            estadoTexto = "En ruta de entrega";
          } else if (estado == "8") {
            estiloTexto = "Entregado";
            estadoTexto = "Entregado";
          } else if (estado == "9") {
            estiloTexto = "Retrabajo";
            estadoTexto = "Retrabajo";
          }
          return (
            <>
              <Badge
                bg={
                  estado == "1"
                    ? "info"
                    : estado == "2"
                      ? "warning"
                      : estado == "3"
                        ? "primary"
                        : estado == "4"
                          ? "secondary"
                          : estado == "5"
                            ? "dark"
                            : estado == "6"
                              ? "primary"
                              : estado == "7"
                                ? "light"
                                : estado == "8"
                                  ? "success"
                                  : estado == "9" ?
                                    "secondary"
                                    : "danger"
                }
              //className="estado"
              >
                {estiloTexto}
              </Badge>
            </>
          );
        },
      },
    },
    {
      name: "progreso",
      label: "Progreso de orden",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Button
            variant="link"
            onClick={() =>
              progresoOrden(
                <ProgresoOrden
                  history={history}
                  setShow={setShowModal}
                  data={tableMeta.rowData[1]}
                />
              )
            }
          >
            <FontAwesomeIcon
              icon={faEye}
              style={{ color: "#000080" }}
            />
          </Button>
        ),
      },
    },
    {
      name: "telefonoDoctor",
      label: "TELEFONO DOCTOR",
    },
    {
      name: "cancelacion",
      label: "CANCELACION",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Dropdown>
                <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                  <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      asignarRetrabajo(
                        <RetrabajoOrden
                          history={history}
                          setShow={setShowModal}
                          orden={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faPen}
                      style={{ color: "#ffc107" }}
                    />
                    &nbsp; Retrabajo
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          );
        },
      },
    },
    {
      name: "cargo",
      label: "CARGO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && "standard",
    selectableRows: isSmOrDown ? "none" : "multiple",
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      <div>
        {mostrarPrimerDiv ? (
          <div>
            {loading && <Load />}
            <h1>Ordenes de cliente</h1>
            <div className="divTabla">
              <MUIDataTable
                title={"Lista de ordenes de cliente registradas"}
                data={listarOrdenes}
                columns={columns}
                options={options}
              />
            </div>
            <BasicModal
              show={showModal}
              setShow={setShowModal}
              title={titulosModal}
            >
              {contentModal}
            </BasicModal>
            <Modal
              show={showModal2}
              onHide={() => setShowModal2(false)}
              size="xl"
            >
              <Modal.Header closeButton>
                <Modal.Title>Información detallada</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Departamento</th>
                        <th>Pieza</th>
                        <th>Sistema de color</th>
                        <th>Tipo de dentadura</th>
                        <th>Diente</th>
                        <th>Tono Inferior</th>
                        <th>Tono Medio</th>
                        <th>Tono Superior</th>
                        <th>Descripción de protesis</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listarServicios.map((selected, index) => (
                        <tr key={index}>
                          <td>{selected.clasificacion}</td>
                          <td>{selected.pieza}</td>
                          <td>{selected.sistemaColor}</td>
                          <td>
                            {selected.distencion === "DP"
                              ? "Permanente"
                              : "Temporal"}
                          </td>
                          <td>{selected.diente}</td>
                          <td>{selected.tonoInferior}</td>
                          <td>{selected.tonoMedio}</td>
                          <td>{selected.tonoSuperior}</td>
                          <td>{selected.descripcionAdicional}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowModal2(false)}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        ) : (
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: "2px",
                left: "10px",
              }}
            >
              <Button
                variant="primary"
                style={{ color: "#fff", textDecoration: "none" }}
                onClick={handleClick}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </Button>
            </div>
            <h2 style={{ textAlign: "center", marginTop: "2%" }}>
              Imagenes de orden de cliente
            </h2>
            <ImageVisualizer idOrdenSel={idOrdenSel} />
          </div>
        )}
      </div>
    </>
  );
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      fecha: data.fecha,
      localidad: data.localidad,
      nombreDoctor: data.nombreDoctor,
      nombrePaciente: data.nombrePaciente,
      tipoDentadura: data.tipoDentadura,
      servicios: data.servicios,
      otros: data.otros,
      recibio: data.recibio,
      fechaEntrada: data.fechaEntrada,
      correoDoctor: data.correoDoctor,
      correoConsultorio: data.correoConsultorio,
      estado: data.estado,
      telefonoDoctor: data.telefonoDoctor,
      cancelacion: data.cancelacion,
      cargo: data.cargoOrden
    });
  });
  return dataTemp;
}

function formatModelServicios(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      clasificacion: data.clasificacion,
      material: data.material,
      pieza: data.pieza,
      precio: data.precio,
      descripcionAdicional: data.descripcionAdicional,
      sistemaColor: data.sistemaColor,
      distencion: data.distencion,
      diente: data.diente,
      tonoInferior: data.tonoInferior,
      tonoMedio: data.tonoMedio,
      tonoSuperior: data.tonoSuperior,
      departamento: data.departamento,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default withRouter(TblRetrabajosODS);
