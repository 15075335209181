import { useState } from 'react'
import { HeaderDash } from '../../components/administrador/Dashboard/Dashboard'
import { MenuDash } from '../../components/administrador/Dashboard/Menu'

export const Admin = () => {
  const [busqueda, setBusqueda] = useState();
  return (
    <>
      <div>
        <HeaderDash busqueda={busqueda} setBusqueda={setBusqueda} />
        <MenuDash busqueda={busqueda}/>
      </div>
    </>
  )
}
