import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
faBars,
faHandPointUp,
faEye
} from "@fortawesome/free-solid-svg-icons";
import { listarIncidencias, listarIncidenciasDepartamento } from "../../api/incidencias";
import { Button, Dropdown, Badge } from "react-bootstrap";
import { withRouter } from "../../utils/withRouter";
import CambiarEstadoIncidencias from "./CambiarEstadoRutas";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Load } from "../load/load";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import { toast } from "react-toastify";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

function TblIncidenciasRutas(props) {
  const { location, history } = props;
  
  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [departamentoUsuario, setDepartamentoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setDepartamentoUsuario(data.departamento);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [listarOrdenes, setListOrdenes] = useState([]);

  const obtenerOrdenesServicio = () => {
    try {
      if (!departamentoUsuario) {
        listarIncidencias()
          .then((response) => {
            const { data } = response;
            const datosOrdenes = formatModelOrdenes(data);
            setListOrdenes(datosOrdenes);
          })
          .catch((e) => { });
      } else if (departamentoUsuario) {
        listarIncidenciasDepartamento(departamentoUsuario)
          .then((response) => {
            const { data } = response;
            const datosOrdenes = formatModelOrdenes(data);
            setListOrdenes(datosOrdenes);
          })
          .catch((e) => { });
      }
    } catch (e) { }
  };

  useEffect(() => {
    obtenerOrdenesServicio();
  }, [location, departamentoUsuario]);

  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarOrdenes);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, [listarOrdenes]);

  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroUsuarios = (content) => {
    setTitulosModal("Registar incidencia");
    setContentModal(content);
    setShowModal(true);
  };

  const modificarUsuarios = (content) => {
    setTitulosModal("Modificar incidencia");
    setContentModal(content);
    setShowModal(true);
  };

  const eliminarUsuarios = (content) => {
    setTitulosModal("Eliminar incidencia");
    setContentModal(content);
    setShowModal(true);
  };

  const cambiarEstadoUsuarios = (content) => {
    setTitulosModal("Cambiar estado incidencia");
    setContentModal(content);
    setShowModal(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded",
      },
    },
    {
      name: "folio",
      label: "FOLIO",
    },
    {
      name: "fecha",
      label: "Fecha",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
            {dayjs(value).format("LL")}
            </>
          );
        },
      },
    },
    {
      name: "descripcion",
      label: "DESCRIPCION",
    },
    {
      name: "departamento",
      label: "DEPARTAMENTO",
    },
    {
      name: "comentarios",
      label: "COMENTARIOS",
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "1") {
            estiloTexto = "pendiente";
            estadoTexto = "Pendiente";
          } else if (estado == "2") {
            estiloTexto = "atendido";
            estadoTexto = "Atendido";
          } else {
            estiloTexto = "cancelado";
            estadoTexto = "Cancelado";
          }

          return <>
            <Badge
              bg={estado == "1" ? "warning" : estado == "2" ? "success" : "danger"}
            >
              {estiloTexto}
            </Badge>
          </>;
        },
      },
    },
    {
      name: "ruta",
      label: "RUTA",
    },
    {
      name: "vehiculo",
      label: "VEHICULO",
    },
    {
      name: "imagen",
      label: "IMAGEN",
      options: {
        customBodyRender: (value) => {
          if (value != "Sin Imagen") {
            return (
              <a href={value} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon
              icon={faEye}
              style={{ color: "#000080" }}
            />
              </a>
            );
          } else {
            return (
              <Badge bg="secondary">
                no disponible
              </Badge>
            );
          }
        },
      },
    },
    {
      name: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[6] == "1" ? (
            <>
              <Dropdown>
                <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                  <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {!departamentoUsuario && (
                    <>
                      <Dropdown.Item
                        onClick={() =>
                          cambiarEstadoUsuarios(
                            <CambiarEstadoIncidencias
                              history={history}
                              setShow={setShowModal}
                              data={tableMeta.rowData}
                            />
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faHandPointUp}
                          style={{ color: "#007bff" }}
                        />
                        &nbsp; Cambiar estado
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            "No disponibles"
          );
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && 'standard',
    selectableRows: isSmOrDown ? 'none' : 'multiple',
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      {loading && <Load />}
      <h1>Incidencias</h1>
      <div className="divTabla">
        <MUIDataTable
          title={"Listado de incidencias registradas"}
          data={listarOrdenes}
          columns={columns}
          options={options}
        />
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    if (data.ruta && data.vehiculo) {
      dataTemp.push({
        id: data._id,
        folio: data.folio,
        fecha: data.fecha,
        descripcion: data.descripcion,
        departamento: data.departamento,
        comentarios: data.comentarios,
        estado: data.estado,
        ruta: data.ruta,
        vehiculo: data.vehiculo,
        imagen: !data.imagen ? "Sin Imagen" : data.imagen,
      });
    }
  });
  return dataTemp;
}

export default withRouter(TblIncidenciasRutas);
