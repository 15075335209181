// Login
export const ENDPOINTLoginAdministrador = "/login";

// Usuarios
export const ENDPOINTListarUsuario = "/usuarios/listar";
export const ENDPOINTRegistrarUsuario = "/usuarios/registro";
export const ENDPOINTRegistrarUsuario2 = "/usuarios/registro2";
export const ENDPOINTObtenerUsuarios = "/usuarios/obtener";
export const ENDPOINTObtenerUsuariosEmail = "/usuarios/obtenerPorEmail";
export const ENDPOINTActualizarUsuario = "/usuarios/actualizar";
export const ENDPOINTEliminarUsuario = "/usuarios/eliminar";
export const ENDPOINTDeshabilitarUsuario = "/usuarios/deshabilitar";
export const ENDPOINTValidarPasswordUsuario = "/usuarios/validacionPassword";

// Admin
export const ENDPOINTListarAdmin = "/admin/listar";
export const ENDPOINTRegistrarAdmin = "/admin/registro";
export const ENDPOINTObtenerAdmin = "/admin/obtener";
export const ENDPOINTActualizarAdmin = "/admin/actualizar";
export const ENDPOINTEliminarAdmin = "/admin/eliminar";
export const ENDPOINTDeshabilitarAdmin = "/admin/deshabilitar";

// DepartamentosODT
export const ENDPOINTListarDepartamentosODT = "/departamentosODT/listar";
export const ENDPOINTRegistrarDepartamentosODT = "/departamentosODT/registro";
export const ENDPOINTObtenerDepartamentosODT = "/departamentosODT/obtener";
export const ENDPOINTActualizarDepartamentosODT = "/departamentosODT/actualizar";
export const ENDPOINTEliminarDepartamentosODT = "/departamentosODT/eliminar";
export const ENDPOINTDeshabilitarDepartamentosODT = "/departamentosODT/deshabilitar";

// Dias Festivos
export const ENDPOINTListarDiasFestivos = "/diasFestivos/listar";
export const ENDPOINTRegistrarDiasFestivos = "/diasFestivos/registro";
export const ENDPOINTObtenerDiasFestivos = "/diasFestivos/obtener";
export const ENDPOINTActualizarDiasFestivos = "/diasFestivos/actualizar";
export const ENDPOINTEliminarDiasFestivos = "/diasFestivos/eliminar";
export const ENDPOINTDeshabilitarDiasFestivos = "/diasFestivos/deshabilitar";

// Mensajeria
export const ENDPOINTListarMensajeria = "/mensajeria/listar";
export const ENDPOINTRegistrarMensajeria = "/mensajeria/registro";
export const ENDPOINTObtenerMensajeria = "/mensajeria/obtener";
export const ENDPOINTObtenerMensajeriaIdChat = "/mensajeria/obtenerIdChat";
export const ENDPOINTActualizarMensajeria = "/mensajeria/actualizar";
export const ENDPOINTEliminarMensajeria = "/mensajeria/eliminar";
export const ENDPOINTDeshabilitarMensajeria = "/mensajeria/deshabilitar";
export const ENDPOINTListarChatsMensajeria = "/mensajeria/listarChats";
export const ENDPOINTListarChatsIDMensajeria = "/mensajeria/listarChatsidChat";
export const ENDPOINTMarcarLeidos = "/mensajeria/marcarLeidos";

// Cargos
export const ENDPOINTListarCargos = "/cargos/listar";
export const ENDPOINTRegistrarCargos = "/cargos/registro";
export const ENDPOINTObtenerCargos = "/cargos/obtener";
export const ENDPOINTActualizarCargos = "/cargos/actualizar";
export const ENDPOINTEliminarCargos = "/cargos/eliminar";
export const ENDPOINTDeshabilitarCargos = "/cargos/deshabilitar";

// Vales
export const ENDPOINTListarVales = "/vales/listar";
export const ENDPOINTListarValesMaterialesRango = "/vales/listarMaterialesRango";
export const ENDPOINTListarValesInsumosRango = "/vales/listarInsumosRango";
export const ENDPOINTListarValesMateriales = "/vales/listarMateriales";
export const ENDPOINTListarValesInsumos = "/vales/listarInsumos";
export const ENDPOINTFiltrarPorFechaYArea = "/vales/filtrarPorFechaYArea";
export const ENDPOINTListarPaginacionVales = "/vales/listarPaginando";
export const ENDPOINTTotalVales = "/vales/total";
export const ENDPOINTRegistrarVales = "/vales/registro";
export const ENDPOINTObtenerVales = "/vales/obtener";
export const ENDPOINTActualizarVales = "/vales/actualizar";
export const ENDPOINTEliminarVales = "/vales/eliminar";
export const ENDPOINTDeshabilitarVales = "/vales/deshabilitar";
export const ENDPOINTIncidenciasVales = "/vales/incidencias";
export const ENDPOINTObtenerFolioVales = "/vales/obtenerNoRegistro";
export const ENDPOINTListarValesEstado = "/vales/listarEstado";
export const ENDPOINTListarValesDepartamento = "/vales/obtenerValesporDepartamento";
export const ENDPOINTListarValesDepartamentoInsumos = "/vales/obtenerValesporDepartamentoInsumos";
export const ENDPOINTListarValesDepartamentoMateriales = "/vales/obtenerValesporDepartamentoMateriales";

// Precios de la orden
export const ENDPOINTListarPreciosOrden = "/preciosOrden/listar";
export const ENDPOINTListarCorreoPreciosOrden = "/preciosOrden/listarPorCorreoCliente";
export const ENDPOINTRegistrarPreciosOrden = "/preciosOrden/registro";
export const ENDPOINTObtenerPreciosOrden = "/preciosOrden/obtener";
export const ENDPOINTActualizarPreciosOrden = "/preciosOrden/actualizar";
export const ENDPOINTEliminarPreciosOrden = "/preciosOrden/eliminar";
export const ENDPOINTDeshabilitarPreciosOrden = "/preciosOrden/deshabilitar";
export const ENDPOINTObtenerPreciosOrdenPorFolio = "/preciosOrden/obtenerDyCFolioOS";
export const ENDPOINTFiltrarFechasCargos = "/preciosOrden/filtrarDescYCargFecha";

// Gastos de los mensajeros
export const ENDPOINTListarMensajerosGastos = "/mensajerosGastos/listar";
export const ENDPOINTRegistrarMensajerosGastos = "/mensajerosGastos/registro";
export const ENDPOINTObtenerMensajerosGastos = "/mensajerosGastos/obtener";
export const ENDPOINTActualizarMensajerosGastos = "/mensajerosGastos/actualizar";
export const ENDPOINTEliminarMensajerosGastos = "/mensajerosGastos/eliminar";
export const ENDPOINTDeshabilitarMensajerosGastos = "/mensajerosGastos/deshabilitar";
export const ENDPOINTListarMensajerosGastosGral = "/mensajerosGastos/listarSaldosMensajerosGral";

// Saldos de los clientes
export const ENDPOINTListarSaldosCliente = "/saldosCliente/listar";
export const ENDPOINTRegistrarSaldosCliente = "/saldosCliente/registro";
export const ENDPOINTObtenerSaldosCliente = "/saldosCliente/obtener";
export const ENDPOINTActualizarSaldosCliente = "/saldosCliente/actualizar";
export const ENDPOINTEliminarSaldosCliente = "/saldosCliente/eliminar";
export const ENDPOINTDeshabilitarSaldosCliente = "/saldosCliente/deshabilitar";
export const ENDPOINTActualizarSaldosPorCorreo = "/saldosCliente/actualizarPorCorreo";
export const ENDPOINTListarSaldoPorCliente = "/saldosCliente/saldoPorCliente";

// Evaluaciones360
export const ENDPOINTListarEvaluaciones360 = "/evaluaciones360/listar";
export const ENDPOINTRegistrarEvaluaciones360 = "/evaluaciones360/registro";
export const ENDPOINTObtenerEvaluaciones360 = "/evaluaciones360/obtener";
export const ENDPOINTObtenerUltimoEvaluaciones360 = "/evaluaciones360/obtenerUltimo";
export const ENDPOINTActualizarEvaluaciones360 = "/evaluaciones360/actualizar";
export const ENDPOINTEliminarEvaluaciones360 = "/evaluaciones360/eliminar";
export const ENDPOINTDeshabilitarEvaluaciones360 = "/evaluaciones360/deshabilitar";

// Eventos
export const ENDPOINTListarEvento = "/eventos/listar";
export const ENDPOINTRegistrarEvento = "/eventos/registro";
export const ENDPOINTObtenerEvento = "/eventos/obtener";
export const ENDPOINTActualizarEvento = "/eventos/actualizar";
export const ENDPOINTEliminarEvento = "/eventos/eliminar";
export const ENDPOINTDeshabilitarEvento = "/eventos/deshabilitar";
export const ENDPOINTListarFolioEvento = "/eventos/obtenerEventosPorFolio";

// Cuentas por pagar
export const ENDPOINTListarCuentasPagar = "/cuentasPagar/listar";
export const ENDPOINTRegistrarCuentasPagar = "/cuentasPagar/registro";
export const ENDPOINTObtenerCuentasPagar = "/cuentasPagar/obtener";
export const ENDPOINTActualizarCuentasPagar = "/cuentasPagar/actualizar";
export const ENDPOINTEliminarCuentas = "/cuentasPagar/eliminar";
export const ENDPOINTDeshabilitarCuentasPagar = "/cuentasPagar/deshabilitar";

// Doctores
export const ENDPOINTListarDoctores = "/doctores/listar";
export const ENDPOINTListarDoctoresPorCorreo = "/doctores/listarPorCorreo";
export const ENDPOINTListarDoctoresPorCorreoDoctor = "/doctores/listarPorCorreoDoctor";
export const ENDPOINTRegistrarDoctores = "/doctores/registro";
export const ENDPOINTObtenerDoctores = "/doctores/obtener";
export const ENDPOINTObtenerDoctoresPorCorreo = "/doctores/obtenerPorCorreo";
export const ENDPOINTObtenerDoctoresPorRFC = "/doctores/obtenerPorRFC";
export const ENDPOINTActualizarDoctores = "/doctores/actualizar";
export const ENDPOINTEliminarDoctores = "/doctores/eliminar";
export const ENDPOINTDeshabilitarDoctores = "/doctores/deshabilitar";
export const ENDPOINTObtenerUltimoDoc = "/doctores/listarUltimoDoc";
export const ENDPOINTObtenerFolioDoctores = "/doctores/obtenerNoRegistro";
export const ENDPOINTTotalDoctores = "/doctores/total";

// Entregas
export const ENDPOINTListarEntregas = "/entregas/listar";
export const ENDPOINTListarEntregasCorreoMensajero = "/entregas/listarPorCorreoMensajero";
export const ENDPOINTRegistrarEntregas = "/entregas/registro";
export const ENDPOINTObtenerEntregas = "/entregas/obtener";
export const ENDPOINTActualizarEntregas = "/entregas/actualizar";
export const ENDPOINTEliminarEntregas = "/entregas/eliminar";
export const ENDPOINTDeshabilitarEntregas = "/entregas/deshabilitar";
export const ENDPOINTObtenerFolioEntregas = "/entregas/obtenerNoRegistro";

// Establecimientos
export const ENDPOINTListarEstablecimientos = "/establecimientos/listar";
export const ENDPOINTListarEstablecimientosPorCorreo = "/establecimientos/listarPorCorreo";
export const ENDPOINTRegistrarEstablecimientos = "/establecimientos/registro";
export const ENDPOINTObtenerEstablecimientos = "/establecimientos/obtener";
export const ENDPOINTObtenerEstablecimientosPorFolio = "/establecimientos/obtenerPorFolio";
export const ENDPOINTObtenerEstablecimientosPorCorreo = "/establecimientos/obtenerPorCorreo";
export const ENDPOINTActualizarEstablecimientos = "/establecimientos/actualizar";
export const ENDPOINTEliminarEstablecimientos = "/establecimientos/eliminar";
export const ENDPOINTDeshabilitarEstablecimientos = "/establecimientos/deshabilitar";
export const ENDPOINTObtenerUltimoEstablecimiento = "/establecimientos/listarUltimo";
export const ENDPOINTObtenerFolioEstablecimiento = "/establecimientos/obtenerNoRegistro";
export const ENDPOINTTotalEstablecimiento = "/establecimientos/total";

// Inventario
export const ENDPOINTListarInventario = "/inventario/listar";
export const ENDPOINTRegistrarInventario = "/inventario/registro";
export const ENDPOINTObtenerInventario = "/inventario/obtener";
export const ENDPOINTActualizarInventario = "/inventario/actualizar";
export const ENDPOINTEliminarInventario = "/inventario/eliminar";
export const ENDPOINTDeshabilitarInventario = "/inventario/deshabilitar";

// Mensajeros
export const ENDPOINTListarMensajeros = "/mensajeros/listar";
export const ENDPOINTRegistrarMensajeros = "/mensajeros/registro";
export const ENDPOINTObtenerMensajeros = "/mensajeros/obtener";
export const ENDPOINTActualizarMensajeros = "/mensajeros/actualizar";
export const ENDPOINTEliminarMensajeros = "/mensajeros/eliminar";
export const ENDPOINTDeshabilitarMensajeros = "/mensajeros/deshabilitar";
export const ENDPOINTEnviarEmailRecordatorioMensajero = "/mensajeros/enviaEmailRecordatorio";

// Ordenes de servicio
export const ENDPOINTListarOrdenesServicio = "/ordenesServicio/listar";
export const ENDPOINTGenerarFolioOrdenesServicio = "/ordenesServicio/generarFolio";
export const ENDPOINTListarOrdenesServicioPorMes = "/ordenesServicio/listarMesActual";
export const ENDPOINTListarOrdenesServicioRetrabajo = "/ordenesServicio/listarRetrabajos";
export const ENDPOINTListarPaginandoOrdenesServicio = "/ordenesServicio/listarPaginando";
export const ENDPOINTTotalOrdenesServicio = "/ordenesServicio/total";
export const ENDPOINTListarUltimoOrdenesServicio = "/ordenesServicio/listarUltimo";
export const ENDPOINTListarOrdenesServicioPorCorreoDoctor = "/ordenesServicio/listarPorCorreoDoctor";
export const ENDPOINTListarOrdenesServicioPorCorreoConsultorio = "/ordenesServicio/listarPorCorreoConsultorio";
export const ENDPOINTListarOrdenesServicioPorCorreoMensajero = "/ordenesServicio/listarPorCorreoMensajero";
export const ENDPOINTRegistrarOrdenesServicio = "/ordenesServicio/registro";
export const ENDPOINTObtenerOrdenesServicio = "/ordenesServicio/obtener";
export const ENDPOINTActualizarOrdenesServicio = "/ordenesServicio/actualizar";
export const ENDPOINTActualizarTotalOrdenesServicio = "/ordenesServicio/actualizarTotal";
export const ENDPOINTAsignarMensajerosOrdenesServicio = "/ordenesServicio/asignarMensajero";
export const ENDPOINTEliminarOrdenesServicio = "/ordenesServicio/eliminar";
export const ENDPOINTDeshabilitarOrdenesServicio = "/ordenesServicio/deshabilitar";
export const ENDPOINTProcesoCancelacionOrdenesServicio = "/ordenesServicio/procesoCancelacion";
export const ENDPOINTActualizarCambiosOrdenesServicio = "/ordenesServicio/actualizarEstadoCmbios";
export const ENDPOINTDeshabilitarOrdenesServicioFolio = "/ordenesServicio/deshabilitarPorFolio";
export const ENDPOINTObtenerFolioOrdenesServicio = "/ordenesServicio/obtenerNoRegistro";
export const ENDPOINTObtenerNoRegistrosPorFecha = "/ordenesServicio/fechasConFrecuencia";
export const ENDPOINTObtenerNoRegistrosPorDireccion = "/ordenesServicio/direccionConsultorioFrecuencia";
export const ENDPOINTRecibirMaterialOrdenesServicio = "/ordenesServicio/recibirMaterial";
export const ENDPOINTActualizaMaterialRecepcion = "/ordenesServicio/actualizaMaterialRecepcion";
export const ENDPOINTOrdenesySaldos = "/ordenesServicio/ordenesYSaldos";
export const ENDPOINTFiltrarSaldosPeriodo = "/ordenesServicio/filtrarSaldosPeriodo";
export const ENDPOINTFiltrarSaldosGeneral = "/ordenesServicio/filtrarSaldosGeneral";
export const ENDPOINTListaFechasyEstadoPago = "/ordenesServicio/listarFechasyEstadoPago";
export const ENDPOINTListaFechasyEstadoPagoCompleta = "/ordenesServicio/listarFechasyEstadoPagoCompleta";
export const ENDPOINTEnviarEmailRecordatorio = "/ordenesServicio/enviaEmailRecordatorio";
export const ENDPOINTObtenerOSPorFolio = "/ordenesServicio/obtenerporFolioOS";
export const ENDPOINTActulizarPagoOSPorFolio = "/ordenesServicio/actualizarPagadoPorFolio";
export const ENDPOINTListarOrdenesConServicios = "/ordenesServicio/listarConServicios";
export const ENDPOINTListarOrdenesConServiciosSep = "/ordenesServicio/listarConServiciosSep";
export const ENDPOINTDuplicarOrdenes = "/ordenesServicio/duplicarOrden";
export const ENDPOINTListarOrdenesPorCargoOrden = "/ordenesServicio/listarPorCargoOrden";
export const ENDPOINTListarSaldosNuevo = "/ordenesServicio/listarSaldosNuevo";
export const ENDPOINTFacturacionOrdenes = "/ordenesServicio/facturacion";

// Ordenes de trabajo
export const ENDPOINTListarOrdenesTrabajo = "/ordenesTrabajo/listar";
export const ENDPOINTListarOrdenesTrabajoPorCorreoDoctor = "/ordenesTrabajo/listarPorCorreoDoctor";
export const ENDPOINTListarOrdenesTrabajoPorCorreoConsultorio = "/ordenesTrabajo/listarPorCorreoConsultorio";
export const ENDPOINTRegistrarOrdenesTrabajo = "/ordenesTrabajo/registro";
export const ENDPOINTObtenerOrdenesTrabajo = "/ordenesTrabajo/obtener";
export const ENDPOINTActualizarOrdenesTrabajo = "/ordenesTrabajo/actualizar";
export const ENDPOINTEliminarOrdenesTrabajo = "/ordenesTrabajo/eliminar";
export const ENDPOINTDeshabilitarOrdenesTrabajo = "/ordenesTrabajo/deshabilitar";
export const ENDPOINTObtenerFolioOrdenesTrabajo = "/ordenesTrabajo/obtenerNoRegistro";
export const ENDPOINTActualizarPrioridadOrdenesTrabajo = "/ordenesTrabajo/actualizarPrioridad";
export const ENDPOINTCalcularHorasTranscurridasOrdenesTrabajo = "/ordenesTrabajo/calcularHorasTranscurridas";

// Pagos
export const ENDPOINTListarPagos = "/pagos/listar";
export const ENDPOINTRegistrarPagos = "/pagos/registro";
export const ENDPOINTObtenerPagos = "/pagos/obtener";
export const ENDPOINTActualizarPagos = "/pagos/actualizar";
export const ENDPOINTEliminarPagos = "/pagos/eliminar";
export const ENDPOINTDeshabilitarPagos = "/pagos/deshabilitar";
export const ENDPOINTListarPagosPorCliente = "/pagos/listarPagosPorCliente";
export const ENDPOINTListarPagosPorPeriodo = "/pagos/filtrarPagosPorPeriodo";

// Proveedores
export const ENDPOINTListarProveedores = "/proveedores/listar";
export const ENDPOINTRegistrarProveedores = "/proveedores/registro";
export const ENDPOINTObtenerProveedores = "/proveedores/obtener";
export const ENDPOINTActualizarProveedores = "/proveedores/actualizar";
export const ENDPOINTEliminarProveedores = "/proveedores/eliminar";
export const ENDPOINTDeshabilitarProveedores = "/proveedores/deshabilitar";
export const ENDPOINTObtenerFolioProveedores = "/proveedores/obtenerNoRegistro";

// Reportes
export const ENDPOINTListarReportes = "/reportes/listar";
export const ENDPOINTRegistrarReportes = "/reportes/registro";
export const ENDPOINTObtenerReportes = "/reportes/obtener";
export const ENDPOINTActualizarReportes = "/reportes/actualizar";
export const ENDPOINTEliminarReportes = "/reportes/eliminar";
export const ENDPOINTDeshabilitarReportes = "/reportes/deshabilitar";

// Rutas
export const ENDPOINTListarRutas = "/rutas/listar";
export const ENDPOINTListarGeneralRutas = "/rutas/listarr";
export const ENDPOINTRegistrarRutas = "/rutas/registro";
export const ENDPOINTObtenerRutas = "/rutas/obtener";
export const ENDPOINTObtenerOrdenesRutas = "/rutas/obtenerOrdenes";
export const ENDPOINTActualizarRutas = "/rutas/actualizar";
export const ENDPOINTEliminarRutas = "/rutas/eliminar";
export const ENDPOINTDeshabilitarRutas = "/rutas/deshabilitar";
export const ENDPOINTObtenerFolioRutas = "/rutas/obtenerFolio";
export const ENDPOINTListarRutasPorCorreo = "/rutas/listarPorCorreo";
export const ENDPOINTListarRutasPorIdOrden = "/rutas/listarPorIdOrdenes";

// Configuracion de Rutas
export const ENDPOINTListarConfiguracionRutas = "/configuracionRutas/listar";
export const ENDPOINTRegistrarConfiguracionRutas = "/configuracionRutas/registro";
export const ENDPOINTObtenerConfiguracionRutas = "/configuracionRutas/obtener";
export const ENDPOINTActualizarConfiguracionRutas = "/configuracionRutas/actualizar";
export const ENDPOINTEliminarConfiguracionRutas = "/configuracionRutas/eliminar";
export const ENDPOINTDeshabilitarConfiguracionRutas = "/configuracionRutas/deshabilitar";
export const ENDPOINTObtenerFolioConfiguracionRutas = "/configuracionRutas/obtenerFolio";
export const ENDPOINTListarPorDiaConfiguracionRutas = "/configuracionRutas/obtenerRutasDias";
export const ENDPOINTListarPorCorreoConfiguracionRutas = "/configuracionRutas/obtenerRutasCorreo";
export const ENDPOINTEnviarEmailRecordatorioConfiguracionRutas = "/configuracionRutas/enviaEmailRecordatorio";

// Servicios
export const ENDPOINTListarServicios = "/servicios/listar";
export const ENDPOINTListarServiciosRetrabajos = "/servicios/listarRetrabajos";
export const ENDPOINTListarServiciosConRuta = "/servicios/listarServiciosConRuta";
export const ENDPOINTListarServiciosODT = "/servicios/listarODT";
export const ENDPOINTRegistrarServicios = "/servicios/registro";
export const ENDPOINTObtenerServicios = "/servicios/obtener";
export const ENDPOINTObtenerServiciosPorFolio = "/servicios/obtenerPorFolio";
export const ENDPOINTActualizarServicios = "/servicios/actualizar";
export const ENDPOINTActualizarDepartamentoServicios = "/servicios/actualizarDepartamento";
export const ENDPOINTEliminarServicios = "/servicios/eliminar";
export const ENDPOINTEliminarServiciosFolio = "/servicios/eliminarPorFolio";
export const ENDPOINTDeshabilitarServicios = "/servicios/deshabilitar";
export const ENDPOINTActualizarProcesoServicios = "/servicios/actualizarProceso";
export const ENDPOINTListarServiciosFolio = "/servicios/listarFolio";
export const ENDPOINTListarServiciosCorreo = "/servicios/listarCorreo";
export const ENDPOINTListarServiciosDepartamento = "/servicios/listarDepartamento";
export const ENDPOINTListarServiciosFolioDepartamento = "/servicios/listarFolioDepartamento";
export const ENDPOINTObtenerFolioODTServicio = "/servicios/obtenerNoODT";
export const ENDPOINTObtenerHorasTranscurridas = "/servicios/calcularHorasTranscurridasS";
export const ENDPOINTActualizarEstadosyOrdenServicios = "/servicios/actualizarEstadosyOrden";
export const ENDPOINTGuardarImagenServicios = "/servicios/imagen";
export const ENDPOINTActualizarGarantiaServicios = "/servicios/garantia";

// Visitas
export const ENDPOINTListarVisitas = "/visitas/listar";
export const ENDPOINTRegistrarVisitas = "/visitas/registro";
export const ENDPOINTObtenerVisitas = "/visitas/obtener";
export const ENDPOINTActualizarVisitas = "/visitas/actualizar";
export const ENDPOINTEliminarVisitas = "/visitas/eliminar";
export const ENDPOINTDeshabilitarVisitas = "/visitas/deshabilitar";
export const ENDPOINTObtenerFolioVisitas = "/visitas/obtenerFolio";
export const ENDPOINTListarVisitasPorCorreo = "/visitas/listarPorCorreo";

// Almacen productos limpieza
export const ENDPOINTListarAlmacenProductosLimpieza = "/almacenProductosLimpieza/listar";
export const ENDPOINTListarAlmacenProductosLimpiezaProveedor = "/almacenProductosLimpieza/listarPorProveedor";
export const ENDPOINTListarPaginandoAlmacenProductosLimpieza = "/almacenProductosLimpieza/listarPaginando";
export const ENDPOINTTotalAlmacenProductosLimpieza = "/almacenProductosLimpieza/total";
export const ENDPOINTRegistrarAlmacenProductosLimpieza = "/almacenProductosLimpieza/registro";
export const ENDPOINTObtenerAlmacenProductosLimpieza = "/almacenProductosLimpieza/obtener";
export const ENDPOINTActualizarAlmacenProductosLimpieza = "/almacenProductosLimpieza/actualizar";
export const ENDPOINTEliminarAlmacenProductosLimpieza = "/almacenProductosLimpieza/eliminar";
export const ENDPOINTDeshabilitarAlmacenProductosLimpieza = "/almacenProductosLimpieza/deshabilitar";
export const ENDPOINTObtenerInsumosPorNombre = "/almacenProductosLimpieza/listarPorNombre";
export const ENDPOINTBuscarInsumosPorIdsAPL = "/almacenProductosLimpieza/buscarInsumosPorIds";

// Almacen medicamentos
export const ENDPOINTListarMedicamentos = "/medicamentos/listar";
export const ENDPOINTListarMedicamentosProveedor = "/medicamentos/listarPorProveedor";
export const ENDPOINTListarPaginandoMedicamentos = "/medicamentos/listarPaginando";
export const ENDPOINTTotalMedicamentos = "/medicamentos/total";
export const ENDPOINTRegistrarMedicamento = "/medicamentos/registro";
export const ENDPOINTObtenerMedicamento = "/medicamentos/obtener";
export const ENDPOINTActualizarMedicamento = "/medicamentos/actualizar";
export const ENDPOINTEliminarMedicamento = "/medicamentos/eliminar";
export const ENDPOINTDeshabilitarMedicamento = "/medicamentos/deshabilitar";
export const ENDPOINTObtenerMedicamentosPorNombre = "/medicamentos/listarPorNombre";
export const ENDPOINTBuscarMedicamentosPorIds = "/medicamentos/buscarPorIds";

// Almacen materias primas
export const ENDPOINTListarAlmacenMateriasPrimas = "/almacenMateriasPrimas/listar";
export const ENDPOINTListarAlmacenMateriasPrimasProveedor = "/almacenMateriasPrimas/listarPorProveedor";
export const ENDPOINTListarPaginandoAlmacenMateriasPrimas = "/almacenMateriasPrimas/listarPaginando";
export const ENDPOINTListarProductosAgotandose = "/almacenMateriasPrimas/listarProductosAgotandose";
export const ENDPOINTTotalAlmacenMateriasPrimas = "/almacenMateriasPrimas/total";
export const ENDPOINTRegistrarAlmacenMateriasPrimas = "/almacenMateriasPrimas/registro";
export const ENDPOINTObtenerAlmacenMateriasPrimas = "/almacenMateriasPrimas/obtener";
export const ENDPOINTActualizarAlmacenMateriasPrimas = "/almacenMateriasPrimas/actualizar";
export const ENDPOINTEliminarAlmacenMateriasPrimas = "/almacenMateriasPrimas/eliminar";
export const ENDPOINTDeshabilitarAlmacenMateriasPrimas = "/almacenMateriasPrimas/deshabilitar";
export const ENDPOINTObtenerFolioAlmacenMateriasPrimas = "/almacenMateriasPrimas/obtenerNoRegistro";
export const ENDPOINTObtenerProductosPorNombre = "/almacenMateriasPrimas/listarPorNombre";
export const ENDPOINTBuscarPorIdsAMP = "/almacenMateriasPrimas/buscarPorIds";

// Movimientos almacen productos limpieza
export const ENDPOINTListarMovimientosAPL = "/movimientosAlmacenPL/listar";
export const ENDPOINTListarMovimientosAPLFiltroFecha = "/movimientosAlmacenPL/listarFiltroFecha";
export const ENDPOINTListarMovimientosAPLFiltroGeneralFecha = "/movimientosAlmacenPL/listarFiltroGeneralFecha";
export const ENDPOINTRegistrarMovimientosAPL = "/movimientosAlmacenPL/registro";
export const ENDPOINTObtenerMovimientosAPL = "/movimientosAlmacenPL/obtener";
export const ENDPOINTActualizarMovimientosAPL = "/movimientosAlmacenPL/actualizar";
export const ENDPOINTEliminarMovimientosAPL = "/movimientosAlmacenPL/eliminar";
export const ENDPOINTDeshabilitarMovimientosAPL = "/movimientosAlmacenPL/deshabilitar";

// Movimientos medicamentos
export const ENDPOINTListarMovimientosMedicamentos = "/movimientosMedicamentos/listar";
export const ENDPOINTListarMovimientosMedicamentosFiltroFecha = "/movimientosMedicamentos/listarFiltroFecha";
export const ENDPOINTListarMovimientosMedicamentosFiltroGeneralFecha = "/movimientosMedicamentos/listarFiltroGeneralFecha";
export const ENDPOINTRegistrarMovimientosMedicamentos = "/movimientosMedicamentos/registro";
export const ENDPOINTObtenerMovimientosMedicamentos = "/movimientosMedicamentos/obtener";
export const ENDPOINTActualizarMovimientosMedicamentos = "/movimientosMedicamentos/actualizar";
export const ENDPOINTEliminarMovimientosMedicamentos = "/movimientosMedicamentos/eliminar";
export const ENDPOINTDeshabilitarMovimientosMedicamentos = "/movimientosMedicamentos/deshabilitar";

// Movimientos almacen productos limpieza
export const ENDPOINTListarMovimientosAMP = "/movimientosAlmacenMP/listar";
export const ENDPOINTListarMovimientosAMPFiltroFecha = "/movimientosAlmacenMP/listarFiltroFecha";
export const ENDPOINTListarMovimientosAMPFiltroGeneralFecha = "/movimientosAlmacenMP/listarFiltroGeneralFecha";
export const ENDPOINTRegistrarMovimientosAMP = "/movimientosAlmacenMP/registro";
export const ENDPOINTObtenerMovimientosAMP = "/movimientosAlmacenMP/obtener";
export const ENDPOINTActualizarMovimientosAMP = "/movimientosAlmacenMP/actualizar";
export const ENDPOINTEliminarMovimientosAMP = "/movimientosAlmacenMP/eliminar";
export const ENDPOINTDeshabilitarMovimientosAMP = "/movimientosAlmacenMP/deshabilitar";

// Facturacion
export const ENDPOINTListarFacturacion = "/facturacion/listar";
export const ENDPOINTRegistrarFacturacion = "/facturacion/registro";
export const ENDPOINTObtenerFacturacion = "/facturacion/obtener";
export const ENDPOINTActualizarFacturacion = "/facturacion/actualizar";
export const ENDPOINTEliminarFacturacion = "/facturacion/eliminar";
export const ENDPOINTDeshabilitarFacturacion = "/facturacion/deshabilitar";
export const ENDPOINTObtenerUltimoFact = "/facturacion/listarUltimoFact";

// Facturas mostrador
export const ENDPOINTListarFacturasMostrador = "/facturasMostrador/listar";
export const ENDPOINTRegistrarFacturasMostrador = "/facturasMostrador/registro";
export const ENDPOINTObtenerFacturasMostrador = "/facturasMostrador/obtener";
export const ENDPOINTActualizarFacturasMostrador = "/facturasMostrador/actualizar";
export const ENDPOINTEliminarFacturasMostrador = "/facturasMostrador/eliminar";
export const ENDPOINTDeshabilitarFacturasMostrador = "/facturasMostrador/deshabilitar";
export const ENDPOINTObtenerUltimoFacturasMostrador = "/facturasMostrador/listarUltimoFact";
export const ENDPOINTObtenerFolioFacturasMostrador = "/facturasMostrador/obtenerNoRegistro";
export const ENDPOINTActualizarEstadoFacturasMostrador = "/facturasMostrador/facturacion";

// Precios
export const ENDPOINTListarPrecios = "/precios/listar";
export const ENDPOINTRegistrarPrecios = "/precios/registro";
export const ENDPOINTObtenerPrecios = "/precios/obtener";
export const ENDPOINTActualizarPrecios = "/precios/actualizar";
export const ENDPOINTEliminarPrecios = "/precios/eliminar";
export const ENDPOINTDeshabilitarPrecios = "/precios/deshabilitar";
export const ENDPOINTObtenerFolioPrecios = "/precios/obtenerNoRegistro";
export const ENDPOINTObtenerPiezasDetalles = "/precios/listarConDetalles";
export const ENDPOINTObtenerPiezasPorMaterial = "/precios/obtenerPiezasPorMaterial";
export const ENDPOINTObtenerPiezasPorDepartamento = "/precios/listarConDetallesPorDepartamento";

// Servicios Dentales
export const ENDPOINTListarServiciosDentales = "/serviciosDentales/listar";
export const ENDPOINTRegistrarServiciosDentales = "/serviciosDentales/registro";
export const ENDPOINTObtenerServiciosDentales = "/serviciosDentales/obtener";
export const ENDPOINTActualizarServiciosDentales = "/serviciosDentales/actualizar";
export const ENDPOINTEliminarServiciosDentales = "/serviciosDentales/eliminar";
export const ENDPOINTDeshabilitarServiciosDentales = "/serviciosDentales/deshabilitar";

// Colorimetria
export const ENDPOINTListarColorimetria = "/colorimetria/listar";
export const ENDPOINTRegistrarColorimetria = "/colorimetria/registro";
export const ENDPOINTObtenerColorimetria = "/colorimetria/obtener";
export const ENDPOINTActualizarColorimetria = "/colorimetria/actualizar";
export const ENDPOINTEliminarColorimetria = "/colorimetria/eliminar";
export const ENDPOINTDeshabilitarColorimetria = "/colorimetria/deshabilitar";

// Requisicion de Compras
export const ENDPOINTListarRequisicionCompras = "/requisicionCompras/listar";
export const ENDPOINTRegistrarRequisicionCompras = "/requisicionCompras/registro";
export const ENDPOINTObtenerRequisicionCompras = "/requisicionCompras/obtener";
export const ENDPOINTActualizarRequisicionCompras = "/requisicionCompras/actualizar";
export const ENDPOINTEliminarRequisicionCompras = "/requisicionCompras/eliminar";
export const ENDPOINTDeshabilitarRequisicionCompras = "/requisicionCompras/deshabilitar";
export const ENDPOINTObtenerFolioRequisicionCompras = "/requisicionCompras/obtenerNoRegistro";

// Vehiculos
export const ENDPOINTListarVehiculos = "/vehiculos/listar";
export const ENDPOINTRegistrarVehiculos = "/vehiculos/registro";
export const ENDPOINTObtenerVehiculos = "/vehiculos/obtener";
export const ENDPOINTActualizarVehiculos = "/vehiculos/actualizar";
export const ENDPOINTEliminarVehiculos = "/vehiculos/eliminar";
export const ENDPOINTDeshabilitarVehiculos = "/vehiculos/deshabilitar";
export const ENDPOINTActualizarVehiculosKm = "/vehiculos/actualizarKilometraje";

// Departamentos
export const ENDPOINTListarDepartamentos = "/departamentos/listar";
export const ENDPOINTListarDepartamentosProduccion = "/departamentos/listarProduccion";
export const ENDPOINTRegistrarDepartamentos = "/departamentos/registro";
export const ENDPOINTObtenerDepartamentos = "/departamentos/obtener";
export const ENDPOINTActualizarDepartamentos = "/departamentos/actualizar";
export const ENDPOINTEliminarDepartamentos = "/departamentos/eliminar";
export const ENDPOINTDeshabilitarDepartamentos = "/departamentos/deshabilitar";
export const ENDPOINTObtenerFolioDepartamentos = "/departamentos/obtenerNoRegistro";
export const ENDPOINTObtenerNombreDepartamento = "/departamentos/obtenerDepartamentoxFolio";
export const ENDPOINTListarDepartamentosTipo = "/departamentos/listarDepartamentosTipo";

// Categorias
export const ENDPOINTListarCategorias = "/categorias/listar";
export const ENDPOINTRegistrarCategorias = "/categorias/registro";
export const ENDPOINTObtenerCategorias = "/categorias/obtener";
export const ENDPOINTActualizarCategorias = "/categorias/actualizar";
export const ENDPOINTEliminarCategorias = "/categorias/eliminar";
export const ENDPOINTDeshabilitarCategorias = "/categorias/deshabilitar";

// Ubicaciones
export const ENDPOINTListarUbicaciones = "/ubicaciones/listar";
export const ENDPOINTRegistrarUbicaciones = "/ubicaciones/registro";
export const ENDPOINTObtenerUbicaciones = "/ubicaciones/obtener";
export const ENDPOINTActualizarUbicaciones = "/ubicaciones/actualizar";
export const ENDPOINTEliminarUbicaciones = "/ubicaciones/eliminar";
export const ENDPOINTDeshabilitarUbicaciones = "/ubicaciones/deshabilitar";

// Coordenadas
export const ENDPOINTListarCoordenadas = "/coordenadas/listar";
export const ENDPOINTRegistrarCoordenadas = "/coordenadas/registro";
export const ENDPOINTObtenerCoordenadas = "/coordenadas/obtener";
export const ENDPOINTActualizarCoordenadas = "/coordenadas/actualizar";
export const ENDPOINTEliminarCoordenadas = "/coordenadas/eliminar";
export const ENDPOINTDeshabilitarCoordenadas = "/coordenadas/deshabilitar";

// Viajes
export const ENDPOINTListarViajes = "/viajes/listar";
export const ENDPOINTRegistrarViajes = "/viajes/registro";
export const ENDPOINTObtenerViajes = "/viajes/obtener";
export const ENDPOINTActualizarViajes = "/viajes/actualizar";
export const ENDPOINTEliminarViajes = "/viajes/eliminar";
export const ENDPOINTDeshabilitarViajes = "/viajes/deshabilitar";

// Gastos Mensajero
export const ENDPOINTListarGastosMensajero = "/gastosMensajeros/listar";
export const ENDPOINTConsultaGastosMensajero = "/gastosMensajeros/informacion-agregada";
export const ENDPOINTRegistrarGastosMensajero = "/gastosMensajeros/registro";
export const ENDPOINTObtenerGastosMensajero = "/gastosMensajeros/obtener";
export const ENDPOINTActualizarGastosMensajero = "/gastosMensajeros/actualizar";
export const ENDPOINTEliminarGastosMensajero = "/gastosMensajeros/eliminar";
export const ENDPOINTDeshabilitarGastosMensajero = "/gastosMensajeros/deshabilitar";
export const ENDPOINTListarGastosMensajeroGral = "/gastosMensajeros/listarGastosMensajerosGral";

//Recepciones
export const ENDPOINTListarRecepciones = "/recepciones/listar";
export const ENDPOINTRegistrarRecepciones = "/recepciones/registro";
export const ENDPOINTObtenerRecepciones = "/recepciones/obtener";
export const ENDPOINTActualizarRecepciones = "/recepciones/actualizar";
export const ENDPOINTEliminarRecepciones = "/recepciones/eliminar";
export const ENDPOINTDeshabilitarRecepciones = "/recepciones/deshabilitar";

// Unidades medida
export const ENDPOINTListarUnidadMedida = "/unidadMedida/listar";
export const ENDPOINTRegistrarUnidadMedida = "/unidadMedida/registro";
export const ENDPOINTObtenerUnidadMedida = "/unidadMedidan/obtener";
export const ENDPOINTActualizarUnidadMedida = "/unidadMedida/actualizar";
export const ENDPOINTEliminarUnidadMedida = "/unidadMedida/eliminar";
export const ENDPOINTDeshabilitarUnidadMedida = "/unidadMedida/deshabilitar";
export const ENDPOINTObtenerFolioUnidadMedida = "/unidadMedida/obtenerNoRegistro";

// Abonos de las ordenes
export const ENDPOINTListarAbonosOrdenes = "/abonosOrdenes/listar";
export const ENDPOINTListarFechaAbonosOrdenes = "/abonosOrdenes/listarFecha";
export const ENDPOINTRegistrarAbonosOrdenes = "/abonosOrdenes/registro";
export const ENDPOINTObtenerAbonosOrdenes = "/abonosOrdenes/obtener";
export const ENDPOINTActualizarAbonosOrdenes = "/abonosOrdenes/actualizar";
export const ENDPOINTEliminarAbonosOrdenes = "/abonosOrdenes/eliminar";
export const ENDPOINTDeshabilitarAbonosOrdenes = "/abonosOrdenes/deshabilitar";
export const ENDPOINTOObtenerAbonosPorFolioOS = "/abonosOrdenes/obtenerAbonosFolioOS";
export const ENDPOINTListarAbonosAprobados = "/abonosOrdenes/listarAbonosAprobados";
export const ENDPOINTFiltrarFechaAbonos = "/abonosOrdenes/filtrarPorFecha";
export const ENDPOINTFiltrarAbonosPorFechas = "/abonosOrdenes/filtrarAbonosFecha";
export const ENDPOINTAbonosPorRuta = "/abonosOrdenes/consultarPorRutas";
export const ENDPOINTListaridsRecibio = "/abonosOrdenes/listaridRecibio";
export const ENDPOINTListarPoridRecibio = "/abonosOrdenes/listarAbonosPorRecibio";
export const ENDPOINTListarAbonosPorCliente = "/abonosOrdenes/listarAbonosAprobadosPorCliente";
export const ENDPOINTListarAbonosPorCargo = "/abonosOrdenes/listarPorCargoOrden";

// Recursos Humanos
export const ENDPOINTListarRecursosHumanos = "/recursosHumanos/listar";
export const ENDPOINTListarDepartamentosRecursosHumanos = "/recursosHumanos/listarDepartamento";
export const ENDPOINTEventsRecursosHumanos = "/recursosHumanos/events";
export const ENDPOINTRegistrarRecursosHumanos = "/recursosHumanos/registro";
export const ENDPOINTObtenerRecursosHumanos = "/recursosHumanos/obtener";
export const ENDPOINTActualizarRecursosHumanos = "/recursosHumanos/actualizar";
export const ENDPOINTEliminarRecursosHumanos = "/recursosHumanos/eliminar";
export const ENDPOINTDeshabilitarRecursosHumanos = "/recursosHumanos/deshabilitar";
export const ENDPOINTObtenerRecursosHumanosByNombre = "/recursosHumanos/obtenerByNombre";

// vacaciones
export const ENDPOINTListarVacaciones = "/vacaciones/listar";
export const ENDPOINTEventsVacaciones = "/vacaciones/events";
export const ENDPOINTRegistrarVacaciones = "/vacaciones/registro";
export const ENDPOINTObtenerVacaciones = "/vacaciones/obtener";
export const ENDPOINTActualizarVacaciones = "/vacaciones/actualizar";
export const ENDPOINTEliminarVacaciones = "/vacaciones/eliminar";
export const ENDPOINTDeshabilitarVacaciones = "/vacaciones/deshabilitar";
export const ENDPOINTVacacionesProximas = "/vacaciones/listarVacacionesProximas";

// mantenimiento
export const ENDPOINTListarMantenimiento = "/mantenimiento/listar";
export const ENDPOINTEventsMantenimiento = "/mantenimiento/events";
export const ENDPOINTRegistrarMantenimiento = "/mantenimiento/registro";
export const ENDPOINTObtenerMantenimiento = "/mantenimiento/obtener";
export const ENDPOINTActualizarMantenimiento = "/mantenimiento/actualizar";
export const ENDPOINTEliminarMantenimiento = "/mantenimiento/eliminar";
export const ENDPOINTDeshabilitarMantenimiento = "/mantenimiento/deshabilitar";

// Maquinas
export const ENDPOINTListarMaquinas = "/maquinas/listar";;
export const ENDPOINTRegistrarMaquinas = "/maquinas/registro";
export const ENDPOINTObtenerMaquinas = "/maquinas/obtener";
export const ENDPOINTActualizarMaquinas = "/maquinas/actualizar";
export const ENDPOINTEliminarMaquinas = "/maquinas/eliminar";
export const ENDPOINTDeshabilitarMaquinas = "/maquinas/deshabilitar";

// Incidencias
export const ENDPOINTListarIncidencias = "/incidencias/listar";
export const ENDPOINTListarIncidenciasDepartamento = "/incidencias/listarDepartamento";
export const ENDPOINTRegistrarIncidencias = "/incidencias/registro";
export const ENDPOINTObtenerIncidencias = "/incidencias/obtener";
export const ENDPOINTActualizarIncidencias = "/incidencias/actualizar";
export const ENDPOINTEliminarIncidencias = "/incidencias/eliminar";
export const ENDPOINTDeshabilitarIncidencias = "/incidencias/deshabilitar";
export const ENDPOINTObtenerFolioIncidencias = "/incidencias/obtenerNoRegistro";
export const ENDPOINTIncidenciasTotalNA = "/incidencias/totalNoAtendidas";

// asistencia
export const ENDPOINTListarAsistencia = "/asistencia/listar";
export const ENDPOINTListarAsistenciaDia = "/asistencia/listarDia";
export const ENDPOINTListarAsistenciaSemana = "/asistencia/listarSemana";
export const ENDPOINTListarDepartamentosAsistencia = "/asistencia/listarDepartamento";
export const ENDPOINTRegistrarAsistencia = "/asistencia/registro";
export const ENDPOINTObtenerAsistencia = "/asistencia/obtener";
export const ENDPOINTActualizarAsistencia = "/asistencia/actualizar";
export const ENDPOINTEliminarAsistencia = "/asistencia/eliminar";
export const ENDPOINTDeshabilitarAsistencia = "/asistencia/deshabilitar";
export const ENDPOINTListarFechasAsistencia = "/asistencia/listarPorFechas";

// orden de compra
export const ENDPOINTListarOrdenCompra = "/ordenCompra/listar";
export const ENDPOINTRegistrarOrdenCompra = "/ordenCompra/registro";
export const ENDPOINTObtenerOrdenCompra = "/ordenCompra/obtener";
export const ENDPOINTActualizarOrdenCompra = "/ordenCompra/actualizar";
export const ENDPOINTEliminarOrdenCompra = "/ordenCompra/eliminar";
export const ENDPOINTDeshabilitarOrdenCompra = "/ordenCompra/deshabilitar";
export const ENDPOINTObtenerFolioOrdenCompra = "/ordenCompra/obtenerNoRegistro";
export const ENDPOINTListarOrdenesCompraRecibidas = "/ordenCompra/listarRecibidas";

// Saldos vencidos
export const ENDPOINTListarSaldosVencidos = "/saldosVencidos/listar";
export const ENDPOINTRegistrarSaldosVencidos = "/saldosVencidos/registro";
export const ENDPOINTObtenerSaldosVencidos = "/saldosVencidos/obtener";
export const ENDPOINTActualizarSaldosVencidos = "/saldosVencidos/actualizar";
export const ENDPOINTEliminarSaldosVencidos = "/saldosVencidos/eliminar";
export const ENDPOINTDeshabilitarSaldosVencidosa = "/saldosVencidos/deshabilitar";
export const ENDPOINTObtenerFolioSaldosVencidos = "/saldosVencidos/obtenerNoRegistro";

// Logs generales
export const ENDPOINTRegistroLogs = "/logs/registro";
export const ENDPOINTListarLogs = "/logs/listar";
export const ENDPOINTObtenerNoLogs = "/logs/obtenerNoLog";
export const ENDPOINTObtenerLogs = "/logs/obtener";
export const ENDPOINTEliminarLogs = "/logs/eliminar";
export const ENDPOINTActualizarLogs = "/logs/actualizar";
export const ENDPOINTListarPaginandoLogs = "/logs/listarPaginando";
export const ENDPOINTTotalLogs = "/logs/total";

// Caja Chica
export const ENDPOINTListarCajaChica = "/cajaChica/listar";
export const ENDPOINTRegistrarCajaChica = "/cajaChica/registro";
export const ENDPOINTObtenerCajaChica = "/cajaChica/obtener";
export const ENDPOINTActualizarCajaChica = "/cajaChica/actualizar";
export const ENDPOINTEliminarCajaChica = "/cajaChica/eliminar";
export const ENDPOINTDeshabilitarCajaChica = "/cajaChica/deshabilitar";

// Sueldos generales
export const ENDPOINTListarSueldosGenerales = "/sueldosGenerales/listar";
export const ENDPOINTRegistrarSueldosGenerales = "/sueldosGenerales/registro";
export const ENDPOINTObtenerSueldosGenerales = "/sueldosGenerales/obtener";
export const ENDPOINTActualizarSueldosGenerales = "/sueldosGenerales/actualizar";
export const ENDPOINTEliminarSueldosGenerales = "/sueldosGenerales/eliminar";
export const ENDPOINTDeshabilitarSueldosGenerales = "/sueldosGenerales/deshabilitar";
export const ENDPOINTListarPorFechasSueldosGenerales = "/sueldosGenerales/listarDescPorFechas";

// Cursos
export const ENDPOINTListarCursos = "/cursos/listar";
export const ENDPOINTRegistrarCursos = "/cursos/registro";
export const ENDPOINTObtenerCursos = "/cursos/obtener";
export const ENDPOINTActualizarCursos = "/cursos/actualizar";
export const ENDPOINTEliminarCursos = "/cursos/eliminar";
export const ENDPOINTDeshabilitarCursos = "/cursos/deshabilitar";

// capacitaciones de empleados
export const ENDPOINTListarCapacitacionesEmpleados = "/capacitacionesEmpleados/listar";
export const ENDPOINTListarAgrupamientoCapacitacionesEmpleados = "/capacitacionesEmpleados/listarAgrupado";
export const ENDPOINTRegistrarCapacitacionesEmpleados = "/capacitacionesEmpleados/registro";
export const ENDPOINTObtenerCapacitacionesEmpleados = "/capacitacionesEmpleados/obtener";
export const ENDPOINTActualizarCapacitacionesEmpleados = "/capacitacionesEmpleados/actualizar";
export const ENDPOINTEliminarCapacitacionesEmpleados = "/capacitacionesEmpleados/eliminar";
export const ENDPOINTDeshabilitarCapacitacionesEmpleados = "/capacitacionesEmpleados/deshabilitar";

// recepcion de compra
export const ENDPOINTListarRecepcionCompra = "/recepcionCompra/listar";
export const ENDPOINTRegistrarRecepcionCompra = "/recepcionCompra/registro";
export const ENDPOINTObtenerRecepcionCompra = "/recepcionCompra/obtener";
export const ENDPOINTActualizarRecepcionCompra = "/recepcionCompra/actualizar";
export const ENDPOINTEliminarRecepcionCompra = "/recepcionCompra/eliminar";
export const ENDPOINTDeshabilitarRecepcionCompra = "/recepcionCompra/deshabilitar";
export const ENDPOINTObtenerFolioRecepcionCompra = "/recepcionCompra/obtenerNoRegistro";

// materiales catalogos
export const ENDPOINTListarMaterialesCatalogos = "/materialesCatalogo/listar";
export const ENDPOINTRegistrarMaterialesCatalogos = "/materialesCatalogo/registro";
export const ENDPOINTEliminarMaterialesCatalogos = "/materialesCatalogo/eliminar";
export const ENDPOINTListarPorDepMaterialesCatalogos = "/materialesCatalogo/obtenerMaterialesPorDepartamento";
export const ENDPOINTObtenerFolioMaterialesCatalogos = "/materialesCatalogo/obtenerNoRegistro";

// Comedor
export const ENDPOINTListarComedor = "/comedor/listar";
export const ENDPOINTRegistrarComedor = "/comedor/registro";
export const ENDPOINTObtenerComedor = "/comedor/obtener";
export const ENDPOINTActualizarComedor = "/comedor/actualizar";
export const ENDPOINTEliminarComedor = "/comedor/eliminar";
export const ENDPOINTDeshabilitarComedor = "/comedor/deshabilitar";

//Permisos
export const ENDPOINTListarPermisos = "/permisos/listar";
export const ENDPOINTConsultarPermisos = "/permisos/listarActivosUsuarios";
export const ENDPOINTCambiarEstadoPermisos = "/permisos/deshabilitar";

// Mesas
export const ENDPOINTRegistroMesas = "/mesas/registro";
export const ENDPOINTObtenerMesas = "/mesas/listar";
export const ENDPOINTOBtenerMesa = "/mesas/obtener";
export const ENDPOINTOcuparDesocuparMesas = "/mesas/ocuparDesocupar";
export const ENDPOINTDeshabilitarMesas = "/mesas/mesasActualizarEstados";
export const ENDPOINTEditarMesa = "/mesas/actualizar";
export const ENDPOINTEditarLugarMesa = "/mesas/actualizarSilla";
export const ENDPOINTEliminarMesa = "/mesas/eliminar";

// Productos
export const ENDPOINTListarProductos = "/productos/listar";
export const ENDPOINTRegistrarProductos = "/productos/registro";
export const ENDPOINTObtenerProductos = "/productos/obtener";
export const ENDPOINTActualizarProductos = "/productos/actualizar";
export const ENDPOINTEliminarProductos = "/productos/eliminar";
export const ENDPOINTDeshabilitarProductos = "/productos/deshabilitar";

// Unidad de medida
export const ENDPOINTListarUnidadMedidas = "/unidadMedida/listar";
export const ENDPOINTRegistrarUnidadMedidas = "/unidadMedida/registro";
export const ENDPOINTObtenerUnidadMedidas = "/unidadMedida/obtener";
export const ENDPOINTActualizarUnidadMedidas = "/unidadMedida/actualizar";
export const ENDPOINTEliminarUnidadMedidas = "/unidadMedida/eliminar";
export const ENDPOINTDeshabilitarUnidadMedidas = "/unidadMedida/deshabilitar";

// Unidad
export const ENDPOINTListarUnidad = "/unidad/listar";
export const ENDPOINTRegistrarUnidad = "/unidad/registro";
export const ENDPOINTObtenerUnidad = "/unidad/obtener";
export const ENDPOINTActualizarUnidad = "/unidad/actualizar";
export const ENDPOINTEliminarUnidad = "/unidad/eliminar";
export const ENDPOINTDeshabilitarUnidad = "/unidad/deshabilitar";

// Conversion de moneda
export const ENDPOINTObtenerTasaCambio = "/latest/USD";

// facturas
export const ENDPOINTFacturar = "/facturas/timbrar";
export const ENDPOINTConsultarArchivos = "/facturas/consultarArchivos";