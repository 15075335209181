import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faPlus,
  faBars,
  faClock,
  faIndustry,
  faFolder,
  faTable,
  faFileContract,
  faStop,
  faSquareCheck,
} from "@fortawesome/free-solid-svg-icons";
import { listarRecursosHumanos, listarDepartamentosRecursosHumanos } from "../../api/recursosHumanos";
import { Button, Dropdown, Badge, Image } from "react-bootstrap";
import { withRouter } from "../../utils/withRouter";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { Load } from "../load/load";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import EliminarEmpleado from "./EliminarEmpleado";
import RegistroEmpleados from "./RegistroEmpleados";
import RegistrarAsistencia from "./RegistroAsistencia";
import RegistroCapacitacion from "./RegistroCapacitacion";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import { toast } from "react-toastify";
import RegistroFaltasTecnicas from "./RegistroFaltasTecnicas";
import { TablaFaltasTecnicas } from "./tablaFaltasTecnicas";
import RenovarContrato from "./RenovarContrato";
import BajaEmpleado from "./BajaEmpleados";
import { FeaturedPlayListOutlined } from "@mui/icons-material";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

function TblRecursosHumanos(props) {
  const { location, history } = props;

  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const [loading, setLoading] = useState(true);

  const [tipoUsuario, setTipoUsuario] = useState("");
  const [departamentoUsuario, setDepartamentoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setTipoUsuario(data.tipo);
          setDepartamentoUsuario(data.departamento);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [listarOrdenes, setListOrdenes] = useState([]);

  const obtenerOrdenesServicio = () => {
    try {
      if (tipoUsuario == "administrador") {
        listarRecursosHumanos()
          .then((response) => {
            const { data } = response;

            if (!listarOrdenes && data) {
              setListOrdenes(formatModelOrdenes(data));
            } else {
              const datosOrdenes = formatModelOrdenes(data);
              setListOrdenes(datosOrdenes);
            }
          })
          .catch((e) => { });
      } else if (tipoUsuario == "departamento") {
        listarDepartamentosRecursosHumanos(departamentoUsuario)
          .then((response) => {
            const { data } = response;

            if (!listarOrdenes && data) {
              setListOrdenes(formatModelOrdenes(data));
            } else {
              const datosOrdenes = formatModelOrdenes(data);
              setListOrdenes(datosOrdenes);
            }
          })
          .catch((e) => { });
      }
    } catch (e) { }
  };

  useEffect(() => {
    obtenerOrdenesServicio();
  }, [location, departamentoUsuario]);

  // Configurando animacion de carga
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const cargarDatos = () => {
    const timeout = setTimeout(() => {
      setRows(listarOrdenes);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  //Para el modal
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const registroUsuarios = (content) => {
    setTitulosModal("Registar falta administrativa");
    setContentModal(content);
    setShowModal(true);
  };

  const faltasTecnicas = (content) => {
    setTitulosModal("Listado de faltas administrativas");
    setContentModal(content);
    setShowModal(true);
  };

  const registroAsistencia = (content) => {
    setTitulosModal("Registar asistencia");
    setContentModal(content);
    setShowModal(true);
  };

  const modificarUsuarios = (content) => {
    setTitulosModal("Modificar usuario");
    setContentModal(content);
    setShowModal(true);
  };

  const eliminarUsuarios = (content) => {
    setTitulosModal("Eliminar empleado");
    setContentModal(content);
    setShowModal(true);
  };

  const registrarCapacitacion = (content) => {
    setTitulosModal("Registrar capacitacion");
    setContentModal(content);
    setShowModal(true);
  };

  const renovarContrato = (content) => {
    setTitulosModal("Renovar contrato");
    setContentModal(content);
    setShowModal(true);
  };

  const bajaEmpleado = (content) => {
    setTitulosModal("Baja de empleado");
    setContentModal(content);
    setShowModal(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "nombreCompleto",
      label: "NOMBRE",
    },
    {
      name: "fechaNacimiento",
      label: "FECHA DE NACIMIENTO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "direccion",
      label: "DIRECCION",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "telefono",
      label: "TELEFONO",
    },
    {
      name: "email",
      label: "CORREO ELECTRONICO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "estadoCivil",
      label: "ESTADO CIVIL",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "cargo",
      label: "PUESTO",
    },
    {
      name: "fechaContratacion",
      label: "FECHA DE CONTRATACION",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
            {dayjs(value.split(" ")[0]).format("LL")}
            </>
          );
        },
      },
    },
    {
      name: "fechaTermino",
      label: "FECHA DE TERMINO",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
            {dayjs(value.split(" ")[0]).format("LL")}
            </>
          );
        },
      },
    },
    {
      name: "salario",
      label: "SALARIO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "formaPago",
      label: "FORMA DE PAGO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "horarioTrabajo",
      label: "HORARIO DE TRABAJO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "diasDescanso",
      label: "DIAS DE DESCANSO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "vacaciones",
      label: "VACACIONES",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "contactoEmergencia",
      label: "CONTACTO DE EMERGENCIA",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "seguroMedico",
      label: "SEGURO MEDICO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "nivelEducacion",
      label: "NIVEL DE EDUCACION",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "foto",
      label: "FOTO DEL EMPLEADO",
      options: {
        customBodyRender: (value) => {
          const imagen = value;

          return imagen != "Sin Imagen" || !imagen ? (
            <Image
              src={imagen}
              alt={`Imagen de ${imagen}`}
              style={{ maxWidth: "100px" }}
            />
          ) : (
            <p>No hay imagen del empleado</p>
          );
        },
      },
    },
    {
      name: "estado",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          const estado = value;

          let estiloTexto = "";
          let estadoTexto = "";

          if (estado == "true") {
            estiloTexto = "Activo";
            estadoTexto = "Activo";
          } else {
            estiloTexto = "Inhabilitado";
            estadoTexto = "Inhabilitado";
          }

          return <>
            <Badge
              bg={estado == "true" ? "success" : "danger"}
            //className="estado"
            >
              {estiloTexto}
            </Badge>
          </>;
        },
      },
    },
    {
      name: "rfc",
      label: "RFC",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "curp",
      label: "CURP",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "regimenFiscal",
      label: "RÉGIMEN FISCAL",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "departamento",
      label: "DEPARTAMENTO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "fechaTermino",
      label: "FECHA DE TERMINO",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "diasDesdeContratacion",
      label: "DIAS DESDE CONTRATACION",
    },
    {
      name: "faltasAdministrativas",
      label: "DIAS DESDE CONTRATACION",
      options: {
        display: "excluded", // "excluded" significa oculto por defecto
      },
    },
    {
      name: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tipoUsuario == "administrador" && tableMeta.rowData[19] == "true" ? (
            <>
              <Dropdown>
                <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                  <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      bajaEmpleado(
                        <BajaEmpleado
                          history={history}
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faStop}
                      style={{ color: "#FF0000" }}
                    />
                    &nbsp; Baja del empleado
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      renovarContrato(
                        <RenovarContrato
                          history={history}
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faFileContract}
                      style={{ color: "#FFFF00" }}
                    />
                    &nbsp; Renovar contrato
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      registroUsuarios(
                        <RegistroFaltasTecnicas
                          history={history}
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faFolder}
                      style={{ color: "#87CEEB" }}
                    />
                    &nbsp; Registrar falta administrativa
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      faltasTecnicas(
                        <TablaFaltasTecnicas
                          history={history}
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faTable}
                      style={{ color: "#ff8000" }}
                    />
                    &nbsp; Lista de faltas administrativas
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      registrarCapacitacion(
                        <RegistroCapacitacion
                          history={history}
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faIndustry}
                      style={{ color: "#000080" }}
                    />
                    &nbsp; Registrar capacitacion
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      modificarUsuarios(
                        <RegistroEmpleados
                          history={history}
                          setShow={setShowModal}
                          data={tableMeta.rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faPen}
                      style={{ color: "#ffc107" }}
                    />
                    &nbsp; Modificar
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            <>
              {tableMeta.rowData[19] == "true" ? (
                <Dropdown>
                  <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                    <FontAwesomeIcon icon={faBars} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        registroAsistencia(
                          <RegistrarAsistencia
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faClock}
                        style={{ color: "#0000ff" }}
                      />
                      &nbsp; Registrar asistencia
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown >
              ) : (
                <Dropdown>
                  <Dropdown.Toggle className="botonDropdown" id="dropdown-basic">
                    <FontAwesomeIcon icon={faBars} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        bajaEmpleado(
                          <BajaEmpleado
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faSquareCheck}
                        style={{ color: "#FF0000" }}
                      />
                      &nbsp; Habilitar empleado
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        faltasTecnicas(
                          <TablaFaltasTecnicas
                            history={history}
                            setShow={setShowModal}
                            data={tableMeta.rowData}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faTable}
                        style={{ color: "#ff8000" }}
                      />
                      &nbsp; Lista de faltas administrativas
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown >
              )}
            </>);
        },
      },
    },
  ];

  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));

  const options = {
    filterType: "checkbox",
    responsive: isSmOrDown && 'standard',
    selectableRows: isSmOrDown ? 'none' : 'multiple',
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTAURAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <>
      {loading && <Load />}
      <h1>Empleados</h1>
      <div className="divTabla">
        <MUIDataTable
          title={"Listado de empleados registrados"}
          data={listarOrdenes}
          columns={columns}
          options={options}
        />
      </div>
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    const fechaActual = new Date().toISOString().split('T')[0]; // Get current date in yyyy-mm-dd format
    const fechaContratacion = new Date(data.fechaContratacion);
    const diferenciaDias = Math.floor((new Date(fechaActual) - fechaContratacion) / (1000 * 60 * 60 * 24));

    dataTemp.push({
      id: data._id,
      nombreCompleto: data.nombreCompleto,
      fechaNacimiento: data.fechaNacimiento,
      direccion: data.direccion,
      telefono: data.telefono,
      email: data.email,
      estadoCivil: data.estadoCivil,
      cargo: data.cargo,
      fechaContratacion: data.fechaContratacion,
      fechaTermino: data.fechaTermino,
      salario: data.salario,
      formaPago: data.formaPago,
      horarioTrabajo: data.horarioLaboral.horaEntrada + "/" + data.horarioLaboral.horaSalida,
      diasDescanso: data.diasDescanso,
      vacaciones: data.vacaciones,
      contactoEmergencia: data.contactoEmergencia,
      seguroMedico: data.seguroMedico,
      nivelEducacion: data.nivelEducacion,
      foto: data.foto,
      estado: data.estado,
      curp: data.curp,
      rfc: data.rfc,
      regimenFiscal: data.regimenFiscal,
      diasDesdeContratacion: diferenciaDias,
      faltasAdministrativas: data.faltasAdministrativas
    });
  });
  return dataTemp;
}

export default withRouter(TblRecursosHumanos);
